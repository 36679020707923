import * as React from 'react';
import { css, cx } from 'emotion';
import { copyText } from '../../utils/copy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';

const styles = {
    token: css`
        background: #6c757d;
        display: flex;
        border-radius: 3px;
        position: relative;
        cursor: pointer;
    `,
    tokenContent: css`
        padding: 0 6px;
        background: transparent;
        border: none;
        color: #fff;
        height: 25px;
        line-height: 25px;
        font-family: monospace;
        font-size: 10px;
        flex: 1;
    `,
    copyIcon: css`
        display: block;
        color: #fff;
        background: #666;
        font-size: 12px;
        height: 25px;
        flex-basis: 25px;
        line-height: 25px;
        text-align: center;
        border-radius: 3px;
        width: 25px;
    `,
};

interface ComponentProps {
    token: string;
    className?: string;
}

/**************** Component ***************/
export class Token extends React.Component<ComponentProps> {
    public render() {
        return (
            <span className={cx(styles.token, this.props.className)}>
                <input readOnly className={styles.tokenContent} value={this.props.token} onClick={this.onInputClick}/>
                <span className={styles.copyIcon} onClick={copyText.bind(this, this.props.token)}>
                    <FontAwesomeIcon icon={faClipboard} />
                </span>
            </span>
        );
    }

    protected onInputClick = (e) => {
        e.target.focus();
        e.target.select();
    }
}
