import * as React from 'react';
import { Modal } from './Modal';
import * as QRCode from 'qrcode-react';
import { css } from 'emotion';
import FormPinInput from '../ux/forms/FormPinInput';
import PinInput from 'react-pin-input';

const styles = {
    header: css`
        text-align: center;
    `,
    text: css`
        text-align: center;
        margin-bottom: 10px;
    `,
    qrcode: css`
        display: flex;
        justify-content: center;
        padding: 10px;
        margin-bottom: 10px;
    `,
    token: css``,
    modal: css`
        &.small{
            min-width: 320px;
            max-width: 400px;
        }
    `,
};

export interface ModalOtpProps {
    active: boolean;
    onClose?: () => void;
    qrcode?: string;
    title: string;
    text?: string;
    confirmText: string;
    name?: string;
}

/**************** Component ***************/
export class ModalOtp extends React.Component<ModalOtpProps> {
    public render() {
        return (
            <Modal
                active={this.props.active}
                className={styles.modal}
                size={'small'}
                buttons={[{
                    text: this.props.confirmText,
                    color: 'green',
                    isSubmit: true,
                }, (this.props.onClose ? {
                    text: 'Cancel',
                    color: 'gray',
                    action: this.props.onClose
                } : null)]}
            >
                <h1 className={styles.header}>{this.props.title}</h1>
                {this.props.text ? <p className={styles.text}>{this.props.text}</p> : null}
                {this.props.qrcode ? (<div className={styles.qrcode}>
                    <QRCode size={200} value={this.props.qrcode} />
                </div>) : null}
                <div className={styles.token}>
                    <FormPinInput focusOnMount resetOnUnmount submitOnEnter length={6} name={this.props.name ? this.props.name : 'otpToken'} />
                </div>
            </Modal>
        );
    }
}

