import * as React from 'react';
import { setByExpression, isFunction, getByExpression, safe, deleteByExpression } from '../../../utils';
import { SaveEventHandler } from '../../utils/SaveEventHandler';


export interface FormData { [name: string]: any; }

/**
 * Form context definition
 */
const FormContext = React.createContext({
    formValueRemove: null,
    formValueChange: null,
    formSubmit: null,
    formGetValue: null,
    formErrorHandler: null,
    formError: null,
    formData: null,
    formValidate: null,
});

/**
 * HOC for wrap component by consumer of form data
 * @param WrappedComponent
 */
export function withFormData<T>(WrappedComponent: React.ComponentType<T>) {
    return (propsOrig: T) => (
        <FormContext.Consumer>
            {(props) => (
                <WrappedComponent {...propsOrig} {...props} />
            )}
        </FormContext.Consumer>
    );
}

/**************** Interfaces ***************/
export interface FormProvidedProps extends React.Props<any> {
    formValueRemove: (name: string, internal?: boolean) => void;
    formValueChange: (name: string, value: any, internal?: boolean) => void;
    formSubmit: (action?: string) => void;
    formGetValue: (name: string) => any;
    formErrorHandler: (name?: string) => string;
    formValidate: (caller?: string) => void;
    formError: any;
    formData: {[key: string]: any};
}

/**************** Component props ***************/
interface ComponentProps {
    action?: () => Promise<any>;
    altActions?: {[name: string]: () => Promise<any>}
    validate?: (caller?: string) => Promise<any>;

    // form data
    onChange: (data: FormData, name?: string, internal?: boolean) => void;
    onError: (error: any) => void;
    value: FormData;
    error: any;

    // handlers
    errorHandler?: (name?: string) => string;

    // save on cmd/ctrl + s
    saveOnNativeEvent?: boolean;
}

/**************** Component ***************/
/**
 * Form provider component
 */
export class FormProvider extends React.Component<ComponentProps> {
    /**
     * Render childs
     */
    public render() {
        const contextValue = {
            formValueRemove: this.valueRemove,
            formValueChange: this.valueChange,
            formSubmit: this.submit,
            formGetValue: this.getValue,
            formErrorHandler: this.errorHandler,
            formError: this.props.error,
            formData: this.props.value,
            formValidate: this.validate,
        };
        return (
            <FormContext.Provider value={contextValue}>
                {this.props.children}
            </FormContext.Provider>
        );
    }

    public componentDidMount() {
        if (this.props.saveOnNativeEvent) {
            SaveEventHandler.addHandler(this.nativeSubmitHandler);
        }
    }

    public componentWillUnmount() {
        SaveEventHandler.removeHandler(this.nativeSubmitHandler);
    }

    /**
     * Value change handler
     */
    protected valueRemove = async (name: string, internal?: boolean) => {
        setImmediate(() => {
            const formData = {...this.props.value};
            deleteByExpression(formData, name);
            this.props.onChange(formData, name, internal);
        });
    };

    /**
     * Value change handler
     */
    protected valueChange = async (name: string, value: any, internal?: boolean) => {
        const formData = {...this.props.value};
        setByExpression(formData, name, value);
        this.props.onChange(formData, name, internal);
    };

    /**
     * Form submit handler
     */
    protected submit = async (altAction?: string) => {
        if (altAction && this.props.altActions && this.props.altActions[altAction]) {
            if (await this.validate()) {
                await this.props.altActions[altAction]();
            }
            return;
        }

        if (!this.props.action) {
            return null;
        }

        if (await this.validate()) {
            await this.props.action();
        }
    };

    /**
     * Validate this form
     */
    protected validate = async (caller?: string) => {
        if (this.props.validate) {
            try {
                await this.props.validate(caller);
                this.onError(null);
                return true;
            } catch (e) {
                this.onError(e);
                return false;
            }
        }

        this.props.onError(null);
        return true;
    };

    /**
     * Send error
     * @param error
     */
    protected onError(error: any) {
        if (this.props.onError) {
            this.props.onError(error);
        }
    }

    /**
     * Handle error
     */
    protected errorHandler = (name?: string): string => {
        if (!isFunction(this.props.errorHandler)) {
            return null;
        }
        return this.props.errorHandler(name);
    };

    /**
     * Get fieldValue
     */
    protected getValue = (name: string): any => {
        return getByExpression(this.props.value, name);
    };

    /**
     * On window key down
     * @param e
     */
    protected nativeSubmitHandler = () => {
        this.submit();
    }
}
