export interface MacroScriptInput {
    namespace: string;
    input: {[name: string]: any};
}

export type MacroScriptActionArgs = {[name: string]: any} | any[];

export interface MacroScriptAction {
    name: string;
    args: MacroScriptActionArgs;
    index: number;
}

export interface MacroScriptActionItem {
    namespace?: string;
    action: MacroScriptAction;
}

export interface LineDefinition {
  content: string;
  index: number;
}

export class MacroScript {
  static namespaceActionReg = /^\$([A-Za-z0-9_]*)\s=\s(.*)$/;
  static inputReg = /^\$([A-Za-z0-9_]*)\s=\s@input\((.*)\)$/;

  static stringArgReg = /^\"([^\"]*)\"$/;
  static numberArgReg = /^[+-]?([0-9]*[.])?[0-9]+$/;

  public readonly inputs: MacroScriptInput[] = [];
  public readonly actions: MacroScriptActionItem[]  = [];

  constructor(public readonly script: string) {
    this.parse();
  }

  protected parse() {
    if (!this.script) {
      throw new Error('Script should be a string.')
    }
    const lines: LineDefinition[] = this.script
      .split('\n')
      .map((content, index) => ({content, index}))
      .filter((i) => !!i.content);

    for (const line of lines) {
      // is input
      const inputMatch = line.content.match(MacroScript.inputReg);
      if (inputMatch) {
        const namespace = inputMatch[1];
        const inputArgs = MacroScript.parseArgs({content: inputMatch[2], index: line.index});
        if (Array.isArray(inputArgs)) {
          throw new Error(`Input props on line ${line.index + 1} can not be array.`)
        }
        const input = Object.keys(inputArgs).reduce((acc, value) => ({
          [value]: MacroScript.cleanupArgs({content: inputArgs[value], index: line.index}),
          ...acc,
        }), {});
        this.inputs.push({namespace, input});
        continue;
      }

      // has namespace
      const withNamespaceMatch = line.content.match(MacroScript.namespaceActionReg);
      if (withNamespaceMatch) {
        const namespace = withNamespaceMatch[1];
        const action = MacroScript.parseAction({content: withNamespaceMatch[2], index: line.index});
        this.actions.push({namespace, action});
        continue;
      }

      this.actions.push({action: MacroScript.parseAction(line)})
    }
  }

  protected static parseArgs(source: LineDefinition): MacroScriptActionArgs {
    const argsRaw = source.content;
    if (!argsRaw.match(/^((([A-Za-z0-9]*):\s?(\$([A-Za-z0-9_]*)|(\"([^\"]*)\")|([+-]?([0-9]*))|true|false)\s?\,?\s?)*)$/gm)) {
      return argsRaw.split(',').map((i) => i.trim());
    }

    const regObj = /([A-Za-z0-9]*):\s?(\$([A-Za-z0-9_]*)|(\"([^\"]*)\")|([+-]?([0-9]*))|true|false)\s?/gm;
    const args = {};
    let result;
    while(result = regObj.exec(argsRaw)) {
      args[result[1]] = result[2];
    }
    return args;
  }

  protected static parseAction(source: LineDefinition): MacroScriptAction {
    const actionParsed = /([^\(\)]*)\(([^\(\)]*)\)/.exec(source.content);
    const name = actionParsed[1];
    const args = MacroScript.parseArgs({content: actionParsed[2], index: source.index});
    return {
      name,
      args,
      index: source.index,
    };
  }

  protected static cleanupArgs(source: LineDefinition) {
    const argRaw = source.content;
    if (argRaw === 'true' || argRaw === 'false') {
      return JSON.parse(argRaw);
    }

    const stringArgMatch = argRaw.match(MacroScript.stringArgReg);
    if (stringArgMatch) {
      return stringArgMatch[1];
    }

    const numberArgMatch = argRaw.match(MacroScript.numberArgReg);
    if (numberArgMatch) {
      return parseFloat(numberArgMatch[0]);
    }

    throw new Error(`Can't parse arguments on line ${source.index + 1}.`)
  }
}
