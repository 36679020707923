import * as React from 'react';
import { css } from 'emotion';
import { SectionHeader } from '../components/ux/SectionHeader';
import { connect } from 'react-redux';
import { ApiInitialState, ApiActions, ApiCallOptions } from '../state/api';
import { IRootStore } from '../state';
import { EntityListView } from '../components/content/EntityListView';
import { EntityListFilter, EntityIndex } from '../api/Endpoints';
import { UserEntity, GetEntity } from '../api/structure';
import { AppActions, NotificationType } from '../state/app';
import { routes } from './Router';
import { faTrash, faPencilAlt, faCheck, faTimesCircle, faPlus, faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import { Badge } from '../components/ux/Badge';
import { JsonValidatorRequired, JsonValidatorType, promisify, safePromise } from '../utils';
import { Modal } from '../components/modals/Modal';
import { Form } from '../components/ux/forms/Form';
import { FormInput } from '../components/ux/forms/FormInput';
import { RegExps } from '../api/regexps';
import { FormSelect } from '../components/ux/forms/FormSelect';
import { EntityEditor } from '../components/content/EntityEditor';
import { waitFor } from '../utils/waitFor';

const style = {
    inviteHeader: css`
        font-size: 20px;
        margin-bottom: 10px;
    `,
    inviteText: css`
        font-size: 14px;
        margin-bottom: 10px;
    `,
    inputWrap: css`
        margin-bottom: 15px;
    `,
};

const UsersList = connect(
    (state: {api: ApiInitialState}) => ({
        data: state.api.users,
    }),
    (dispatch) => ({
        loadData: (opt: ApiCallOptions, filter: EntityListFilter<GetEntity<UserEntity>>) => dispatch(ApiActions.listUsers(opt, filter)),
    }),
)(EntityListView);

/**
 * Page
 */
class UsersComponent extends React.Component<{
    clientIndex: EntityIndex;
    redirectTo: (to: string) => void;
    deleteUser: (opt: ApiCallOptions, id: string) => Promise<void>;
    postUserResetPassword: (opt: ApiCallOptions, id: string) => Promise<void>;
    activateUser: (opt: ApiCallOptions, id: string) => Promise<void>;
    deactivateUser: (opt: ApiCallOptions, id: string) => Promise<void>;
    getClientsIndex: (opt: ApiCallOptions) => Promise<EntityIndex>;
    pushNotification: (text: string, type: NotificationType) => void;
    inviteSelfcareUser: (opt: ApiCallOptions, email: string, clientId: string) => Promise<void>;
}, {
    inviteOpened: boolean;
}> {
    constructor(props) {
        super(props);
        this.state = {
            inviteOpened: false,
        };
    }

    public componentDidMount() {
        safePromise(this.props.getClientsIndex({}));
    }

    public render() {
        return (
            <>
                <SectionHeader>Users</SectionHeader>
                <UsersList
                    columns={[
                        {
                            text: 'Username',
                            name: 'username',
                            sortable: true,
                            filterType: 'full',
                            size: 2,
                            defaultSort: 1,
                            filterPlaceholder: 'Filter by username',
                        },
                        {
                            text: 'Type',
                            name: 'type',
                            size: 1,
                            filterType: 'enum',
                            filterOptions: {
                                'admin': 'Admin',
                                'selfcare': 'Selfcare',
                            },
                            filterPlaceholder: 'User type'
                        },
                        {
                            sortable: true,
                            text: 'Created on',
                            name: 'createdOn',
                            size: 1,
                        },
                        {
                            text: 'Clients',
                            name: 'clientsId',
                            size: 1,
                        },
                        {
                            text: 'Activated',
                            name: 'activated',
                            size: 0.5,
                        },
                        {
                            text: 'OTP',
                            name: 'otpActivated',
                            size: 0.5,
                        }
                    ]}
                    actions={[{
                        name: 'Reset password',
                        color: 'yellow',
                        action: this.onPasswordReset,
                        confirm: 'Do you realy want to reset password for this User?',
                        reloadAfter: true,
                        icon: faLock,
                    }, {
                        name: 'Activate',
                        color: 'green',
                        action: this.onUserActivate,
                        icon: faCheck,
                        condition: (i: UserEntity) => !i.activated,
                        reloadAfter: true,
                    }, {
                        name: 'Deactivate',
                        color: 'red',
                        action: this.onUserDeactivate,
                        icon: faTimesCircle,
                        confirm: 'Do you realy want to deactivate this user?',
                        condition: (i: UserEntity) => i.activated,
                        reloadAfter: true,
                    }, {
                        name: 'Edit',
                        color: 'green',
                        action: this.onEdit,
                        icon: faPencilAlt,
                    }, {
                        name: 'Remove',
                        color: 'red',
                        action: this.onRemove,
                        confirm: 'Do you realy want to remove this User?',
                        reloadAfter: true,
                        icon: faTrash,
                    }]}
                    renderer={{
                        createdOn: (value: any) => <span>{new Date(value.createdOn).toLocaleDateString()}</span>,
                        clientsId: (value: any) => <span>{value.clientsId && this.props.clientIndex ? value.clientsId.map(c => this.props.clientIndex[c]).join(', ') : ''}</span>,
                        activated: (value: any) => <Badge color={value.activated ? 'green' : 'red'}>{value.activated ? 'Active' : 'Inactive'}</Badge>,
                        otpActivated: (value: any) => <Badge color={value.otpActivated ? 'green' : 'red'}>{value.otpActivated ? 'Active' : 'Inactive'}</Badge>
                    }}
                    headerActions={[{
                        text: 'Invite user',
                        icon: faEnvelope,
                        color: 'yellow',
                        action: this.onInvite,
                        reloadAfter: true,
                    }, {
                        text: 'Add user',
                        icon: faPlus,
                        color: 'green',
                        action: this.onCreateNew,
                    }]}
                    mainList
                />

                <Form
                    action={this.onInviteConfirm}
                    schema={{
                        email: {
                            required: JsonValidatorRequired.True,
                            type: JsonValidatorType.String,
                            regexp: RegExps.email,
                        },
                        clientId: {
                            required: JsonValidatorRequired.True,
                            type: JsonValidatorType.String,
                            regexp: RegExps.entityId,
                        },
                    }}
                >
                    <Modal
                        active={this.state.inviteOpened}
                        size={'xsmall'}
                        buttons={[{
                            text: 'Close',
                            color: 'gray',
                            action: this.onInviteClose
                        },
                        {
                            text: 'Send',
                            color: 'green',
                            isSubmit: true,
                        }]}
                    >
                        <p className={style.inviteHeader}>Invite user by email</p>
                        <p className={style.inviteText}>Password will be generated automaticaly and will be send to email with invitation.</p>
                        <div className={style.inputWrap}>
                            <FormInput type="text" name="email" label="Email" resetOnUnmount/>
                        </div>
                        <div className={style.inputWrap}>
                            <FormSelect name="clientId" label="Client" options={this.props.clientIndex} resetOnUnmount/>
                        </div>
                    </Modal>
                </Form>
            </>
        );
    }

    protected onUserActivate = async (i: GetEntity<UserEntity>) => {
        return this.props.activateUser({}, i._id)
            .catch((err) => {
                this.props.pushNotification(err.message, 'red');
            });
    }

    protected onUserDeactivate = async (i: GetEntity<UserEntity>) => {
        return this.props.deactivateUser({}, i._id)
            .catch((err) => {
                this.props.pushNotification(err.message, 'red');
            });
    }

    protected onEdit = async (i: GetEntity<UserEntity>) => {
        this.props.redirectTo(routes.users.edit.path.replace(':id', i._id));
    }

    protected onRemove = async (i: GetEntity<UserEntity>) => {
        return this.props.deleteUser({}, i._id)
            .catch((err) => {
                this.props.pushNotification(err.message, 'red');
            });
    }

    protected onPasswordReset = async (i: GetEntity<UserEntity>) => {
        return this.props.postUserResetPassword({}, i._id)
            .catch((err) => {
                this.props.pushNotification(err.message, 'red');
            });
    }

    protected onCreateNew = () => {
        this.props.redirectTo(routes.users.edit.path.replace(':id', 'create'));
    }

    protected onInvite = async () => {
        return new Promise((resolve: () => void, reject: () => void) => {
            this.setState({inviteOpened: true}, () => {
                waitFor(() => !this.state.inviteOpened)
                    .then(resolve);
            });
        });
    }

    protected onInviteClose = () => {
        this.setState({inviteOpened: false});
    }

    protected onInviteConfirm = async (data) => {
        await this.props.inviteSelfcareUser({}, data.email, data.clientId);
        this.setState({inviteOpened: false});
    }

}

export const Users = connect(
    (state: IRootStore) => ({
        clientIndex: state.api.clientIndex,
    }),
    (dispatch) => ({
        redirectTo: (to: string) => dispatch(AppActions.redirectTo(to)),
        deleteUser: (opt: ApiCallOptions, id: string) => dispatch(ApiActions.deleteUser(opt, id)),
        postUserResetPassword: (opt: ApiCallOptions, id: string) => dispatch(ApiActions.postUserResetPassword(opt, id)),
        activateUser: (opt: ApiCallOptions, id: string) => dispatch(ApiActions.activateUser(opt, id)),
        deactivateUser: (opt: ApiCallOptions, id: string) => dispatch(ApiActions.deactivateUser(opt, id)),
        getClientsIndex: (opt: ApiCallOptions) => dispatch(ApiActions.getClientsIndex(opt)),
        pushNotification: (text: string, type: NotificationType) => dispatch(AppActions.pushNotification(text, type)),
        inviteSelfcareUser: (opt: ApiCallOptions, email: string, clientId: string) => dispatch(ApiActions.inviteSelfcareUser(opt, email, clientId)),
    }),
)(UsersComponent);