import { normalizeUrl, HTTP_STATUS, Request, queryParams, QueryParams } from '../utils';
import {
    NewSession,
    OtpSessionRequest,
    Session,
    DatasetEntity,
    DatasetEntityPatch,
    NodeEntity,
    ClientEntity,
    UserEntity,
    UserEntityPatch,
    LogItem,
    DashboardMetrics,
    GetEntity,
    ConnectorEntity,
    NodeEntityClientConnector,
    NodeEntityClientConnectorDetailed,
    CreditTransactionEntity,
    CreditBalance,
    ConnectorStatusEntity,
    DashboardMetricsRequests,
    ConnectionAbility,
    SanityRequestResult,
    RequestEntity,
    CreditInvoicing,
    MacroTemplateEntity,
    ClientIntegrationEntity,
    MacroExecution,
    MacroResult,
} from './structure';

// tslint:disable-next-line: no-var-requires
const config = require('config');

// set by location host
let serverUrl = config.serverUrl;
if (serverUrl.indexOf('http://') !== 0 && serverUrl.indexOf('https://') !== 0) {
    serverUrl = `${location.protocol}//${window.location.host}${config.serverUrl}`;
}

export interface ApiResponse {
    status: string;
}
export interface EntityList<T> {
    count: number;
    items: T[];
}

export type EntityListFilterQuery<T> = {
    [F in keyof T]: string | number | {$eq?: string; $gt?: string; $lt?: string; $gte?: string; $lte?: string; };
};

export interface EntityListFilter<T> extends QueryParams {
    skip?: number;
    limit?: number;
    sort?: string;
    filter?: EntityListFilterQuery<T>;
}

export interface EntityIndex<T = string> {
    [id: string]: T;
}

export interface TransactionsFilter extends QueryParams {
    skip?: number;
    limit?: number;
    sort?: string;
    filter?: {
        timestamp?: {$eq?: string; $gt?: string; $lt?: string; $gte?: string; $lte?: string; };
        owner?: {
            clientId?: string;
            creditAccountType: string;
        };
        source?: 'request_fee' | 'request_revenue' | 'data_fee' | 'data_revenue' | 'withdraw' | 'deposit' | 'manual' | 'request_rest';
        sourceClientId?: string;
        sourceNodeId?: string;
        sourceRequestToken?: string;
    };
}

export interface SessionReincarnation {
    role: 'selfcare';
    clientId: string;
}

export interface CallOptions {
    token: string;
    reincarnation?: SessionReincarnation;
    clientId?: string
}

export interface InvoicingFilter extends QueryParams {
    from: string | Date;
    to: string | Date;
}

/**
 * Endpoints
 */
export class Endpoints {
    /**************************************
     * System endpoints
     **************************************/

    /**
     * Get version
     */
    public static async getVersion(
        options: CallOptions,
    ) {
        return (await Request.get<{version: string}>(normalizeUrl(serverUrl + '/info/version'), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data.version;
    }

    /**
     * Get timezone
     */
     public static async getTimezone(
        options: CallOptions,
    ) {
        return (await Request.get<{timezone: {offset: number}}>(normalizeUrl(serverUrl + '/info/timezone'), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data.timezone;
    }

    /**
     * List logs
     * @param token
     */
    public static async listLog(
        options: CallOptions,
        filter: EntityListFilter<LogItem>,
    ) {
        return (await Request.get<{logs: EntityList<LogItem>}>(normalizeUrl(serverUrl + '/log' + queryParams(filter)), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data.logs;
    }

    /**************************************
     * Macros endpoints
     **************************************/

    /**
     * Macro execute
     * @param token
     */
     public static async postMacroExecute(
        options: CallOptions,
        script: string,
        params: any,
    ) {
        return (await Request.post<{output: MacroExecution}>(normalizeUrl(serverUrl + '/macro/execute'), {
            ...Endpoints.getHeadersFromOptions(options),
        }, {
            script,
            params,
        }, true, true))
        .expectStatus(HTTP_STATUS.OK).data.output;
    }

    /**
     * Macro result
     * @param token
     */
    public static async getMacroResult(
        options: CallOptions,
        hash: string,
    ) {
        return (await Request.get<{output: MacroResult}>(normalizeUrl(serverUrl + '/macro/result/' + hash), {
            ...Endpoints.getHeadersFromOptions(options),
        }, true, true))
        .expectStatus(HTTP_STATUS.OK).data.output;
    }

    /**
     * List macros
     * @param token
     */
     public static async getMacroTemplateList(
        options: CallOptions,
    ) {
        return (await Request.get<{templates: GetEntity<MacroTemplateEntity>[]}>(normalizeUrl(serverUrl + '/macro/template/list'), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data.templates;
    }

    /**
     * Post macro template
     * @param token
     */
     public static async postMacroTemplate(
        options: CallOptions,
        data: MacroTemplateEntity,
    ) {
        return (await Request.post<{template: GetEntity<MacroTemplateEntity>}>(normalizeUrl(serverUrl + '/macro/template'), {
            ...Endpoints.getHeadersFromOptions(options),
        }, data))
        .expectStatus(HTTP_STATUS.CREATED).data.template;
    }

    /**
     * Patch macro template
     * @param token
     */
     public static async patchMacroTemplate(
        options: CallOptions,
        id: string,
        data: MacroTemplateEntity,
    ) {
        return (await Request.patch<{template: GetEntity<MacroTemplateEntity>}>(normalizeUrl(serverUrl + `/macro/template/${escape(id)}`), {
            ...Endpoints.getHeadersFromOptions(options),
        }, data))
        .expectStatus(HTTP_STATUS.OK).data.template;
    }

    /**
     * Delete macro template
     * @param token
     */
     public static async deleteMacroTemplate(
        options: CallOptions,
        id: string,
    ) {
        return (await Request.delete<{}>(normalizeUrl(serverUrl + `/macro/template/${escape(id)}`), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data;
    }

    /**************************************
     * Session endpoints
     **************************************/

    /**
     * OTP request
     */
    public static async requestOtp(
        username: string,
        password: string,
    ) {
        return (await Request.put<{otpRequest: OtpSessionRequest}>(normalizeUrl(serverUrl + '/session/otp-request'), {}, {
            username,
            password,
        }))
        .expectStatus(HTTP_STATUS.CREATED).data.otpRequest;
    }

    /**
     * OTP verify
     */
    public static async verifyOtp(
        username: string,
        password: string,
        otpToken: string,
    ) {
        return (await Request.put(normalizeUrl(serverUrl + '/session/otp-verify'), {}, {
            username,
            password,
            otpToken,
        }))
        .expectStatus(HTTP_STATUS.OK);
    }

    /**
     * Login
     * Creates session and returns session and expiration date
     * @param username
     * @param password
     * @param otpToken
     */
    public static async login(
        username: string,
        password: string,
        otpToken?: string,
    ) {
        return (await Request.post<{session: NewSession}>(normalizeUrl(serverUrl + '/session'), {}, {
            username,
            password,
            otpToken,
        }))
        .expectStatus(HTTP_STATUS.CREATED).data.session;
    }

    /**
     * Get session
     * @param token
     */
    public static async getSession(
        options: CallOptions,
    ) {
        return (await Request.get<{session: Session}>(normalizeUrl(serverUrl + '/session'), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data.session;
    }

    /**
     * Logout
     * Delete session
     * @param token
     */
    public static async logout(
        options: CallOptions,
    ) {
        return (await Request.delete<void>(normalizeUrl(serverUrl + '/session'), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data;
    }

    /**************************************
     * Datasets endpoints
     **************************************/

    /**
     * List datasets
     * @param token
     */
    public static async listDatasets(
        options: CallOptions,
        filter?: EntityListFilter<DatasetEntity>,
    ) {
        return (await Request.get<{dataSets: EntityList<GetEntity<DatasetEntity>>}>(normalizeUrl(serverUrl + '/data-set' + queryParams(filter)), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data.dataSets;
    }

    /**
     * Get datasets index
     * @param token
     */
    public static async getDatasetsIndex(
        options: CallOptions,
    ) {
        return (await Request.get<{dataSetIndex: {[id: string]: string}}>(normalizeUrl(serverUrl + '/data-set/index'), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data.dataSetIndex;
    }

    /**
     * Get dataset
     * @param token
     */
    public static async getDataset(
        options: CallOptions,
        id: string,
    ) {
        return (await Request.get<{dataSet: GetEntity<DatasetEntity>}>(normalizeUrl(serverUrl + `/data-set/${escape(id)}`), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data.dataSet;
    }

    /**
     * Post dataset
     * @param token
     */
    public static async postDataset(
        options: CallOptions,
        data: DatasetEntity,
    ) {
        return (await Request.post<{dataSet: GetEntity<DatasetEntity>}>(normalizeUrl(serverUrl + `/data-set`), {
            ...Endpoints.getHeadersFromOptions(options),
        }, data))
        .expectStatus(HTTP_STATUS.CREATED).data.dataSet;
    }

    /**
     * Delete dataset
     * @param token
     */
    public static async deleteDataset(
        options: CallOptions,
        id: string,
    ) {
        return (await Request.delete(normalizeUrl(serverUrl + `/data-set/${escape(id)}`), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data;
    }

    /**
     * Patch dataset
     * @param token
     */
    public static async patchDataset(
        options: CallOptions,
        id: string,
        data: Partial<DatasetEntityPatch>,
    ) {
        return (await Request.patch<{dataSet: GetEntity<DatasetEntity>}>(normalizeUrl(serverUrl + `/data-set/${escape(id)}`), {
            ...Endpoints.getHeadersFromOptions(options),
        }, data))
        .expectStatus(HTTP_STATUS.OK).data.dataSet;
    }

    /**************************************
     * Nodes endpoints
     **************************************/

    /**
     * List nodes
     * @param token
     */
    public static async listNodes(
        options: CallOptions,
        filter?: EntityListFilter<NodeEntity>,
    ) {
        return (await Request.get<{nodes: EntityList<GetEntity<NodeEntity>>}>(normalizeUrl(serverUrl + '/node' + queryParams(filter)), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data.nodes;
    }

    /**
     * Get nodes index
     * @param token
     */
    public static async getNodesIndex(
        options: CallOptions,
    ) {
        return (await Request.get<{nodeIndex: EntityIndex}>(normalizeUrl(serverUrl + '/node/index'), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data.nodeIndex;
    }

    /**
     * Get node
     * @param token
     */
    public static async getNode(
        options: CallOptions,
        id: string,
    ) {
        return (await Request.get<{node: GetEntity<NodeEntity>}>(normalizeUrl(serverUrl + `/node/${escape(id)}`), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data.node;
    }

    /**
     * Post node
     * @param token
     */
    public static async postNode(
        options: CallOptions,
        data: NodeEntity,
    ) {
        return (await Request.post<{node: GetEntity<NodeEntity>}>(normalizeUrl(serverUrl + `/node`), {
            ...Endpoints.getHeadersFromOptions(options),
        }, data))
        .expectStatus(HTTP_STATUS.CREATED).data.node;
    }

    /**
     * Delete node
     * @param token
     */
    public static async deleteNode(
        options: CallOptions,
        id: string,
    ) {
        return (await Request.delete(normalizeUrl(serverUrl + `/node/${escape(id)}`), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data;
    }

    /**
     * Patch dataset
     * @param token
     */
    public static async patchNode(
        options: CallOptions,
        id: string,
        data: Partial<NodeEntity>,
    ) {
        return (await Request.patch<{node: GetEntity<NodeEntity>}>(normalizeUrl(serverUrl + `/node/${escape(id)}`), {
            ...Endpoints.getHeadersFromOptions(options),
        }, data))
        .expectStatus(HTTP_STATUS.OK).data.node;
    }

    /**
     * Get node connectors
     * @param token
     */
    public static async getNodeConnectors(
        options: CallOptions,
        id: string,
    ) {
        return (await Request.get<{connectors: NodeEntityClientConnector[]}>(normalizeUrl(serverUrl + `/node/${escape(id)}/connector`), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data.connectors;
    }

    /**
     * Get node connectors with info
     * @param token
     */
     public static async getNodeConnectorsDetailed(
        options: CallOptions,
        id: string,
    ) {
        return (await Request.get<{connectors: NodeEntityClientConnectorDetailed[]}>(normalizeUrl(serverUrl + `/node/${escape(id)}/connector-detailed`), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data.connectors;
    }

    /**
     * Post node connector
     * @param token
     */
    public static async postNodeConnector(
        options: CallOptions,
        clientId: string,
        data: NodeEntityClientConnector,
    ) {
        return (await Request.post<{connector: NodeEntityClientConnector}>(normalizeUrl(serverUrl + `/node/${escape(clientId)}/connector`), {
            ...Endpoints.getHeadersFromOptions(options),
        }, data))
        .expectStatus(HTTP_STATUS.CREATED).data.connector;
    }

    /**
     * Patch node connector
     * @param token
     */
    public static async patchNodeConnector(
        options: CallOptions,
        nodeId: string,
        connectorId: string,
        data: Partial<NodeEntityClientConnector>,
    ) {
        return (await Request.patch<{connector: NodeEntityClientConnector}>(normalizeUrl(serverUrl + `/node/${escape(nodeId)}/connector/${escape(connectorId)}`), {
            ...Endpoints.getHeadersFromOptions(options),
        }, data))
        .expectStatus(HTTP_STATUS.OK).data.connector;
    }

    /**
     * Delete node connector
     * @param token
     */
    public static async deleteNodeConnector(
        options: CallOptions,
        nodeId: string,
        connectorId: string,
    ) {
        return (await Request.delete(normalizeUrl(serverUrl + `/node/${escape(nodeId)}/connector/${escape(connectorId)}`), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data;
    }

    /**************************************
     * Clients endpoints
     **************************************/

    /**
     * List clients
     * @param token
     */
    public static async listClients(
        options: CallOptions,
        filter?: EntityListFilter<ClientEntity>,
    ) {
        return (await Request.get<{clients: EntityList<GetEntity<ClientEntity>>}>(normalizeUrl(serverUrl + '/client' + queryParams(filter)), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data.clients;
    }

    /**
     * Get client index
     * @param token
     */
    public static async getClientsIndex(
        options: CallOptions,
    ) {
        return (await Request.get<{clientIndex: EntityIndex}>(normalizeUrl(serverUrl + '/client/index'), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data.clientIndex;
    }

    /**
     * Get client index
     * @param token
     */
    public static async getClientsConnectorsIndex(
        options: CallOptions,
    ) {
        return (await Request.get<{clientConnectorIndex: EntityIndex<EntityIndex<string>>}>(normalizeUrl(serverUrl + '/client/connector-index'), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data.clientConnectorIndex;
    }

    /**
     * Get client
     * @param token
     */
    public static async getClient(
        options: CallOptions,
        id: string,
    ) {
        return (await Request.get<{client: GetEntity<ClientEntity>}>(normalizeUrl(serverUrl + `/client/${escape(id)}`), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data.client;
    }

    /**
     * Post client
     * @param token
     */
    public static async postClient(
        options: CallOptions,
        data: ClientEntity,
    ) {
        return (await Request.post<{client: GetEntity<ClientEntity>}>(normalizeUrl(serverUrl + `/client`), {
            ...Endpoints.getHeadersFromOptions(options),
        }, data))
        .expectStatus(HTTP_STATUS.CREATED).data.client;
    }

    /**
     * Delete client
     * @param token
     */
    public static async deleteClient(
        options: CallOptions,
        id: string,
    ) {
        return (await Request.delete(normalizeUrl(serverUrl + `/client/${escape(id)}`), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data;
    }

    /**
     * Patch client
     * @param token
     */
    public static async patchClient(
        options: CallOptions,
        id: string,
        data: Partial<ClientEntity>,
    ) {
        return (await Request.patch<{client: GetEntity<ClientEntity>}>(normalizeUrl(serverUrl + `/client/${escape(id)}`), {
            ...Endpoints.getHeadersFromOptions(options),
        }, data))
        .expectStatus(HTTP_STATUS.OK).data.client;
    }

    /**
     * Get client connectors
     * @param token
     */
    public static async getClientConnectors(
        options: CallOptions,
        id: string,
    ) {
        return (await Request.get<{connectors: GetEntity<ConnectorEntity>[]}>(normalizeUrl(serverUrl + `/client/${escape(id)}/connector`), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data.connectors;
    }

    /**
     * Post client connector
     * @param token
     */
    public static async postClientConnector(
        options: CallOptions,
        clientId: string,
        data: Partial<ConnectorEntity>,
    ) {
        return (await Request.post<{connector: GetEntity<ConnectorEntity>}>(normalizeUrl(serverUrl + `/client/${escape(clientId)}/connector`), {
            ...Endpoints.getHeadersFromOptions(options),
        }, data))
        .expectStatus(HTTP_STATUS.CREATED).data.connector;
    }

    /**
     * Patch client connector
     * @param token
     */
    public static async patchClientConnector(
        options: CallOptions,
        clientId: string,
        connectorId: string,
        data: Partial<ConnectorEntity>,
    ) {
        return (await Request.patch<{connector: GetEntity<ClientEntity>}>(normalizeUrl(serverUrl + `/client/${escape(clientId)}/connector/${escape(connectorId)}`), {
            ...Endpoints.getHeadersFromOptions(options),
        }, data))
        .expectStatus(HTTP_STATUS.OK).data.connector;
    }

    /**
     * Delete client connector
     * @param token
     */
    public static async deleteClientConnector(
        options: CallOptions,
        clientId: string,
        connectorId: string,
    ) {
        return (await Request.delete(normalizeUrl(serverUrl + `/client/${escape(clientId)}/connector/${escape(connectorId)}`), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data;
    }

    /**
     * Activate client connector
     * @param token
     */
    public static async postClientConnectorActivate(
        options: CallOptions,
        clientId: string,
        connectorId: string,
    ) {
        return (await Request.post(normalizeUrl(serverUrl + `/client/${escape(clientId)}/connector/${escape(connectorId)}/activate`), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data;
    }

    /**
     * Deactivate client connector
     * @param token
     */
    public static async postClientConnectorDeactivate(
        options: CallOptions,
        clientId: string,
        connectorId: string,
    ) {
        return (await Request.post(normalizeUrl(serverUrl + `/client/${escape(clientId)}/connector/${escape(connectorId)}/deactivate`), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data;
    }

    /**
     * Get client connectors statuses
     * @param token
     */
    public static async getClientConnectorStatus(
        options: CallOptions,
        clientId: string,
        connectorId: string,
        limit?: number,
    ) {
        return (await Request.get<{statuses: GetEntity<ConnectorStatusEntity>[]}>(normalizeUrl(serverUrl + `/client/${escape(clientId)}/connector/${escape(connectorId)}/status` + queryParams({limit})), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data.statuses;
    }

    /**
     * Get client connectors is banned
     * @param token
     */
    public static async getClientConnectorIsBanned(
        options: CallOptions,
        clientId: string,
        connectorId: string,
    ) {
        return (await Request.get<{isBanned: boolean}>(normalizeUrl(serverUrl + `/client/${escape(clientId)}/connector/${escape(connectorId)}/is-banned`), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data.isBanned;
    }

    /**
     * Get client connectors
     * @param token
     */
    public static async getClientConnectionAbility(
        options: CallOptions,
        id: string,
    ) {
        return (await Request.get<{connectionAbility: ConnectionAbility}>(normalizeUrl(serverUrl + `/client/${escape(id)}/connection-ability`), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data.connectionAbility;
    }

    /**************************************
     * Client integration endpoints
     **************************************/

    /**
     * Get client slack jwt
     */
     public static async getClientIntegrationSlackInstall(
        options: CallOptions,
    ) {
        return (await Request.get<{installUrl: string}>(normalizeUrl(serverUrl + `/client/integration/slack-install`), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data.installUrl;
    }

    /**
     * Get client integration
     */
     public static async getClientIntegration(
        options: CallOptions,
    ) {
        return (await Request.get<{clientIntegration: GetEntity<ClientIntegrationEntity>}>(normalizeUrl(serverUrl + `/client/integration`), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data.clientIntegration;
    }

    /**
     * Post client integration
     */
     public static async postClientIntegration(
        options: CallOptions,
        data: Partial<ClientIntegrationEntity>,
    ) {
        return (await Request.post<{clientIntegration: GetEntity<ClientIntegrationEntity>}>(normalizeUrl(serverUrl + `/client/integration`), {
            ...Endpoints.getHeadersFromOptions(options),
        }, data))
        .expectStatus(HTTP_STATUS.OK).data.clientIntegration;
    }

    /**
     * Post client integration
     */
     public static async deleteClientIntegrationSlack(
        options: CallOptions,
    ) {
        return (await Request.delete<{clientIntegration: GetEntity<ClientIntegrationEntity>}>(normalizeUrl(serverUrl + `/client/integration/slack`), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data.clientIntegration;
    }

    /**
     * Get client integration welcome
     */
     public static async getClientIntegrationWelcome(
        options: CallOptions,
    ) {
        return (await Request.get(normalizeUrl(serverUrl + `/client/integration/welcome`), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data;
    }

    /**************************************
     * Users endpoints
     **************************************/

    /**
     * List users
     * @param token
     */
    public static async listUsers(
        options: CallOptions,
        filter?: EntityListFilter<UserEntity>,
    ) {
        return (await Request.get<{users: EntityList<GetEntity<UserEntity>>}>(normalizeUrl(serverUrl + '/user' + queryParams(filter)), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data.users;
    }

    /**
     * Get user
     * @param token
     */
    public static async getUser(
        options: CallOptions,
        id: string,
    ) {
        return (await Request.get<{user: GetEntity<UserEntity>}>(normalizeUrl(serverUrl + `/user/${escape(id)}`), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data.user;
    }

    /**
     * Post user
     * @param token
     */
    public static async postUser(
        options: CallOptions,
        data: UserEntity,
    ) {
        return (await Request.post<{user: GetEntity<UserEntity>}>(normalizeUrl(serverUrl + `/user`), {
            ...Endpoints.getHeadersFromOptions(options),
        }, data))
        .expectStatus(HTTP_STATUS.CREATED).data.user;
    }

    /**
     * Delete user
     * @param token
     */
    public static async deleteUser(
        options: CallOptions,
        id: string,
    ) {
        return (await Request.delete(normalizeUrl(serverUrl + `/user/${escape(id)}`), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data;
    }

    /**
     * Patch user
     * @param token
     */
    public static async patchUser(
        options: CallOptions,
        id: string,
        data: Partial<UserEntityPatch>,
    ) {
        return (await Request.patch<{user: GetEntity<UserEntity>}>(normalizeUrl(serverUrl + `/user/${escape(id)}`), {
            ...Endpoints.getHeadersFromOptions(options),
        }, data))
        .expectStatus(HTTP_STATUS.OK).data.user;
    }

    /**
     * Put user activate
     * @param token
     */
    public static async putUserActivate(
        options: CallOptions,
        id: string,
    ) {
        return (await Request.put(normalizeUrl(serverUrl + `/user/${escape(id)}/activate`), {
            ...Endpoints.getHeadersFromOptions(options),
        }, {}))
        .expectStatus(HTTP_STATUS.OK).data;
    }

    /**
     * Put user deactivate
     * @param token
     */
    public static async putUserDeactivate(
        options: CallOptions,
        id: string,
    ) {
        return (await Request.put(normalizeUrl(serverUrl + `/user/${escape(id)}/deactivate`), {
            ...Endpoints.getHeadersFromOptions(options),
        }, {}))
        .expectStatus(HTTP_STATUS.OK).data;
    }

    /**
     * Put user otp request
     * @param token
     */
    public static async putUserOtpRequest(
        options: CallOptions,
        id: string,
    ) {
        return (await Request.put<{otpRequest: OtpSessionRequest}>(normalizeUrl(serverUrl + `/user/${escape(id)}/otp-request`), {
            ...Endpoints.getHeadersFromOptions(options),
        }, {}))
        .expectStatus(HTTP_STATUS.CREATED).data.otpRequest;
    }

    /**
     * Put user otp verify
     * @param token
     */
    public static async putUserOtpVerify(
        options: CallOptions,
        id: string,
        otpToken: string,
    ) {
        return (await Request.put(normalizeUrl(serverUrl + `/user/${escape(id)}/otp-verify`), {
            ...Endpoints.getHeadersFromOptions(options),
        }, {
            otpToken,
        }))
        .expectStatus(HTTP_STATUS.OK).data;
    }

    /**
     * Put user otp reset
     */
     public static async putUserOtpReset(
        options: CallOptions,
        id: string,
    ) {
        return (await Request.put(normalizeUrl(serverUrl + `/user/${escape(id)}/otp-reset`), {
            ...Endpoints.getHeadersFromOptions(options),
        }, {}))
        .expectStatus(HTTP_STATUS.OK).data;
    }

    /**
     * Post user invite
     * @param token
     */
    public static async inviteSelfcareUser(
        options: CallOptions,
        email: string,
        clientId: string,
    ) {
        return (await Request.post(normalizeUrl(serverUrl + `/user/invite-selfcare`), {
            ...Endpoints.getHeadersFromOptions(options),
        }, {
            email,
            clientsId: [clientId],
        }))
        .expectStatus(HTTP_STATUS.OK).data;
    }

    /**
     * Delete user
     * @param token
     */
    public static async postUserResetPassword(
        options: CallOptions,
        id: string,
    ) {
        return (await Request.post(normalizeUrl(serverUrl + `/user/${escape(id)}/reset-password`), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data;
    }

    /**************************************
     * Request endpoints
     **************************************/

    /**
     * List requests
     * @param token
     */
    public static async listRequests(
        options: CallOptions,
        filter?: EntityListFilter<GetEntity<RequestEntity>>,
    ) {
        return (await Request.get<{requests: EntityList<GetEntity<RequestEntity>>}>(normalizeUrl(serverUrl + '/request' + queryParams(filter)), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data.requests;
    }

    /**
     * List fetch requests
     * @param token
     */
    public static async listRequestsFetch(
        options: CallOptions,
        filter?: EntityListFilter<GetEntity<RequestEntity>>,
    ) {
        return (await Request.get<{requests: EntityList<GetEntity<RequestEntity>>}>(normalizeUrl(serverUrl + '/request/fetch' + queryParams(filter)), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data.requests;
    }

    /**
     * Get request
     * @param token
     */
    public static async getRequest(
        options: CallOptions,
        id: string,
    ) {
        return (await Request.get<{request: GetEntity<RequestEntity>}>(normalizeUrl(serverUrl + `/request/${escape(id)}`), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data.request;
    }

    /**
     * Anonymize request
     * @param token
     */
    public static async anonymizeRequest(
        options: CallOptions,
        id: string,
    ) {
        return (await Request.delete(normalizeUrl(serverUrl + `/request/${escape(id)}`), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data;
    }

    /**************************************
     * Dashboard endpoints
     **************************************/
    /**
     * Get dashboard
     * @param token
     */
    public static async getDashboard(
        options: CallOptions,
    ) {
        return (await Request.get<{dashboard: DashboardMetrics}>(normalizeUrl(serverUrl + `/dashboard`), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data.dashboard;
    }

    /**
     * Get dashboard requests
     * @param token
     */
    public static async getDashboardRequests(
        options: CallOptions,
    ) {
        return (await Request.get<{dashboardRequests: DashboardMetricsRequests}>(normalizeUrl(serverUrl + `/dashboard/requests`), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data.dashboardRequests;
    }

    /**************************************
     * Transactions endpoints
     **************************************/

    /**
     * List transactions
     * @param token
     */
    public static async listTransactions(
        options: CallOptions,
        filter?: TransactionsFilter,
    ) {
        return (await Request.get<{transactions: EntityList<GetEntity<CreditTransactionEntity>>}>(normalizeUrl(serverUrl + '/credit/transaction' + queryParams(filter)), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data.transactions;
    }

    /**
     * Get credit balance transactions
     * @param token
     */
    public static async getCreditBalance(
        options: CallOptions,
    ) {
        return (await Request.get<{balances: CreditBalance}>(normalizeUrl(serverUrl + '/credit/transaction/balance'), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data.balances;
    }

    /**
     * Put deposit transactions
     * @param token
     */
    public static async putCreditDeposit(
        options: CallOptions,
        clientId: string,
        amount: number,
        message?: string,
    ) {
        return (await Request.put(normalizeUrl(serverUrl + '/credit/transaction/deposit'), {
            ...Endpoints.getHeadersFromOptions(options),
        }, {
            clientId,
            amount,
            message,
        }))
        .expectStatus(HTTP_STATUS.OK).data;
    }

    /**
     * Put withdraw transactions
     * @param token
     */
    public static async putCreditWithdraw(
        options: CallOptions,
        clientId: string,
        amount: number,
        message?: string,
    ) {
        return (await Request.put(normalizeUrl(serverUrl + '/credit/transaction/withdraw'), {
            ...Endpoints.getHeadersFromOptions(options),
        }, {
            clientId,
            amount,
            message,
        }))
        .expectStatus(HTTP_STATUS.OK).data;
    }

    /**
     * Get credit invoicing info
     * @param token
     */
     public static async getCreditInvoicing(
        options: CallOptions,
        filter: InvoicingFilter,
    ) {
        return (await Request.get<{invoicing: CreditInvoicing}>(normalizeUrl(serverUrl + '/credit/invoicing' + queryParams(filter)), {
            ...Endpoints.getHeadersFromOptions(options),
        }))
        .expectStatus(HTTP_STATUS.OK).data.invoicing;
    }

    /**************************************
     * Sanity endpoints
     **************************************/

    /**
     * Call sanity check
     */
    public static async putSanityRequest(
        options: CallOptions,
        nodeId: string,
        connectorId: string,
        query: string
    ) {
        return (await Request.put<{result: SanityRequestResult}>(normalizeUrl(serverUrl + `/sanity/${escape(nodeId)}/${escape(connectorId)}/data`), {
            ...Endpoints.getHeadersFromOptions(options),
        }, {
            query,
        }))
        .expectStatus(HTTP_STATUS.OK).data.result;
    }

    /*******************************
     *
     * Helpers
     *
     *******************************/

    /**
     * Get headers from options
     */
    public static getHeadersFromOptions(options: CallOptions) {
        return {
            'user-session-token': options.token,
            ...(options.clientId ? {
                'use-client-id': options.clientId,
            } : {}),
            ...(options.reincarnation ? {
                'use-role': options.reincarnation.role,
                'use-client-id': options.reincarnation.clientId,
            } : {}),
        }
    }
}
