import * as React from 'react';
import ReactJson from 'react-json-view';
import { css } from 'emotion';

export const style = {
    root: css``,
};

export interface ComponentProps {
    data: object;
    collapsed?: boolean;
    openAll?: boolean;
}

export class JSONView extends React.Component<ComponentProps> {
    public render() {
        return (
            <div className={style.root}>
                <ReactJson
                    enableClipboard={false}
                    src={this.props.data}
                    displayDataTypes={false}
                    displayObjectSize={false}
                    name={false}
                    shouldCollapse={(field) => !!field.name && !this.props.openAll}
                    collapsed={this.props.collapsed}
                />
            </div>
        );
    }
}