import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { css, cx } from 'emotion';
import * as React from 'react';
import { mediaQLarge, mediaQMedium } from '../../utils/responsivity';
import { style } from '../forms/NodeConnectorForm';

const styles = {
    contentRow: css`
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom:15px;
        &:last-child {
            margin-bottom: 0;
        }
    `,
    statisticsBox: css`
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        padding: 20px;
        padding-right: 80px;
        margin-right: 15px;
        position: relative;
        width: 250px;
        max-width: 250px;
        margin-bottom: 15px;
        flex: 1;
        &:last-child {
            margin-right: 0;
        }
        &.red {
            color: #fff;
            background-color: #dc3545;
            border-color: #dc3545;
        }
        &.green {
            color: #fff;
            background-color: #28a745;
            border-color: #28a745;
        }
        &.yellow {
            color: #212529;
            background-color: #ffc107;
            border-color: #ffc107;
        }
        &.gray {
            color: #fff;
            background-color: #6c757d;
            border-color: #6c757d;
        }

        @media ${mediaQLarge} {
            flex-basis: calc(50% - 15px - 80px - 20px);
            width: calc(50% - 15px - 80px - 20px);
            max-width: initial;
            margin-right: 0;
            &:nth-child(odd) {
                margin-right: 15px;
            }
        }
        @media ${mediaQMedium} {
            flex-basis: 100%;
            margin-right: 0;
            max-width: initial;
            &:nth-child(odd) {
                margin-right: 0;
            }
        }
    `,
    statisticsContent: css`
        display: block;
    `,
    statisticsBoxIcon: css`
        font-size: 60px;
        margin-right: 10px;
        position: absolute;
        right: 10px;
        bottom: 0;
        color: #000;
        opacity: 0.2;
    `,
    statisticsBoxName: css`
        text-align: center;
    `,
    statisticsBoxValue: css`
        text-align: center;
        font-size: 2.5em;
        font-weight: bold;
    `,
    statisticsBoxSubTime: css`
        font-size: 12px;
    `,
};

export function StatisticsRow(props: React.Props<any>) {
    return (
        <div className={styles.contentRow}>{props.children}</div>
    );
}

/**
 * Statistics box
 * @param props
 */
export function StatisticsBox(props: {
    color: string;
    icon: IconProp;
    name: string;
    value: number | string;
    subTime?: string;
}) {
    return (
        <div className={cx(styles.statisticsBox, props.color)}>
            <div className={styles.statisticsBoxIcon}>
                <FontAwesomeIcon icon={props.icon} />
            </div>
            <div className={styles.statisticsContent}>
                <div className={styles.statisticsBoxName}>{props.name}</div>
                <div className={styles.statisticsBoxValue}>{props.value}</div>
                {props.subTime ? <div className={styles.statisticsBoxSubTime}>{props.subTime}</div> : null}
            </div>
        </div>
    );
}