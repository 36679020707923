import * as React from 'react';
import { FormProvidedProps, withFormData } from './FormProvider';
import { FormError } from './FormError';
import { css, cx } from 'emotion';

const styles = {
    root: css`
        cursor: pointer;
    `,
    error: css`
        width: 100%;
        display: inline-block;
    `,
};

/**************** Component props ***************/
interface ComponentProps extends Partial<FormProvidedProps> {
    name: string;
    label?: string;
    labelProps?: React.InputHTMLAttributes<HTMLLabelElement>;
    inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
    className?: string;
    labelClassName?: string;
    resetOnUnmount?: boolean;
}

/**************** Component ***************/
class Component extends React.Component<ComponentProps> {
    public componentWillUnmount() {
        if (this.props.resetOnUnmount) {
            this.props.formValueRemove(this.props.name);
        }
    }

    /**
     * Render
     */
    public render() {
        return (
            <div className={cx(styles.root, this.props.className)}>
                <label
                    {...this.props.labelProps ? this.props.labelProps : null}
                    className={this.props.labelClassName}
                >
                    <input
                        type="checkbox"
                        {...this.props.inputProps ? this.props.inputProps : null}
                        checked={!!this.props.formGetValue(this.props.name)}
                        onChange={this.onChange}
                    />
                    {this.props.label}
                </label>
                <FormError className={styles.error} name={this.props.name} />
            </div>
        );
    }

    protected onChange = (e) => {
        this.props.formValueChange(this.props.name, !this.props.formGetValue(this.props.name));
    }
}

export const FormCheckbox = withFormData(Component);
