import * as React from 'react';
import { FormProvidedProps, withFormData } from './FormProvider';
import { css, cx } from 'emotion';
import { FormError } from './FormError';

const styles = {
    error: css`
        width: 100%;
        display: inline-block;
    `,
    input: css`
        display: block;
        padding: 5px 10px;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 4px;
        cursor: text;
        line-height: 1.5;
        color: #495057;
        font-size: 16px;
        width: calc(100% - 20px);
        resize: vertical;
    `,
    label: css`
        font-size: 16px;
        pointer-events: none;
        color: #848b91;
    `,
};

/**************** Component props ***************/
interface ComponentProps extends Partial<FormProvidedProps> {
    name: string;
    label?: string;
    labelProps?: React.InputHTMLAttributes<HTMLLabelElement>;
    className?: string;
    labelClassName?: string;
    resetOnUnmount?: boolean;
    textareaStyle?: any;
}

/**************** Component ***************/
class Component extends React.Component<ComponentProps> {
    public componentWillUnmount() {
        if (this.props.resetOnUnmount) {
            this.props.formValueRemove(this.props.name);
        }
    }

    /**
     * Render
     */
    public render() {
        return (
            <div className={this.props.className}>
                <label
                    {...this.props.labelProps ? this.props.labelProps : null}
                    className={cx(styles.label, this.props.labelClassName)}
                >
                    {this.props.label}
                </label>
                <textarea
                    className={styles.input}
                    value={this.props.formGetValue(this.props.name)}
                    onChange={this.onChange}
                    style={this.props.textareaStyle}
                />
                <FormError className={styles.error} name={this.props.name} />
            </div>
        );
    }

    protected onChange = (e) => {
        this.props.formValueChange(this.props.name, e.target.value);
    }
}

export const FormTextarea = withFormData(Component);
