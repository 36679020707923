import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { css } from 'emotion';
import * as React from 'react';
import { Form } from '../ux/forms/Form';
import { FormInput } from '../ux/forms/FormInput';
import { FormSubmit } from '../ux/forms/FormSubmit';
import { FormError } from '../ux/forms/FormError';
import { JsonValidatorType, RequestError, HTTP_STATUS, JsonValidatorRequired } from '../../utils';
import { RegExps } from '../../api/regexps';
import { ModalOtp } from '../modals/ModalOtp';

/**************** Style ***************/
const style = {
    inputGroup: css`
        margin-top: 1em;
    `,
    buttons: css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 1.5em;
    `,
};

export interface LoginFormData {
    username: string;
    password: string;
    otpToken?: string;
}

/**************** Props ***************/
interface ComponentProps {
    data?: Partial<LoginFormData>;
    onChange?: (data: Partial<LoginFormData>) => void;

    className?: string;
    onLogin?: (data: LoginFormData) => void;

    onCancel?: () => void;
    otpNeeded?: boolean;
}

/**************** Component ***************/
export class LoginForm extends React.Component<ComponentProps> {
    public render() {
        return (
            <div className={this.props.className}>
                <Form
                    data={this.props.data}
                    onChange={this.props.onChange}
                    action={this.action}
                    schema={{
                        username: {
                            required: JsonValidatorRequired.True,
                            regexp: RegExps.username,
                            type: JsonValidatorType.String,
                        },
                        password: {
                            required: JsonValidatorRequired.True,
                            regexp: RegExps.password,
                            type: JsonValidatorType.String,
                        },
                        otpToken: {
                            required: this.props.otpNeeded ? JsonValidatorRequired.True : JsonValidatorRequired.False,
                            regexp: RegExps.otpToken,
                            type: JsonValidatorType.String,
                        },
                    }}
                >
                    <FormInput
                        name="username"
                        label="Username"
                        className={style.inputGroup}
                        submitOnEnter
                    />
                    <FormInput
                        name="password"
                        label="Password"
                        className={style.inputGroup}
                        type={'password'}
                        submitOnEnter
                    />
                    <ModalOtp
                        onClose={this.props.onCancel}
                        active={this.props.otpNeeded}
                        title={'Two factor authorization'}
                        text={'Your account is protected by OTP, you need to use your OTP token to access to console.'}
                        confirmText={'Login'}
                    />
                    <div className={style.buttons}>
                        <FormSubmit
                            icon={faSignInAlt}
                        >
                            Sign in
                        </FormSubmit>
                        <div>
                            <FormError />
                        </div>
                    </div>
                </Form>
            </div>
        );
    }

    // main form action
    protected action = async (data: any) => {
        if (this.props.onLogin) {
            try {
                await this.props.onLogin(data);
            } catch (e) {
                if (e instanceof RequestError) {
                    if (e.data?.error?.key === 'wrong_otp_code') {
                        throw new Error('Invalid OTP code');
                    }
                    if (e.status === HTTP_STATUS.UNAUTHORIZED) {
                        const message = e?.data?.error?.message;
                        throw new Error(message ? message : 'Failed to login');
                    }
                }
                throw e;
            }
        }
    }
}

