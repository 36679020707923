import { keyframes } from 'emotion';

export const rotate = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

export const fadeOut = keyframes`
    99%{
        opacity : 0;
        display: block;
    }
    99%{
        opacity : 0;
    }
    100%{
        opacity : 0;
        display: none;
    }
`;

export const fadeIn = keyframes`
    0%{
        opacity : 0;
        display: block;
    }
    1%{
        opacity : 0;
    }
    100%{
        opacity : 1;
    }
`;
