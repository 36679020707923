import * as React from 'react';
import { FormProvidedProps, withFormData } from './FormProvider';
import { css, cx } from 'emotion';
import { FormError } from './FormError';
import { isString, isNumber, isUndefined, isSet } from '../../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

const styles = {
    root: css`
        display: flex;
        flex-direction: column;
        position: relative;
    `,
    error: css`
        width: 100%;
        display: inline-block;
    `,
    inputWrap: css`
        display: block;
        height: 40px;
        padding: 0;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 4px;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        position: relative;
        cursor: text;
    `,
    label: css`
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        font-size: 16px;
        transition: top 0.2s linear, transform 0.2s linear, font-size 0.2s linear;
        pointer-events: none;
        color: #848b91;
        &.top {
            transform: translateY(0);
            top: 2px;
            font-size: 12px;
        }
    `,
    input: css`
        display: block;
        line-height: 1.5;
        color: #495057;
        border: none;
        font-size: 16px;
        font-weight: 400;
        padding: 0;
        z-index: 0;
        width: calc(100% - 40px);
        padding: 0 20px;
        height: 25px;
        line-height: 25px;
        margin-top: 15px;
        background: transparent;
        border-radius: 4px;

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
            border: none;
            background: transparent !important;
            box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px #fff;
        }
    `,
    arrows: css`
        position: absolute;
        right: 5px;
        top: 50%;
        display: flex;
        flex-direction: column;
        transform: translateY(-50%);
    `,
    arrowButton: css`
        padding: 3px;
        cursor: pointer;
        height: 10px;
        line-height: 10px;
        &.down svg{
            margin-top: -5px;
        }
    `,
};

/**************** Component props ***************/
interface ComponentProps extends Partial<FormProvidedProps> {
    name: string;
    inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
    className?: string;
    inputClassName?: string;
    type?: string;
    submitOnEnter?: boolean;
    label?: React.ReactChild;
    resetOnUnmount?: boolean;
    defaultValue?: string | number;
    min?: number;
    max?: number;
    step?: number;
    canBeEmpty?: boolean;
}

/**************** Component ***************/
class Component extends React.Component<ComponentProps, {
    secondaryValue: string;
    focused: boolean;
    autofilled: boolean;
}> {
    public readonly state = {
        secondaryValue: null,
        focused: false,
        autofilled: false,
    };

    protected inputRef = React.createRef<HTMLInputElement>();

    public componentDidMount() {
        if (isSet(this.props.defaultValue)) {
            if (!isSet(this.props.formGetValue(this.props.name))) {
                if (this.props.defaultValue) {
                    this.props.formValueChange(this.props.name, this.props.defaultValue, true);
                }
            }
        } else {
            if (!isSet(this.props.formGetValue(this.props.name))) {
                this.props.formValueRemove(this.props.name, true);
            }
        }

        setTimeout(() => {
            const autofilled = this.inputRef.current ? this.inputRef.current.matches(':-webkit-autofill') : false;
            if (autofilled) {
                this.setState({autofilled});
            }
        } , 500);
    }

    public componentWillUnmount() {
        if (this.props.resetOnUnmount) {
            this.props.formValueRemove(this.props.name, true);
            this.setState({secondaryValue: null});
        }
    }

    /**
     * Render
     */
    public render() {
        const isNumeric = this.props.type === 'float' || this.props.type === 'number';

        let type = 'text';
        if (this.props.type) {
            type = this.props.type;
        }
        if (isNumeric) {
            type = 'text';
        }

        const value = isString(this.state.secondaryValue) ? this.state.secondaryValue : this.props.formGetValue(this.props.name);
        const valueCorrect = isNumber(value) || (isString(value) && value.length);

        const labelTop = (valueCorrect || this.state.focused || this.state.autofilled) ? true : false;

        return (
            <div className={cx(styles.root, this.props.className)}>
                <div className={styles.inputWrap} onClick={this.onWrapClick}>
                    {this.props.label ? <label className={cx(styles.label, {['top']: labelTop})}>{this.props.label}</label> : null}
                    <input
                        type={type}
                        {...this.props.inputProps ? this.props.inputProps : null}
                        value={!isSet(value) ? '' : value}
                        onChange={this.onChange}
                        onBlur={this.onBlur}
                        className={cx(styles.input, this.props.inputClassName)}
                        onKeyDown={this.onKeyDown}
                        onFocus={this.onFocus}
                        ref={this.inputRef}
                        inputMode={isNumeric ? 'decimal' : undefined}
                    />
                    {isNumeric ? (
                        <div className={styles.arrows}>
                            <div className={cx(styles.arrowButton, 'up')} onClick={this.onNumericUp}><FontAwesomeIcon icon={faSortUp} /></div>
                            <div className={cx(styles.arrowButton, 'down')} onClick={this.onNumericDown}><FontAwesomeIcon icon={faSortDown} /></div>
                        </div>
                    ) : null}
                </div>
                <FormError className={styles.error} name={this.props.name} />
            </div>
        );
    }

    protected onNumericUp = () => {
        const isNumeric = this.props.type === 'float' || this.props.type === 'number';
        if (isNumeric) {
            const step = isNumber(this.props.step) ? this.props.step : 1;
            const currentValue = isString(this.state.secondaryValue) ? this.state.secondaryValue : this.props.formGetValue(this.props.name);
            let val = parseFloat(currentValue);
            if (!isNaN(val)) {
                val += step;
                if (isNumber(this.props.max) && val > this.props.max) {
                    val = this.props.max;
                }
                const t = Math.round(1 / step);
                val = Math.round(val * t) / t;
                this.setState({secondaryValue: val.toString()});
            }
        }
    }

    protected onNumericDown = () => {
        const isNumeric = this.props.type === 'float' || this.props.type === 'number';
        if (isNumeric) {
            const step = isNumber(this.props.step) ? this.props.step : 1;
            const currentValue = isString(this.state.secondaryValue) ? this.state.secondaryValue : this.props.formGetValue(this.props.name);
            let val = parseFloat(currentValue);
            if (!isNaN(val)) {
                val -= step;
                if (isNumber(this.props.min) && val < this.props.min) {
                    val = this.props.min;
                }
                const t = Math.round(1 / step);
                val = Math.round(val * t) / t;
                this.setState({secondaryValue: val.toString()});
            }
        }
    }

    protected onChange = (e) => {
        if (this.props.type === 'number') {
            // value will be filled on blur
            let secondaryValue = e.target.value
                .replace(/[^0-9]/, '');
            this.setState({secondaryValue});
        } else if (this.props.type === 'float') {
            // value will be filled on blur
            let secondaryValue = e.target.value
                .replace(/\,/, '.')
                .replace(/[^0-9\.\-]/, '');
            this.setState({secondaryValue});
        } else {
            this.props.formValueChange(this.props.name, e.target.value);
        }
    }

    protected onBlur = (e) => {
        const isNumeric = this.props.type === 'float' || this.props.type === 'number';
        // only on float we are setting value on blur
        if (isNumeric) {
            const currentValue = isString(this.state.secondaryValue) ? this.state.secondaryValue : this.props.formGetValue(this.props.name);

            let val;
            if (this.props.type === 'float') {
                val = parseFloat(currentValue);
            } else if (this.props.type === 'number') {
                val = parseInt(currentValue, 10);
            }

            // clamp value
            if (isNumber(this.props.min) && val < this.props.min) {
                val = this.props.min;
            } else if (isNumber(this.props.max) && val > this.props.max) {
                val = this.props.max;
            }

            if (isNaN(val)) {
                if (this.props.canBeEmpty) {
                    this.props.formValueRemove(this.props.name);
                } else {
                    this.props.formValueChange(this.props.name, isNumber(this.props.min) ? this.props.min : 0);
                }
            } else {
                this.props.formValueChange(this.props.name, val);
            }
            this.setState({
                secondaryValue: null,
                focused: false,
            });
        } else {
            this.setState({focused: false});
        }

        this.props.formValidate(this.props.name);
    }

    protected onFocus = (e) => {
        this.setState({
            focused: true,
            autofilled: false,
        });
    }

    protected onKeyDown = (e) => {
        const isNumeric = this.props.type === 'float' || this.props.type === 'number';
        // in float there can be only one dot
        if (isNumeric) {
            const currentValue = isString(this.state.secondaryValue) ? this.state.secondaryValue : this.props.formGetValue(this.props.name);

            // up 38, down 40 e.keyCode
            if ((e.keyCode === 38 || e.keyCode === 40) && isNumber(this.props.step)) {
                let val = parseFloat(currentValue);
                if (!isNaN(val)) {
                    if (e.keyCode === 38) {
                        val += this.props.step;
                    } else if (e.keyCode === 40) {
                        val -= this.props.step;
                    }
                    if (isNumber(this.props.min) && val < this.props.min) {
                        val = this.props.min;
                    } else if (isNumber(this.props.max) && val > this.props.max) {
                        val = this.props.max;
                    }
                    // correcting by step
                    const t = Math.round(1 / this.props.step);
                    val = Math.round(val * t) / t;
                    this.setState({secondaryValue: val.toString()});
                }
            }

            if (e.key === '.' && isString(currentValue) && currentValue.indexOf('.') !== -1) {
                e.preventDefault();
            }
        }

        if (this.props.submitOnEnter && e.keyCode === 13) {
            this.props.formSubmit();
        }
    }

    protected onWrapClick = (e) => {
        if (this.inputRef.current) {
            this.inputRef.current.focus();
        }
    }
}

export const FormInput = withFormData(Component);
