import * as React from 'react';
import { css, cx } from 'emotion';
import { connect } from 'react-redux';
import { ApiInitialState, ApiActions, ApiCallOptions } from '../../state/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faSignOutAlt, faCog } from '@fortawesome/free-solid-svg-icons';
import { UserEntity, GetEntity } from '../../api/structure';
import { Dropdown } from '../ux/Dropdown';
import { ModalConfirm } from '../modals/ModalConfirm';
import { mediaQSmall } from '../../utils/responsivity';
import { AppActions } from '../../state/app';
import { routes } from '../../routes/Router';
import { EntityIndex, SessionReincarnation } from '../../api/Endpoints';
import { safePromise } from '../../utils';

const styles = {
    head: css`
        height: 60px;
        background: #fff;
        line-height: 60px;
        font-weight: bold;
        font-size: 1.5em;
        padding: 0 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
        position: relative;
        z-index: 10;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        color: #666;
        @media ${mediaQSmall} {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
        }
    `,
    authbox: css`
        font-size: 0.75em;
        display: flex;
        align-items: center;
        padding: 0 10px;
        min-width: 150px;
        justify-content: flex-end;
        cursor: pointer;
        @media ${mediaQSmall} {
            min-width: 45px;
            margin-right: 8px;
        }
    `,
    icon: css`
        font-size: 35px;
        margin-left: 8px;
    `,
    dimmedName: css`
        color: #AAA;
        font-weight: normal;
        margin-left: 8px;
        font-size: 10px;
    `,
    clientName: css`
        color: #AAA;
        font-weight: normal;
        margin-left: 8px;
    `,
    reincarnationBox: css`
        font-size: 0.75em;
        display: flex;
        align-items: center;
        padding: 0 10px;
        min-width: 170px;
        justify-content: flex-end;
        cursor: pointer;
        font-weight: normal;
        margin-top: -2px;
    `,
    reincarnationMenu: css`
        width: auto;
        width: 160px;
    `,
    authboxContent: css`
        display: flex;
        flex-direction: column;
        line-height: 1;
        align-items: flex-end;
    `,
    showAs: css`
        display: flex;
        flex-direction: column;
        line-height: 1;
        align-items: center;
        justify-content: center;
        text-align: center;
    `,
    userDropdown: css`
        &.mobile {
            display: none;
        }
        &.desktop {
            display: block;
        }
        @media ${mediaQSmall} {
            &.mobile {
                display: block;
            }
            &.desktop {
                display: none;
            }
        }
    `,
};

export interface HeaderProps {
    token: string;
    sessionUser: GetEntity<UserEntity>;
    sessionClientId: string;
    clientIndex: EntityIndex;
    clientIndexForAdmin: EntityIndex;
    sessionReincarnation: SessionReincarnation;
    redirectTo: (to: string) => void;
    logout: () => Promise<void>;
    getClientsIndex: (opt: ApiCallOptions) => Promise<EntityIndex>;
    getClientsIndexForAdmin: (opt: ApiCallOptions) => Promise<EntityIndex>;
    reincarnateToAdmin: () => void;
    reincarnateToSelfcare: (clientId: string) => void;
    setClient: (clientId: string) => void;
}

/**************** Component ***************/
class Component extends React.Component<HeaderProps> {
    public readonly state = {
        confirmLogout: false,
    };

    public componentDidMount() {
        safePromise(this.props.getClientsIndex({}));
        if (this.props.sessionUser?.type === 'admin' || this.props.sessionReincarnation) {
            safePromise(this.props.getClientsIndexForAdmin({}));
        }
    }

    public render() {
        const showReincarnation = this.props.sessionUser?.type === 'admin' || this.props.sessionReincarnation;
        const reincarnations = Object.keys(this.props.clientIndexForAdmin || {})
            .map((clientId) => ({
                text: this.props.clientIndexForAdmin[clientId],
                onClick: () => this.reincarnateTo(clientId),
            }));
        const clientId = this.props.sessionClientId;


        const clientsMenu = Object.keys(this.props.clientIndex || {})
            .map((clientId) => ({
                text: this.props.clientIndex[clientId],
                onClick: () => this.setClient(clientId),
            }));

        return (
            <>
                <div className={cx(styles.head)}>
                    {showReincarnation ? (
                        <Dropdown
                            menuItems={[
                                {
                                    text: 'Admin',
                                    onClick: this.resetReincarnation,
                                },
                                ...reincarnations,
                            ]}
                            menuClassName={styles.reincarnationMenu}
                        >
                            <div className={styles.reincarnationBox}>
                                <span className={styles.showAs}>
                                    <span className={styles.dimmedName}>Show as: </span>
                                    {this.props.sessionReincarnation ? this.props.clientIndexForAdmin[this.props.sessionReincarnation.clientId] : 'Admin'}
                                </span>
                            </div>
                        </Dropdown>
                    ) : null}

                    {this.props.sessionUser?.type !== 'admin' && clientsMenu?.length > 1 && this.props.sessionClientId ? (
                        <Dropdown
                            menuItems={clientsMenu}
                            menuClassName={styles.reincarnationMenu}
                        >
                            <div className={styles.reincarnationBox}>
                                <span className={styles.showAs}>
                                    <span className={styles.dimmedName}>Client: </span>
                                    {this.props.clientIndex[this.props.sessionClientId]}
                                </span>
                            </div>
                        </Dropdown>
                    ) : null}

                    {this.props.sessionUser ? (
                        <>
                            <Dropdown
                                className={cx(styles.userDropdown, 'desktop')}
                                menuItems={[
                                    {
                                        text: 'Account',
                                        icon: faCog,
                                        onClick: this.onSettings,
                                    },
                                    {
                                        text: 'Logout',
                                        icon: faSignOutAlt,
                                        onClick: this.onLogout,
                                    }
                                ]}
                            >
                                <div className={styles.authbox}>
                                    <span className={styles.authboxContent}>
                                        <span>{this.props.sessionUser.username}</span>
                                        {clientId && clientsMenu?.length <= 1 ? <span className={cx(styles.dimmedName, styles.clientName)}>
                                            ({this.props.clientIndex ? this.props.clientIndex[clientId] : 'N/A'})
                                        </span> : null}
                                    </span>
                                    <span className={styles.icon}>
                                        <FontAwesomeIcon icon={faUserCircle} />
                                    </span>
                                </div>
                            </Dropdown>
                            <Dropdown
                                className={cx(styles.userDropdown, 'mobile')}
                                menuItems={[
                                    {
                                        text: this.props.sessionUser.username,
                                        icon: faCog,
                                        onClick: this.onSettings,
                                    },
                                    {
                                        text: 'Logout',
                                        icon: faSignOutAlt,
                                        onClick: this.onLogout,
                                    }
                                ]}
                            >
                                <div className={styles.authbox}>
                                    <span className={styles.authboxContent}>
                                        {!showReincarnation ? (
                                            <>
                                                <span>{this.props.sessionUser.username}</span>
                                                {clientId ? <span className={cx(styles.dimmedName, styles.clientName)}>
                                                    ({this.props.clientIndex ? this.props.clientIndex[clientId] : 'N/A'})
                                                </span> : null}
                                            </>
                                        ) : null}
                                    </span>
                                    <span className={styles.icon}>
                                        <FontAwesomeIcon icon={faUserCircle} />
                                    </span>
                                </div>
                            </Dropdown>
                        </>
                    ) : null}
                </div>
                <ModalConfirm
                    onClose={this.onModalClose}
                    onConfirm={this.onLogoutConfirm}
                    active={this.state.confirmLogout}
                    title="Confirm logout"
                    text="Do you realy want to logout from your account?"
                    confirmText="Logout"
                />
            </>
        );
    }

    protected onModalClose = () => {
        this.setState({
            confirmLogout: false,
        });
    }

    protected onSettings = () => {
        this.props.redirectTo(routes.users.edit.path.replace(':id', this.props.sessionUser._id));
    }

    protected onLogout = () => {
        this.setState({
            confirmLogout: true,
        });
    }

    protected onLogoutConfirm = () => {
        this.props.logout();
    }

    protected resetReincarnation = () => {
        this.props.reincarnateToAdmin();
    }

    protected reincarnateTo = (clientId: string) => {
        this.props.reincarnateToSelfcare(clientId);
    }

    protected setClient = (clientId: string) => {
        this.props.setClient(clientId);
    }
}

// Export connected component as default
export const Header = connect(
    (state: {api: ApiInitialState}) => ({
        token: state.api.token,
        sessionUser: state.api.sessionUser,
        sessionClientId: state.api.sessionClientId,
        clientIndex: state.api.clientIndex,
        clientIndexForAdmin: state.api.clientIndexForAdmin,
        sessionReincarnation: state.api.sessionReincarnation,
    }),
    (dispatch) => ({
        redirectTo: (path: string) => dispatch(AppActions.redirectTo(path)),
        logout: () => dispatch(ApiActions.logout()),
        getClientsIndex: (opt: ApiCallOptions) => dispatch(ApiActions.getClientsIndex(opt)),
        getClientsIndexForAdmin: (opt: ApiCallOptions) => dispatch(ApiActions.getClientsIndexForAdmin(opt)),
        reincarnateToAdmin: () => dispatch(ApiActions.reincarnateToAdmin()),
        reincarnateToSelfcare: (clientId: string) => dispatch(ApiActions.reincarnateToSelfcare(clientId)),
        setClient: (clientId: string) => dispatch(ApiActions.setClient(clientId)),
    }),
)(Component);
