import * as React from 'react';
import { connect } from 'react-redux';
import { LoginFormData, LoginForm } from '../forms/LoginForm';
import { ApiInitialState, ApiActions, ApiCallOptions } from '../../state/api';
import { NewSession, OtpSessionRequest } from '../../api/structure';
import { css } from 'emotion';
import { ModalOtp } from '../modals/ModalOtp';
import { RequestError, JsonValidatorType, safe, JsonValidatorRequired, safePromise } from '../../utils';
import { Form } from '../ux/forms/Form';
import { RegExps } from '../../api/regexps';
import { ModalAlert } from '../modals/ModalAlert';

import * as LogoImg from '../../../assets/logo.invert.svg';
import { AppActions, NotificationType } from '../../state/app';

const styles = {
    root: css`
        width: 100%;
        min-height: 80vh;
        position: relative;
        padding-top: 20vh;
    `,
    form: css`
        margin: auto;
        width: 300px;
    `,
    backLogo: css`
        position: absolute;
        right: 0;
        bottom: 0;
        max-width: 50%;
        max-height: 50%;
        width: 400px;
        height: 400px;
    `,
};

export class Component extends React.Component<{
    token: string;
    requestOtp: (opt: ApiCallOptions, username: string, password: string) => Promise<OtpSessionRequest>;
    verifyOtp: (opt: ApiCallOptions, username: string, password: string, otpToken: string) => Promise<void>;
    login: (opt: ApiCallOptions, username: string, password: string, otp?: string) => Promise<NewSession>;
    pushNotification: (text: string, type: NotificationType) => void;
}> {
    public readonly state = {
        otpNeeded: false,
        otpActivationUri: null,
        modalErrorVisible: false,
        modalError: null,

        username: null,
        password: null,
    };

    public render() {
        return (
            <div className={styles.root}>
                <LoginForm
                    className={styles.form}
                    onLogin={this.onLogin}
                    otpNeeded={this.state.otpNeeded}
                    onCancel={this.onCancel}
                />
                <img className={styles.backLogo} src={LogoImg} />

                <Form
                    action={this.otpActivate}
                    schema={{
                        otpToken: {
                            required: JsonValidatorRequired.True,
                            regexp: RegExps.otpToken,
                            type: JsonValidatorType.String,
                        },
                    }}
                >
                    <ModalOtp
                        onClose={this.onCancel}
                        active={this.state.otpActivationUri}
                        qrcode={this.state.otpActivationUri}
                        title={'Activate Two factor authorization'}
                        text={'You didn\'t activated Two factor authorization yet. You need it before access to console.'}
                        confirmText={'Activate'}
                    />
                    <ModalAlert
                        onClose={this.onCancel}
                        active={this.state.modalErrorVisible}
                        text={this.state.modalError}
                    />
                </Form>
            </div>
        );
    }

    protected onLogin = async (data: LoginFormData & { otpToken?: string; }) => {
        try {
            const session = await (this.props.login({}, data.username, data.password, data.otpToken));
        } catch (e) {
            if (e instanceof RequestError) {
                if (safe(() => e.data.error.key, null) === 'user_not_activated') {
                    this.setState({
                        modalError: e.data.error.message, // TODO
                        modalErrorVisible: true,
                    });
                    return;
                } else if (safe(() => e.data.error.details.find((d) => d.field === 'otpToken').field, false)) {
                    this.setState({
                        otpNeeded: true,
                    });
                    return;
                } else if (safe(() => e.data.error.key, null) === 'otp_not_activated') {
                    this.setState({
                        username: data.username,
                        password: data.password,
                    });
                    return this.requestOtp(data.username, data.password)
                        .catch((err) => {
                            this.props.pushNotification(err.message, 'red');
                        });
                }
            }

            throw e;
        }
    }

    /**
     * Activate otp
     * @param data
     */
    protected otpActivate = async (data: { otpToken: string; }) => {
        try {
            await (this.props.verifyOtp({}, this.state.username, this.state.password, data.otpToken))
            this.setState({
                otpActivationUri: null,
            });
        } catch (err) {
            throw new Error('Invalid OTP code');
        }
    }

    /**
     * Request otp
     */
    protected requestOtp = async (username: string, password: string) => {
        try {
            const otp = await this.props.requestOtp({}, username, password);
            this.setState({
                otpActivationUri: otp.uri,
            });
        } catch (err) {
            this.props.pushNotification(err.message, 'red');
        }
    };

    protected onCancel = () => {
        this.setState({
            otpNeeded: false,
            otpActivationUri: null,
            modalErrorVisible: false,
        });
    }
}

// Export connected component as default
export const LoginPage = connect(
    (state: {api: ApiInitialState}) => ({
        token: state.api.token,
    }),
    (dispatch) => ({
        requestOtp: (opt: ApiCallOptions, username: string, password: string) => dispatch(ApiActions.requestOtp(opt, username, password)),
        verifyOtp: (opt: ApiCallOptions, username: string, password: string, otpToken: string) => dispatch(ApiActions.verifyOtp(opt, username, password, otpToken)),
        login: (opt: ApiCallOptions, username: string, password: string, otp?: string) => dispatch(ApiActions.login(opt, username, password, otp)),
        pushNotification: (text: string, type: NotificationType) => dispatch(AppActions.pushNotification(text, type)),
    }),
)(Component);
