import * as React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { css, cx } from 'emotion';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { rotate } from '../../utils';

export type BadgeColors = 'red' | 'green' | 'yellow' | 'gray' | 'blue';
export type BadgeSizes = 'normal' | 'large';

const styles = {
    badge: css`
        display: inline-block;
        font-weight: 400;
        text-align: center;
        vertical-align: middle;
        user-select: none;
        border: 1px solid #2f69b2;
        padding: 4px;
        font-size: 0.75rem;
        line-height: 1.2;
        border-radius: .25rem;
        color: #fff;
        white-space: nowrap;
        &.red {
            color: #dc3545;
            border-color: #dc3545;
        }
        &.green {
            color: #28a745;
            border-color: #28a745;
        }
        &.yellow {
            color: #ffc107;
            border-color: #ffc107;
        }
        &.gray {
            color: #6c757d;
            border-color: #6c757d;
        }
        &.blue {
            color: #007bff;
            border-color: #007bff;
        }
        &.large {
            font-size: 1rem;
        }
        &.infill {
            &.red {
                color: #fff;
                background-color: #dc3545;
            }
            &.green {
                color: #fff;
                background-color: #28a745;
            }
            &.yellow {
                color: #fff;
                background-color: #ffc107;
            }
            &.gray {
                color: #fff;
                background-color: #6c757d;
            }
            &.blue {
                color: #007bff;
                background-color: #007bff;
            }
        }
    `,
    icon: css`
        margin-right: 0.4em;
        display: inline-block;
    `,
    loading: css`
        animation: ${rotate} 1.2s linear infinite;
    `,
};

interface ComponentProps {
    className?: string;
    icon?: IconProp;
    iconClassName?: string;
    color?: BadgeColors;
    size?: BadgeSizes;
    infill?: boolean;
    loading?: boolean;
}

/**************** Component ***************/
export class Badge extends React.Component<ComponentProps> {
    public render() {
        return (
            <div
                {...this.props}
                className={cx(styles.badge, this.props.className, this.props.color, this.props.size, {['infill']: this.props.infill})}
            >
                {this.props.loading ? (
                    <span className={cx(styles.icon, this.props.iconClassName, styles.loading)}>
                        <FontAwesomeIcon icon={faSpinner} />
                    </span>
                ) : null}
                {this.props.icon ? (
                    <span className={cx(styles.icon, this.props.iconClassName)}>
                        <FontAwesomeIcon icon={this.props.icon} />
                    </span>
                ) : null}
                {this.props.children}
            </div>
        );
    }
}

