import { css, cx } from 'emotion';
import * as React from 'react';
import { fadeOut, fadeIn, isNumber } from '../../utils';
import { Ring } from './Ring';

// tslint:disable-next-line: no-var-requires
const config = require('config');

/**************** Style ***************/
const style = {
    root: css`
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.5);
        z-index: 250;
    `,
    visible: css`
        animation: ${fadeIn} 0.2s linear forwards;
        pointer-events: auto;
    `,
    hidden: css`
        animation: ${fadeOut} 0.2s linear forwards;
        pointer-events: none;
    `,
};

/**************** Component ***************/
export class LoadingOverlay extends React.Component<{
    isLoading: boolean;
}> {
    public readonly state = {
        show: false,
        isLoading: false,
    };

    protected timeout = null;

    public UNSAFE_componentWillUpdate(nextProps, nextState) {
        if (nextState.isLoading !== nextProps.isLoading) {
            if (nextProps.isLoading) {
                this.setTimeout();
            } else {
                this.clearTimeout();
            }
            this.setState({
                isLoading: nextProps.isLoading,
            });
        }
    }

    public componentWillUnmount() {
        if (this.timeout) {
            this.timeout = null;
            clearTimeout(this.timeout);
        }
    }

    public render() {
        const isLoading = this.state.show;
        return (
            <div
                className={cx(style.root, {[style.visible]: isLoading}, {[style.hidden]: !isLoading})}
            >
                <Ring />
            </div>
        );
    }

    protected clearTimeout = () => {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.setState({
                    show: false,
                });
            }, 100);
        }
    };

    protected setTimeout = () => {
        this.clearTimeout();
        this.timeout = setTimeout(() => {
            this.setState({
                show: true,
            });
        }, isNumber(config.loadingHyst) ? config.loadingHyst : 200);
    };
}
