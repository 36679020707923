import * as React from 'react';
import { FormProvidedProps, withFormData } from './FormProvider';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { css, cx } from 'emotion';
import { FormError } from './FormError';
import { isDate } from '../../../utils';
import { dateToTimezone } from '../../../utils/date';

import MomentLocaleUtils from 'react-day-picker/moment';

const styles = {
    root: css`
        display: flex;
        flex-direction: column;
        position: relative;
    `,

    overlay: css`
        position: absolute;
        left: 0;
        top: 100%;
        background: #fff;
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
        z-index: 20;
        border-radius: 3px;

        .DayPicker-Day {
            line-height: 1em;
        }
    `,
    error: css`
        width: 100%;
        display: inline-block;
    `,
    inputWrap: css`
        display: block;
        height: 40px;
        padding: 0;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 4px;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        position: relative;
        cursor: text;
    `,
    label: css`
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        font-size: 16px;
        transition: top 0.2s linear, transform 0.2s linear, font-size 0.2s linear;
        pointer-events: none;
        color: #848b91;
        &.top {
            transform: translateY(0);
            top: 2px;
            font-size: 12px;
        }
    `,
    input: css`
        input {
            display: block;
            line-height: 1.5;
            color: #495057;
            border: none;
            font-size: 16px;
            font-weight: 400;
            padding: 0;
            z-index: 0;
            width: calc(100% - 40px);
            padding: 0 20px;
            height: 25px;
            line-height: 25px;
            margin-top: 15px;
            background: transparent;
        }
    `,
};


/**************** Component props ***************/
interface ComponentProps extends Partial<FormProvidedProps> {
    name: string;
    label?: string;
    labelProps?: React.InputHTMLAttributes<HTMLLabelElement>;
    inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
    className?: string;
    resetOnUnmount?: boolean;
}

/**************** Component ***************/
class Component extends React.Component<ComponentProps, {
    focused: boolean;
}> {
    public readonly state = {
        focused: false,
    };

    protected inputRef = React.createRef<HTMLInputElement>();

    public componentWillUnmount() {
        if (this.props.resetOnUnmount) {
            this.props.formValueRemove(this.props.name);
        }
    }

    /**
     * Render
     */
    public render() {
        const value = this.getValue();
        const labelTop = (isDate(value) || this.state.focused) ? true : false;

        return (
            <div className={cx(styles.root, this.props.className)}>
                {this.props.label ? <label className={cx(styles.label, {['top']: labelTop})}>{this.props.label}</label> : null}
                <div className={styles.inputWrap} onClick={this.onWrapClick}>
                    <DayPickerInput
                        classNames={{
                            container: styles.input,
                            overlayWrapper: styles.overlay,
                            overlay: '',
                        }}
                        formatDate={MomentLocaleUtils.formatDate}
                        parseDate={MomentLocaleUtils.parseDate}
                        placeholder={''}
                        value={value}
                        onDayChange={this.onChange}
                        inputProps={{
                            ref: this.inputRef,
                        }}
                    />
                </div>
                <FormError className={styles.error} name={this.props.name} />
            </div>
        );
    }

    protected onChange = (day) => {
        const d = day ? new Date(dateToTimezone(day)) : null;
        this.props.formValueChange(this.props.name, d);
    }

    protected getValue = () => {
        return this.props.formGetValue(this.props.name);
    }

    protected onWrapClick = (e) => {
        if (this.inputRef.current) {
            this.inputRef.current.focus();
        }
    }
}

export const FormDate = withFormData(Component);
