import { getByExpression } from './expression';

/**
 * Replace all tags ({{tag}}) by value from options
 * @param input
 * @param options
 */
export function templateString(input: string, options: object) {
    return input.replace(
        /\{\{(.+?)\}\}/g,
        function(match, contents, offset, inputString) {
            return getByExpression(options, contents);
        }
    );
}