import * as React from 'react';
import { Global, css } from '@emotion/core';

export function StylesReset() {
    return (
        <Global
            styles={css`
                html, body {
                    border: 0;
                    font-family: "Helvetica-Neue", "Helvetica", Arial, sans-serif;
                    line-height: 1.5;
                    margin: 0;
                    padding: 0;
                }

                div, span, object, iframe, img, table, caption, thead, tbody,
                tfoot, tr, tr, td, article, aside, canvas, details, figure, hgroup, menu,
                nav, footer, header, section, summary, mark, audio, video {
                    border: 0;
                    margin: 0;
                    padding: 0;
                }

                h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, address, cit, code,
                del, dfn, em, ins, q, samp, small, strong, sub, sup, b, i, hr, dl, dt, dd,
                ol, ul, li, fieldset, legend, label {
                    border: 0;
                    font-size: 100%;
                    vertical-align: baseline;
                    margin: 0;
                    padding: 0;
                }

                article, aside, canvas, figure, figure img, figcaption, hgroup,
                footer, header, nav, section, audio, video {
                    display: block;
                }

                table {
                    border-collapse: separate;
                    border-spacing: 0;
                    caption, th, td {
                        text-align: left;
                        vertical-align: middle;
                    }
                }

                a img {
                    border: 0;
                }

                :focus {
                    outline: 0;
                }
            `}
        />
    );
}