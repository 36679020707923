import * as React from 'react';
import { FormProvidedProps, withFormData } from './FormProvider';
import { isArray } from '../../../utils';
import { css, cx } from 'emotion';
import { Button } from '../Button';
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FormError } from './FormError';

const style = {
    list: css``,
    item: css`
        display: flex;
        align-items: flex-start;
        background: #eee;
        position: relative;
        padding: 15px;
        border-radius: 3px;
        margin-bottom: 10px;
    `,
    itemContent: css``,
    deleteButton: css``,
    footer: css``,
};

interface ComponentProps extends Partial<FormProvidedProps> {
    name: string;
    item: (namePrefix: string, data: any) => React.ReactElement;
    itemClassName?: string;
    contentClassName?: string;
    deleteClassName?: string;
    addButtonText?: string;
    defaultItem?: any;
}

class Component extends React.Component<ComponentProps> {
    public render() {
        const data = this.props.formGetValue(this.props.name) || [];
        let items = [];
        if (isArray(data)) {
            items = data.map((item, index) => (
                <div key={`array-item-${index}`} className={cx(style.item, this.props.itemClassName)}>
                    <div className={cx(style.itemContent, this.props.contentClassName)}>
                        {this.props.item(`${this.props.name}[${index}]`, item)}
                    </div>
                    <Button
                        color={'red'}
                        icon={faTrash}
                        onClick={() => this.removeItem(index)}
                        className={cx(style.deleteButton, this.props.deleteClassName)}
                    />
                </div>
            ));
        }

        return (
            <div>
                <div className={style.list}>
                    {items}
                </div>
                <div className={style.footer}>
                    <Button
                        color={'green'}
                        icon={faPlus}
                        onClick={this.addItem}
                    >
                        {this.props.addButtonText ? this.props.addButtonText : 'Add value'}
                    </Button>
                </div>
                <FormError name={this.props.name} />
            </div>
        );
    }

    protected addItem = () => {
        const item = this.props.defaultItem ? this.props.defaultItem : {};
        const data: any[] = this.props.formGetValue(this.props.name) || [];
        this.props.formValueChange(this.props.name, data.concat([item]));
    }

    protected removeItem = (index: number) => {
        const data: any[] = this.props.formGetValue(this.props.name) || [];
        data.splice(index, 1);
        this.props.formValueChange(this.props.name, data);
    }
}

export const FormArray = withFormData(Component);
