import * as cookies from 'browser-cookies';
import { ReduxActionBody, wrapLoadingPromise, RequestError, Dispatchable, ThenArg, OmitFirstArg } from '../utils';
import {
    DatasetEntityPatch,
    DatasetEntity,
    NodeEntity,
    ClientEntity,
    UserEntity,
    UserEntityPatch,
    LogItem,
    RequestEntity,
    DashboardMetrics,
    GetEntity,
    ConnectorEntity,
    NodeEntityClientConnector,
    CreditTransactionEntity,
    CreditBalance,
    ConnectorStatusEntity,
    DashboardMetricsRequests,
    ConnectionAbility,
    SanityRequestResult,
    NodeEntityClientConnectorDetailed,
    CreditInvoicing,
    MacroTemplateEntity,
    ClientIntegrationEntity,
} from '../api/structure';
import { Endpoints, EntityList, EntityListFilter, EntityIndex, TransactionsFilter, SessionReincarnation, CallOptions, InvoicingFilter } from '../api/Endpoints';
import { AppActions } from './app';
import { routes } from '../routes/Router';
import { IRootStore } from '.';

// Constants
const JOINTEFF_SESSION_COOKIE_NAME = 'JOINTEFF_ADMIN_SESSION';
const JOINTEFF_SESSION_CLIENT = 'JOINTEFF_SESSION_CLIENT';

// Constants
export const constants = {
    SET_STATE: 'APP_SET_STATE',
};

export interface ApiCallOptions {
    silent?: boolean;
}

// Store
export class ApiInitialState {
    public readonly version: string;
    public readonly timezone: {offset: number};
    public readonly token: string = cookies.get(JOINTEFF_SESSION_COOKIE_NAME);
    public readonly sessionReincarnation: SessionReincarnation;
    public readonly sessionUser: GetEntity<UserEntity>;
    public readonly sessionClientId: string = cookies.get(JOINTEFF_SESSION_CLIENT);
    public readonly datasets: EntityList<GetEntity<DatasetEntity>>;
    public readonly dataset: GetEntity<DatasetEntity>;
    public readonly datasetIndex: EntityIndex;
    public readonly nodes: EntityList<GetEntity<NodeEntity>>;
    public readonly node: GetEntity<NodeEntity>;
    public readonly nodeConnectors: {[connectorId: string]: NodeEntityClientConnector};
    public readonly nodeConnectorsDetailed: {[connectorId: string]: NodeEntityClientConnectorDetailed};
    public readonly nodeIndex: EntityIndex;
    public readonly clients: EntityList<GetEntity<ClientEntity>>;
    public readonly client: GetEntity<ClientEntity>;
    public readonly clientConnectors: {[id: string]: ConnectorEntity};
    public readonly clientIndex: EntityIndex;
    public readonly clientIndexForAdmin: EntityIndex;
    public readonly clientConnectorsIndex: EntityIndex<EntityIndex<string>>;
    public readonly connectionAbility: ConnectionAbility;
    public readonly users: EntityList<GetEntity<UserEntity>>;
    public readonly user: GetEntity<UserEntity>;
    public readonly requests: EntityList<GetEntity<RequestEntity>>;
    public readonly requestsFetch: EntityList<GetEntity<RequestEntity>>;
    public readonly request: GetEntity<RequestEntity>;
    public readonly dashboard: DashboardMetrics;
    public readonly dashboardRequests: DashboardMetricsRequests;
    public readonly log: EntityList<LogItem>;
    public readonly transactions: EntityList<GetEntity<CreditTransactionEntity>>;
    public readonly balance: CreditBalance;
    public readonly invoicing: CreditInvoicing;
    public readonly clientConnectorsStatuses: {[id: string]: ConnectorStatusEntity[]};
    public readonly clientConnectorsBans: {[id: string]: boolean};
    public readonly sanityRequestResult: SanityRequestResult;
    public readonly macroResult: LogItem[];
    public readonly macroTemplates: GetEntity<MacroTemplateEntity>[];
    public readonly macroTemplate: GetEntity<MacroTemplateEntity>;
    public readonly slackInstallUrl: string;
    public readonly clientIntegration: GetEntity<ClientIntegrationEntity>;
}

// Actions
export class ApiActions {
    /**
     * Generic function to set values to store
     * @param values
     */
    public static setState(values: Partial<ApiInitialState>): ReduxActionBody {
        return {
            type: constants.SET_STATE,
            values,
        };
    }

    /**
     * Info
     */

    /**
     * Get version
     */
    public static getVersion(options: ApiCallOptions) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.getVersion,
            (data, dispatch) => {
                dispatch(ApiActions.setState({
                    version: data,
                }));
                return data;
            },
        );
    }

    /**
     * Get timezone
     */
     public static getTimezone(options: ApiCallOptions) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.getTimezone,
            (data, dispatch) => {
                dispatch(ApiActions.setState({
                    timezone: data,
                }));
                return data;
            },
        );
    }

    /**
     * Log
     */

    /**
     * List log
     * @param options
     */
    public static listLog(options: ApiCallOptions, filter?: EntityListFilter<LogItem>) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.listLog,
            (data, dispatch) => {
                dispatch(ApiActions.setState({
                    log: data,
                }));
                return data;
            },
            filter,
        );
    }

    /**
     * Session
     */

    /**
     * Request otp
     * @param username
     * @param password
     * @param otp
     */
    public static requestOtp(options: ApiCallOptions, username: string, password: string) {
        return ApiActions.apiCall(
            options,
            Endpoints.requestOtp,
            (data, dispatch) => data,
            username,
            password,
        );
    }

    /**
     * Request otp
     * @param username
     * @param password
     * @param otp
     */
    public static verifyOtp(options: ApiCallOptions, username: string, password: string, otpToken: string) {
        return ApiActions.apiCall(
            options,
            Endpoints.verifyOtp,
            (data, dispatch) => data,
            username,
            password,
            otpToken,
        );
    }

    /**
     * Login action
     * @param username
     * @param password
     * @param otp
     */
    public static login(options: ApiCallOptions, username: string, password: string, otp?: string) {
        return ApiActions.apiCall(
            options,
            Endpoints.login,
            (session, dispatch, state) => {
                // creates cookie with session
                cookies.set(JOINTEFF_SESSION_COOKIE_NAME, session.token, {expires: session.expiry});

                let sessionClientId = state.api.sessionClientId;
                const userClients = (session.user?.clientsId || [])
                if (!userClients.includes(sessionClientId)) {
                    sessionClientId = userClients[0]
                }

                if (sessionClientId) {
                    cookies.set(JOINTEFF_SESSION_CLIENT, sessionClientId);
                } else {
                    cookies.erase(JOINTEFF_SESSION_CLIENT);
                }

                dispatch(ApiActions.setState({
                    token: session.token,
                    sessionUser: session.user,
                    sessionClientId,
                }));
            },
            username,
            password,
            otp,
        );
    }

    /**
     * Get session
     */
    public static getSession(options: ApiCallOptions) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.getSession,
            (session, dispatch, state) => {
                const token = state.api.token;
                const reincarnation: SessionReincarnation = state.api.sessionReincarnation;
                // creates cookie with session
                cookies.set(JOINTEFF_SESSION_COOKIE_NAME, token, {expires: session.expiry});
                if (reincarnation) {
                    dispatch(ApiActions.setState({
                        sessionUser: {
                            ...session.user,
                            type: reincarnation.role,
                        },
                        sessionClientId: null,
                    }));
                } else {
                    let sessionClientId = state.api.sessionClientId;
                    const userClients = (session.user?.clientsId || [])
                    if (!userClients.includes(sessionClientId)) {
                        sessionClientId = userClients[0]
                    }
                    dispatch(ApiActions.setState({
                        sessionUser: session.user,
                        sessionClientId,
                    }));
                }
                return session;
            },
        );
    }

    /**
     * Logout
     */
    public static logout() {
        return function(dispatch, getState) {
            const token = getState().api.token;
            if (!token) {
                return;
            }

            cookies.erase(JOINTEFF_SESSION_COOKIE_NAME);
            cookies.erase(JOINTEFF_SESSION_CLIENT);
            dispatch(ApiActions.setState({
                token: null,
                sessionUser: null,
            }));

            return Endpoints.logout(token)
                .catch((err: RequestError) => null);
        };
    }

    /**************************************
     * Datasets endpoints
     **************************************/

    /**
     * List datasets
     */
    public static listDatasets(options: ApiCallOptions, filter: EntityListFilter<DatasetEntity>) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.listDatasets,
            (data, dispatch, state) => {
                dispatch(ApiActions.setState({
                    datasets: data,
                }));
                return data;
            },
            filter,
        );
    }

    /**
     * Get dataset index
     */
    public static getDatasetIndex(options: ApiCallOptions) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.getDatasetsIndex,
            (data, dispatch, state) => {
                dispatch(ApiActions.setState({
                    datasetIndex: data,
                }));
                return data;
            },
        );
    }

    /**
     * Get dataset
     */
    public static getDataset(options: ApiCallOptions, id: string) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.getDataset,
            (data, dispatch, state) => {
                dispatch(ApiActions.setState({
                    dataset: data,
                }));
                return data;
            },
            id,
        );
    }

    /**
     * Patch dataset
     */
    public static patchDataset(options: ApiCallOptions, id: string, inputData: Partial<DatasetEntityPatch>) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.patchDataset,
            (data, dispatch, state) => {
                dispatch(ApiActions.setState({
                    dataset: data,
                }));
                return data;
            },
            id,
            inputData,
        );
    }

    /**
     * Post dataset
     */
    public static postDataset(options: ApiCallOptions, inputData: DatasetEntity) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.postDataset,
            (data, dispatch, state) => {
                dispatch(ApiActions.setState({
                    dataset: data,
                }));
                return data;
            },
            inputData,
        );
    }

    /**
     * Delete dataset
     */
    public static deleteDataset(options: ApiCallOptions, id: string) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.deleteDataset,
            (data, dispatch, state) => data,
            id,
        );
    }

    /**************************************
     * Nodes endpoints
     **************************************/

    /**
     * List nodes
     */
    public static listNodes(options: ApiCallOptions, filter: EntityListFilter<NodeEntity>) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.listNodes,
            (data, dispatch, state) => {
                dispatch(ApiActions.setState({
                    nodes: data,
                }));
                return data;
            },
            filter,
        );
    }

    /**
     * Get node
     */
    public static getNode(options: ApiCallOptions, id: string) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.getNode,
            (data, dispatch, state) => {
                dispatch(ApiActions.setState({
                    node: data,
                }));
                return data;
            },
            id,
        );
    }

    /**
     * Get node index
     */
    public static getNodesIndex(options: ApiCallOptions) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.getNodesIndex,
            (data, dispatch, state) => {
                dispatch(ApiActions.setState({
                    nodeIndex: data,
                }));
                return data;
            },
        );
    }

    /**
     * Patch node
     */
    public static patchNode(options: ApiCallOptions, id: string, inputData: Partial<NodeEntity>) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.patchNode,
            (data, dispatch, state) => {
                dispatch(ApiActions.setState({
                    node: data,
                }));
                return data;
            },
            id,
            inputData
        );
    }

    /**
     * Post node
     */
    public static postNode(options: ApiCallOptions, inputData: NodeEntity) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.postNode,
            (data, dispatch, state) => {
                dispatch(ApiActions.setState({
                    node: data,
                }));
                return data;
            },
            inputData
        );
    }

    /**
     * Delete node
     */
    public static deleteNode(options: ApiCallOptions, id: string) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.deleteNode,
            (data, dispatch, state) => data,
            id,
        );
    }

    /**
     * Get node connectors
     */
    public static getNodeConnectors(options: ApiCallOptions, nodeId: string) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.getNodeConnectors,
            (data, dispatch, state) => {
                const nodeConnectors = data.reduce((result, connector) => {
                    result[connector.connectorId] = connector;
                    return result;
                }, {} as {[id: string]: NodeEntityClientConnector});

                dispatch(ApiActions.setState({
                    nodeConnectors,
                }));
                return data;
            },
            nodeId,
        );
    }

    /**
     * Get node connectors detailed
     */
     public static getNodeConnectorsDetailed(options: ApiCallOptions, nodeId: string) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.getNodeConnectorsDetailed,
            (data, dispatch, state) => {
                const nodeConnectorsDetailed = data.reduce((result, connector) => {
                    result[connector.connectorId] = connector;
                    return result;
                }, {} as {[id: string]: NodeEntityClientConnectorDetailed});

                dispatch(ApiActions.setState({
                    nodeConnectorsDetailed,
                }));
                return data;
            },
            nodeId,
        );
    }

    /**
     * Clear node connectors
     */
    public static clearNodeConnectors() {
        return function(dispatch, getState) {
            dispatch(ApiActions.setState({
                nodeConnectors: {},
                nodeConnectorsDetailed: {},
            }));
        };
    }

    /**
     * Post node connector
     */
    public static postNodeConnector(options: ApiCallOptions, nodeId: string, inputData: NodeEntityClientConnector) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.postNodeConnector,
            (data, dispatch, state) => dispatch(ApiActions.getNodeConnectors(options, nodeId)),
            nodeId,
            inputData
        );
    }

    /**
     * Patch node connector
     */
    public static patchNodeConnector(options: ApiCallOptions, nodeId: string, connectorId: string, inputData: Partial<NodeEntityClientConnector>) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.patchNodeConnector,
            (data, dispatch, state) => dispatch(ApiActions.getNodeConnectors(options, nodeId)),
            nodeId,
            connectorId,
            inputData,
        );
    }

    /**
     * Delete node connector
     */
    public static deleteNodeConnector(options: ApiCallOptions, nodeId: string, connectorId: string) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.deleteNodeConnector,
            (data, dispatch, state) => dispatch(ApiActions.getNodeConnectors(options, nodeId)),
            nodeId,
            connectorId,
        );
    }

    /**************************************
     * Clients endpoints
     **************************************/

    /**
     * List clients
     */
    public static listClients(options: ApiCallOptions, filter: EntityListFilter<ClientEntity>) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.listClients,
            (data, dispatch, state) => {
                dispatch(ApiActions.setState({
                    clients: data,
                }));
                return data;
            },
            filter,
        );
    }

    /**
     * Get client
     */
    public static getClient(options: ApiCallOptions, id: string) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.getClient,
            (data, dispatch) => {
                dispatch(ApiActions.setState({
                    client: data,
                }));
                return data;
            },
            id,
        );
    }

    /**
     * Get clients index
     */
    public static getClientsIndex(options: ApiCallOptions) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.getClientsIndex,
            (data, dispatch, state) => {
                dispatch(ApiActions.setState({
                    clientIndex: data,
                }));
                return data;
            },
        );
    }

    /**
     * Get clients index for admin
     */
     public static getClientsIndexForAdmin(options: ApiCallOptions) {
        return ApiActions.authorizedApiCallWithoutReincarnation(
            options,
            Endpoints.getClientsIndex,
            (data, dispatch, state) => {
                dispatch(ApiActions.setState({
                    clientIndexForAdmin: data,
                }));
                return data;
            },
        );
    }

    /**
     * Get clients connectors index
     */
    public static getClientsConnectorsIndex(options: ApiCallOptions) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.getClientsConnectorsIndex,
            (data, dispatch, state) => {
                dispatch(ApiActions.setState({
                    clientConnectorsIndex: data,
                }));
                return data;
            },
        );
    }

    /**
     * Patch client
     */
    public static patchClient(options: ApiCallOptions, id: string, inputData: Partial<ClientEntity>) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.patchClient,
            (data, dispatch, state) => {
                dispatch(ApiActions.setState({
                    client: data,
                }));
                return data;
            },
            id,
            inputData,
        );
    }

    /**
     * Post client
     */
    public static postClient(options: ApiCallOptions, inputData: ClientEntity) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.postClient,
            (data, dispatch, state) => {
                dispatch(ApiActions.setState({
                    client: data,
                }));
                return data;
            },
            inputData,
        );
    }

    /**
     * Delete client
     */
    public static deleteClient(options: ApiCallOptions, id: string) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.deleteClient,
            (data, dispatch, state) => data,
            id,
        );
    }

    /**
     * Get client connectors
     */
    public static getClientConnectors(options: ApiCallOptions, clientId: string) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.getClientConnectors,
            (data, dispatch, state) => {
                const clientConnectors = data.reduce((result, connector) => {
                    result[connector._id] = connector;
                    return result;
                }, {} as {[id: string]: GetEntity<ConnectorEntity>});

                dispatch(ApiActions.setState({
                    clientConnectors,
                }));
                return clientConnectors;
            },
            clientId,
        );
    }

    /**
     * Get client connector statuses
     */
    public static getClientConnectorStatus(options: ApiCallOptions, clientId: string, connectorId: string, limit: number = 5) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.getClientConnectorStatus,
            (data, dispatch, state) => {
                const clientConnectorsStatuses = {
                    ...state.api.clientConnectorsStatuses,
                    [connectorId]: data,
                };
                dispatch(ApiActions.setState({
                    clientConnectorsStatuses,
                }));
                return clientConnectorsStatuses;
            },
            clientId,
            connectorId,
            limit,
        );
    }

    /**
     * Get client connector isBanned
     */
    public static getClientConnectorIsBanned(options: ApiCallOptions, clientId: string, connectorId: string) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.getClientConnectorIsBanned,
            (data, dispatch, state) => {
                const clientConnectorsBans = {
                    ...state.api.clientConnectorsBans,
                    [connectorId]: data,
                };
                dispatch(ApiActions.setState({
                    clientConnectorsBans,
                }));
                return clientConnectorsBans;
            },
            clientId,
            connectorId,
        );
    }

    /**
     * Clear client connectors
     */
    public static clearClientConnectors() {
        return function(dispatch, getState) {
            dispatch(ApiActions.setState({
                clientConnectors: {},
            }));
        };
    }

    /**
     * Post client connector
     */
    public static postClientConnector(options: ApiCallOptions, clientId: string, inputData: Partial<ConnectorEntity>) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.postClientConnector,
            (data, dispatch, state) => dispatch(ApiActions.getClientConnectors(options, clientId)),
            clientId,
            inputData,
        );
    }

    /**
     * Patch client connector
     */
    public static patchClientConnector(options: ApiCallOptions, clientId: string, connectorId: string, inputData: Partial<ConnectorEntity>) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.patchClientConnector,
            (data, dispatch, state) => dispatch(ApiActions.getClientConnectors(options, clientId)),
            clientId,
            connectorId,
            inputData,
        );
    }

    /**
     * Delete client connector
     */
    public static deleteClientConnector(options: ApiCallOptions, clientId: string, connectorId: string) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.deleteClientConnector,
            (data, dispatch, state) => dispatch(ApiActions.getClientConnectors(options, clientId)),
            clientId,
            connectorId,
        );
    }

    /**
     * Activate client connector
     */
    public static postClientConnectorActivate(options: ApiCallOptions, clientId: string, connectorId: string) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.postClientConnectorActivate,
            (data, dispatch, state) => dispatch(ApiActions.getClientConnectors(options, clientId)),
            clientId,
            connectorId,
        );
    }

    /**
     * Deactivate client connector
     */
    public static postClientConnectorDeactivate(options: ApiCallOptions, clientId: string, connectorId: string) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.postClientConnectorDeactivate,
            (data, dispatch, state) => dispatch(ApiActions.getClientConnectors(options, clientId)),
            clientId,
            connectorId,
        );
    }

    /**
     * Get client connectors ability
     */
    public static getClientConnectionAbility(options: ApiCallOptions, clientId: string) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.getClientConnectionAbility,
            (data, dispatch, state) => {
                dispatch(ApiActions.setState({
                    connectionAbility: data,
                }));
                return data;
            },
            clientId,
        );
    }

    /**************************************
     * Client integration endpoints
     **************************************/

    /**
     * Get client slack-jwt
     */
     public static getClientIntegrationSlackInstall(options: ApiCallOptions) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.getClientIntegrationSlackInstall,
            (data, dispatch, state) => {
                dispatch(ApiActions.setState({
                    slackInstallUrl: data,
                }));
                return data;
            }
        );
    }

    /**
     * Get client integration
     */
     public static getClientIntegration(options: ApiCallOptions) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.getClientIntegration,
            (data, dispatch, state) => {
                dispatch(ApiActions.setState({
                    clientIntegration: data,
                }));
                return data;
            }
        );
    }

    /**
     * Post client integration
     */
     public static postClientIntegration(options: ApiCallOptions, data: Partial<ClientIntegrationEntity>) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.postClientIntegration,
            (data, dispatch, state) => {
                dispatch(ApiActions.setState({
                    clientIntegration: data,
                }));
                return data;
            },
            data,
        );
    }

    /**
     * Delete client integration slack
     */
     public static deleteClientIntegrationSlack(options: ApiCallOptions) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.deleteClientIntegrationSlack,
            (data, dispatch, state) => {
                dispatch(ApiActions.setState({
                    clientIntegration: data,
                }));
                return data;
            }
        );
    }

    /**
     * Get client integration welcome
     */
     public static getClientIntegrationWelcome(options: ApiCallOptions) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.getClientIntegrationWelcome,
            (data, dispatch, state) => data,
        );
    }

    /**************************************
     * Users endpoints
     **************************************/

    /**
     * List clients
     */
    public static listUsers(options: ApiCallOptions, filter: EntityListFilter<UserEntity>) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.listUsers,
            (data, dispatch, state) => {
                dispatch(ApiActions.setState({
                    users: data,
                }));
                return data;
            },
            filter,
        );
    }

    /**
     * Get user
     */
    public static getUser(options: ApiCallOptions, id: string) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.getUser,
            (data, dispatch, state) => {
                dispatch(ApiActions.setState({
                    user: data,
                }));
                return data;
            },
            id,
        );
    }

    /**
     * Patch user
     */
    public static patchUser(options: ApiCallOptions, id: string, inputData: Partial<UserEntityPatch>) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.patchUser,
            (data, dispatch, state) => {
                dispatch(ApiActions.setState({
                    user: data,
                }));
                return data;
            },
            id,
            inputData,
        );
    }

    /**
     * Post user
     */
    public static postUser(options: ApiCallOptions, inputData: UserEntity) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.postUser,
            (data, dispatch, state) => {
                dispatch(ApiActions.setState({
                    user: data,
                }));
                return data;
            },
            inputData,
        );
    }

    /**
     * Delete user
     */
    public static deleteUser(options: ApiCallOptions, id: string) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.deleteUser,
            (data, dispatch, state) => data,
            id,
        );
    }

    /**
     * Activate user
     */
    public static activateUser(options: ApiCallOptions, id: string) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.putUserActivate,
            (data, dispatch, state) => data,
            id,
        );
    }

    /**
     * Dectivate user
     */
    public static deactivateUser(options: ApiCallOptions, id: string) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.putUserDeactivate,
            (data, dispatch, state) => data,
            id,
        );
    }

    /**
     * Request user otp
     * @param username
     * @param password
     * @param otp
     */
    public static putUserOtpRequest(options: ApiCallOptions, id: string) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.putUserOtpRequest,
            (data, dispatch, state) => data,
            id,
        );
    }

    /**
     * Verify user otp
     * @param otpToken
     */
    public static putUserOtpVerify(options: ApiCallOptions, id: string, otpToken: string) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.putUserOtpVerify,
            (data, dispatch, state) => data,
            id,
            otpToken,
        );
    }

    /**
     * Reset user otp
     */
    public static putUserOtpReset(options: ApiCallOptions, id: string) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.putUserOtpReset,
            (data, dispatch, state) => data,
            id,
        );
    }

    /**
     * Invite user
     */
    public static inviteSelfcareUser(options: ApiCallOptions, email: string, clientId: string) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.inviteSelfcareUser,
            (data, dispatch, state) => data,
            email,
            clientId,
        );
    }

    /**
     * Post userreset password
     */
    public static postUserResetPassword(options: ApiCallOptions, id: string) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.postUserResetPassword,
            (data, dispatch, state) => data,
            id,
        );
    }

    /**************************************
     * Requests endpoints
     **************************************/

    /**
     * List requests
     */
    public static listRequests(options: ApiCallOptions, filter: EntityListFilter<GetEntity<RequestEntity>>) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.listRequests,
            (data, dispatch, state) => {
                dispatch(ApiActions.setState({
                    requests: data,
                }));
                return data;
            },
            filter,
        );
    }

    /**
     * List requests fetch
     */
    public static listRequestsFetch(options: ApiCallOptions, filter: EntityListFilter<GetEntity<RequestEntity>>) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.listRequestsFetch,
            (data, dispatch, state) => {
                dispatch(ApiActions.setState({
                    requestsFetch: data,
                }));
                return data;
            },
            filter,
        );
    }

    /**
     * Get request
     */
    public static getRequest(options: ApiCallOptions, id: string) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.getRequest,
            (data, dispatch, state) => {
                dispatch(ApiActions.setState({
                    request: data,
                }));
                return data;
            },
            id,
        );
    }

    /**
     * Anonymize request
     */
    public static anonymizeRequest(options: ApiCallOptions, id: string) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.anonymizeRequest,
            (data, dispatch, state) => data,
            id,
        );
    }

    /**************************************
     * Dashboard endpoints
     **************************************/
    /**
     * Get dashboard
     */
    public static getDashboard(options: ApiCallOptions) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.getDashboard,
            (data, dispatch, state) => {
                dispatch(ApiActions.setState({
                    dashboard: data,
                }));
                return data;
            },
        );
    }

    /**
     * Get dashboard request
     */
    public static getDashboardRequests(options: ApiCallOptions) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.getDashboardRequests,
            (data, dispatch, state) => {
                dispatch(ApiActions.setState({
                    dashboardRequests: data,
                }));
                return data;
            },
        );
    }

    /**************************************
     * Transactions endpoints
     **************************************/

    /**
     * List transactions
     */
    public static listTransactions(options: ApiCallOptions, filter: TransactionsFilter) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.listTransactions,
            (data, dispatch, state) => {
                dispatch(ApiActions.setState({
                    transactions: data,
                }));
                return data;
            },
            filter,
        );
    }

    /**
     * Get balance
     */
    public static getCreditBalance(options: ApiCallOptions) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.getCreditBalance,
            (data, dispatch, state) => {
                dispatch(ApiActions.setState({
                    balance: data,
                }));
                return data;
            },
        );
    }

    /**
     * Deposit credits
     */
    public static putCreditDeposit(options: ApiCallOptions, clientId: string, amount: number, message?: string) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.putCreditDeposit,
            (data, dispatch, state) => dispatch(ApiActions.getCreditBalance(options)),
            clientId,
            amount,
            message,
        );
    }

    /**
     * Withdraw credits
     */
    public static putCreditWithdraw(options: ApiCallOptions, clientId: string, amount: number, message?: string) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.putCreditWithdraw,
            (data, dispatch, state) => dispatch(ApiActions.getCreditBalance(options)),
            clientId,
            amount,
            message,
        );
    }

    /**
     * Get balance
     */
     public static getCreditInvoicing(options: ApiCallOptions, filter: InvoicingFilter) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.getCreditInvoicing,
            (data, dispatch, state) => {
                dispatch(ApiActions.setState({
                    invoicing: data,
                }));
                return data;
            },
            filter,
        );
    }

    /**************************************
     * Sanity endpoints
     **************************************/

    /**
     * Put sanity request
     */
    public static putSanityRequest(options: ApiCallOptions, nodeId: string, connectorId: string, query: string) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.putSanityRequest,
            (data, dispatch, state) => {
                dispatch(ApiActions.setState({
                    sanityRequestResult: data,
                }));
                return data;
            },
            nodeId,
            connectorId,
            query,
        );
    }

    /**************************************
     * Macro script endpoints
     **************************************/

    /**
     * Post macro script execution
     */
     public static postMacroExecute(options: ApiCallOptions, script: string, params: any) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.postMacroExecute,
            (data) => data,
            script,
            params,
        );
    }

    /**
     * Get macro script result
     */
    public static getMacroResult(options: ApiCallOptions, hash: string) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.getMacroResult,
            (data, dispatch, state) => {
                dispatch(ApiActions.setState({
                    macroResult: data.value,
                }));
                return data;
            },
            hash,
        );
    }

    /**
     * Get macro template list
     */
     public static getMacroTemplateList(options: ApiCallOptions) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.getMacroTemplateList,
            (data, dispatch, state) => {
                dispatch(ApiActions.setState({
                    macroTemplates: data,
                }));
                return data;
            },
        );
    }

    /**
     * Post macro template
     */
     public static postMacroTemplate(options: ApiCallOptions, entity: MacroTemplateEntity) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.postMacroTemplate,
            (data, dispatch, state) => {
                dispatch(ApiActions.setState({
                    macroTemplate: data,
                }));
                return data;
            },
            entity,
        );
    }

    /**
     * Post macro template
     */
     public static patchMacroTemplate(options: ApiCallOptions, id: string, entity: MacroTemplateEntity) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.patchMacroTemplate,
            (data, dispatch, state) => {
                dispatch(ApiActions.setState({
                    macroTemplate: data,
                }));
                return data;
            },
            id,
            entity,
        );
    }

    /**
     * Delete macro template
     */
     public static deleteMacroTemplate(options: ApiCallOptions, id: string) {
        return ApiActions.authorizedApiCall(
            options,
            Endpoints.deleteMacroTemplate,
            (data, dispatch, state) => data,
            id,
        );
    }

    /**************************************
     * Admin controll
     **************************************/

    /**
     * Reincarnate admin to selfcare
     * @param clientId
     */
    public static reincarnateToSelfcare(clientId: string) {
        return function(dispatch, getState) {
            const sessionUser: UserEntity = getState().api.sessionUser;
            const reincarnation = getState().api.sessionReincarnation;
            if (sessionUser.type === 'admin' || reincarnation) {
                dispatch(ApiActions.setState({
                    sessionReincarnation: {
                        role: 'selfcare',
                        clientId,
                    },
                    sessionClientId: null,
                }));
                dispatch(AppActions.redirectTo(routes.home.path));
                dispatch(ApiActions.getSession({}));
                dispatch(ApiActions.getClientsIndex({}));
            }
        };
    }

    /**
     * Reincarnate admin back
     */
    public static reincarnateToAdmin() {
        return function(dispatch, getState) {
            const sessionUser: UserEntity = getState().api.sessionUser;
            const reincarnation = getState().api.sessionReincarnation;
            if (sessionUser.type === 'admin' || reincarnation) {
                dispatch(ApiActions.setState({
                    sessionReincarnation: null,
                    sessionClientId: null,
                }));
                dispatch(AppActions.redirectTo(routes.home.path));
                dispatch(ApiActions.getSession({}));
                dispatch(ApiActions.getClientsIndex({}));
            }
        };
    }

    /**************************************
     * Selfcare controll
     **************************************/

    /**
     * Reincarnate admin to selfcare
     * @param clientId
     */
    public static setClient(sessionClientId: string) {
        return function(dispatch, getState) {

            if (sessionClientId) {
                cookies.set(JOINTEFF_SESSION_CLIENT, sessionClientId);
            } else {
                cookies.erase(JOINTEFF_SESSION_CLIENT);
            }

            dispatch(ApiActions.setState({
                sessionClientId,
                sessionReincarnation: null,
            }));
            dispatch(AppActions.redirectTo(routes.home.path));
        };
    }

    /**
     * Wrap api call with auth check
     * @param callOptions
     * @param fnc
     * @param then
     * @param args
     */
    protected static authorizedApiCall<T extends (opt: CallOptions, ...args: any[]) => Promise<ThenArg<ReturnType<T>>>>(
        callOptions: ApiCallOptions,
        fnc: T,
        then: (data: ThenArg<ReturnType<T>>, dispatch: (a) => ReduxActionBody, state?: IRootStore) => any,
        ...args: Parameters<OmitFirstArg<T>>
    ) {
        return function(dispatch, getState) {
            const state = getState();
            const options = {
                token: state.api.token,
                reincarnation: state.api.sessionReincarnation,
                clientId: state.api.sessionClientId,
            }
            if (!options) {
                return;
            }

            return wrapLoadingPromise(
                dispatch,
                fnc(options, ...args).then((data) => then(data, dispatch, getState())),
                callOptions.silent,
            );
        };
    }

    /**
     * Wrap api call with auth check
     * @param callOptions
     * @param fnc
     * @param then
     * @param args
     */
     protected static authorizedApiCallWithoutReincarnation<T extends (opt: CallOptions, ...args: any[]) => Promise<ThenArg<ReturnType<T>>>>(
        callOptions: ApiCallOptions,
        fnc: T,
        then: (data: ThenArg<ReturnType<T>>, dispatch: (a) => ReduxActionBody, state?: IRootStore) => any,
        ...args: Parameters<OmitFirstArg<T>>
    ) {
        return function(dispatch, getState) {
            const state = getState();
            const options = {
                token: state.api.token,
            }
            if (!options) {
                return;
            }

            return wrapLoadingPromise(
                dispatch,
                fnc(options, ...args).then((data) => then(data, dispatch, getState())),
                callOptions.silent,
            );
        };
    }

    /**
     * Wrap api call
     * @param callOptions
     * @param fnc
     * @param then
     * @param args
     */
    protected static apiCall<T extends (...args: any[]) => Promise<ThenArg<ReturnType<T>>>>(
        callOptions: ApiCallOptions,
        fnc: T,
        then: (data: ThenArg<ReturnType<T>>, dispatch: (a) => ReduxActionBody, state?: IRootStore) => any,
        ...args: Parameters<T>
    ) {
        return function(dispatch, getState) {
            return wrapLoadingPromise(
                dispatch,
                fnc(...args).then((data) => then(data, dispatch, getState())),
                callOptions.silent,
            );
        };
    }
}


// Reducer
export const ApiReducer = (state: ApiInitialState = new ApiInitialState(), action: ReduxActionBody) => {
    switch (action.type) {
        case constants.SET_STATE:
            return {
                ...state,
                ...action.values,
            };
            break;
        default:
            return state;
    }
};
