import * as React from 'react';
import { connect } from 'react-redux';
import { GetEntity, RequestEntity } from '../../api/structure';
import { JSONView } from '../ux/JSONView';
import { IRootStore } from '../../state';
import { ApiActions, ApiCallOptions } from '../../state/api';
import { Alert } from '../ux/Alert';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { localDate } from '../../utils/date';
import { safePromise } from '../../utils';

class RequestResponseComponent extends React.Component<{
    request: GetEntity<RequestEntity>;
    getRequest: (opt: ApiCallOptions, id: string) => Promise<GetEntity<RequestEntity>>;
    id: string;
}, {
    loaded: boolean;
}> {
    public componentDidMount() {
        safePromise(this.props.getRequest({}, this.props.id))
            .then(() => this.setState({loaded: true}));
    }

    public render() {
        const { request } = this.props;
        if (request?.anonymizedOn) {
            return (
                <>
                    <Alert icon={faUserSecret} color={'red'}>Request was anonymized on {localDate(request.anonymizedOn)}</Alert>
                </>
            );
        }
        return (<JSONView collapsed={false} openAll data={request?.finalResponse || {}} />);
    }
}

export const RequestResponse = connect(
    (state: IRootStore) => ({
        request: state.api.request,
    }),
    (dispatch) => ({
        getRequest: (opt: ApiCallOptions, id: string) => dispatch(ApiActions.getRequest(opt, id)),
    }),
)(RequestResponseComponent);