import * as cookie from 'cookie';
import { safe } from './safe';
import { isNumber, isDate, isObject } from './typechecks';

export class Storage {
    public static getValue(key: string, defaultValue?: any) {
        return safe(
            (() => JSON.parse(localStorage.getItem(key))),
            defaultValue,
        );
    }

    public static setValue(key: string, value: any) {
        safe(
            () => localStorage.setItem(key, JSON.stringify(value)),
            null,
        );
    }

    public static setCookie(key: string, value: string, ttl?: number | Date) {
        let expires = undefined;
        if (isNumber(ttl)) {
            expires = new Date(Date.now() + ttl);
        } else if (isDate(ttl)) {
            expires = ttl;
        }

        const ck = cookie.serialize('token', 'testToken', {
            expires,
        });
        document.cookie = ck;
    }

    public static getCookie(key: string): string {
        const ck = cookie.parse(document.cookie);
        return isObject(ck) ? ck[key] : null;
    }
}