import { css } from 'emotion';
import * as React from 'react';
import { connect } from 'react-redux';
import { AppInitialState, AppActions, Notification } from '../../state/app';
import { NotificationsComponent } from '../ux/NotificationsComponent';

/**************** Component props ***************/
interface ComponentProps extends React.Props<Component> {
    notifications: Notification[];
    popOldNotifications: () => void;
    popNotification: (id: string) => void;
}

/**************** Component ***************/
class Component extends React.Component<ComponentProps> {
    protected interval = null;

    public componentDidMount() {
        this.interval = setInterval(this.intervalTick, 5000);
    }

    public componentWillUnmount() {
        clearInterval(this.interval);
    }

    public render() {
        return (
            <NotificationsComponent notifications={this.props.notifications} onDismiss={this.onDismiss}/>
        );
    }

    protected onDismiss = (id: string) => {
        this.props.popNotification(id);
    }

    protected intervalTick = () => {
        this.props.popOldNotifications();
    };
}

// Export connected component as default
export const Notifications = connect(
    (state: {app: AppInitialState}) => ({
        notifications: state.app.notifications,
    }),
    (dispatch) => ({
        popOldNotifications: () => dispatch(AppActions.popOldNotifications()),
        popNotification: (id: string) => dispatch(AppActions.popNotification(id)),
    }),
)(Component);
