import * as React from 'react';
import { FormProvidedProps, withFormData } from './FormProvider';
import { Button, ButtonColors, ComponentProps as ButtonProps } from '../Button';
import { ModalConfirm } from '../../modals/ModalConfirm';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

/**************** Component props ***************/
interface ComponentProps extends ButtonProps, FormProvidedProps {
    confirmation?: {
        text?: string;
        title?: string;
        confirmText: string;
        confirmIcon?: IconProp;
        confirmColor?: ButtonColors;
        onConfirm?: () => void;
    };
    changeValue: {
        name: string;
        value: any;
    };
}

/**************** Component ***************/
class Component extends React.Component<ComponentProps, {
    confirmActive: boolean;
}> {
    constructor(props) {
        super(props);

        this.state = {
            confirmActive: false,
        };
    }

    /**
     * Render
     */
    public render() {
        return (
            <>
                <Button
                    {...this.props}
                    onClick={this.onClick}
                >
                    {this.props.children}
                </Button>
                <ModalConfirm
                    onClose={this.onConfirmClose}
                    onConfirm={this.onConfirm}
                    active={this.state.confirmActive}
                    text={this.props.confirmation.text}
                    title={this.props.confirmation.title}
                    confirmText={this.props.confirmation.confirmText}
                    confirmIcon={this.props.confirmation.confirmIcon}
                    confirmColor={this.props.confirmation.confirmColor}
                />
            </>
        );
    }

    protected onClick = (e) => {
        if (this.props.onClick) {
            this.props.onClick(e);
        }
        if (this.props.confirmation) {
            this.setState({confirmActive: true});
        } else {
            this.action();
        }
    }

    protected onConfirmClose = () => {
        this.setState({confirmActive: false});
    }

    protected onConfirm = () => {
        this.setState({confirmActive: false}, () => {
            if (this.props.confirmation.onConfirm) {
                this.props.confirmation.onConfirm();
            }
            this.action();
        });
    }

    protected action = () => {
        if (this.props.changeValue) {
            this.props.formValueChange(this.props.changeValue.name, this.props.changeValue.value);
        }
    }
}

export const FormSetValueButton = withFormData(Component);
