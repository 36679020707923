import { safe } from "./safe";

export function localDate(isoDate: string): string {
    return safe(() => new Date(isoDate).toLocaleString(), 'N/A');
}

export function dateToTimezone(date: Date, offset: number = 0) {
    const offsetString = offset === 0 ? 'Z' : `${offset < 0 ? '-' : '+'}${offset < 10 ? `0${Math.abs(offset)}` : Math.abs(offset)}:00`
    return date.toISOString().split('T')[0]+`T00:00:00.000` + offsetString;
}
