import * as React from 'react';
import { css } from 'emotion';
import { SectionHeader } from '../components/ux/SectionHeader';
import { connect } from 'react-redux';
import { ApiInitialState, ApiActions, ApiCallOptions } from '../state/api';
import { IRootStore } from '../state';
import { EntityListView } from '../components/content/EntityListView';
import { EntityListFilter } from '../api/Endpoints';
import { NodeEntity, GetEntity, UserSessionType } from '../api/structure';
import { AppActions } from '../state/app';
import { routes } from './Router';
import { faTrash, faPencilAlt, faSearch, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Modal } from '../components/modals/Modal';
import { NodeDetails } from '../components/content/NodeDetails';

const style = {};

const NodesList = connect(
    (state: {api: ApiInitialState}) => ({
        data: state.api.nodes,
    }),
    (dispatch) => ({
        loadData: (opt: ApiCallOptions, filter: EntityListFilter<GetEntity<NodeEntity>>) => dispatch(ApiActions.listNodes(opt, filter)),
    }),
)(EntityListView);

/**
 * Page
 */
class NodesComponent extends React.Component<{
    sessionType: UserSessionType;
    sessionClientId: string;
    redirectTo: (to: string) => void;
    deleteNode: (opt: ApiCallOptions, id: string) => Promise<void>;
}> {
    public readonly state = {
        nodeDetailsShow: null,
    };

    public render() {
        const isAdmin = this.props.sessionType === 'admin';
        const isSelfcare = this.props.sessionType === 'selfcare';

        return (
            <>
                <SectionHeader>Nodes</SectionHeader>
                <NodesList
                    columns={[
                        {
                            text: 'Name',
                            name: 'name',
                            sortable: true,
                            filterType: 'full',
                            size: 2,
                            defaultSort: 1,
                            filterPlaceholder: 'Filter by name',
                        }
                    ]}
                    actions={isAdmin ? [
                        {
                            name: 'Edit',
                            color: 'green',
                            action: this.onEdit,
                            icon: faPencilAlt,
                        }, {
                            name: 'Remove',
                            color: 'red',
                            action: this.onRemove,
                            confirm: 'Do you realy wont to remove this Node?',
                            reloadAfter: true,
                            icon: faTrash,
                        }
                    ] : [
                        {
                            name: 'Details',
                            color: 'green',
                            action: this.onDetailsShow,
                            icon: faSearch,
                        }
                    ]}
                    headerActions={isAdmin ? [{
                        text: 'Add node',
                        icon: faPlus,
                        color: 'green',
                        action: this.onCreateNew,
                    }] : []}
                    mainList
                />
                <Modal
                    active={!!this.state.nodeDetailsShow}
                    size={'small'}
                    buttons={[{
                        text: 'Close',
                        color: 'gray',
                        action: this.onDetailsClose
                    }]}
                >
                    <NodeDetails nodeId={this.state.nodeDetailsShow} />
                </Modal>
            </>
        );
    }

    protected onEdit = async (i: GetEntity<NodeEntity>) => {
        this.props.redirectTo(routes.nodes.edit.path.replace(':id', i._id));
    }

    protected onRemove = async (i: GetEntity<NodeEntity>) => {
        return this.props.deleteNode({}, i._id);
    }

    protected onCreateNew = () => {
        this.props.redirectTo(routes.nodes.edit.path.replace(':id', 'create'));
    }

    protected onDetailsShow = (i: GetEntity<NodeEntity>) => {
        this.setState({nodeDetailsShow: i._id});
    }

    protected onDetailsClose = () => {
        this.setState({nodeDetailsShow: null});
    }

}

export const Nodes = connect(
    (state: IRootStore) => ({
        sessionType: state.api.sessionUser?.type,
        sessionClientId: state.api.sessionClientId,
    }),
    (dispatch) => ({
        redirectTo: (to: string) => dispatch(AppActions.redirectTo(to)),
        deleteNode: (opt: ApiCallOptions, id: string) => dispatch(ApiActions.deleteNode(opt, id)),
    }),
)(NodesComponent);