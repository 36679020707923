import * as React from 'react';
import { TableFilter } from './Table';
import { cx, css } from 'emotion';
import { mediaQSmall } from '../../../utils/responsivity';

const styles = {
    pagination: css`
        display: flex;
        flex-direction: row;
        background: #fff;
        justify-content: flex-end;
        padding: 10px;
        user-select: none;
        border-top: 2px solid #eff2f6;
        position: relative;
    `,
    paginationPage: css`
        display: block;
        line-height: 35px;
        width: 35px;
        height: 35px;
        margin: 0 5px;
        text-align: center;
        cursor: pointer;
        user-select: none;
        &.active {
            border-radius: 3px;
            background: #eee;
            cursor: auto;
        }
    `,
    paginationPageSelect: css`
        display: block;
        line-height: 35px;
        height: 35px;
        margin: 0 5px;
        text-align: center;
        cursor: pointer;
        user-select: none;
        border: 1px solid #f1f1f1;
        padding: 0 5px;
        border-radius: 3px;
    `,
    paginationPageDots: css`
        display: block;
        line-height: 35px;
        height: 35px;
        margin: 0 5px;
        text-align: center;
        letter-spacing: 5px;
        padding-left: 5px;
        user-select: none;
    `,
    paginationButton: css`
        display: block;
        line-height: 35px;
        height: 35px;
        margin: 0 5px;
        text-align: center;
        border-radius: 3px;
        background: #2f69b2;
        color: #fff;
        padding: 0 10px;
        cursor: pointer;
        user-select: none;
    `,
    mobileHidden: css`
        @media ${mediaQSmall} {
            display: none;
        }
    `,
    mobileShow: css`
        display: none;
        @media ${mediaQSmall} {
            display: block;
        }
    `,
    count: css`
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -10px;
        align-self: center;
        white-space: nowrap;

        @media only screen and (max-width: 990px) {
            position: static;
            margin-top: 0;
            margin-right: 5px;
        }
    `,
};

export interface TablePaginatorProps {
    count: number;
    current: number;
    onFilterChange?: (filter: Partial<TableFilter>) => void;
    itemsCount?: number;
}

export class TablePaginator extends React.Component<TablePaginatorProps> {
    public render() {
        const { current, count } = this.props;
        const ret = [];
        if (current !== 0) {
            ret.push(
                <a
                    onClick={this.onPageChange.bind(this, 0)}
                    key={`pagination-first`}
                    className={cx(styles.paginationButton, styles.mobileHidden)}
                >
                    First
                </a>
            );
            ret.push(
                <a
                    onClick={this.onPageChange.bind(this, current - 1)}
                    key={`pagination-previous`}
                    className={cx(styles.paginationButton)}
                >
                    Previous
                </a>
            );
        }

        ret.push(
            <select
                key={`pagination-page-mobile-select`}
                className={cx(styles.paginationPageSelect, styles.mobileShow)}
                value={current}
                onChange={(e) => this.onPageChange(parseInt(e.target.value, 10))}
            >
                {new Array(count).fill(0).map((i, index) => (
                    <option key={`page-select-option-${index}`} value={index}>{index + 1}</option>
                ))}
            </select>
        )

        // without dots
        if (count < 10) {
            for (let i = 0; i < count; i++) {
                ret.push(
                    <a
                        key={`pagination-page-${i}`}
                        className={cx(styles.paginationPage, {['active']: i === current}, styles.mobileHidden)}
                        onClick={this.onPageChange.bind(this, i)}
                    >
                        {i + 1}
                    </a>
                );
            }
        } else {
            const start = (current > count - 4) ? Math.max(count - 7, 0) : Math.max(current - 3, 0);
            const end = Math.min(start + 6, count - 1);

            if (start > 0) {
                ret.push(<a key={`pagination-dot-1`} className={cx(styles.paginationPageDots, styles.mobileHidden)}>...</a>);
            }

            for (let i = start; i < end + 1; i++) {
                ret.push(
                    <a
                        key={`pagination-page-${i}`}
                        className={cx(styles.paginationPage, {['active']: i === current}, styles.mobileHidden)}
                        onClick={this.onPageChange.bind(this, i)}
                    >
                        {i + 1}
                    </a>
                );
            }

            if (end < count - 1) {
                ret.push(<a key={`pagination-dot-2`} className={cx(styles.paginationPageDots, styles.mobileHidden)}>...</a>);
            }
        }

        if (current !== count - 1) {
            ret.push(
                <a
                    onClick={this.onPageChange.bind(this, current + 1)}
                    key={`pagination-next`}
                    className={cx(styles.paginationButton)}
                >
                    Next
                </a>
            );
            ret.push(
                <a
                    onClick={this.onPageChange.bind(this, count - 1)}
                    key={`pagination-last`}
                    className={cx(styles.paginationButton, styles.mobileHidden)}
                >
                    Last
                </a>
            );
        }

        return (
            <div className={styles.pagination}>
                {this.props.itemsCount ? <div className={styles.count}>Count: <strong>{this.props.itemsCount}</strong></div> : null}
                {ret}
            </div>
        );
    }

    protected onPageChange = (page: number) => {
        this.props.onFilterChange({
            currentPage: page,
        });
    }
}
