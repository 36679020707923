import * as React from 'react';
import { connect } from 'react-redux';
import { AppInitialState, AppActions } from '../../state/app';
import { NavLink } from 'react-router-dom';

// tslint:disable-next-line: no-var-requires
const config = require('config');

/**************** Component props ***************/
interface ComponentProps extends React.Props<Component> {
    redirectTo: (path: string) => void;
    to: string;
    className?: string;
    activeClassName?: string;
    onClick?: () => void;
}

/**************** Component ***************/
class Component extends React.Component<ComponentProps> {
    public render() {
        const hrefTo = (config.publicPath ? config.publicPath : '/') + this.props.to.substr(1);
        return (
            <NavLink
                onClick={this.onClick}
                to={this.props.to}
                href={hrefTo}
                className={this.props.className}
                activeClassName={this.props.activeClassName}
            >
                {this.props.children}
            </NavLink>
        );
    }

    protected onClick = (e) => {
        e.preventDefault();
        this.props.redirectTo(this.props.to);
        if (this.props.onClick) {
            this.props.onClick();
        }
    };
}

// Export connected component as default
export default connect(
    (state: {app: AppInitialState}) => ({}),
    (dispatch) => ({
        redirectTo: (path: string) => dispatch(AppActions.redirectTo(path)),
    })
)(Component);
