import * as monacoEditor from 'monaco-editor/esm/vs/editor/editor.api';
import * as React from 'react';
import MonacoEditor from 'react-monaco-editor';
import { FormProvidedProps, withFormData } from './FormProvider';
import { FormError } from './FormError';
import { css } from 'emotion';

const styles = {
    error: css`
        width: 100%;
        display: inline-block;
    `,
};

/**************** Interfaces ***************/
/**
 * Monaco error interface
 */
interface MonacoError extends Error {
    key: string;
    details: {
        diagnostics: any;
    };
}

/**************** Component props ***************/
export interface ComponentProps extends Partial<FormProvidedProps> {
    name: string;
    className?: string;
    onChange?: (value: string) => void;
    resetOnUnmount?: boolean;
    label?: string;

    options?: monacoEditor.editor.IEditorConstructionOptions;
    language?: string;
    width?: number;
    height?: number;
    focusOnMount?: boolean;
    typings?: string;
    jsonSchema?: string;
    omitErrorMarkers?: string[];
}

/**************** Component ***************/
class Component extends React.Component<ComponentProps> {
    protected typingsRef: monacoEditor.IDisposable = null;
    protected editorRef: monacoEditor.editor.IStandaloneCodeEditor = null;

    protected jsonSchemaModel: monacoEditor.editor.ITextModel = null;
    protected jsonSchemaUniqueId: string = null;
    protected jsonSchemaUnregister: () => void = null;
    protected jsonSchemaChange: (jsonSchema: string) => void = null;

    /**
     * remove typings
     */
    public componentWillUnmount() {
        if (this.props.resetOnUnmount) {
            this.props.formValueRemove(this.props.name);
        }

        if (this.typingsRef) {
            this.typingsRef.dispose();
        }
        if (this.jsonSchemaModel && this.jsonSchemaUniqueId) {
            this.jsonSchemaUnregister();
            this.jsonSchemaModel = null;
            this.jsonSchemaUniqueId = null;
            this.jsonSchemaUnregister = null;
            this.jsonSchemaChange = null;
        }
    }

    public UNSAFE_componentWillReceiveProps(nextProps: ComponentProps) {
        if (this.props.jsonSchema !== nextProps.jsonSchema) {
            if (this.jsonSchemaChange && this.jsonSchemaUnregister) {
                if (nextProps.jsonSchema !== '') {
                    this.jsonSchemaChange(nextProps.jsonSchema);
                } else {
                    this.jsonSchemaUnregister();
                }
            }
        }
    }

    /**
     * Render
     */
    public render() {
        const value = this.props.formGetValue(this.props.name);

        return (
            <div className={this.props.className}>
                {this.props.label ? <label>{this.props.label}</label> : null}
                <MonacoEditor
                    width={this.props.width}
                    height={this.props.height}
                    language={this.props.jsonSchema !== undefined ? undefined : this.props.language} // this is fix for bug "Cannot read property 'getModeId' of null" - when used json model, don't set language
                    theme="vs-dark"
                    value={value}
                    options={this.props.options ? this.props.options : undefined}
                    onChange={this.onChange}
                    editorDidMount={this.editorDidMount}
                    editorWillMount={this.editorWillMount}
                />
                <FormError className={styles.error} name={this.props.name} />
            </div>
        );
    }

    /**
     * Get build error from form errors
     */
    protected getBuildError = () => {
        const buildError = this.props.formError as MonacoError;
        if (buildError && buildError.key === 'type_script_error') {
            return buildError.details;
        }
        return null;
    }

    /**
     * Handle on change
     */
    protected onChange = (value: string) => {
        if (this.props.onChange) {
            this.props.onChange(value);
        }
        this.props.formValueChange(this.props.name, value);
    };

    /**
     * Focus when editor did mount
     */
    protected editorDidMount = (editor: monacoEditor.editor.IStandaloneCodeEditor, monaco: typeof monacoEditor) => {
        this.editorRef = editor;

        if (this.props.jsonSchema !== undefined) {

            this.generateSchemaUniqueId();
            const schemaUri = this.getModelSchemaUri();

            const modelUri = monaco.Uri.parse(this.getModelFileName());

            const value = this.props.formGetValue(this.props.name);

            this.jsonSchemaModel = monaco.editor.createModel(value, 'json', modelUri);
            this.editorRef.setModel(this.jsonSchemaModel);

            this.jsonSchemaUnregister = () => {
                if (this.jsonSchemaUniqueId) {
                    const unregOldSchemas = monaco.languages.json.jsonDefaults.diagnosticsOptions.schemas;
                    const uregNewSchemas = unregOldSchemas.filter((schema) => schema.uri !== schemaUri);
                    monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
                        validate: true,
                        schemas: uregNewSchemas,
                    });
                }
            };

            this.jsonSchemaChange = (jsonSchema: string) => {
                if (this.jsonSchemaUniqueId) {
                    const regOldSchemas = monaco.languages.json.jsonDefaults.diagnosticsOptions.schemas;
                    const regNewSchemas = regOldSchemas.filter((schema) => schema.uri !== schemaUri);
                    regNewSchemas.push({
                        uri: schemaUri,
                        fileMatch: [modelUri.toString()],
                        schema: JSON.parse(jsonSchema),
                    });
                    monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
                        validate: true,
                        schemas: regNewSchemas,
                    });
                }
            };

            if (this.props.jsonSchema !== '') {
                this.jsonSchemaChange(this.props.jsonSchema);
            }
        }

        if (this.props.omitErrorMarkers) {
            this.editorRef.onDidChangeModelDecorations((e: monacoEditor.editor.IModelDecorationsChangedEvent) => {
                const model = this.editorRef.getModel();
                const markers = monaco.editor.getModelMarkers({owner: 'typescript', resource: model.uri});

                const filtered = markers.filter((marker) => this.props.omitErrorMarkers.indexOf(marker.message) === -1);
                if (filtered.length !== markers.length) {
                    monaco.editor.setModelMarkers(model, 'typescript', filtered);
                }
            });
        }

        if (this.props.focusOnMount) {
            editor.focus();
        }
    };

    /**
     * Generate unique id of editor model
     */
    protected generateSchemaUniqueId = () => {
        if (this.jsonSchemaUniqueId) {
            throw new Error('this.jsonSchemaUniqueId already exists');
        }
        this.jsonSchemaUniqueId = (Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15));
    }

    /**
     * Get schema uri from by jsonSchemaUniqueId
     */
    protected getModelSchemaUri = () => {
        if (!this.jsonSchemaUniqueId) {
            throw new Error('Missing this.jsonSchemaUniqueId');
        }
        return `https://jointeff.com/${this.jsonSchemaUniqueId}-schema.json`;
    }

    /**
     * Get filename from by jsonSchemaUniqueId
     */
    protected getModelFileName = () => {
        if (!this.jsonSchemaUniqueId) {
            throw new Error('Missing this.jsonSchemaUniqueId');
        }
        return `z://${this.jsonSchemaUniqueId}.json`;
    }

    /**
     * Before editor creation
     */
    protected editorWillMount = (monaco: typeof monacoEditor) => {
        if (this.props.typings) {
            monaco.languages.typescript.typescriptDefaults.setCompilerOptions({
                target: monaco.languages.typescript.ScriptTarget.ES2015,
                noLib: true,
                allowNonTsExtensions: true,
            });

            this.typingsRef = monaco.languages.typescript.typescriptDefaults.addExtraLib(this.props.typings);
        }
    }
}

export default withFormData(Component);
