import { JsonValidatorType, JsonValidatorObjectChildsSchema, JsonValidatorRequired } from '../../../utils';
import { RegExps } from '../../../api/regexps';

export const commonInterfaceMetadataSchema = () => ({
    type: JsonValidatorType.Object,
    required: JsonValidatorRequired.False,
    childs: {
        required: {
            required: JsonValidatorRequired.False,
            type: JsonValidatorType.Boolean,
        },
        enabled: {
            required: JsonValidatorRequired.False,
            type: JsonValidatorType.Boolean,
        },
    },
});

export const commonInterfaceSchema = (connectorSchemaDef: JsonValidatorObjectChildsSchema, required?: boolean) => ({
    type: JsonValidatorType.Object,
    required: required ? JsonValidatorRequired.True : JsonValidatorRequired.False,
    nullable: true,
    childs: {
        type: {
            required: JsonValidatorRequired.False,
            type: JsonValidatorType.Enum,
            enum: ['', 'http_json_command', 'http_json_fetch', 'email_service', 'user_integration_service'],
        },
        config: {
            required: JsonValidatorRequired.False,
            type: JsonValidatorType.Object,
            childs: connectorSchemaDef,
        },
    }
});

export const connectorSchema = {
    email_service: {
        address: {
            required: JsonValidatorRequired.True,
            type: JsonValidatorType.String,
            regexp: RegExps.email,
        }
    },
    http_json_command: {
        apiAccess: {
            required: JsonValidatorRequired.True,
            type: JsonValidatorType.Array,
            unique: 'name',
            of: {
                type: JsonValidatorType.Object,
                childs: {
                    name: {
                        required: JsonValidatorRequired.True,
                        type: JsonValidatorType.String,
                    },
                    ipWhitelist: {
                        required: JsonValidatorRequired.True,
                        type: JsonValidatorType.String,
                    },
                    apiKey: {
                        required: JsonValidatorRequired.False,
                        type: JsonValidatorType.String,
                        nullable: true,
                    }
                }
            }
        }
    },
    http_json_fetch: {
        data: {
            required: JsonValidatorRequired.True,
            type: JsonValidatorType.Object,
            childs: {
                url: {
                    required: JsonValidatorRequired.True,
                    type: JsonValidatorType.String,
                    minLength: 1,
                    // TODO url pattern
                },
                headers: {
                    required: JsonValidatorRequired.False,
                    type: JsonValidatorType.Object,
                    of: {
                        type: JsonValidatorType.String,
                    }
                }
            }
        },
        status: {
            required: JsonValidatorRequired.True,
            type: JsonValidatorType.Object,
            childs: {
                url: {
                    required: JsonValidatorRequired.True,
                    type: JsonValidatorType.String,
                    minLength: 1,
                    // TODO url pattern
                },
                headers: {
                    required: JsonValidatorRequired.False,
                    nullable: true,
                    type: JsonValidatorType.Object,
                    of: {
                        type: JsonValidatorType.String,
                    }
                }
            }
        },
        secret: {
            required: JsonValidatorRequired.False,
            type: JsonValidatorType.String,
            nullable: true,
        }
    },
    user_integration_service: {},
};
