import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect as ReactRedirect, Router, withRouter } from 'react-router';
import { AppActions, AppInitialState } from '../../state/app';
import { ModalConfirm } from '../modals/ModalConfirm';

interface ComponentProps extends React.Props<Component> {
    redirectBack: string;
    redirectTo: string;
    redirectLocked: boolean;
    redirectReset: () => void;
    redirectLock: (locked: boolean) => void;
}

/**************** Component ***************/
class Component extends React.Component<ComponentProps> {
    public readonly state = {
        confirmConfirmed: false,
    };

    public componentDidMount() {
        window.addEventListener('beforeunload', this.beforeUnload);
    }

    public componentWillUnmount() {
        window.removeEventListener('beforeunload', this.beforeUnload);
    }

    public render() {
        const redirectToAnyPlace = this.props.redirectTo || this.props.redirectBack;
        const confirmOpen = redirectToAnyPlace && this.props.redirectLocked;
        const confirmConfirmed = (this.props.redirectLocked && this.state.confirmConfirmed) || !this.props.redirectLocked;
        const redirectEnabled = redirectToAnyPlace && confirmConfirmed;

        return (
            <>
                <ModalConfirm
                    onClose={this.onConfirmClose}
                    onConfirm={this.onConfirm}
                    active={confirmOpen}
                    title="Confirm leave"
                    text="Do you realy want to leave?"
                    confirmText="Leave"
                />
            </>
        );
    }

    public componentDidUpdate() {
        const confirmConfirmed = (this.props.redirectLocked && this.state.confirmConfirmed) || !this.props.redirectLocked;
        const redirectToAnyPlace = this.props.redirectTo || this.props.redirectBack;
        const redirectEnabled = redirectToAnyPlace && confirmConfirmed;

        if (redirectEnabled) {
            if (this.props.redirectBack) {
                (this.props as any).history.goBack();
            } else if (this.props.redirectTo) {
                (this.props as any).history.push(this.props.redirectTo);
            }
            this.props.redirectReset();
            this.props.redirectLock(false);
            this.setState({
                confirmConfirmed: false,
            });
        }
    }

    /**
     * Close popup
     */
    protected onConfirmClose = () => {
        this.props.redirectReset();
    };

    /**
     * Confirm leave popup
     */
    protected onConfirm = () => {
        this.setState({
            confirmConfirmed: true,
        });
    };

    /**
     * Before page unload
     */
    protected beforeUnload = (e) => {
        if (this.props.redirectLocked) {
            e.preventDefault();
            e.returnValue = 'Do you realy want to leave?';
        }
    }
}

// Export connected component as default
export const Redirect = withRouter(connect(
    (state: {app: AppInitialState}) => ({
        redirectBack: state.app.redirectBack,
        redirectTo: state.app.redirectTo,
        redirectLocked: state.app.redirectLocked,
    }),
    (dispatch) => ({
        redirectReset: () => dispatch(AppActions.redirectReset()),
        redirectLock: (locked: boolean) => dispatch(AppActions.redirectLock(locked)),
    }),
)(Component));
