import * as React from 'react';
import { FormProvidedProps, withFormData } from './FormProvider';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Button, ButtonColors } from '../Button';

/**************** Component props ***************/
interface ComponentProps extends Partial<FormProvidedProps> {
    name: string;
    condition: (value: any) => boolean;
}

/**************** Component ***************/
class Component extends React.Component<ComponentProps> {
    /**
     * Render
     */
    public render() {
        if (this.props.condition(this.props.formGetValue(this.props.name))) {
            return this.props.children;
        }
        return null;
    }
}

export const FormValueIs = withFormData(Component);
