import * as React from 'react';
import { Modal } from './Modal';

export interface ModalConfirmProps {
    title?: string;
    text?: string;
    active: boolean;
    onClose: () => void;
}

/**************** Component ***************/
export class ModalAlert extends React.Component<ModalConfirmProps> {
    public render() {
        return (
            <Modal
                active={this.props.active}
                size={'small'}
                buttons={[{
                    text: 'Close',
                    color: 'gray',
                    action: this.props.onClose
                }]}
            >
                {this.props.title ? <h1>{this.props.title}</h1> : null}
                {this.props.text ? <p>{this.props.text}</p> : null}
                {this.props.children}
            </Modal>
        );
    }
}

