import { faCheck, faEnvelope, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { css } from 'emotion';
import * as React from 'react';
import { connect } from 'react-redux';
import { ClientIntegrationEntity } from '../api/structure';
import { EntityEditor, EntityEditorInput } from '../components/content/EntityEditor';
import { ModalAlert } from '../components/modals/ModalAlert';
import { ModalConfirm } from '../components/modals/ModalConfirm';
import { AddToSlack } from '../components/ux/AddToSlack';
import { Button } from '../components/ux/Button';
import { FormArray } from '../components/ux/forms/FormArray';
import { FormCheckbox } from '../components/ux/forms/FormCheckbox';
import { FormInput } from '../components/ux/forms/FormInput';
import { FormSetValueButton } from '../components/ux/forms/FormSetValueButton';
import { SectionHeader } from '../components/ux/SectionHeader';
import { ApiActions, ApiCallOptions, ApiInitialState } from '../state/api';

interface WorkingData {
    clientId: string;
    slack: {
        teamId?: string;
        channel?: string;
        allowedCategories: {[name: string]: boolean};
    };
    email: {
        adress: string;
        allowedCategories: {[name: string]: boolean};
    }[];
}


const IntegrationEditor = connect(
    (state: {api: ApiInitialState}) => ({
        data: state.api.clientIntegration,
    }),
    (dispatch) => ({
        loadData: (opt: ApiCallOptions, _id: string) => dispatch(ApiActions.getClientIntegration(opt)),
        patchData: (opt: ApiCallOptions, _id: string, data: Partial<ClientIntegrationEntity>) => dispatch(ApiActions.postClientIntegration(opt, data)),
    }),
)(EntityEditor);

const style = {
    p: css`
        max-width: 600px;
        margin-bottom: 20px;
    `,
    section: css`
        margin-bottom: 25px;
    `,
    arrayItem: css`
        padding: 0px;
        border-radius: 3px;
        margin-bottom: 10px;
        background: none;
    `,
    arrayItemInputsContent: css`
        display: flex;
    `,
    arrayDeleteButton: css`
        margin-top: 0;
        margin-left: 10px;
    `,
    arrayInput: css`
        margin-right: 10px;
    `,
    aloneButton: css`
        margin-bottom: 12px;
    `,
    ol: css`
        & > li {
            margin: 0;
            padding: 0 0 0 0.5em;
            text-indent: 0;
            list-style-type: none;
            counter-increment: item;
            display: flex;
        }

        & > li > p {
            display: inline-block;
        }

        & > li:before {
            display: inline-block;
            width: 1em;
            padding-right: 0.5em;
            font-weight: bold;
            text-align: right;
            content: counter(item) ".";
        }
    `,
    categories: css`
        margin: 10px 0;
    `
};

/**
 * Page
 */
class IntegrationComponent extends React.Component<{
    slackInstallUrl: string;
    deleteClientIntegrationSlack: (opt: ApiCallOptions) => Promise<void>;
    getClientIntegrationWelcome: (opt: ApiCallOptions) => Promise<void>;
    getClientIntegrationSlackInstall: (opt: ApiCallOptions) => Promise<void>;
}, {
    sendWelcome: boolean;
    saveConfirmed: boolean;
}> {
    constructor(props) {
        super(props);
        this.state = {
            sendWelcome: false,
            saveConfirmed: false,
        };
    }

    componentDidMount(): void {
        this.props.getClientIntegrationSlackInstall({});
    }

    public render() {
        const defaultEmailItem = {
            address: '',
            allowedCategories: {
                CONNECTOR_INFO: true,
                NEWS: true,
                USER_INFO: true,
            },
        };

        return (
            <>
                <SectionHeader>Integration</SectionHeader>
                <IntegrationEditor
                    onComplete={this.onSaveComplete}
                    inputDataTransform={this.inputDataTransform}
                    outputDataTransform={this.outputDataTransform}
                >
                    {(currentData: WorkingData) => (
                        <>
                            <p className={style.p}>You can setup integrations, to receive informations about your account.</p>
                            <p className={style.p}>
                                If you are connected to some node, where full data exchange is provided,
                                we recommending to use it, to keep you informed about issues on fetching data from your side.
                            </p>
                            <SectionHeader>Email integration</SectionHeader>
                            <div className={style.section}>
                                <p className={style.p}>
                                    Specify emails, where all informations will be sent.
                                </p>
                                <FormArray
                                    name={`email`}
                                    itemClassName={style.arrayItem}
                                    deleteClassName={style.arrayDeleteButton}
                                    addButtonText={'Add new email'}
                                    defaultItem={defaultEmailItem}
                                    item={(prefix: string, itemData: string) =>  (
                                        <>
                                            <FormInput
                                                name={`${prefix}.adress`}
                                                label={'Email address'}
                                            />
                                            <EntityEditorInput small>
                                                <div className={style.categories}>
                                                    <FormCheckbox
                                                        name={`${prefix}.allowedCategories.CONNECTOR_INFO`}
                                                        label={'Informations about connectors status'}
                                                    />
                                                    <FormCheckbox
                                                        name={`${prefix}.allowedCategories.NEWS`}
                                                        label={'Informations about Jointeff service'}
                                                    />
                                                    <FormCheckbox
                                                        name={`${prefix}.allowedCategories.USER_INFO`}
                                                        label={'Informations about users'}
                                                    />
                                                </div>
                                            </EntityEditorInput>
                                        </>
                                    )}
                                />
                            </div>
                            {this.props.slackInstallUrl ? (
                                <>
                                    <SectionHeader>Slack integration</SectionHeader>
                                    <div className={style.section}>
                                        {currentData?.slack?.teamId ? (
                                            <>
                                                <p className={style.p}>
                                                    Your account is connected to slack.
                                                </p>
                                                <EntityEditorInput small>
                                                    <FormInput
                                                        name={`slack.channel`}
                                                        label={'Slack channel (e.g. #jointeff-bot)'}
                                                    />
                                                </EntityEditorInput>
                                                <p className={style.p}>
                                                    Don't forget to invite our bot to your channel after adding Jointeff application to your Slack.
                                                </p>
                                                <EntityEditorInput small>
                                                    <div className={style.categories}>
                                                        <FormCheckbox
                                                            name={`slack.allowedCategories.CONNECTOR_INFO`}
                                                            label={'Informations about connectors status'}
                                                        />
                                                        <FormCheckbox
                                                            name={`slack.allowedCategories.NEWS`}
                                                            label={'Informations about Jointeff service'}
                                                        />
                                                        <FormCheckbox
                                                            name={`slack.allowedCategories.USER_INFO`}
                                                            label={'Informations about users'}
                                                        />
                                                    </div>
                                                </EntityEditorInput>
                                                <FormSetValueButton
                                                    className={style.aloneButton}
                                                    color='red'
                                                    icon={faMinusCircle}
                                                    changeValue={{name: 'slack.teamId', value: null}}
                                                    confirmation={{
                                                        onConfirm: this.removeSlackIntegrationConfirm,
                                                        text: 'Do you really want to remove slack integration?',
                                                        confirmText: 'Remove',
                                                        confirmIcon: faMinusCircle,
                                                        confirmColor: 'red',
                                                    }}
                                                >
                                                    Remove slack integration
                                                </FormSetValueButton>
                                            </>
                                        ) : (
                                            <>
                                                <p className={style.p}>
                                                    You can setup your account to receive messages from us by slack.
                                                </p>
                                                <ol className={style.ol}>
                                                    <li>
                                                        <p className={style.p}>
                                                            Click on Add to Slack button, and add our application to your Slack team account.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p className={style.p}>
                                                            Specify channel, where message from us will be posted.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p className={style.p}>
                                                            Don't forget to invite our bot to your channel after adding Jointeff application to your Slack.
                                                        </p>
                                                    </li>
                                                </ol>
                                                <AddToSlack />
                                            </>
                                        )}
                                    </div>
                                </>
                            ) : null}
                            <SectionHeader>Test your integration</SectionHeader>
                            <div className={style.section}>
                                <p className={style.p}>
                                    You can try, if your integration is set correctly. By clicking on Send Welcome button, we will send you welcome message to every each channel you set on this page.
                                </p>
                                <Button className={style.aloneButton} color='yellow' icon={faEnvelope} onClick={this.sendWelcome}>
                                    Send Welcome
                                </Button>
                            </div>
                        </>
                    )}
                </IntegrationEditor>
                <ModalConfirm
                    onClose={this.sendWelcomeClose}
                    onConfirm={this.sendWelcomeConfirm}
                    active={this.state.sendWelcome}
                    text={'Do you really want to send welcome to everybody, who is specified on this Integration page?'}
                    confirmText={'Send'}
                    confirmIcon={faEnvelope}
                    confirmColor={'yellow'}
                />
                <ModalAlert
                    onClose={this.onSaveConfirmed}
                    active={this.state.saveConfirmed}
                    title={'Your changes was applied'}
                    text={'Changes you made, will be applied immediately. You can try to send welcome message, to see, if everything is set correctly.'}
                />
            </>
        );
    }

    protected inputDataTransform = (data: ClientIntegrationEntity): WorkingData => {
        return {
            ...data,
            slack: {
                ...data.slack,
                allowedCategories: (data?.slack?.allowedCategories || []).reduce((acc, i) => ({...acc, [i]: true}), {}),
            },
            email: [
                ...data.email.map((i) => ({
                    ...i,
                    allowedCategories: (i?.allowedCategories || []).reduce((acc, i) => ({...acc, [i]: true}), {}),
                })),
            ],
        };
    }

    protected outputDataTransform = (data: WorkingData): ClientIntegrationEntity => {
        return {
            ...data,
            slack: {
                channel: data?.slack?.channel || '',
                allowedCategories: Object.keys(data?.slack?.allowedCategories).reduce((acc, i) => (
                    [...acc].concat(data?.slack?.allowedCategories[i] ? [i] : [])
                ), []),
            },
            email: [
                ...data.email
                    .filter((email) => email.adress)
                    .map((email) => ({
                        ...email,
                        allowedCategories: Object.keys(email?.allowedCategories).reduce((acc, i) => (
                            [...acc].concat(email?.allowedCategories[i] ? [i] : [])
                        ), []),
                    })),
            ],
        };
    }

    protected removeSlackIntegrationConfirm = async () => {
        await this.props.deleteClientIntegrationSlack({});
    }

    protected sendWelcome = () => {
        this.setState({sendWelcome: true});
    }

    protected sendWelcomeConfirm = async () => {
        await this.props.getClientIntegrationWelcome({});
        this.setState({sendWelcome: false});
    }

    protected sendWelcomeClose = () => {
        this.setState({sendWelcome: false});
    }

    protected onSaveComplete = () => {
        this.setState({saveConfirmed: true});
    }

    protected onSaveConfirmed = () => {
        this.setState({saveConfirmed: false});
    }
}

export const Integration = connect(
    (state: {api: ApiInitialState}) => ({
        slackInstallUrl: state.api.slackInstallUrl,
    }),
    (dispatch) => ({
        deleteClientIntegrationSlack: (opt: ApiCallOptions) => dispatch(ApiActions.deleteClientIntegrationSlack(opt)),
        getClientIntegrationWelcome: (opt: ApiCallOptions) => dispatch(ApiActions.getClientIntegrationWelcome(opt)),
        getClientIntegrationSlackInstall: (opt: ApiCallOptions) => dispatch(ApiActions.getClientIntegrationSlackInstall(opt)),
    }),
)(IntegrationComponent);

