import * as React from 'react';
import { Form } from '../../ux/forms/Form';
import { JsonValidatorType, JsonValidatorObjectChildsSchema, JsonValidatorRequired, objectOmit } from '../../../utils';
import { ConnectorEntity, BaseConnectorInterface, ConnectorApiAccess } from '../../../api/structure';
import { FormSelect } from '../../ux/forms/FormSelect';
import { css } from 'emotion';
import { FormInput } from '../../ux/forms/FormInput';
import { connectorSchema, commonInterfaceSchema, commonInterfaceMetadataSchema } from './schema';
import { sanitizeConnectorForSave } from './sanitize';
import { connectorEditor } from './editors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt, faTerminal, faWrench } from '@fortawesome/free-solid-svg-icons';
import { FormCheckbox } from '../../ux/forms/FormCheckbox';
import { FormValueIs } from '../../ux/forms/FormValueIs';

export const style = {
    header: css`
        border-bottom: 1px solid #ddd;
        margin-bottom: 20px;
        display: block;
        font-size: 1.5em;
    `,
    label: css`
        border-bottom: 1px solid #ddd;
        margin-bottom: 20px;
        display: block;
        font-size: 1.2em;
        svg {
            margin-right: 10px;
        }
    `,
    group: css`
        margin-bottom: 30px;
    `,
    interfaceGroup: css`
        margin-bottom: 30px;
        background: #f9f9f9;
        padding: 10px;
        border: 1px dotted #ccc;
        position: relative;
    `,
    interfaceGroupContent: css`
    `,
    input: css`
        margin-bottom: 15px;
        &.small {
            max-width: 300px;
        }
    `,
    checkboxInput: css`
        margin-bottom: 10px;
    `,
};

export const InterfaceEditor = (props: {prefix: string; data: BaseConnectorInterface}) => {
    if (props.data && props.data.type) {
        if (connectorEditor[props.data.type]) {
            return connectorEditor[props.data.type](props.prefix, props.data);
        } else {
            throw new Error(`Editor for connector with type ${props.data.type} does not exists.`);
        }
    }
    return null;
};

/**************** Props ***************/
interface ComponentProps {
    data?: ConnectorEntity;
    onSubmit?: (data: ConnectorEntity) => void;
    isAdmin: boolean;
}

/**************** Component ***************/
export class ConnectorForm extends React.Component<ComponentProps, {
    previousData: ConnectorEntity;
    data: ConnectorEntity;
}> {
    constructor(props: ComponentProps) {
        super(props);
        const data = JSON.parse(JSON.stringify(props.data));
        this.state = {
            previousData: data,
            data,
        };
    }

    public render() {
        const isAdmin = this.props.isAdmin;

        if (!this.props.data) {
            return null;
        }

        // enums
        const connectorCommandOptions = {
            'http_json_command': 'HTTP Json Command',
        };

        const connectorFetchOptions = {
            'http_json_fetch': 'HTTP Json Fetch',
        };

        const connectorServiceOptions = {
            'email_service': 'Email service (Deprecated)',
            'user_integration_service': 'User integration (Default)',
        };

        // conditions for renderer
        const metaInfo = this.props.data?.interfaceMetadata ? this.props.data?.interfaceMetadata : {
            fetchInterface: null,
            commandInterface: null,
            serviceInterface: null,
        };

        const interfacesEnabled = {
            fetchInterface:   isAdmin || metaInfo.fetchInterface?.enabled,
            commandInterface: isAdmin || metaInfo.commandInterface?.enabled,
            serviceInterface: isAdmin || metaInfo.serviceInterface?.enabled,
        };

        const interfacesRequired = {
            fetchInterface:   !isAdmin && metaInfo.fetchInterface?.required,
            commandInterface: !isAdmin && metaInfo.commandInterface?.required,
            serviceInterface: !isAdmin && metaInfo.serviceInterface?.required,
        };

        // prepare schema for admin and for selfcare
        const schema = isAdmin ? {
            name: {
                required: JsonValidatorRequired.True,
                type: JsonValidatorType.String,
            },
            fetchInterface: commonInterfaceSchema(this.generateInterfaceSchema(this.state.data.fetchInterface), false),
            commandInterface: commonInterfaceSchema(this.generateInterfaceSchema(this.state.data.commandInterface), false),
            serviceInterface: commonInterfaceSchema(this.generateInterfaceSchema(this.state.data.serviceInterface), false),
            interfaceMetadata: {
                type: JsonValidatorType.Object,
                required: isAdmin ? JsonValidatorRequired.True : JsonValidatorRequired.False,
                childs: {
                    fetchInterface: commonInterfaceMetadataSchema(),
                    commandInterface: commonInterfaceMetadataSchema(),
                    serviceInterface: commonInterfaceMetadataSchema(),
                },
            },
        } : {
            ...(interfacesEnabled.fetchInterface ? {
                fetchInterface: commonInterfaceSchema(
                    this.generateInterfaceSchema(this.state.data.fetchInterface),
                    interfacesRequired.fetchInterface,
                ),
            } : null),
            ...(interfacesEnabled.commandInterface ? {
                commandInterface: commonInterfaceSchema(
                    this.generateInterfaceSchema(this.state.data.commandInterface),
                    interfacesRequired.commandInterface,
                ),
            } : null),
            ...(interfacesEnabled.serviceInterface ? {
                serviceInterface: commonInterfaceSchema(
                    this.generateInterfaceSchema(this.state.data.serviceInterface),
                    interfacesRequired.serviceInterface,
                )
            } : null),
        };

        const data = {
            ...objectOmit(this.state.data, [
                !interfacesEnabled.fetchInterface ? 'fetchInterface' : null,
                !interfacesEnabled.commandInterface ? 'commandInterface' : null,
                !interfacesEnabled.serviceInterface ? 'serviceInterface' : null,
                !isAdmin ? 'interfaceMetadata' : null,
                !isAdmin ? 'name' : null,
            ]),
        };

        return (
            <Form
                data={data}
                onChange={this.onChange}
                action={this.action}
                schema={schema}
                saveOnNativeEvent
            >
                {isAdmin ? (
                    <label className={style.header}>Client`s connector</label>
                ) : (
                    <label className={style.header}>Edit connector ({this.props.data.name})</label>
                )}
                {isAdmin ? (<div className={style.group}>
                    <div className={style.input}>
                        <FormInput
                            name={'name'}
                            label={'Name'}
                        />
                    </div>
                </div>) : null}
                {interfacesEnabled.fetchInterface ? (
                    <>
                        <label className={style.label}>
                            <FontAwesomeIcon icon={faCloudUploadAlt} />
                            Fetch interface
                        </label>
                        <div className={style.interfaceGroup}>
                            <div className={style.interfaceGroupContent}>
                                {isAdmin ? (
                                    <>
                                        <div className={style.checkboxInput}>
                                            <FormCheckbox name={`interfaceMetadata.fetchInterface.enabled`} label={'Enable interface'} />
                                        </div>
                                        <div className={style.checkboxInput}>
                                            <FormCheckbox name={`interfaceMetadata.fetchInterface.required`} label={'Required interface'} />
                                        </div>
                                    </>
                                ) : null}
                                <div className={style.input}>
                                    <FormSelect
                                        name={`fetchInterface.type`}
                                        label={'Type'}
                                        canBeEmpty={!interfacesRequired.fetchInterface}
                                        options={connectorFetchOptions}
                                    />
                                </div>
                                <InterfaceEditor
                                    prefix={'fetchInterface'}
                                    data={this.state.data.fetchInterface}
                                />
                            </div>
                        </div>
                    </>
                ) : null}
                {interfacesEnabled.commandInterface ? (
                    <>
                        <label className={style.label}>
                            <FontAwesomeIcon icon={faTerminal} />
                            Command interface
                        </label>
                        <div className={style.interfaceGroup}>
                            <div className={style.interfaceGroupContent}>
                                {isAdmin ? (
                                    <>
                                        <div className={style.checkboxInput}>
                                            <FormCheckbox name={`interfaceMetadata.commandInterface.enabled`} label={'Enable interface'} />
                                        </div>
                                        <div className={style.checkboxInput}>
                                            <FormCheckbox name={`interfaceMetadata.commandInterface.required`} label={'Required interface'} />
                                        </div>
                                    </>
                                ) : null}
                                <div className={style.input}>
                                    <FormSelect
                                        name={`commandInterface.type`}
                                        label={'Type'}
                                        canBeEmpty={!interfacesRequired.commandInterface}
                                        options={connectorCommandOptions}
                                    />
                                </div>
                                <InterfaceEditor
                                    prefix={'commandInterface'}
                                    data={this.state.data.commandInterface}
                                />
                            </div>
                        </div>
                    </>
                ) : null}
                {interfacesEnabled.serviceInterface ? (
                    <>
                        <label className={style.label}>
                            <FontAwesomeIcon icon={faWrench} />
                            Service interface
                        </label>
                        <div className={style.interfaceGroup}>
                            <div className={style.interfaceGroupContent}>
                                {isAdmin ? (
                                    <>
                                        <div className={style.checkboxInput}>
                                            <FormCheckbox name={`interfaceMetadata.serviceInterface.enabled`} label={'Enable interface'} />
                                        </div>
                                        <div className={style.checkboxInput}>
                                            <FormCheckbox name={`interfaceMetadata.serviceInterface.required`} label={'Required interface'} />
                                        </div>
                                    </>
                                ) : null}
                                <div className={style.input}>
                                    <FormSelect
                                        name={`serviceInterface.type`}
                                        label={'Type'}
                                        canBeEmpty={!interfacesRequired.serviceInterface}
                                        options={connectorServiceOptions}
                                    />
                                </div>
                                <InterfaceEditor
                                    prefix={'serviceInterface'}
                                    data={this.state.data.serviceInterface}
                                />
                            </div>
                        </div>
                    </>
                ) : null}
                {this.props.children}
            </Form>
        );
    }

    /**
     * Validate form
     * @param data
     */
    protected onChange = async (data: ConnectorEntity, internal: boolean) => {
        this.cleanupStructure(data);
        // set data and make copy of previous data, because form is editing same reference
        this.setState({
            previousData: JSON.parse(JSON.stringify(data)),
            data,
        });
    }

    /**
     * Cleanup data structure
     * @param data
     */
    protected cleanupStructure = async (data: ConnectorEntity) => {
        /**
         * Cleanup structure, if type was not provided, remove this interface
         * if type is not same, reset configuration
         */
        if (!data?.commandInterface?.type) {
            data.commandInterface = null;
        } else if (data?.commandInterface?.type !== this.state.previousData?.commandInterface?.type) {
            // when type changed, clear config
            data.commandInterface.config = {};
        }

        if (!data?.fetchInterface?.type) {
            data.fetchInterface = null;
        } else if (data?.fetchInterface?.type !== this.state.previousData?.fetchInterface?.type) {
            // when type changed, clear config
            data.fetchInterface.config = {};
        }

        if (!data?.serviceInterface?.type) {
            data.serviceInterface = null;
        } else if (data?.serviceInterface?.type !== this.state.previousData?.serviceInterface?.type) {
            // when type changed, clear config
            data.serviceInterface.config = {};
        }

        if (!this.props.isAdmin) {
            // if not admin remove data
            const metaInfo = this.props.data.interfaceMetadata;

            if (!metaInfo.commandInterface?.enabled) {
                delete data.commandInterface;
            }
            if (!metaInfo.fetchInterface?.enabled) {
                delete data.fetchInterface;
            }
            if (!metaInfo.serviceInterface?.enabled) {
                delete data.serviceInterface;
            }

            delete data.name;
            delete data.interfaceMetadata;
        } else {
            // if admin, fill in data
            data.interfaceMetadata = {
                commandInterface: {
                    required: false,
                    enabled: false,
                    ...data.interfaceMetadata?.commandInterface,
                },
                fetchInterface: {
                    required: false,
                    enabled: false,
                    ...data.interfaceMetadata?.fetchInterface,
                },
                serviceInterface: {
                    required: false,
                    enabled: false,
                    ...data.interfaceMetadata?.serviceInterface,
                },
            };
        }

        return data;
    }

    /**
     * Cleanup interface
     * @param data
     */
    protected cleanupInterface = async (data: BaseConnectorInterface) => {
        if (data && data.type && sanitizeConnectorForSave[data.type]) {
            sanitizeConnectorForSave[data.type](data);
        }
        return data;
    }

    /**
     * Submit form
     * @param data
     */
    protected action = async (data: any) => {
        this.cleanupStructure(data);
        /**
         * Cleanup specific interface properties
         */
        if (data?.commandInterface?.type) {
            this.cleanupInterface(data.commandInterface);
        }
        if (data?.fetchInterface?.type) {
            this.cleanupInterface(data.fetchInterface);
        }
        if (data?.serviceInterface?.type) {
            this.cleanupInterface(data.serviceInterface);
        }

        if (this.props.onSubmit) {
            return this.props.onSubmit(data);
        }
    }

    /**
     * Validator schema generators
     */
    protected generateInterfaceSchema = (data: BaseConnectorInterface): JsonValidatorObjectChildsSchema => {
        if (!data || !data.type) {
            return {};
        }

        if (connectorSchema[data.type]) {
            return connectorSchema[data.type];
        }

        throw new Error(`Schema for connector ${data.type} does not exists.`);
    }
}

