import * as React from 'react';
import { css, cx } from 'emotion';
import { mediaQSmall } from '../../utils/responsivity';

const style = {
    root: css`
        margin-bottom: 30px;
        position: relative;
    `,
    header: css`
        font-size: 1.5em;
        color: #666;
        border-bottom: 1px solid #aaa;
    `,
    tabs: css`
        position: absolute;
        right: 0;
        bottom: 1px;
        display: flex;
        align-items: flex-end;
        @media ${mediaQSmall} {
            position: relative;
            justify-content: stretch;
            bottom: 0;
        }
    `,
    tabsButton: css`
        padding: 5px 15px;
        line-height: 25px;
        font-size: 16px;
        background: #fff;
        margin-left: 10px;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        font-weight: 200;
        cursor: pointer;
        &.selected {
            background: #255794;
            color: #fff;
        }

        @media ${mediaQSmall} {
            font-size: 12px;
            line-height: 20px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            margin-left: 5px;
            margin-right: 5px;
            flex: 1;
            text-align: center;
            &.selected {
                line-height: 20px;
            }
        }
    `,
};

/**************** Component ***************/
export function SectionHeader(props: {
    tabs?: {[name: string]: string};
    onTabClick?: (name: string) => void;
    currentTab?: string;
} & React.Props<any>) {
    return (
        <div className={cx(style.root)}>
            <div className={cx(style.header)}>
                {props.children}
            </div>

            {props.tabs && Object.keys(props.tabs).length > 1 ? <div className={style.tabs}>
                {Object.keys(props.tabs).map((name: string) => (
                    <div
                        key={`tab-${name}`}
                        className={cx(style.tabsButton, {['selected']: props.currentTab === name})}
                        onClick={() => props.onTabClick(name)}
                    >
                        {props.tabs[name]}
                    </div>
                ))}
            </div> : null}
        </div>
    );
}
