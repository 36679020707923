import * as React from 'react';
import { css, cx } from 'emotion';
import { ContentBox } from '../layouts/ContentBox';
import { SectionHeader } from '../ux/SectionHeader';
import { connect } from 'react-redux';
import { IRootStore } from '../../state';
import { ApiActions, ApiCallOptions } from '../../state/api';
import { safe, safePromise } from '../../utils';
import { EntityIndex, SessionReincarnation } from '../../api/Endpoints';
import { ConnectionAbility, DashboardMetrics, DashboardMetricsRequests, GetEntity, UserEntity } from '../../api/structure';
import { AllConnectorsStatus } from './AllConnectorsStatus';
import { ClientResponseStatistics } from './ClientResponseStatistics';
import { StatisticsBox, StatisticsRow } from '../ux/StatisticsBox';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';

const style = {
    header: css`
        font-size: 1.5em;
        border-bottom: 1px solid #aaa;
        margin-bottom: 15px;
    `,
    headerThin: css`
        font-weight: 100;
        color: #aaa;
    `,
    clients: css`
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: stretch;
    `,
    chartWrap: css`
        position: relative;
        margin-bottom: 20px;
    `,
    chart: css`
        position: absolute;
        left: 0;
        right: 0;
        overflow: hidden;
    `,
    clientOverview: css`
        margin-bottom: 20px;
    `,
    clientConnectorsOverview: css`
        padding: 15px;
        background: #efefef;
        border-radius: 5px;
        margin-bottom: 20px;
    `,
    clientOverviewHeader: css`
        font-size: 1.2em;
        border-bottom: 1px solid #aaa;
        margin-bottom: 20px;
        font-weight: bold;
    `,
};

/**
 * Page
 */
class SelfcareOverviewComponent extends React.Component<{
    clientIndex: EntityIndex;
    dashboard: DashboardMetrics;
    dashboardRequests: DashboardMetricsRequests;
    connectionAbility: ConnectionAbility;
    sessionUser: GetEntity<UserEntity>;
    sessionClientId: string;
    sessionReincarnation: SessionReincarnation;
    getClientsIndex: (opt: ApiCallOptions) => Promise<EntityIndex>;
    getDashboard: (opt: ApiCallOptions) => Promise<DashboardMetrics>;
    getDashboardRequests: (opt: ApiCallOptions) => Promise<DashboardMetricsRequests>;
    getClientConnectionAbility: (opt: ApiCallOptions, clientId: string) => Promise<ConnectionAbility>;
}> {
    protected interval = null;
    protected boxRef = React.createRef<HTMLDivElement>();
    public readonly state = {
        width: 0,
    };

    public componentDidMount() {
        this.loadData();
        safePromise(this.props.getClientsIndex({}));
        this.interval = setInterval(() => this.loadData(true), 5000);
        const clientId = this.props.sessionReincarnation ? this.props.sessionReincarnation.clientId : this.props.sessionClientId;
        safePromise(this.props.getClientConnectionAbility({}, clientId));
    }

    public componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
            this.interval = null;
        }
    }

    public render() {
        const allCLients = [this.props.sessionReincarnation ? this.props.sessionReincarnation.clientId : this.props.sessionClientId];
        const data = this.props.dashboard;

        const ability = this.props.connectionAbility ? this.props.connectionAbility : {fetch: true, command: true};

        const clientsData =  allCLients.map((clientId) => {
            const clientName = this.props.clientIndex?.[clientId];
            return {
                clientId,
                clientName,
                failed: safe(() => data.last24Hours.statusesCount[clientId]['error'], null),
                success: safe(() => data.last24Hours.statusesCount[clientId]['success'], null),
                notFound: safe(() => data.last24Hours.statusesCount[clientId]['not-found'], null),
                averageTime: safe(() => data.last24Hours.avergateResponseTimes[clientId].all, null),
                failedTime: safe(() => data.last24Hours.avergateResponseTimes[clientId]['error'], null),
                successTime: safe(() => data.last24Hours.avergateResponseTimes[clientId]['success'], null),
                notFoundTime: safe(() => data.last24Hours.avergateResponseTimes[clientId]['not-found'], null),
            };
        });

        return (
            <>
                <SectionHeader>Overview</SectionHeader>
                <ContentBox>
                    <div className={style.header}>Requests statistics</div>
                    <StatisticsRow>
                        <StatisticsBox
                            color="green"
                            name="Requests total count"
                            icon={faCalendar}
                            value={safe(() => this.props.dashboardRequests.count.total, 'N/A')}
                        />
                        <StatisticsBox
                            color="green"
                            name="Requests this year count"
                            icon={faCalendar}
                            value={safe(() => this.props.dashboardRequests.count.thisYear, 'N/A')}
                        />
                        <StatisticsBox
                            color="green"
                            name="Requests this month count"
                            icon={faCalendar}
                            value={safe(() => this.props.dashboardRequests.count.thisMonth, 'N/A')}
                        />
                        <StatisticsBox
                            color="green"
                            name="Requests last 24 hours count"
                            icon={faCalendar}
                            value={safe(() => this.props.dashboardRequests.count.last24Hours, 'N/A')}
                        />
                    </StatisticsRow>
                    {ability.fetch ? (
                        <>
                            <div className={style.header}>Response statistics <span className={style.headerThin}>for last 24 hours</span></div>
                            <div className={style.clients}>
                                {clientsData.map((item) => (
                                    <div className={style.clientOverview} key={item.clientName}>
                                        <ClientResponseStatistics data={item} />
                                    </div>
                                ))}
                            </div>
                        </>
                    ) : null}
                    {ability.fetch ? (
                        <>
                            <div className={style.header}>Connectors statuses</div>
                            <div className={style.clients}>
                                {clientsData.map((item) => (
                                    <div className={style.clientConnectorsOverview} key={item.clientName}>
                                        <AllConnectorsStatus clientId={item.clientId}/>
                                    </div>
                                ))}
                            </div>
                        </>
                    ) : null}

                </ContentBox>
            </>
        );
    }

    protected loadData = (silent?: boolean) => {
        safePromise(this.props.getDashboard({silent}));
        safePromise(this.props.getDashboardRequests({silent}));
    }
}

export const SelfcareOverview = connect(
    (state: IRootStore) => ({
        clientIndex: state.api.clientIndex,
        dashboard: state.api.dashboard,
        dashboardRequests: state.api.dashboardRequests,
        connectionAbility: state.api.connectionAbility,
        sessionUser: state.api.sessionUser,
        sessionClientId: state.api.sessionClientId,
        sessionReincarnation: state.api.sessionReincarnation,
    }),
    (dispatch) => ({
        getClientsIndex: (opt: ApiCallOptions) => dispatch(ApiActions.getClientsIndex(opt)),
        getDashboard: (opt: ApiCallOptions) => dispatch(ApiActions.getDashboard(opt)),
        getDashboardRequests: (opt: ApiCallOptions) => dispatch(ApiActions.getDashboardRequests(opt)),
        getClientConnectionAbility: (opt: ApiCallOptions, clientId: string) => dispatch(ApiActions.getClientConnectionAbility(opt, clientId)),
    }),
)(SelfcareOverviewComponent);
