import * as React from 'react';
import { css, cx } from 'emotion';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt, faUser, faUserLock, faBug, faLayerGroup, faTable, faSpinner, faBars, faMoneyBillAlt, faPlug, faSubscript, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import NavLink from '../utils/NavLink';
import { routes } from '../../routes/Router';
import { connect } from 'react-redux';

import * as LogoImg from '../../../assets/logo-text.svg';
import { mediaQSmall } from '../../utils/responsivity';
import { IRootStore } from '../../state';
import { UserSessionType } from '../../api/structure';
import { getVersion } from '../../utils/enviroment';
import { ApiActions } from '../../state/api';

const styles = {
    root: css`
        background: #122845;
        flex-basis: 270px;
        width: 270px;
        min-height: 100vh;
        height: 100%
        position: relative;
        display: flex;
        flex-direction: column;
        @media ${mediaQSmall} {
            position: fixed;
            left: -270px;
            transition: left 0.2s linear;
            z-index: 200;
            max-height: 100vh;
            &.open {
                left: 0;
            }
        }
    `,
    head: css`
        height: 60px;
        background: #24518A;
        line-height: 60px;
        color: #fff;
        font-weight: bold;
        font-size: 1.5em;
        padding: 0 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
        position: relative;
    `,
    logo: css`
        height: 50px;
        display: inline-block;
    `,
    nav: css`
        padding: 25px 0;
        position: sticky;
        top: 0;
        max-height: calc(100vh - 80px);
        overflow-y: auto;
        @media ${mediaQSmall} {
            position: static;
        }
    `,
    navItem: css`
        display: block;
        padding: 0 15px;
        line-height: 50px;
        height: 50px;
        text-decoration: none;
        color: #6A7382;
        cursor: pointer;
        &.active {
            color: #fff;
            background: #163357;
        }
        .icon {
            margin-right: 10px;
        }
    `,
    menuButton: css`
        position: absolute;
        right: -60px;
        top: 0;
        width: 60px;
        height: 60px;
        display: none;
        color: black;
        text-align: center;
        background: #fff;
        @media ${mediaQSmall} {
            display: block;
        }
    `,
    overlay: css`
        display: none;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 199;
        @media ${mediaQSmall} {
            &.open {
                display: block;
            }
        }
    `,
    info: css`
        position: fixed;
        left: 0;
        bottom: 0;
        width: 270px;
        text-align: center;
        background: #122845;
        color: #41526a;
        font-size: 12px;
        line-height: 15px;
        @media ${mediaQSmall} {
            position: absolute;
        }
    `,
    version: css`
        font-size: 10px;
    `,
};

function MenuNavItem(props: {to: string; text: string; icon: IconProp; onClick: () => void}) {
    return (
        <NavLink onClick={props.onClick} to={props.to} activeClassName={'active'} className={styles.navItem}>
            <span className={'icon'}>
                <FontAwesomeIcon icon={props.icon} />
            </span>
            {props.text}
        </NavLink>
    );
}

/**************** Component ***************/
export class MenuComponent extends React.Component<{
    serverVersion: string;
    sessionType: UserSessionType;
    sessionClientId: string;
    getVersion: () => Promise<void>;
    getTimezone: () => Promise<void>;
}> {
    public readonly state = {
        opened: false,
    };

    public componentDidMount() {
        this.props.getVersion();
        this.props.getTimezone();
    }

    public render() {
        const isAdmin = this.props.sessionType === 'admin';

        return (
            <>
                <div onClick={this.toggle} className={cx(styles.overlay, {['open']: this.state.opened})} />
                <div className={cx(styles.root, {['open']: this.state.opened})}>
                    <div className={styles.head}>
                        <img className={styles.logo} src={LogoImg} alt={'jointeff'}/>
                        <a onClick={this.toggle} className={styles.menuButton}><FontAwesomeIcon icon={faBars} /></a>
                    </div>
                    <div className={styles.nav}>
                        <MenuNavItem onClick={this.toggle} to={routes.overview.path} text={'Overview'} icon={faTachometerAlt}/>
                        <MenuNavItem onClick={this.toggle} to={routes.requests.path} text={'Requests'} icon={faSpinner}/>
                        {isAdmin ? <MenuNavItem onClick={this.toggle} to={routes.clients.path} text={'Clients'} icon={faUser}/> : null}
                        {!isAdmin ? <MenuNavItem onClick={this.toggle} to={routes.connectors.edit.path.replace(':id', this.props.sessionClientId)} text={'Connectors'} icon={faPlug}/> : null}
                        {!isAdmin ? <MenuNavItem onClick={this.toggle} to={routes.integration.path} text={'Integration'} icon={faEnvelope}/> : null}
                        <MenuNavItem onClick={this.toggle} to={routes.nodes.path} text={'Nodes'} icon={faLayerGroup}/>
                        {isAdmin ? <MenuNavItem onClick={this.toggle} to={routes.datasets.path} text={'Datasets'} icon={faTable}/> : null}
                        {isAdmin ? <MenuNavItem onClick={this.toggle} to={routes.users.path} text={'Users'} icon={faUserLock}/> : null}
                        <MenuNavItem onClick={this.toggle} to={routes.credits.path} text={'Credits'} icon={faMoneyBillAlt}/>
                        {isAdmin ? <MenuNavItem onClick={this.toggle} to={routes.log.path} text={'Log'} icon={faBug}/> : null}
                        {isAdmin ? <MenuNavItem onClick={this.toggle} to={routes.script.path} text={'Script'} icon={faSubscript}/> : null}
                    </div>
                    <div className={styles.info}>
                        <div>&copy; {new Date().getFullYear()} Jointeff LTD</div>
                        <div className={styles.version}>v{getVersion()} (server: {this.props.serverVersion})</div>
                    </div>
                </div>
            </>
        );
    }

    protected toggle = () => {
        this.setState({opened: !this.state.opened});
    }
}

export const Menu = connect(
    (state: IRootStore) => ({
        serverVersion: state.api.version,
        sessionType: state.api.sessionUser?.type,
        sessionClientId: state.api.sessionClientId,
    }),
    (dispatch) => ({
        getVersion: () => dispatch(ApiActions.getVersion({})),
        getTimezone: () => dispatch(ApiActions.getTimezone({}))
    }),
)(MenuComponent);