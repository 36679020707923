import { css, cx } from 'emotion';
import * as React from 'react';
import ScrollToBottom from 'react-scroll-to-bottom';
import { isArray } from '../../utils';
import { Ring } from '../ux/Ring';

/**************** Interfaces ***************/
export interface LogItem {
    _id?: string;
    timestamp: string;
    level: string;
    message: string;
    forkName?: string;
    pid?: number;
    linkedEntityName?: string;
    linkedEntityValue?: string;
}

/**************** Style ***************/
const style = {
    root: css`
        background-color: black;
        color: silver;
        font-family: monospace;
        word-break: break-all;
        white-space: pre-wrap;
        overflow: hidden auto;
        min-height: 300px;
        position: relative;
    `,
    log: css`
        font-family: Inconsolata;
        padding: 0 10px;
        font-size: 13px;
        overflow: hidden;
        word-wrap: break-word;
        border-left: 5px solid #555;
    `,
    log_E: css`
        border-left: 5px solid #ff0000;
        background: #540a0a;
    `,
    log_W: css`border-left: 5px solid #ffd200;`,
    log_I: css`border-left: 5px solid #0090ff;`,
    log_meta_bold: css`
        font-weight: bold;
        color: seashell;
    `,
    log_meta_grey: css`
        color: grey;
    `,
    logWrapper: css`
        margin-bottom: 13px;
    `,
    overlay: css`
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 10;
        background: #000;
        pointer-events: none;
        transition: 0.2s opacity linear;
    `,
    spinner: css`
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    `,
    scrollContainer: css`
        height: 100%;
    `,
};

/**************** Component props ***************/
export interface ComponentProps {
    logs: LogItem[];
    style?: any;
    className?: string;
    shortView?: boolean;
}

/**************** Component ***************/
export class LogView extends React.Component<ComponentProps> {
    public readonly state = {
        scrollMode: 'top',
    };

    // ref to scrolled div
    protected rootDivRef: HTMLDivElement = null;

    /**
     * Life cycle methods
     * @param nextProps
     */
    public componentDidMount() {
        if (isArray(this.props.logs)) {
            this.planScrollingChange();
        }
        if ((document as any).fonts) {
            (document as any).fonts.load('13px Inconsolata', 'console').then(() => {
                this.scrollToBottom();
            });
        }
    }

    public UNSAFE_componentWillReceiveProps(nextProps: ComponentProps) {
        // Scroll component to bottom if props is received
        if (isArray(nextProps.logs) && this.state.scrollMode === 'top') {
            this.planScrollingChange();
        }
    }

    /**
     * Render
     */
    public render() {
        const sorted = [...(this.props.logs || [])];
        sorted.reverse();

        const overlayDisplay = {
            opacity: this.state.scrollMode === 'top' ? 1 : 0,
        };

        return (
            <div className={cx(style.root, this.props.className)} style={this.props.style}>
                <ScrollToBottom
                    className={style.scrollContainer}
                    mode={this.state.scrollMode}
                >
                    <div className={style.logWrapper} ref={(element) => this.rootDivRef = element}>
                    {
                        sorted.map((item) => (
                            <div
                                className={cx(style.log, this.levelStyleByName(item.level))}
                                key={'log-' + (item._id ? item._id : item.timestamp)}
                            >
                                <span>
                                    <span className={style.log_meta_grey}>{this.getHeader(item)}</span>
                                    {!this.props.shortView ? (
                                        <>
                                            <span className={style.log_meta_bold}>{this.getSpaces(item) + '[' + item.level + ']'}</span>
                                            <span className={style.log_meta_grey}>{' :: '}</span>
                                        </>
                                    ) : null}
                                    {item.linkedEntityName ? <span>[{item.linkedEntityName} - {item.linkedEntityValue}]</span> : null}
                                </span>
                                <span>{item.message}</span>
                            </div>
                        ))
                    }
                    </div>
                </ScrollToBottom>
                <div className={style.overlay} style={overlayDisplay}>
                    <Ring color={'#fff'} className={style.spinner}/>
                </div>
            </div>
        );
    }

    /**
     * This method plan scroll type change to next tick
     */
    protected planScrollingChange = () => {
        setImmediate(() => {
            this.scrollToBottom();
            this.setState({
                scrollMode: 'bottom',
            });
        });
    }

    /**
     * Scroll to bottom helper
     */
    protected scrollToBottom = () => {
        if (this.rootDivRef) {
            this.rootDivRef.parentElement.scrollTop = this.rootDivRef.offsetHeight;
        }
    }

    /**
     * Get style of log level
     * @param level
     */
    protected levelStyleByName(level: string) {
        if (level === 'error') {
            return style.log_E;
        } else if (level === 'info') {
            return style.log_I;
        } else if (level === 'warn') {
            return style.log_W;
        }
        return null;
    }

    /**
     * Get metadata header
     * @param item
     */
    protected getHeader(item: LogItem) {
        if (this.props.shortView) {
            return '';
        }
        return item.timestamp.replace('T', ' ').replace('Z', '') + ' [' + item.pid + '] {' + item.forkName + '} ';
    }

    /**
     * Get spaces between meta data and message to keep it same for each line
     * @param item
     */
    protected getSpaces(item: LogItem) {
        const header = this.getHeader(item)  + '[' + item.level + ']';
        const repeat = 51 /* wanted spaces */ - header.length;
        return ((repeat > 0) ? ' '.repeat(repeat) : '');
    }

}

