import * as React from 'react';
import { css, cx } from 'emotion';
import { fadeOut, fadeIn } from '../../utils';
import { Button, ButtonColors } from '../ux/Button';
import { FormSubmit } from '../ux/forms/FormSubmit';
import { mediaQSmall } from '../../utils/responsivity';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FormError } from '../ux/forms/FormError';

export type ModalSizes = 'large' | 'medium' | 'small' | 'auto' | 'xsmall';

const styles = {
    overlay: css`
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        overflow: auto;
        background: rgba(0,0,0,0.75);
        z-index: 250;
        &.hidden {
            animation: ${fadeOut} 0.2s linear forwards;
            pointer-events: none;
        }
        &.visible {
            animation: ${fadeIn} 0.2s linear forwards;
            pointer-events: auto;
        }
    `,
    modal: css`
        position: relative;
        width: 50%;
        background: #fff;
        box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.75);
        margin: 10% auto;
        border-radius: 3px;
        color: #444;
        h1 {
            font-size: 1.5em;
        }
        &.auto {
            display: inline-block;
            width: auto;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
        }
        &.small {
            width: 30%;
            min-width: 400px;
            max-width: 600px;
            @media ${mediaQSmall} {
                min-width: 0;
                width: 90%;
            }
        }
        &.xsmall {
            width: 30%;
            min-width: 300px;
            max-width: 400px;
            @media ${mediaQSmall} {
                min-width: 0;
                width: 90%;
            }
        }
        &.medium {
            width: 50%;
            min-width: 500px;
            max-width: 700px;
            @media ${mediaQSmall} {
                min-width: 0;
                width: 90%;
            }
        }
        &.large {
            width: 90%;
        }
    `,
    modalContent: css`
        padding: 20px;
    `,
    footer: css`
        background: #EEE;
        border-top: 1px solid #ccc;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        button {
            margin-right: 10px;
        }
    `,
    footerError: css`
        margin-right: 10px;
    `,
};

export interface ModalProps {
    active: boolean;
    size?: ModalSizes;
    className?: string;
    buttons?: {
        text: string;
        action?: () => void;
        color: ButtonColors;
        isSubmit?: boolean;
        icon?: IconProp;
    }[];
}

/**************** Component ***************/
export class Modal extends React.Component<ModalProps> {
    public readonly state = {
        visible: null,
        contentVisible: false,
    };

    public static getDerivedStateFromProps(nextProps, prevState) {
        // initial state - frist load flick fix
        if (!nextProps.active && prevState.visible === null) {
            return null;
        }

        // change visible acording to props
        if (nextProps.active !== prevState.visible) {
            return {
                visible: nextProps.active,
                contentVisible: nextProps.active ? true : prevState.contentVisible
            };
        }

        return null;
    }

    public render() {
        if (this.state.visible === null) {
            return null;
        }

        const submitable = !!((this.props.buttons || []).find((i) => i.isSubmit));

        if (!this.props.active) {
            return null
        }

        return (
            <div
                className={cx(styles.overlay, this.state.visible ? 'visible' : 'hidden')}
                onAnimationEnd={this.onAnimationEnd}
            >
                <div className={cx(styles.modal, this.props.size ? this.props.size : 'medium', this.props.className)}>
                    {this.state.visible || this.state.contentVisible ? (
                        <div className={styles.modalContent}>
                            {this.props.children}
                        </div>
                    ) : null}
                    {this.props.buttons ? (
                        <div className={styles.footer}>
                            {submitable ? (<div className={styles.footerError}>
                                <FormError />
                            </div>) : null}
                            {this.props.buttons.map((i) => (
                                !!i ? (
                                    i.isSubmit ? (
                                        <FormSubmit icon={i.icon} key={i.text} color={i.color}>{i.text}</FormSubmit>
                                    ) : (
                                        <Button icon={i.icon} key={i.text} onClick={i.action} color={i.color}>{i.text}</Button>
                                    )
                                ) : null
                            ))}
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }

    protected onAnimationEnd = () => {
        // hide content after animation
        this.setState({
            contentVisible: this.state.visible,
        });
    }
}

