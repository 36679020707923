import * as React from 'react';
import { FormProvider } from './FormProvider';
import { JsonValidatorObjectChildsSchema, isArray, JsonValidationFieldError, JsonValidator, isString, filterUndefined } from '../../../utils';

/**************** Component support ***************/
export class FormValidationError extends Error {
    constructor(
        public readonly fields: {
            [field: string]: string;
        },
    ) {
        super('Form validation failed.');
        const actualProto = new.target.prototype;
        if (Object.setPrototypeOf) {
            Object.setPrototypeOf(this, actualProto);
        } else {
            (this as any).__proto__ = actualProto;
        }
    }
}

/**************** Component interface ***************/
export interface FormProps<T> {
    // external data drive
    data?: Partial<T>;
    onChange?: (data: Partial<T>, internal?: boolean) => void;

    defaultData?: Partial<T>;
    schema?: JsonValidatorObjectChildsSchema;
    validate?: (data: Partial<T>) => Promise<void>;
    action?: (data: Partial<T>) => Promise<void>;
    altActions?: {[name: string]: (data: Partial<T>) => Promise<any>}

    enableReinitialize?: boolean

    // save on cmd/ctrl + s
    saveOnNativeEvent?: boolean;
}

export interface FormState<T> {
    data: Partial<T>;
    error: Error;
    actionError: Error;
    visited: string[] | true;
}

/**************** Component ***************/
export class Form<T extends object> extends React.Component<FormProps<T>, FormState<T>> {
    constructor(props: FormProps<T>) {
        super(props);
        this.state = {
            data: props.defaultData ? props.defaultData : {},
            error: null,
            actionError: null,
            visited: [],
        };
    }

    public UNSAFE_componentWillReceiveProps(nextProps: FormProps<T>, nextState: FormState<T>) {
        if (this.props.defaultData !== nextProps.defaultData && nextProps.defaultData && nextProps.enableReinitialize) {
            this.setState({data: nextProps.defaultData});
        }
    }

    /**
     * Render
     */
    public render() {
        return (
            <FormProvider
                value={this.getData()}
                error={this.state.error}
                onChange={this.onChange}
                onError={this.onError}
                errorHandler={this.errorHandler}
                action={this.action}
                altActions={this.getAltActions()}
                validate={this.validate}
                saveOnNativeEvent={this.props.saveOnNativeEvent}
            >
                {this.props.children}
            </FormProvider>
        );
    }

    /**
     * Try to call action
     */
    protected action = async () => {
        if (this.props.action) {
            try {
                await this.props.action(this.getData());
                this.setState({actionError: null});
            } catch (e) {
                console.log(e)
                this.setState({actionError: e});
            }
        }
    }

    /**
     * Try to call action
     */
     protected getAltActions = () => {
        if (this.props.altActions) {
            return Object.keys(this.props.altActions).reduce((acc, name) => {
                return {
                    ...acc,
                    [name]: async () => {
                        try {
                            await this.props.altActions[name](this.getData());
                            this.setState({actionError: null});
                        } catch (e) {
                            this.setState({actionError: e});
                        }
                    }
                }
            }, {})
        }

        return undefined;
    }

    /**
     * Validate form
     */
    protected validate = async (caller?: string) => {
        if (caller) {
            this.setState({
                visited: isArray(this.state.visited) ? this.state.visited.concat([caller]) : [caller],
            });
        } else {
            this.setState({
                visited: true,
            });
        }
        if (this.props.validate) {
            await this.props.validate(this.getData());
        }

        if (this.props.schema) {
            try {
                JsonValidator.objectValidator(this.getData(), this.props.schema);
            } catch (e) {
                if (e instanceof JsonValidationFieldError) {
                    throw new FormValidationError(e.details.reduce((result, detail) => {
                        result[detail.field] = detail.message;
                        return result;
                    }, {}));
                }
            }
        }
    }

    /**
     * On data changed
     */
    protected onChange = (data: any, name?: string, internal?: boolean) => {
        if (this.props.onChange) {
            this.props.onChange(data, internal);
        }
        if (!this.props.data) {
            this.setState({data});
        }
    }

    /**
     * On validation error
     */
    protected onError = (error: Error) => {
        if (error) {
            // tslint:disable-next-line: no-console
            console.error('Form error', (error as any).fields);
        }
        this.setState({error});
    }

    /**
     * Common error handler
     */
    protected errorHandler = (name?: string): string => {
        if (isString(name) && this.state.error && this.state.error instanceof FormValidationError) {
            const error: FormValidationError = this.state.error;
            if (
                this.state.visited === true || (
                    isArray(this.state.visited) && this.state.visited.indexOf(name) !== -1
                )
            ) {
                return error.fields[name] ? error.fields[name] : null;
            }
        } else if (!isString(name) && this.state.actionError) {
            return this.state.actionError.message;
        }
        return null;
    }

    protected getData = () => {
        return this.props.data ? this.props.data : this.state.data;
    }
}

