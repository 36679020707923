import * as React from 'react';

/**
 * Form save event provider component
 */
export class SaveEventHandler extends React.Component {
    protected static listeners: (() => void)[] = [];

    /**
     * Public event add and remove
     * @param handler
     */
    public static addHandler(handler: () => void) {
        SaveEventHandler.listeners.push(handler);
    }

    public static removeHandler(handler: () => void) {
        SaveEventHandler.listeners = SaveEventHandler.listeners.filter((h) => h !== handler);
    }

    /**
     * Render childs
     */
    public render() {
        return null;
    }

    public componentDidMount() {
        window.addEventListener('keydown', this.onKeyDown);
    }

    public componentWillUnmount() {
        window.removeEventListener('keydown', this.onKeyDown);
    }

    /**
     * On window key down
     * @param e
     */
    protected onKeyDown = (e: KeyboardEvent) => {
        if (e.metaKey && e.key === 's') {
            e.preventDefault();
            e.stopPropagation();
            const listener = SaveEventHandler.listeners[SaveEventHandler.listeners.length - 1];
            if (listener) {
                listener();
            }
        }
    }
}