import { getByExpression } from ".";

/**
 * Flatten array to one dimensional array
 * @param arr1
 */
export function flatten(arr: any[]) {
    return arr.reduce((acc, val) => Array.isArray(val) ? acc.concat(flatten(val)) : acc.concat(val), []);
}

/**
 * Get diff of two arrays
 * @param arr1
 * @param arr2
 */
export function arrayDiff(arr1: any[], arr2: any[]): any[] {
    return arr1.filter((x) => !arr2.includes(x));
}

export function mergeArrays(array1: any[], array2: any[]) {
    return array1.map((item, index) => array2[index] ? array2[index] : item);
}

export function mergeObjectArrays(array1: object[], array2: object[]) {
    return array1.map((item, index) => ({...item, ...array2[index]}));
}

export function move(array: any[], from: number, to: number) {
    array.splice(to, 0, array.splice(from, 1)[0]);
}

export function arrayOmit(array: any[], indexes: number[]) {
    return array.filter((item, index) => indexes.indexOf(index) === -1);
}

export function findDuplicities(array: any[], key?: string) {
    let notUniques = [];
    array.forEach((item1, index1) => {
        const found = array.findIndex((item2, index2) => {
            if (index1 === index2) {
                return false
            }
            if (key) {
                return getByExpression(item1, key) === getByExpression(item2, key);
            } else {
                return item2 === item1;
            }
        });

        if (found !== -1 && !notUniques.includes(found)) {
            notUniques.push(found);
        }
    })
    return notUniques;
}
