import { combineReducers } from 'redux';
import { ApiReducer, ApiInitialState } from './api';
import { AppReducer, AppInitialState } from './app';

// Combined interface from all initial states
export interface IRootStore {
    app: AppInitialState;
    api: ApiInitialState;
}

// Main combined reducer
export const RootStore = combineReducers({
    app: AppReducer,
    api: ApiReducer,
});
