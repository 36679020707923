import * as React from 'react';
import { css, cx } from 'emotion';
import { isArray } from '../../../utils';
import { mediaQMedium } from '../../../utils/responsivity';

const styles = {
    row: css`
        display: table-row;
        @media ${mediaQMedium} {
            display: table;
            width: 100%;
            border-bottom: 1px solid #ccc;
        }
    `,
    cell: css`
        display: table-cell;
        padding: 10px;
        &.odd {
            background: #f8f9fb;
        }
        @media ${mediaQMedium} {
            display: table-row;
            width: 100% !important;
            &.mobileHidden {
                display: none;
            }
        }
    `,
    mobileLabel: css`
        font-weight: bold;
        display: none;
        white-space: nowrap;
        @media ${mediaQMedium} {
            display: table-cell;
            width: 1px;
            padding-right: 10px;
        }
    `,
    valueWraper: css`
        @media ${mediaQMedium} {
            display: table-cell;
            flex-wrap: wrap;
            padding: 5px 0;
        }
    `,
    mobileBlocks: css`
        display: none;
        &.odd {
            background: #f8f9fb;
        }
        @media ${mediaQMedium} {
            display: block;
        }
    `,
    mobileBlockItem: css`
        &.odd {
            background: #f8f9fb;
        }
    `,
};

export interface TableRowProps {
    data: React.ReactChild[] | {[name: string]: React.ReactChild};
    odd?: boolean;
    cols: {
        columnClassName: string;
        size: number;
        name: string;
        text: string;
        mobileBlock: boolean;
    }[];
    sizeCount: number;
    cellsClassName?: string;
}

export class TableRow extends React.Component<TableRowProps> {
    public render() {
        const items = isArray(this.props.data) ? (
            this.props.data
        ) : (
            this.props.cols.map((i) => this.props.data[i.name])
        );

        return (
            <>
                <div className={styles.row}>
                    {items.map((item, index) => (
                        <div
                            key={`table-cell-${index}`}
                            className={cx(
                                styles.cell,
                                {['odd']: this.props.odd},
                                this.props.cols[index].columnClassName,
                                {['mobileHidden']: this.props.cols[index].mobileBlock},
                                this.props.cellsClassName,
                            )}
                            style={{
                                width: `${this.props.cols[index].size / this.props.sizeCount * 100}%`,
                            }}
                        >
                            <label className={cx(styles.mobileLabel)}>{this.props.cols[index].text}</label>
                            <span className={styles.valueWraper}>{item}</span>
                        </div>
                    ))}
                </div>

                {/* Full width mobile blocks */}
                <div className={styles.mobileBlocks}>
                    {items.map((item, index) => (
                        this.props.cols[index].mobileBlock ? (
                            <div
                                key={`table-cell-block-${index}`}
                                className={cx(
                                    styles.mobileBlockItem,
                                    {['odd']: this.props.odd},
                                )}
                            >
                                <span className={styles.valueWraper}>{item}</span>
                            </div>
                        ) : null
                    ))}
                </div>
            </>
        );
    }
}
