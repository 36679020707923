import * as React from 'react';
import { faBan, faCheck, faHourglass, faTimes } from '@fortawesome/free-solid-svg-icons';
import { isNumber } from '../../utils';
import { StatisticsBox, StatisticsRow } from '../ux/StatisticsBox';

/**
 * Overview component
 */
export interface ClientOverviewData {
    clientName: string;
    failed: number;
    success: number;
    notFound: number;
    averageTime: number;
    successTime: number;
    notFoundTime: number;
    failedTime: number;
}

export interface ClientResponseStatisticsProps {
    data: ClientOverviewData;
}

export class ClientResponseStatistics extends React.Component<ClientResponseStatisticsProps> {

    public render() {
        const item = this.props.data;

        return (
            <StatisticsRow>
                <StatisticsBox
                    color="green"
                    name="Success responses count"
                    icon={faCheck}
                    value={item.success ? item.success : 0}
                    subTime={this.renderSubTime(item.successTime)}
                />
                <StatisticsBox
                    color="yellow"
                    name="Not found responses count"
                    icon={faBan}
                    value={item.notFound ? item.notFound : 0}
                    subTime={this.renderSubTime(item.notFoundTime)}
                />
                <StatisticsBox
                    color="red"
                    name="Failed responses count"
                    icon={faTimes}
                    value={item.failed ? item.failed : 0}
                    subTime={this.renderSubTime(item.failedTime)}
                />
                <StatisticsBox
                    color="gray"
                    name="Average response time"
                    icon={faHourglass}
                    value={item.averageTime ? Math.round(item.averageTime) + ' ms' : 'N/A'}
                />
            </StatisticsRow>
        );
    }

    protected renderSubTime = (time: number) => {
        return isNumber(time) && !isNaN(time) ? `Average time: ${Math.round(time)} ms` : 'Average time: N/A ms';
    }
}

