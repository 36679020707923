import * as React from 'react';
import { isArray, isString, isError, objectHasProperties, isObject } from '../../utils';

export function renderErrors(e: any, className?: string) {
    if (isArray(e)) {
        return e.map((i, index) => (
            <div className={className} key={`err-${index}`}>
                {stringifyError(i)}
            </div>
        ));
    } else if (isString(e)) {
        return stringifyError(e);
    } else if (e) {
        return `Unknown error`;
    }
    return null;
}

export function  stringifyError(e: any) {
    if (isString(e)) {
        return e;
    } else if (isError(e)) {
        return e.message;
    } else if (isObject(e)) {
        if (objectHasProperties(e, ['message', 'dataPath'])) {
            return `Validation error: ${e.dataPath} ${e.message}`;
        } else {
            return `Unknown error`;
        }
    }
    return `Unknown error`;
}