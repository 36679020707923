import * as React from 'react';
import { css, cx } from 'emotion';
import { BaseConnectorInterface } from '../../../api/structure';
import { Token } from '../../ux/Token';

export const style = {
    row: css`
        display: flex;
        margin-bottom: 5px;
        font-size: 12px;
        align-items: center;
    `,
    rowSection: css`
        margin-top: 15px;
        border-bottom: 1px solid #ddd;
    `,
    key: css`
        font-weight: bold;
        margin-right: 5px;
        min-width: 100px;
    `,
    value: css`
        flex: 1;
        word-wrap: break-word;
        overflow: hidden;
    `,
    info: css`
        font-weight: bold;
        text-align: center;
        font-size: 14px;
        margin: 0 10px;
    `,
    deprecated: css`
        padding: 10px;
        border-radius: 8px;
        background: #f5dd95;
        border: 1px solid #ffc107;
        color: #000;
        margin: 20px 0;
    `,
};

export const connectorViewer = {
    email_service: (prefix: string, data: BaseConnectorInterface) => {
        return (
        <>
            <div className={style.deprecated}>
                    This service is deprecated and will be removed in future versions. You should use User integration service and then navigate to User Integration section to setup your email, slack, etc.
                </div>
            <div className={style.row}>
                <div className={style.key}>Type:</div>
                <div className={style.value}>Email service</div>
            </div>
            <div className={style.row}>
                <div className={style.key}>Email address:</div>
                <div className={style.value}>{data.config.address}</div>
            </div>
        </>);
    },
    http_json_command: (prefix: string, data: BaseConnectorInterface) => {
        return (<>
            <div className={style.row}>
                <div className={style.key}>Type:</div>
                <div className={style.value}>HTTP Json Command</div>
            </div>
            <div className={cx(style.row, style.rowSection)}>
                <div className={style.key}>Api accesses:</div>
            </div>
            {data.config.apiAccess.map((access) => (
                <div className={style.row} key={`api-access-${access.name}`}>
                    <div className={style.key}>{access.name}</div>
                    <div className={style.value}><Token token={access.apiKey} /></div>
                </div>
            ))}
        </>);
    },
    http_json_fetch: (prefix: string, data: BaseConnectorInterface) => {
        return (<>
            <div className={style.row}>
                <div className={style.key}>Type:</div>
                <div className={style.value}>HTTP Json Fetch</div>
            </div>
            <div className={style.row}>
                <div className={style.key}>Data URL:</div>
                <div className={style.value}>{data.config.data.url}</div>
            </div>
            <div className={style.row}>
                <div className={style.key}>Status URL:</div>
                <div className={style.value}>{data.config.status.url}</div>
            </div>
            <div className={style.row}>
                <div className={style.key}>Secret:</div>
                <div className={style.value}><Token token={data.config.secret} /></div>
            </div>
        </>);
    },
    user_integration_service: (prefix: string, data: BaseConnectorInterface) => {
        return (
            <div className={style.info}>
                Set in client's integration section.
            </div>
        );
    }
};
