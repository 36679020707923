import * as React from 'react';
import { FormProvidedProps, withFormData } from './FormProvider';
import { Form } from './Form';
import { FormArray } from './FormArray';
import { FormInput } from './FormInput';
import { css } from 'emotion';
import { FormError } from './FormError';

const style = {
    arrayItemInputsContent: css`
        display: flex;
    `,
    input: css`
        margin-bottom: 15px;
        &.small {
            max-width: 300px;
        }
    `,
    arrayInput: css`
        margin-right: 10px;
    `,
    itemStyle: css`
        padding: 0;
        margin-bottom: 0;
    `,
};

interface ComponentProps extends Partial<FormProvidedProps> {
    name: string;
}

interface DataInternalStructure {
    array: any[];
}

class Component extends React.Component<ComponentProps, {data: DataInternalStructure}> {
    public static getDerivedStateFromProps(nextProps: ComponentProps, prevState: {data: DataInternalStructure}) {
        const value = nextProps.formGetValue(nextProps.name) || {};

        const data = Object.keys(value).reduce((result, name: string) => {
            result.push({
                name,
                value: value[name],
            });
            return result;
        }, [])
            .concat((prevState?.data?.array || []).filter((i) => !i.name));

        return {
            data: {
                array: data,
            },
        };
    }

    public readonly state = {
        data: {
            array: [],
        },
    };

    public render() {
        return (
            <Form
                data={this.state.data}
                onChange={this.onChange}
            >
                <FormArray
                    name={`array`}
                    itemClassName={style.itemStyle}
                    item={(prefix: string, data: any) => {
                        return (
                            <>
                                <div className={style.arrayItemInputsContent}>
                                    <div className={style.input}>
                                        <FormInput
                                            name={`${prefix}.name`}
                                            className={style.arrayInput}
                                        />
                                    </div>
                                    <div className={style.input}>
                                        <FormInput
                                            name={`${prefix}.value`}
                                            className={style.arrayInput}
                                        />
                                    </div>
                                </div>
                            </>
                        );
                    }}
                />
                <FormError name={this.props.name} />
            </Form>
        );
    }

    protected onChange = (data: DataInternalStructure) => {
        this.setState({data});
        const output = data.array.reduce((result, item) => {
            if (item.name) {
                result[item.name] = item.value;
            }
            return result;
        }, {});

        this.props.formValueChange(this.props.name, output);
    }
}

export const FormObject = withFormData(Component);
