import * as React from 'react';
import { css, cx } from 'emotion';
import { TableHeadCell } from './TableHeadCell';
import { TableDataStructure, TableFilter } from './Table';
import { mediaQMedium } from '../../../utils/responsivity';
import { TableFilterCell } from './TableFilterCell';

const styles = {
    head: css`
        display: table-header-group;
        background: #fff;
        color: #4f73a1;
        @media ${mediaQMedium} {
            display: block;
        }
    `,
    headRow: css`
        display: table-row;
        @media ${mediaQMedium} {
            display: block;
        }
    `,
    mobileHidden: css`
        @media ${mediaQMedium} {
            display: none;
        }
    `,
    mobileVisible: css`
        display: none;
        @media ${mediaQMedium} {
            display: block;
        }
    `,
    headerMobile: css`
        display: none;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        color: #666;
        margin-bottom: 8px;
        @media ${mediaQMedium} {
            display: flex;
        }
    `,
    headerDropdown: css`
        border: 1px solid #f1f1f1;
        border-radius: 3px;
        padding: 0 8px;
        margin-left: 10px;
        text-align: right;
        min-width: 30%;
        position: relative;
    `,
    headerDropdownOverlay: css`
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 100;
        &.hidden {
            display: none;
        }
    `,
    headerDropdownMenu: css`
        border: 1px solid #f1f1f1;
        border-radius: 3px;
        padding: 0 8px;
        margin-left: 10px;
        text-align: right;
        min-width: 100%;
        position: absolute;
        right: -1px;
        top: -1px;
        background: #fff;
        z-index: 110;
        &.hidden {
            display: none;
        }
    `,
};

export interface TableHeadProps {
    className?: string;
    columns: TableDataStructure[];
    onFilterChange: (filter: Partial<TableFilter>) => void;
    filter?: TableFilter;
}

export class TableHead extends React.Component<TableHeadProps, {
    mobileSortDropdown: boolean;
}> {
    public readonly state = {
        mobileSortDropdown: false,
    };

    public componentDidMount() {
        if (!this.props.filter?.sortName) {
            const foundDefault = this.props.columns.find((c) => c.defaultSort);
            if (foundDefault) {
                this.onFilterChange({
                    sortName: foundDefault.name,
                    sortOrder: foundDefault.defaultSort,
                });
            }
        }
    }

    public render() {
        const foundDefault = this.props.columns.find((c) => c.defaultSort);
        const actualSortedHeader = this.props.columns.find((i) => i.name === this.props.filter?.sortName) || foundDefault;
        const sortableColumns = this.props.columns.filter((i) => i.sortable);

        return (
            <div className={styles.head}>
                <div className={styles.headRow}>
                    {this.props.columns.map((header, index) => (
                        <TableHeadCell
                            {...header}
                            key={`table-header-cell-${header.name}`}
                            sortValue={this.props.filter?.sortName === header.name ? this.props.filter?.sortOrder as any : undefined}
                            onSortToggle={this.toggleSort.bind(this, header.name)}
                            className={styles.mobileHidden}
                        />
                    ))}

                    {this.props.filter && sortableColumns.length ? (
                        <div className={cx(styles.headerMobile)}>
                            Sort by:
                            <div className={cx(styles.headerDropdown)}>
                                <TableHeadCell
                                    sortValue={this.props.filter?.sortOrder}
                                    onSortToggle={
                                        (sortableColumns.length || !actualSortedHeader) > 1 ? this.toggleMobileSort : this.toggleSort.bind(this, actualSortedHeader?.name)
                                    }
                                    sortable
                                    text={actualSortedHeader ? actualSortedHeader.text : 'N/A'}
                                />
                                <div
                                    className={cx(styles.headerDropdownOverlay, {['hidden']: !this.state.mobileSortDropdown})}
                                    onClick={this.toggleMobileSort}
                                />
                                <div className={cx(styles.headerDropdownMenu, {['hidden']: !this.state.mobileSortDropdown})}>
                                    {sortableColumns.map((header, index) => (
                                        <TableHeadCell
                                            {...header}
                                            key={`table-header-cell-${header.name}`}
                                            sortValue={this.props.filter?.sortName === header.name ? this.props.filter?.sortOrder as any : undefined}
                                            onSortToggle={this.toggleSort.bind(this, header.name)}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>

                {this.props.filter ? (
                    <div className={styles.headRow}>
                        {this.props.columns.map((header, index) => (
                            <TableFilterCell
                                {...header}
                                key={`table-filter-cell-${header.name}`}
                                filterValue={this.props.filter?.filters ? this.props.filter?.filters[header.name] : ''}
                                onFilterChange={this.findFilterChange.bind(this, header.name)}
                            />
                        ))}
                    </div>
                ) : null}
            </div>
        );
    }

    protected toggleSort = (sortName: string) => {
        this.onFilterChange({
            sortName,
            sortOrder: (this.props.filter?.sortName === sortName ? this.props.filter?.sortOrder * -1 : 1) as any,
        });
        this.setState({mobileSortDropdown: false});
    }

    protected toggleMobileSort = () => {
        this.setState({mobileSortDropdown: !this.state.mobileSortDropdown});
    }

    protected findFilterChange = (name: string, value: string) => {
        this.onFilterChange({
            filters: {
                ...this.props.filter?.filters,
                [name]: value,
            },
        });
    }

    protected onFilterChange = (filter: Partial<TableFilter>) => {
        this.props.onFilterChange({
            ...filter,
        });
    }
}
