import * as React from 'react';
import { connect } from 'react-redux';
import { IRootStore } from '../state';
import { AdminOverview } from '../components/content/AdminOverview';
import { UserSessionType } from '../api/structure';
import { SelfcareOverview } from '../components/content/SelfcareOverview';

const style = {};

/**
 * Page
 */
class OverviewComponent extends React.Component<{
    sessionType: UserSessionType;
}> {
    public render() {
        const isAdmin = this.props.sessionType === 'admin';

        if (isAdmin) {
            return <AdminOverview />;
        }

        return <SelfcareOverview />;
    }
}

export const Overview = connect(
    (state: IRootStore) => ({
        sessionType: state.api.sessionUser?.type,
    }),
    (dispatch) => ({}),
)(OverviewComponent);
