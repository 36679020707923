import * as React from 'react';
import { css, cx } from 'emotion';
import { SectionHeader } from '../components/ux/SectionHeader';
import { connect } from 'react-redux';
import { ApiInitialState, ApiActions, ApiCallOptions } from '../state/api';
import { IRootStore } from '../state';
import { EntityListView } from '../components/content/EntityListView';
import { EntityListFilter, EntityIndex, SessionReincarnation } from '../api/Endpoints';
import { ConnectionAbility, GetEntity, RequestEntity, UserSessionType } from '../api/structure';
import { AppActions } from '../state/app';
import { routes } from './Router';
import { faPencilAlt, faBatteryFull, faSearch, faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { Token } from '../components/ux/Token';
import { safe, isArray, isString, isError, isObject, objectHasProperties, safePromise } from '../utils';
import { Badge } from '../components/ux/Badge';
import { Button } from '../components/ux/Button';
import { Modal } from '../components/modals/Modal';
import { RequestResponse } from '../components/content/RequestResponse';
import { renderErrors } from '../components/utils/DataErrors';
import { mediaQSmall } from '../utils/responsivity';
import { localDate } from '../utils/date';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const style = {
    token: css`
        font-family: monospace;
        font-size: 0.9em;
        padding: 4px 6px;
        display: block;
        background: #6c757d;
        color: #fff;
        display: inline-block;
        border-radius: 3px;
        cursor: pointer;
    `,
    fetchStatus: css`
        display: flex;
        align-items: center;
        white-space: nowrap;
        @media ${mediaQSmall} {
            flex-wrap: wrap;
        }
    `,
    fetchStatusItem: css`
        margin-left: 8px;
        white-space: nowrap;
    `,
    fetchStatusItemRight: css`
        margin-left: 8px;
        justify-self: flex-end;
        white-space: nowrap;
    `,

    errorsLabel: css`
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 15px;
        display: block;
    `,
    errorsList: css`
        color: rgb(220, 53, 69);
        margin-bottom: 20px;
    `,
    errorsListItem: css`
        margin-bottom: 5px;
    `,
    nowrap: css`
        white-space: nowrap;
    `,
    anonymizedCell: css``,
    listIcon: css`
        margin-right: 5px;
    `,
};

const RequestsList = connect(
    (state: {api: ApiInitialState}) => ({
        data: state.api.requests,
    }),
    (dispatch) => ({
        loadData: (opt: ApiCallOptions, filter: EntityListFilter<GetEntity<RequestEntity>>) => dispatch(ApiActions.listRequests(opt, filter)),
    }),
)(EntityListView);

const RequestsFetchList = connect(
    (state: {api: ApiInitialState}) => ({
        data: state.api.requestsFetch,
    }),
    (dispatch) => ({
        loadData: (opt: ApiCallOptions, filter: EntityListFilter<GetEntity<RequestEntity>>) => dispatch(ApiActions.listRequestsFetch(opt, filter)),
    }),
)(EntityListView);

/**
 * Page
 */
class RequestsComponent extends React.Component<{
    datasetIndex: EntityIndex;
    clientIndex: EntityIndex;
    nodeIndex: EntityIndex;
    sessionType: UserSessionType;
    sessionClientId: string;
    sessionReincarnation: SessionReincarnation;
    clientConnectorsIndex: EntityIndex<EntityIndex<string>>;
    connectionAbility: ConnectionAbility;
    redirectTo: (to: string) => void;
    getDatasetIndex: (opt: ApiCallOptions) => Promise<EntityIndex>;
    getClientsIndex: (opt: ApiCallOptions) => Promise<EntityIndex>;
    getNodesIndex: (opt: ApiCallOptions) => Promise<EntityIndex>;
    getClientsConnectorsIndex: (opt: ApiCallOptions) => Promise<EntityIndex<EntityIndex<string>>>;
    getClientConnectionAbility: (opt: ApiCallOptions, clientId: string) => Promise<ConnectionAbility>;
}> {
    public readonly state = {
        tab: null,
        showResponse: null,
        showErrors: null,
    };

    public componentDidMount() {
        safePromise(this.props.getNodesIndex({}));

        const isAdmin = this.props.sessionType === 'admin';
        if (isAdmin) {
            safePromise(this.props.getDatasetIndex({}));
            safePromise(this.props.getClientsIndex({}));
        } else {
            safePromise(this.props.getClientsConnectorsIndex({}));
            const clientId = this.props.sessionReincarnation ? this.props.sessionReincarnation.clientId : this.props.sessionClientId;
            safePromise(this.props.getClientConnectionAbility({}, clientId));
        }
    }

    public render() {
        const isAdmin = this.props.sessionType === 'admin';
        const ability = this.props.connectionAbility ? this.props.connectionAbility : {fetch: false, command: false};

        const tabs = isAdmin ? null : {
            ...(ability.command ? {requests: 'Command requests'} : null),
            ...(ability.fetch ? {fetch: 'Fetch requests'} : null),
        };

        const currentTab = this.state.tab ? this.state.tab : (tabs ? Object.keys(tabs)[0] : 'requests');

        return (
            <>
                <SectionHeader
                    tabs={tabs}
                    currentTab={currentTab}
                    onTabClick={this.onTabClick}
                >
                    Requests
                </SectionHeader>
                {currentTab === 'requests' ? (
                    <RequestsList
                        reloadInterval={10000}
                        columns={[
                            {
                                text: 'Created on',
                                name: 'createdOn',
                                size: 1,
                                sortable: true,
                                defaultSort: -1,
                                filterType: 'date',
                            },
                            ...(isAdmin ? [{
                                text: 'Client',
                                name: 'clientId',
                                sortable: false,
                                size: 1,
                                filterType: 'enum',
                                filterOptions: this.props.clientIndex || {},
                                filterPlaceholder: 'Filter by client'
                            }] : []),
                            {
                                text: 'Node',
                                name: 'nodeId',
                                sortable: false,
                                size: 0.5,
                                filterType: 'enum',
                                filterOptions: this.props.nodeIndex || {},
                                filterPlaceholder: 'Filter by node'
                            },
                            {
                                text: 'Anonymized',
                                name: 'anonymizedOn',
                                sortable: false,
                                size: 1,
                            },
                            {
                                text: 'Status',
                                name: 'wasSuccess',
                                sortable: false,
                                size: 1,
                            },
                            {
                                text: 'Token',
                                name: 'token',
                                size: 0,
                                filterType: 'full',
                                filterPlaceholder: 'Search by token'
                            }
                        ]}
                        actions={isAdmin ? [{
                            name: 'Show',
                            color: 'green',
                            action:  this.onShow,
                            icon: faPencilAlt,
                        }] : [{
                            name: 'Show response',
                            color: 'green',
                            action:  this.onResponseShow,
                            icon: faSearch,
                        }]}
                        renderer={{
                            createdOn: (value: GetEntity<RequestEntity>) => <span>{localDate(value.createdOn)}</span>,
                            clientId: (value: GetEntity<RequestEntity>) => <span>{this.props.clientIndex ? this.props.clientIndex[value.clientId] : 'N/A'}</span>,
                            nodeId: (value: GetEntity<RequestEntity>) => <span>{this.props.nodeIndex ? this.props.nodeIndex[value.nodeId] : 'N/A'}</span>,
                            anonymizedOn: (value: GetEntity<RequestEntity>) => (
                                value.anonymizedOn ? (
                                    <span className={style.nowrap}>
                                        <FontAwesomeIcon className={style.listIcon} icon={faUserSecret} />
                                        <span>{localDate(value.anonymizedOn)}</span>
                                    </span>
                                ) : ''
                            ),
                            wasSuccess: (value: GetEntity<RequestEntity>) => (
                                value.inProgress ? (
                                    <span className={style.nowrap}>
                                        <Badge color="blue" loading>In progress</Badge>
                                    </span>
                                ) : value.wasSuccess ? (
                                    <span className={style.nowrap}>
                                        <Badge color="green">Success</Badge>
                                    </span>
                                ) : (
                                    <span className={style.nowrap}>
                                        <Badge color="yellow">Not found</Badge>
                                    </span>
                                )
                            ),
                            token: (value: GetEntity<RequestEntity>) => <Token token={value.token} />,
                        }}
                        cellsClassName={(value: GetEntity<RequestEntity>, odd: boolean) => value.anonymizedOn ? cx(style.anonymizedCell, {['odd']: odd}) : null}
                        mainList
                    />
                ) : currentTab === 'fetch' ? (
                    <RequestsFetchList
                        reloadInterval={10000}
                        columns={[
                            {
                                text: 'Created on',
                                name: 'createdOn',
                                size: 1,
                                sortable: true,
                                defaultSort: -1,
                                filterType: 'date',
                            },
                            {
                                text: 'Node',
                                name: 'nodeId',
                                sortable: false,
                                size: 0.5,
                            },
                            {
                                text: 'Anonymized',
                                name: 'anonymizedOn',
                                sortable: false,
                                size: 1,
                            },
                            {
                                text: 'Status',
                                name: 'callStatuses',
                                sortable: false,
                                size: 1,
                            },
                        ]}
                        renderer={{
                            createdOn: (value: any) => <span>{localDate(value.createdOn)}</span>,
                            nodeId: (value: any) => <span>{this.props.nodeIndex ? this.props.nodeIndex[value.nodeId] : 'N/A'}</span>,
                            anonymizedOn: (value: GetEntity<RequestEntity>) => (
                                value.anonymizedOn ? (
                                    <span className={style.nowrap}>
                                        <FontAwesomeIcon className={style.listIcon} icon={faUserSecret} />
                                        <span>{localDate(value.anonymizedOn)}</span>
                                    </span>
                                ) : ''
                            ),
                            callStatuses: (value: any) => (
                                <div>
                                    {Object.keys(value.callStatuses).map((connId) => {
                                        const status = value.callStatuses[connId];
                                        const connName = safe(() => this.props.clientConnectorsIndex[this.props.sessionClientId][connId], 'Unknown');
                                        return (
                                            <div key={`conn-status-${connId}`} className={style.fetchStatus}>
                                                <strong className={style.fetchStatusItem}>{connName}:</strong>
                                                {
                                                    status.status === 'success' ?
                                                        <Badge className={style.fetchStatusItem} color={'green'}>Success</Badge>
                                                    : status.status === 'error' ?
                                                        <Badge className={style.fetchStatusItem} color={'red'}>Error</Badge>
                                                    : status.status === 'not-found' ?
                                                        <Badge className={style.fetchStatusItem} color={'yellow'}>Not found</Badge>
                                                    : <Badge className={style.fetchStatusItem} color={'gray'}>{status.status}</Badge>
                                                }
                                                {status.status === 'error' ? (
                                                    <Button
                                                        className={style.fetchStatusItemRight}
                                                        color={'red'}
                                                        size={'small'}
                                                        icon={faSearch}
                                                        onClick={() => this.onErrorsShow(value)}
                                                    >
                                                        Show errors
                                                    </Button>
                                                ) : null}
                                            </div>
                                        );
                                    })}
                                </div>
                            ),
                        }}
                        cellsClassName={(value: GetEntity<RequestEntity>, odd: boolean) => value.anonymizedOn ? cx(style.anonymizedCell, {['odd']: odd}) : null}
                        mainList
                    />
                ) : null}

                <Modal
                    active={!!this.state.showResponse}
                    size={'small'}
                    buttons={[{
                        text: 'Close',
                        color: 'gray',
                        action: this.onResponseClose
                    }]}
                >
                    <SectionHeader>Command request response</SectionHeader>
                    <RequestResponse id={this.state.showResponse} />
                </Modal>
                <Modal
                    active={!!this.state.showErrors}
                    size={'small'}
                    buttons={[{
                        text: 'Close',
                        color: 'gray',
                        action: this.onErrorsClose
                    }]}
                >
                    <SectionHeader>Fetch request errors</SectionHeader>
                    {Object.keys(this.state.showErrors || {}).map((connId) => {
                        return (
                            <div key={`connector-error-${connId}`}>
                                <label className={style.errorsLabel}>{safe(() => this.props.clientConnectorsIndex[this.props.sessionClientId][connId], 'Unknown')}</label>
                                <div className={style.errorsList}>{renderErrors(this.state.showErrors[connId].errors, style.errorsListItem)}</div>
                            </div>
                        );
                    })}
                </Modal>
            </>
        );
    }

    protected onShow = async (i: GetEntity<RequestEntity>) => {
        this.props.redirectTo(routes.requests.show.path.replace(':id', i._id));
    }

    protected onTabClick = (name: string) => {
        this.setState({tab: name});
    }

    protected onResponseShow = async (i: GetEntity<RequestEntity>) => {
        this.setState({showResponse: i._id});
    }

    protected onResponseClose = () => {
        this.setState({showResponse: null});
    }

    protected onErrorsShow = (i: GetEntity<RequestEntity>) => {
        this.setState({showErrors: i.callStatuses});
    }

    protected onErrorsClose = () => {
        this.setState({showErrors: null});
    }
}

export const Requests = connect(
    (state: IRootStore) => ({
        datasetIndex: state.api.datasetIndex,
        clientIndex: state.api.clientIndex,
        nodeIndex: state.api.nodeIndex,
        sessionType: state.api.sessionUser?.type,
        sessionClientId: state.api.sessionClientId,
        sessionReincarnation: state.api.sessionReincarnation,
        clientConnectorsIndex: state.api.clientConnectorsIndex,
        connectionAbility: state.api.connectionAbility,
    }),
    (dispatch) => ({
        redirectTo: (to: string) => dispatch(AppActions.redirectTo(to)),
        getDatasetIndex: (opt: ApiCallOptions) => dispatch(ApiActions.getDatasetIndex(opt)),
        getClientsIndex: (opt: ApiCallOptions) => dispatch(ApiActions.getClientsIndex(opt)),
        getNodesIndex: (opt: ApiCallOptions) => dispatch(ApiActions.getNodesIndex(opt)),
        getClientsConnectorsIndex: (opt: ApiCallOptions) => dispatch(ApiActions.getClientsConnectorsIndex(opt)),
        getClientConnectionAbility: (opt: ApiCallOptions, clientId: string) => dispatch(ApiActions.getClientConnectionAbility(opt, clientId)),
    }),
)(RequestsComponent);