import { HTTP_STATUS } from './http';
import { RequestError } from './Request';

export async function retryierBaseDecision(e: Error) {
    if (e instanceof RequestError && (
        e.status === HTTP_STATUS.BAD_REQUEST ||
        e.status === HTTP_STATUS.SERVER_ERROR ||
        e.status === HTTP_STATUS.NOT_FOUND
    )) {
        throw e;
    }
}

export class PromiseRetryer {
    /**
     * Try some promise more times, if throws some error
     *
     * @param promiseConstructor
     * @param decideFunction
     * @param attempts
     * @param attempsDelay
     */
    public static try<T>(
        promiseConstructor: () => Promise<T>,
        decisionFunction: (error: any) => Promise<any> = async () => null,
        attempts: number = 3,
        attempsDelay: number = 1000,
    ): Promise<T> {
        let i = attempts;
        const again = async (): Promise<T> => {
            i--;
            return promiseConstructor()
                .catch((error) => {
                    if (i > 0) {
                        return decisionFunction(error)
                            .then((result: any) => {
                                if (!result) {
                                    return PromiseRetryer.asyncDelay(attempsDelay)
                                        .then(() => again());
                                }
                                return result;
                            });
                    }
                    throw error;
                });
        };
        return again();
    }

    /**
     * Promise to delay some chain
     * @param timeMilis in miliseconds
     */
    protected static async asyncDelay(timeMilis: number) {
        if (timeMilis === 0) {
            return;
        }

        return new Promise((resolve: () => void, reject: () => void) => {
            setTimeout(resolve, timeMilis);
        });
    }
}
