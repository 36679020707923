import * as React from 'react';
import { css, cx } from 'emotion';
import { ContentBox } from '../layouts/ContentBox';
import { SectionHeader } from '../ux/SectionHeader';
import { connect } from 'react-redux';
import {
    LineChart,
    XAxis,
    CartesianGrid,
    Tooltip,
    Line,
} from 'recharts';
import { IRootStore } from '../../state';
import { ApiActions, ApiCallOptions } from '../../state/api';
import { safe, safePromise } from '../../utils';
import { EntityIndex } from '../../api/Endpoints';
import { ConnectionAbility, DashboardMetrics } from '../../api/structure';
import { AllConnectorsStatus } from '../content/AllConnectorsStatus';
import { ClientResponseStatistics } from '../content/ClientResponseStatistics';

const style = {
    header: css`
        font-size: 1.5em;
        border-bottom: 1px solid #aaa;
        margin-bottom: 15px;
    `,
    headerThin: css`
        font-weight: 100;
        color: #aaa;
    `,
    clients: css`
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: stretch;
    `,
    chartWrap: css`
        position: relative;
        margin-bottom: 20px;
    `,
    chart: css`
        position: absolute;
        left: 0;
        right: 0;
        overflow: hidden;
    `,
    clientOverview: css`
        padding: 15px;
        background: #efefef;
        border-radius: 5px;
        margin-bottom: 20px;
    `,
    clientOverviewHeader: css`
        font-size: 1.2em;
        border-bottom: 1px solid #aaa;
        margin-bottom: 20px;
        font-weight: bold;
    `,
};

/**
 * Page
 */
class AdminOverviewComponent extends React.Component<{
    clientIndex: EntityIndex;
    dashboard: DashboardMetrics;
    getClientsIndex: (opt: ApiCallOptions) => Promise<EntityIndex>;
    getDashboard: (opt: ApiCallOptions) => Promise<DashboardMetrics>;
    getClientConnectionAbility: (opt: ApiCallOptions, clientId: string) => Promise<ConnectionAbility>;
}, {
    width: number;
    abilities: {[clientId: string]: ConnectionAbility};
}> {
    protected interval = null;
    protected boxRef = React.createRef<HTMLDivElement>();

    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            abilities: {},
        };
    }

    public componentDidMount() {
        safePromise(this.props.getClientsIndex({}))
            .then(() => this.loadData());

        window.addEventListener('resize', this.onResize);
        this.onResize();

        this.interval = setInterval(() => this.loadData(true), 5000);
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
        if (this.interval) {
            clearInterval(this.interval);
            this.interval = null;
        }
    }

    public render() {
        const allCLients = this.props.clientIndex ? Object.keys(this.props.clientIndex) : [];
        const data = this.props.dashboard;
        const overallData = safe(() => data.last24Hours.perHourCount || [], []);

        const clientsData = allCLients.map((clientId) => {
            const clientName = this.props.clientIndex[clientId];
            return {
                clientId,
                clientName,
                failed: safe(() => data.last24Hours.statusesCount[clientId]['error'], null),
                success: safe(() => data.last24Hours.statusesCount[clientId]['success'], null),
                notFound: safe(() => data.last24Hours.statusesCount[clientId]['not-found'], null),
                averageTime: safe(() => data.last24Hours.avergateResponseTimes[clientId].all, null),
                failedTime: safe(() => data.last24Hours.avergateResponseTimes[clientId]['error'], null),
                successTime: safe(() => data.last24Hours.avergateResponseTimes[clientId]['success'], null),
                notFoundTime: safe(() => data.last24Hours.avergateResponseTimes[clientId]['not-found'], null),
            };
        });

        return (
            <>
                <SectionHeader>Overview</SectionHeader>
                <ContentBox>
                    <div className={style.header}>Overall requests</div>

                    <div ref={this.boxRef} className={style.chartWrap} style={{height: '300px'}}>
                        <div className={style.chart}>
                            <LineChart
                                width={this.state.width}
                                height={300}
                                data={overallData}
                                margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                            >
                                <XAxis
                                    dataKey="date"
                                    tickFormatter={(tickItem) => {
                                        const d = new Date(tickItem);
                                        const m = d.getMinutes().toString();
                                        return `${d.getHours()}:${m.length < 2 ? '0' + m : m}`;
                                    }}
                                />
                                <Tooltip />
                                <CartesianGrid stroke="#f5f5f5" />
                                <Line type="monotone" dataKey="count" stroke="#24518A" yAxisId={0} />
                            </LineChart>
                        </div>
                    </div>

                    <div className={style.header}>Clients statuses <span className={style.headerThin}>for last 24 hours</span></div>

                    <div className={style.clients}>
                        {clientsData.map((item) => (
                            <div className={style.clientOverview} key={item.clientName}>
                                <div className={style.clientOverviewHeader}>{item.clientName}</div>
                                <>
                                    {this.state.abilities[item.clientId]?.fetch ? <ClientResponseStatistics data={item} /> : null}
                                    <AllConnectorsStatus clientId={item.clientId}/>
                                </>
                            </div>
                        ))}
                    </div>

                </ContentBox>
            </>
        );
    }

    protected loadData = async (silent?: boolean) => {
        await safePromise(this.props.getDashboard({silent}));
        const allClientsIds = Object.keys(this.props.clientIndex);
        const abilities = (await Promise.all(allClientsIds.map((id) => safePromise(this.props.getClientConnectionAbility({silent}, id)))))
            .reduce((result, ability, index) => {
                result[allClientsIds[index]] = ability;
                return result;
            }, {});
        this.setState({abilities});
    }

    protected onResize = () => {
        this.setState({
            width: this.boxRef.current.clientWidth,
        });
    }
}

export const AdminOverview = connect(
    (state: IRootStore) => ({
        clientIndex: state.api.clientIndex,
        dashboard: state.api.dashboard,
    }),
    (dispatch) => ({
        getClientsIndex: (opt: ApiCallOptions) => dispatch(ApiActions.getClientsIndex(opt)),
        getDashboard: (opt: ApiCallOptions) => dispatch(ApiActions.getDashboard(opt)),
        getClientConnectionAbility: (opt: ApiCallOptions, clientId: string) => dispatch(ApiActions.getClientConnectionAbility(opt, clientId)),
    }),
)(AdminOverviewComponent);
