import * as React from 'react';
import { css, cx } from 'emotion';
import { ContentBox } from '../components/layouts/ContentBox';
import { SectionHeader } from '../components/ux/SectionHeader';
import { LogItem, LogView } from '../components/content/LogView';
import { connect } from 'react-redux';
import { IRootStore } from '../state';
import { NotificationType, AppActions } from '../state/app';
import { Form } from '../components/ux/forms/Form';
import FormCodeInput from '../components/ux/forms/FormCodeInput';
import { FormSubmit } from '../components/ux/forms/FormSubmit';
import { Modal } from '../components/modals/Modal';
import { MacroScript } from '../utils/macro/MacroScript';
import { FormInput } from '../components/ux/forms/FormInput';
import { ApiActions, ApiCallOptions } from '../state/api';
import { FormCheckbox } from '../components/ux/forms/FormCheckbox';
import { isSet, JsonValidatorRequired, JsonValidatorType, objectOmit, wrapLoadingPromise } from '../utils';
import { Button } from '../components/ux/Button';
import { Table } from '../components/ux/table/Table';
import { faCheck, faTrash } from '@fortawesome/free-solid-svg-icons';
import { mediaQSmall } from '../utils/responsivity';
import { ModalConfirm } from '../components/modals/ModalConfirm';
import { RegExps } from '../api/regexps';
import { FormTextarea } from '../components/ux/forms/FormTextarea';
import { GetEntity, MacroExecution, MacroResult, MacroTemplateEntity } from '../api/structure';

const style = {
    log: css`
        height: 400px;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
    `,
    logWraper: css`
        position: relative;
        width: 100%;
        height: 400px;
        margin-bottom: 25px;
    `,
    input: css`
        margin-bottom: 15px;
    `,
    modal: {
        label: css`
            border-bottom: 1px solid #ddd;
            margin-bottom: 15px;
            display: block;
            font-size: 1.1em;
        `,
    },
    buttons: {
        wrapper: css`display: flex;`,
        button: css`margin-right: 8px;`,
    },
    cellRow: css`
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-end;
    `,
    cellRowLeft: css`
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
    `,
    cellRowItem: css`
        margin-left: 8px;
        @media ${mediaQSmall} {
            margin-left: 0px;
            margin-right: 8px;
        }
    `,
    saveHeader: css`
        font-size: 20px;
        margin-bottom: 10px;
    `,
    inputWrap: css`
        margin-bottom: 15px;
    `,
    paramInput: css`
        margin-bottom: 15px;
    `
};

/**
 * Page
 */
class ScriptComponent extends React.Component<{
    dispatch: any
    macroTemplate: GetEntity<MacroTemplateEntity>
    macroTemplates: GetEntity<MacroTemplateEntity>[]
    getMacroTemplateList: (options: ApiCallOptions) => Promise<GetEntity<MacroTemplateEntity>[]>
    postMacroTemplate: (options: ApiCallOptions, entity: MacroTemplateEntity) => Promise<GetEntity<MacroTemplateEntity>>
    patchMacroTemplate: (options: ApiCallOptions, id: string, entity: MacroTemplateEntity) => Promise<GetEntity<MacroTemplateEntity>>
    deleteMacroTemplate: (options: ApiCallOptions, id: string) => Promise<void>

    pushNotification: (text: string, type: NotificationType) => void;
    postMacroExecute: (opt: ApiCallOptions, script: string, params: any) => Promise<MacroExecution>
    getMacroResult: (opt: ApiCallOptions, hash: string) => Promise<MacroResult>
}, {
    scriptInstance: MacroScript,
    defaultProps: any,
    logs: LogItem[],
    removeTemplateId: string,
    predefinedData: any,
    saveAsTemplate: {script: string}
    editTemplateConfirm: any,
}> {
    protected waitingHash = null

    constructor(props) {
        super(props);
        this.state = {
            scriptInstance: null,
            defaultProps: null,
            logs: [],
            removeTemplateId: null,
            predefinedData: null,
            saveAsTemplate: null,
            editTemplateConfirm: null,
        };
    }

    public componentDidMount() {
        this.props.getMacroTemplateList({});
    }

    public render() {
        const templates = (this.props.macroTemplates || []).map((i) => this.renderTemplateItem(i));

        return (
            <>
                <SectionHeader>Macro Script</SectionHeader>
                <ContentBox>
                    <div className={style.logWraper}>
                        <LogView className={style.log} logs={this.state.logs} shortView/>
                    </div>
                    <Form
                        action={this.parse}
                        altActions={{save: this.save}}
                        defaultData={this.state.predefinedData}
                        enableReinitialize
                    >
                        <div className={style.input}>
                            <FormCodeInput
                                language={'plaintext'}
                                name={'script'}
                                height={200}
                            />
                        </div>
                        <div className={style.buttons.wrapper}>
                            <FormSubmit className={style.buttons.button} color="green">Execute</FormSubmit>
                            <FormSubmit className={style.buttons.button} color="gray" altAction="save">Save as template</FormSubmit>
                        </div>
                    </Form>
                </ContentBox>
                <ContentBox>
                    <Table
                        columns={[
                            {
                                text: 'Template name',
                                name: 'name',
                                size: 1,
                                mobileBlock: true,
                            },
                            {
                                text: 'Description',
                                name: 'description',
                                mobileBlock: true,
                            },
                            {
                                text: '',
                                name: 'actions',
                                size: 0,
                                mobileBlock: true,
                            }
                        ]}
                        data={templates}
                    />
                </ContentBox>
                <Form
                    action={this.execute}
                    defaultData={this.state.defaultProps}
                    enableReinitialize
                >
                    <Modal
                        size={'medium'}
                        active={!!this.state.scriptInstance}
                        buttons={[{
                            text: 'Cancel',
                            color: 'gray',
                            action: this.onExecutionCancel,
                        }, {
                            text: 'Submit',
                            color: 'green',
                            isSubmit: true,
                        }]}
                    >
                        {this.state.scriptInstance ? (
                            <>
                                <label className={style.modal.label}>
                                    Properties of execution
                                </label>
                                {(this.state.scriptInstance as MacroScript).inputs.map((input) => (
                                    <>
                                    {input.input.type === 'string' ? (
                                        <FormInput className={style.paramInput} name={input.namespace} label={input.input.name} />
                                    ) : input.input.type === 'number' ? (
                                        <FormInput className={style.paramInput} type={'number'} name={input.namespace} label={input.input.name} />
                                    ) : input.input.type === 'float' ? (
                                        <FormInput className={style.paramInput} type={'float'} name={input.namespace} label={input.input.name} />
                                    ) : input.input.type === 'boolean' ? (
                                        <FormCheckbox className={style.paramInput} name={input.namespace} label={input.input.name} />
                                    ) : null}
                                    </>
                                ))}
                            </>
                        ) : null}
                    </Modal>
                </Form>
                <ModalConfirm
                    onClose={this.onRemoveTemplateCancel}
                    onConfirm={this.onRemoveTemplateConfirm}
                    active={!!this.state.removeTemplateId}
                    title="Confirm remove template"
                    text="Do you realy want to remove template?"
                    confirmText="Remove"
                />
                <ModalConfirm
                    onClose={this.onSaveAsTemplateUpdateCancel}
                    onConfirm={this.onSaveAsTemplateUpdateConfirm}
                    active={!!this.state.editTemplateConfirm}
                    title="Confirm update template"
                    text="Do you realy want to update template?"
                    confirmText="Update"
                />
                <Form
                    action={this.onSaveAsTemplateConfirm}
                    schema={{
                        name: {
                            required: JsonValidatorRequired.True,
                            type: JsonValidatorType.String,
                            regexp: RegExps.name,
                        },
                        description: {
                            required: JsonValidatorRequired.True,
                            type: JsonValidatorType.String,
                        },
                    }}
                >
                    <Modal
                        active={!!this.state.saveAsTemplate}
                        size={'small'}
                        buttons={[{
                            text: 'Close',
                            color: 'gray',
                            action: this.onSaveAsTemplateClose
                        },
                        {
                            text: 'Save',
                            color: 'green',
                            isSubmit: true,
                        }]}
                    >
                        <p className={style.saveHeader}>Save script as template</p>
                        <div className={style.inputWrap}>
                            <FormInput type="text" name="name" label="Name" resetOnUnmount/>
                        </div>
                        <div className={style.inputWrap}>
                            <FormTextarea name="description" label="Description" resetOnUnmount/>
                        </div>
                    </Modal>
                </Form>
            </>
        );
    }

    protected log = (level: 'error' | 'info' | 'warn', message: string) => {
        const nLog = {
            timestamp: (new Date()).toISOString(),
            level,
            message,
        };
        this.setState({
            logs: [nLog, ...this.state.logs]
        });
    }

    protected onExecutionCancel = () => {
        this.setState({scriptInstance: null}, () => {
            this.log('info', '> Running script canceled');
        });
    }

    protected parse = async (data: any) => {
        try {
            const scriptInstance = new MacroScript(data.script);
            this.log('info', '> Running script');
            if (scriptInstance.inputs.length === 0) {
                this.setState({scriptInstance}, () => {
                    this.execute({});
                });
            } else {
                const defaultProps = scriptInstance.inputs.reduce((acc, input) => ({
                    [input.namespace]: isSet(input.input.default) ? input.input.default : undefined,
                    ...acc,
                }), {})
                this.setState({scriptInstance, defaultProps})
            }
        } catch(e) {
            console.log(e);
            this.setState({scriptInstance: null});
            this.log('error', `Parsing script failed ${e.message}`);
        }
    }

    protected getResult = async () => {
        if (!this.waitingHash) {
            return
        }
        const result = await this.props.getMacroResult({}, this.waitingHash);
        if (result.status === 'waiting') {
            await new Promise((resolve) => setTimeout(() => resolve(null), 500))
            await this.getResult()
        } else {
            if (result.error) {
                this.log('error', `${result.error}`);
            } else {
                const logs = result.value
                logs.reverse();
                this.setState({
                    logs: [...logs, ...this.state.logs]
                });
            }
            this.waitingHash = null
        }
    }

    protected execute = async (data: any) => {
        const script = this.state.scriptInstance.script;
        this.setState({scriptInstance: null});
        try {
            const reference = await this.props.postMacroExecute({}, script, data);
            if (reference.status === 'waiting') {
                this.waitingHash = reference.hash
            }
            await wrapLoadingPromise(this.props.dispatch, this.getResult())

        } catch(e) {
            this.log('error', `Executing script ${e}`);
        }
    }

    protected save = async (data: any) => {
       this.setState({saveAsTemplate: data});
    }

    protected renderTemplateItem = (item) => {
        return {
            name: item.name,
            description: item.description,
            actions: (
                <div className={style.cellRow}>
                    <Button
                        icon={faCheck}
                        size={'small'}
                        className={style.cellRowItem}
                        color={'green'}
                        onClick={this.onTemplateUse.bind(this, item._id)}
                    >
                        Use
                    </Button>
                    <Button
                        icon={faTrash}
                        size={'small'}
                        className={style.cellRowItem}
                        color={'red'}
                        onClick={this.onTemplateRemove.bind(this, item._id)}
                    >
                        Remove
                    </Button>
                </div>
            )
        }
    }

    protected onTemplateUse = (id: string) => {
        const found = (this.props.macroTemplates || []).find((i) => i._id === id);
        if (found) {
            this.setState({predefinedData: {script: found.script}});
        }
    }

    protected onTemplateRemove = (id: string) => {
        this.setState({removeTemplateId: id});
    }

    protected onRemoveTemplateCancel = () => {
        this.setState({removeTemplateId: null});
    }

    protected onRemoveTemplateConfirm = async () => {
        try {
            await this.props.deleteMacroTemplate({}, this.state.removeTemplateId);
            await this.props.getMacroTemplateList({});
        } catch(e) {
            this.props.pushNotification(`Deleting template failed ${e.message}`, 'red');
        }
        this.setState({removeTemplateId: null});
    }

    protected onSaveAsTemplateClose = () => {
        this.setState({saveAsTemplate: null})
    }

    protected onSaveAsTemplateConfirm = async (data: {name: string, description: string}) => {
        const foundTemplate = (this.props.macroTemplates || []).find((i) => i.name === data.name);
        if (foundTemplate) {
            this.setState({
                editTemplateConfirm: {
                    _id: foundTemplate._id,
                    name: data.name,
                    description: data.description,
                    script: this.state.saveAsTemplate.script,
                },
                saveAsTemplate: null,
            });
        } else {
            try {
                await this.props.postMacroTemplate({}, {
                    name: data.name,
                    description: data.description,
                    script: this.state.saveAsTemplate.script,
                })
                await this.props.getMacroTemplateList({});
            } catch(e) {
                this.props.pushNotification(`Saving template failed ${e.message}`, 'red');
            }
            this.setState({saveAsTemplate: null})
        }
    }

    protected onSaveAsTemplateUpdateCancel = () => {
        this.setState({ editTemplateConfirm: null})
    }

    protected onSaveAsTemplateUpdateConfirm = async () => {
        try {
            const entity = objectOmit(this.state.editTemplateConfirm, ['_id']) as MacroTemplateEntity;
            await this.props.patchMacroTemplate({}, this.state.editTemplateConfirm._id, entity)
            await this.props.getMacroTemplateList({});
        } catch(e) {
            this.props.pushNotification(`Updating template failed ${e.message}`, 'red');
        }
        this.setState({ editTemplateConfirm: null})
    }
}

export const Script = connect(
    (state: IRootStore) => ({
        macroTemplate: state.api.macroTemplate,
        macroTemplates: state.api.macroTemplates,
    }),
    (dispatch) => ({
        dispatch,
        getMacroTemplateList: (opt: ApiCallOptions) => dispatch(ApiActions.getMacroTemplateList(opt)),
        postMacroTemplate: (opt: ApiCallOptions, entity: MacroTemplateEntity) => dispatch(ApiActions.postMacroTemplate(opt, entity)),
        patchMacroTemplate: (opt: ApiCallOptions, id: string, entity: MacroTemplateEntity) => dispatch(ApiActions.patchMacroTemplate(opt, id, entity)),
        deleteMacroTemplate: (opt: ApiCallOptions, id: string) => dispatch(ApiActions.deleteMacroTemplate(opt, id)),
        pushNotification: (text: string, type: NotificationType) => dispatch(AppActions.pushNotification(text, type)),
        postMacroExecute: (opt: ApiCallOptions, script: string, params: any) => dispatch(ApiActions.postMacroExecute(opt, script, params)),
        getMacroResult: (opt: ApiCallOptions, hash: string) => dispatch(ApiActions.getMacroResult(opt, hash))
    }),
)(ScriptComponent);

/**
 *
                if (data.status === 'waiting') {
                    const timeout = setTimeout()
                }

 */