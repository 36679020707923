import * as React from 'react';
import { css } from 'emotion';
import { ConnectorEntity, GetEntity } from '../../api/structure';
import { IRootStore } from '../../state';
import { connect } from 'react-redux';
import { ApiActions, ApiCallOptions } from '../../state/api';
import { ConnectorStatus } from './ConnectorStatus';
import { safePromise } from '../../utils';
import { SessionReincarnation } from '../../api/Endpoints';

const styles = {
    contentRow: css`
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom:15px;
        &:last-child {
            margin-bottom: 0;
        }
    `,
};

export interface AllConnectorsStatusComponentProps {
    getClientConnectors: (opt: ApiCallOptions, clientId: string) => Promise<{[id: string]: GetEntity<ConnectorEntity>}>;
    sessionReincarnation: SessionReincarnation;
    clientId: string;
}

class AllConnectorsStatusComponent extends React.Component<AllConnectorsStatusComponentProps, {
    connectors: {[id: string]: GetEntity<ConnectorEntity>};
}> {

    constructor(props) {
        super(props);
        this.state = {
            connectors: {},
        };
    }

    public componentDidMount() {
        const clientId = this.props.sessionReincarnation ? this.props.sessionReincarnation.clientId : this.props.clientId;
        safePromise(this.props.getClientConnectors({}, clientId)
            .then((connectors) => {
                this.setState({
                    connectors,
                });
            }));
    }


    public render() {
        const connectorsKeys = Object.keys(this.state.connectors)
        if (!connectorsKeys.length) {
            return (
                <div className={styles.contentRow}>
                    Connector is not set
                </div>
            )
        }

        return (
            <div className={styles.contentRow}>
                {connectorsKeys.map((connectorId) => {
                    const connector = this.state.connectors[connectorId];
                    return (
                        <ConnectorStatus
                            key={`connector-status-${connectorId}`}
                            connector={connector}
                        />
                    );
                })}
            </div>
        );
    }
}

export const AllConnectorsStatus = connect(
    (state: IRootStore) => ({
        sessionReincarnation: state.api.sessionReincarnation,
    }),
    (dispatch) => ({
        getClientConnectors: (opt: ApiCallOptions, clientId: string) => dispatch(ApiActions.getClientConnectors(opt, clientId)),
    }),
)(AllConnectorsStatusComponent);

