import * as React from 'react';
import { css, cx } from 'emotion';
import { SectionHeader } from '../components/ux/SectionHeader';
import { connect } from 'react-redux';
import { AppActions, NotificationType } from '../state/app';
import { PropsRouteMatch, safe, isObject, isNumber, safePromise } from '../utils';
import { ApiActions, ApiCallOptions } from '../state/api';
import { GetEntity, RequestEntity } from '../api/structure';
import { IRootStore } from '../state';
import { ContentBox } from '../components/layouts/ContentBox';
import { EntityIndex } from '../api/Endpoints';
import { Badge } from '../components/ux/Badge';
import { Button } from '../components/ux/Button';
import { Token } from '../components/ux/Token';
import { JSONView } from '../components/ux/JSONView';
import { renderErrors } from '../components/utils/DataErrors';
import { faBan, faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { ModalConfirm } from '../components/modals/ModalConfirm';
import { Alert } from '../components/ux/Alert';
import { localDate } from '../utils/date';
import { RequestCallStatuses } from '../components/content/RequestCallStatuses';

const style = {
    badge: css`
        margin-bottom: 15px;
    `,
    token: css`
        margin-bottom: 10px;
        max-width: 220px;
    `,
    metaWraper: css`
        margin-bottom: 20px;
    `,
    itemWraper: css`
        background: #efefef;
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 20px;
    `,
    keyValueTable: css`
        display: table;
    `,
    keyValueRow: css`
        display: table-row;
        &.red {
            color: #dc3545;
        }
    `,
    keyValueCell: css`
        display: table-cell;
        padding: 2px 5px;
    `,
    keyValueError: css`
        color: #dc3545;
        word-break: break-all;
    `,
    header: css`
        font-size: 1.5em;
        border-bottom: 1px solid #aaa;
        margin-bottom: 15px;
    `,
    footer: css`
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        &.single {
            justify-content: flex-end;
        }
    `,
};

/**
 * Page
 */
class RequestShowComponent extends React.Component<{
    request: GetEntity<RequestEntity>;
    datasetIndex: EntityIndex;
    clientIndex: EntityIndex;
    nodeIndex: EntityIndex;
    redirectBack: () => void;
    redirectTo: (to: string) => void;
    redirectLock: (locked: boolean) => void;
    getRequest: (opt: ApiCallOptions, id: string) => Promise<GetEntity<RequestEntity>>;
    getDatasetIndex: (opt: ApiCallOptions) => Promise<EntityIndex>;
    getClientsIndex: (opt: ApiCallOptions) => Promise<EntityIndex>;
    getNodesIndex: (opt: ApiCallOptions) => Promise<EntityIndex>;
    anonymizeRequest: (opt: ApiCallOptions, id: string) => Promise<void>;
    pushNotification: (text: string, type: NotificationType) => void;
} & PropsRouteMatch<{id: string}>, {
    loaded: boolean;
    confirmAnonymize: boolean;
}> {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            confirmAnonymize: false,
        };
    }

    public componentDidMount() {
        safePromise(this.props.getDatasetIndex({}));
        safePromise(this.props.getClientsIndex({}));
        safePromise(this.props.getNodesIndex({}));
        this.loadData();
    }

    public render() {
        const { request } = this.props;

        return (
            <>
                <SectionHeader>Show request</SectionHeader>
                {this.state.loaded ? (
                <ContentBox>
                    {request?.anonymizedOn ? (
                        <div>
                            <Alert className={style.badge} icon={faUserSecret} color={'red'}>Request was anonymized on {localDate(request.anonymizedOn)}</Alert>
                        </div>
                    ) : null}
                    <div>
                        <Token className={style.token} token={request.token} />
                    </div>

                    <div className={style.metaWraper}>
                        <div className={style.keyValueTable}>
                            <div className={style.keyValueRow}>
                                <div className={style.keyValueCell}><strong>Created on</strong></div>
                                <div className={style.keyValueCell}>{safe(() => new Date(request.createdOn).toLocaleString(), 'N/A')}</div>
                            </div>
                            <div className={style.keyValueRow}>
                                <div className={style.keyValueCell}><strong>Client</strong></div>
                                <div className={style.keyValueCell}>{safe(() => this.props.clientIndex[request.clientId], 'N/A')}</div>
                            </div>
                            <div className={style.keyValueRow}>
                                <div className={style.keyValueCell}><strong>Node</strong></div>
                                <div className={style.keyValueCell}>{safe(() => this.props.nodeIndex[request.nodeId], 'N/A')}</div>
                            </div>
                        </div>
                    </div>
                    <div className={style.header}>
                        Requested clients
                    </div>
                    <RequestCallStatuses clientIndex={this.props.clientIndex} callStatuses={request.callStatuses}/>
                    <div className={style.header}>
                        Final response
                    </div>
                    <div>
                        <JSONView data={request.finalResponse || {}} />
                    </div>
                    {!request.anonymizedOn ? (
                        <div className={style.footer}>
                            <Button color={'red'} icon={faBan} onClick={this.anonymize}>Anonymize request</Button>
                            <Button color={'gray'} onClick={this.goBack}>Back</Button>
                        </div>
                    ) : (
                        <div className={cx(style.footer, 'single')}>
                            <Button color={'gray'} onClick={this.goBack}>Back</Button>
                        </div>
                    )}
                </ContentBox>
                ) : null}
                <ModalConfirm
                    onClose={this.anonymizeCancel}
                    onConfirm={this.anonymizeConfirm}
                    active={this.state.confirmAnonymize}
                    text={'Do you realy want to anonymize this connector? This action will remove all personal data from entity.'}
                    confirmText={'Anonymize'}
                    confirmColor={'red'}
                    confirmIcon={faBan}
                />
            </>
        );
    }

    protected anonymize = () => {
        this.setState({confirmAnonymize: true});
    }

    protected anonymizeCancel = () => {
        this.setState({confirmAnonymize: false});
    }

    protected anonymizeConfirm = async () => {
        await (this.props.anonymizeRequest({}, this.props.match.params.id)
            .catch((err) => {
                this.props.pushNotification(err.message, 'red');
            }));
        this.setState({confirmAnonymize: false});
        this.loadData();
    }

    protected goBack = () => {
        this.props.redirectBack();
    }

    protected loadData = () => {
        safePromise(this.props.getRequest({}, this.props.match.params.id)
            .then(() => this.setState({loaded: true})));
    }
}

export const RequestShow = connect(
    (state: IRootStore) => ({
        request: state.api.request,
        datasetIndex: state.api.datasetIndex,
        clientIndex: state.api.clientIndex,
        nodeIndex: state.api.nodeIndex,
    }),
    (dispatch) => ({
        redirectBack: () => dispatch(AppActions.redirectBack()),
        redirectTo: (to: string) => dispatch(AppActions.redirectTo(to)),
        getRequest: (opt: ApiCallOptions, id: string) => dispatch(ApiActions.getRequest(opt, id)),
        getDatasetIndex: (opt: ApiCallOptions) => dispatch(ApiActions.getDatasetIndex(opt)),
        getClientsIndex: (opt: ApiCallOptions) => dispatch(ApiActions.getClientsIndex(opt)),
        getNodesIndex: (opt: ApiCallOptions) => dispatch(ApiActions.getNodesIndex(opt)),
        anonymizeRequest: (opt: ApiCallOptions, id: string) => dispatch(ApiActions.anonymizeRequest(opt, id)),
        pushNotification: (text: string, type: NotificationType) => dispatch(AppActions.pushNotification(text, type)),
    }),
)(RequestShowComponent);