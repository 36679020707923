import * as React from 'react';
import { FormInput } from '../../ux/forms/FormInput';
import { css } from 'emotion';
import { FormArray } from '../../ux/forms/FormArray';
import { ConnectorApiAccess, BaseConnectorInterface } from '../../../api/structure';
import { Token } from '../../ux/Token';
import { FormObject } from '../../ux/forms/FormObject';
import { AddToSlack } from '../../ux/AddToSlack';
import { FormError } from '../../ux/forms/FormError';

export const style = {
    input: css`
        margin-bottom: 15px;
        &.small {
            max-width: 300px;
        }
    `,
    labelDimm: css`
        font-size: 12px;
        color: #888;
    `,
    arrayItem: css`
        padding: 15px;
        border-radius: 3px;
        margin-bottom: 10px;
    `,
    arrayItemInputsContent: css`
        display: flex;
    `,
    arrayDeleteButton: css`
        margin-top: 0;
        margin-left: 00px;
    `,
    arrayInput: css`
        margin-right: 10px;
    `,
    accessGroup: css`
        background: #eee;
        padding: 20px;
    `,
    token: css`
        margin-bottom: 10px;
    `,
    deprecated: css`
        padding: 10px;
        border-radius: 8px;
        background: #f5dd95;
        border: 1px solid #ffc107;
        color: #000;
        margin: 20px 0;
    `,
};

export const connectorEditor = {
    email_service: (prefix: string, data: BaseConnectorInterface) => {
        return (
            <>
                <div className={style.deprecated}>
                    This service is deprecated and will be removed in future versions. You should integration service page to setup your email, and use User integration service.
                </div>
                <div className={style.input}>
                    <FormInput
                        name={`${prefix}.config.address`}
                        label={'Email address'}
                    />
                </div>
            </>
        );
    },
    http_json_command: (prefix: string, data: BaseConnectorInterface) => {
        return (
            <>
                <div className={style.input}>
                    <FormArray
                        name={`${prefix}.config.apiAccess`}
                        itemClassName={style.arrayItem}
                        deleteClassName={style.arrayDeleteButton}
                        addButtonText={'Add api access'}
                        defaultItem={{name: 'default', ipWhitelist: ''}}
                        item={(aprefix: string, itemData: ConnectorApiAccess) => {
                            return (
                                <>
                                    <div className={style.arrayItemInputsContent}>
                                        <div className={style.input}>
                                            <FormInput
                                                name={`${aprefix}.name`}
                                                label={'Name'}
                                                className={style.arrayInput}
                                            />
                                        </div>
                                        <div className={style.input}>
                                            <FormInput
                                                name={`${aprefix}.ipWhitelist`}
                                                label={'IP Whitelist'}
                                                defaultValue={''}
                                                className={style.arrayInput}
                                            />
                                        </div>
                                    </div>
                                    {itemData.apiKey ? (
                                        <>
                                            <label>Api key</label>
                                            <Token className={style.token} token={itemData.apiKey} />
                                        </>
                                    ) : null}
                                </>
                            );
                        }}
                    />
                </div>
            </>
        );
    },
    http_json_fetch: (prefix: string, data: BaseConnectorInterface) => {
        return (
            <>
                <div className={style.input}>
                    <label>Data <span className={style.labelDimm}>("{"{{nodeName}}"}" will be replaced with name of node)</span></label>
                    <div className={style.accessGroup}>
                        <div className={style.input}>
                            <FormInput
                                name={`${prefix}.config.data.url`}
                                label={'Url'}
                                defaultValue={''}
                                className={style.arrayInput}
                            />
                        </div>
                        <label>Headers</label>
                        <FormObject name={`${prefix}.config.data.headers`} />
                        <FormError name={`${prefix}.config.data`} />
                    </div>
                </div>
                <div className={style.input}>
                    <label>Status</label>
                    <div className={style.accessGroup}>
                        <div className={style.input}>
                            <FormInput
                                name={`${prefix}.config.status.url`}
                                label={'Url'}
                                defaultValue={''}
                                className={style.arrayInput}
                            />
                        </div>
                        <label>Headers</label>
                        <FormObject name={`${prefix}.config.status.headers`} />
                        <FormError name={`${prefix}.config.status`} />
                    </div>
                </div>
                {data?.config?.secret ? (
                    <>
                        <label>Secret</label>
                        <Token className={style.token} token={data.config.secret} />
                    </>
                ) : null}
            </>
        );
    },
    user_integration_service: (prefix: string, data: BaseConnectorInterface) => {
        return (null);
    },
};
