import * as React from 'react';
import { connect } from 'react-redux';
import { ApiInitialState, ApiActions, ApiCallOptions } from '../../state/api';
import { RequestError, HTTP_STATUS } from '../../utils';
import { AppActions, NotificationType } from '../../state/app';
import { stringify } from 'query-string';

export class Component extends React.Component<{
    token: string;
    getSession: (opt: ApiCallOptions) => Promise<void>;
    logout: () => Promise<void>;
    pushNotification: (text: string, type: NotificationType, persistent?: boolean) => string;
    popNotification: (id: string) => void;
}, {
    persistentError: string,
}> {
    constructor(props) {
        super(props);
        this.state = {
            persistentError: null,
        };
    }

    protected interval = null;

    public componentDidMount() {
        this.checkAdminUser();
        this.interval = setInterval(() => this.checkAdminUser(true), 10000);
    }

    public componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
            this.interval = null;
        }
    }

    public render() {
        return <></>;
    }

    // check load admin user
    protected checkAdminUser = async (silent?: boolean) => {
        if (this.props.token) {
            try {
                await this.props.getSession({silent});
                if (this.state.persistentError) {
                    this.props.popNotification(this.state.persistentError);
                    this.setState({persistentError: null});
                }
            } catch (e) {
                if (e instanceof RequestError && e.status === HTTP_STATUS.UNAUTHORIZED) {
                    await this.props.logout();
                } else {
                    if (!this.state.persistentError) {
                        const persistentError = this.props.pushNotification('Checking session failed', 'red', true);
                        this.setState({persistentError});
                    }
                }
            }
        }
    };
}

// Export connected component as default
export const SessionRefresher = connect(
    (state: {api: ApiInitialState}) => ({
        token: state.api.token,
    }),
    (dispatch) => ({
        getSession: (opt: ApiCallOptions) => dispatch(ApiActions.getSession(opt)),
        logout: () => dispatch(ApiActions.logout()),
        pushNotification: (text: string, type: NotificationType, persistent?: boolean) => dispatch(AppActions.pushNotification(text, type, persistent)),
        popNotification: (id: string) => dispatch(AppActions.popNotification(id)),
    }),
)(Component);
