import * as React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { css, cx } from 'emotion';

export type AlertColors = 'red' | 'green' | 'yellow' | 'gray';

const styles = {
    alert: css`
        display: block;
        border: 1px solid #2f69b2;
        padding: 16px;
        border-radius: .25rem;
        min-height: 50px;
        position: relative;
        &.red {
            color: #fff;
            background-color: #dc3545;
            border-color: #dc3545;
        }
        &.green {
            color: #fff;
            background-color: #28a745;
            border-color: #28a745;
        }
        &.yellow {
            color: #212529;
            background-color: #ffc107;
            border-color: #ffc107;
        }
        &.gray {
            color: #fff;
            background-color: #6c757d;
            border-color: #6c757d;
        }
    `,
    text: css`
        position: absolute;
        left: 16px;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        font-weight: 400;
        text-align: left;
        font-size: 16px;
        line-height: 1.2;
        &.icon {
            left: 60px;
        }
    `,
    icon: css`
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 32px;
    `,
};

interface ComponentProps {
    className?: string;
    icon?: IconProp;
    iconClassName?: string;
    color?: AlertColors;
}

/**************** Component ***************/
export class Alert extends React.Component<ComponentProps> {
    public render() {
        return (
            <div
                {...this.props}
                className={cx(styles.alert, this.props.className, this.props.color)}
            >
                {this.props.icon ? (
                    <span className={cx(styles.icon, this.props.iconClassName)}>
                        <FontAwesomeIcon icon={this.props.icon} />
                    </span>
                ) : null}
                <div className={cx(styles.text, {['icon']: !!this.props.icon})}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

