import * as React from 'react';
import { css } from 'emotion';
import { ContentBox } from '../layouts/ContentBox';
import { Table, TableFilter, TableDataStructure, TableData } from '../ux/table/Table';
import { Button, ButtonColors } from '../ux/Button';
import { EntityList, EntityListFilter, EntityListFilterQuery } from '../../api/Endpoints';
import { faPlus, faCheck } from '@fortawesome/free-solid-svg-icons';
import { ModalConfirm } from '../modals/ModalConfirm';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { mediaQSmall } from '../../utils/responsivity';
import { isObject, isNumber, setCurrentQuery, safePromise, isString } from '../../utils';
import { ApiCallOptions } from '../../state/api';

const style = {
    header: css`
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
        margin-bottom: 15px;
    `,
    button: css`
        display: inline-block;
        white-space: nowrap;
        margin-left: 8px;
    `,
    actions: css`
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-end;
        @media ${mediaQSmall} {
            flex-wrap: wrap;
        }
    `,
    actionsButton: css`
        margin-left: 8px;
        @media ${mediaQSmall} {
            margin-left: 0px;
            margin-right: 8px;
            margin-bottom: 5px;
        }
    `,
};

export interface EntityAction<T> {
    color: ButtonColors;
    name: string;
    icon?: IconProp;
    action: (i: T) => Promise<void>;
    confirm?: string;
    reloadAfter?: boolean;
    condition?: (i: T) => boolean;
}

export interface HeaderAction {
    text: string;
    icon: IconProp;
    color: ButtonColors;
    action: () => void;
    reloadAfter?: boolean;
}

export interface EntityListViewProps<T> {
    columns: TableDataStructure[];
    data: EntityList<T>;
    loadData: (opt: ApiCallOptions, filter: EntityListFilter<T>) => Promise<EntityList<T>>;
    actions?: EntityAction<T>[];
    renderer?: {[name: string]: (data: any) => React.ReactElement};

    transformFilters?: (filter: EntityListFilterQuery<T>) => EntityListFilterQuery<T>;
    filters?: EntityListFilterQuery<T>;
    reloadInterval?: number;
    mainList?: boolean;
    cellsClassName?: (data: T, odd: boolean) => string;

    headerActions?: HeaderAction[];
}

export interface EntityListViewState<T> {
    filter: TableFilter;
    countOnPage: number;
    confirm?: {
        name: string;
        text: string;
        action: EntityAction<T>;
        entity: T;
        color?: ButtonColors;
        icon?: IconProp;
    };
    confirmVisible: boolean;
    loaded: boolean;
}

export class EntityListView<T> extends React.Component<EntityListViewProps<T>, EntityListViewState<T>> {
    protected reloadInterval = null;

    constructor(props) {
        super(props);

        this.state = {
            countOnPage: 30,
            filter: {
                filters: {},
                currentPage: 0,
            },
            confirm: null,
            confirmVisible: null,
            loaded: false,
        };
    }

    public UNSAFE_componentWillReceiveProps(nextProps: EntityListViewProps<T>, nextState) {
        if (isObject(nextProps.filters) && nextProps.filters !== this.props.filters) {
            this.setState({
                filter: {
                    ...this.state.filter,
                    ...nextProps.filters,
                },
            }, this.loadData);
        }
    }

    public componentDidMount() {
        if (isNumber(this.props.reloadInterval)) {
            this.reloadInterval = setInterval(this.intervalReloadTick, this.props.reloadInterval);
        }

        if (this.props.mainList) {
            const urlParams = new URLSearchParams(window.location.search);
            const currentPage = urlParams.get('page');
            if (currentPage) {
                const int = parseInt(currentPage, 10);
                if (!isNaN(int)) {
                    this.onFilterChange({
                        currentPage: int - 1,
                    });
                    return;
                }
            }
        }

        this.loadData();
    }

    public componentWillUnmount() {
        if (this.reloadInterval) {
            clearInterval(this.reloadInterval);
            this.reloadInterval = null;
        }
    }

    public render() {
        return (
            <ContentBox>
                {this.props.children}

                <div className={style.header}>
                    {(this.props.headerActions || []).map((action, index) => (
                        <Button
                            key={`header-action-${index}`}
                            onClick={() => this.doHeaderAction(action)}
                            className={style.button}
                            color={action.color}
                            icon={action.icon}
                        >
                            {action.text}
                        </Button>
                    ))}
                </div>


                {this.state.loaded ? (
                    <Table
                        filter={this.state.filter as any}
                        onFilterChange={this.onFilterChange}
                        pagesCount={this.props.data ? Math.ceil(this.props.data.count / this.state.countOnPage) : 0}
                        columns={[
                            ...this.props.columns,
                            ...(this.props.actions ? [{
                                text: '',
                                name: 'actions',
                                size: 0,
                                mobileBlock: true,
                            }] : []),
                        ]}
                        data={this.props.data ? (this.props.data.items.map((i) => this.prepareItem(i))) : []}
                        cellsClassName={this.cellsClassName}
                        itemsCount={this.props?.data?.count}
                    />
                ) : null}
                <ModalConfirm
                    onClose={this.onConfirmCancel}
                    onConfirm={this.onConfirmSubmit}
                    active={this.state.confirmVisible}
                    text={this.state.confirm ? this.state.confirm.text : ''}
                    confirmText={this.state.confirm?.name ? this.state.confirm.name : 'Confirm'}
                    confirmIcon={(this.state.confirm?.icon ? (this.state.confirm.icon as any) : faCheck)}
                    confirmColor={(this.state.confirm?.color ? (this.state.confirm.color as any) : 'green')}
                />
            </ContentBox>
        );
    }

    protected cellsClassName = (index: number, odd: boolean) => {
        if (this.props.cellsClassName && this.props.data.items) {
            const item = this.props.data.items[index];
            return this.props.cellsClassName(item, odd);
        }
        return null;
    }

    protected getDefaultSort = () => {
        const foundDefault = this.props.columns.find((c) => c.defaultSort);
        if (foundDefault) {
            return {
                sortName: foundDefault.name,
                sortOrder: foundDefault.defaultSort,
            };
        }
        return null;
    }

    protected intervalReloadTick = () => {
        if (this.state.filter.currentPage === 0) {
            this.loadData(true);
        }
    }

    protected onFilterChange = (filter: Partial<TableFilter>) => {
        if (isNumber(filter?.currentPage) && this.props.mainList) {
            const newPage = filter.currentPage + 1;
            if (newPage === 1) {
                setCurrentQuery({});
            } else {
                setCurrentQuery({
                    page: newPage,
                });
            }
        }

        this.setState({
            filter: {
                ...this.state.filter,
                ...filter,
            },
        }, () => this.loadData(true));
    }

    protected prepareItem = (i: T): TableData => {
        const f = Object.keys(i).reduce((result, property) => {
            if (this.props.renderer && this.props.renderer[property]) {
                result[property] = this.props.renderer[property](i);
            } else {
                result[property] = i[property];
            }
            return result;
        }, {}) as TableData;

        if (this.props.actions) {
            f['actions'] = (
                <div className={style.actions}>
                    {this.props.actions.map((a) => {
                        if (a.condition && !a.condition(i)) {
                            return null;
                        }
                        return (
                            <Button
                                key={`list-action-${a.name}`}
                                color={a.color}
                                onClick={() => this.doAction(i, a)}
                                className={style.actionsButton}
                                icon={a.icon}
                                size={'small'}
                            >
                                {a.name}
                            </Button>
                        );
                    })}
                </div>
            );
        }

        return f;
    }

    protected loadData = (silent?: boolean) => {
        let sort = {
            sortName: this.state.filter.sortName,
            sortOrder: this.state.filter.sortOrder,
        };

        if (!sort.sortName) {
            sort = this.getDefaultSort();
        }

        const filter: EntityListFilter<T> = {
            skip: this.state.filter.currentPage * this.state.countOnPage,
            limit: this.state.countOnPage,
            sort: sort?.sortName ? `${sort.sortOrder === -1 ? '>' : '<'}${sort.sortName}` : undefined,
            filter: {...this.state.filter.filters as any},
        };
        filter.filter = Object.keys(filter.filter).reduce((result, name) => {
            const item = filter.filter[name];
            if (isObject<{from: any; to: any;}>(item)) {
                result[name] = {}
                if (item.from) {
                    result[name].$gte = item.from
                }
                if (item.to) {
                    result[name].$lte = item.to
                }
            } else {
                result[name] = item
            }
            return result;
        }, {}) as EntityListFilterQuery<T>

        if (this.props.transformFilters) {
            filter.filter = this.props.transformFilters(filter.filter);
        }

        return safePromise(this.props.loadData({silent}, filter).then(() => {
            this.setState({loaded: true});
        }));
    }

    protected doAction = async (entity: T, action: EntityAction<T>) => {
        if (action.confirm) {
            this.setState({
                confirmVisible: true,
                confirm: {
                    name: action.name,
                    text: action.confirm,
                    action,
                    entity,
                    color: action.color,
                    icon: action.icon,
                },
            });
        } else {
            await action.action(entity);
            if (action.reloadAfter) {
                this.loadData();
            }
        }
    }

    protected onConfirmCancel = () => {
        this.setState({
            confirmVisible: false,
        });
    }

    protected onConfirmSubmit = async () => {
        if (this.state.confirm) {
            await this.state.confirm.action.action(this.state.confirm.entity);
            if (this.state.confirm.action.reloadAfter) {
                this.loadData();
            }
        }
        this.setState({
            confirmVisible: false,
        });
    }

    protected doHeaderAction = async (action: HeaderAction) => {
        await action.action();
        if (action.reloadAfter) {
            this.loadData();
        }
    }

    public reload = async () => {
        return this.loadData();
    }
}
