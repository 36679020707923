import * as React from 'react';
import { css, cx } from 'emotion';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { isArray } from '../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const styles = {
    wraper: css`
        position: relative;
    `,
    overlay: css`
        position: fixed;
        z-index: 49;
        left: 0;
        bottom: 0;
        top: 0;
        right: 0;
        display: none;
        &.visible {
            display: block;
        }
    `,
    menu: css`
        position: absolute;
        z-index: 50;
        right: 0;
        bottom: 0;
        transform: translateY(100%);
        display: none;
        background: #fff;
        padding: 10px 15px;
        line-height: 30px;
        box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.75);
        font-weight: normal;
        width: 140px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        &.visible {
            display: block;
        }
    `,
    trigger: css`
        cusros: pointer;
        display: block;
        position: relative;
        background: #fff;
        z-index: 51;
    `,
    menuItem: css`
        cursor: pointer;
        font-size: 16px;
        display: block;
        padding: 5px 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 25px;
    `,
    menuItemIcon: css`
        margin-right: 8px;
    `,
};

export interface MenuItem {
    text: string;
    onClick?: () => void;
    icon?: IconProp;
}

export interface DropdownProps {
    className?: string;
    triggerClassName?: string;
    menuClassName?: string;
    menuContent?: React.ReactElement;
    menuItems?: MenuItem[];
}

/**************** Component ***************/
export class Dropdown extends React.Component<DropdownProps> {
    public readonly state = {
        open: false,
    };

    public render() {
        return (
            <div className={cx(styles.wraper, this.props.className)}>
                <a className={cx(styles.trigger, this.props.triggerClassName)} onClick={this.toggle}>
                    {this.props.children}
                </a>
                <div className={cx(styles.overlay, {['visible']: this.state.open})} onClick={this.toggle}/>
                <div className={cx(styles.menu, this.props.menuClassName, {['visible']: this.state.open})}>
                    {this.props.menuContent ? this.props.menuContent : null}
                    {isArray(this.props.menuItems) ? this.props.menuItems.map((i, index) => (
                        <a key={`dropdown-item-${index}`} className={styles.menuItem} onClick={this.onItemClick.bind(this, i)}>
                            <span className={styles.menuItemIcon}>
                                {i.icon ? <FontAwesomeIcon icon={i.icon} /> : null}
                            </span>
                            {i.text}
                        </a>
                    )) : null}
                </div>
            </div>
        );
    }

    protected onItemClick = (item: MenuItem) => {
        this.toggle();
        item.onClick();
    }

    protected toggle = () => {
        this.setState({
            open: !this.state.open,
        });
    }
}
