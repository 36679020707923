import * as React from 'react';
import { FormProvidedProps, withFormData } from './FormProvider';
import { css, cx } from 'emotion';

const style = {
    label: css`
        color: #dc3545;
        font-size: 0.75em;
    `,
};

/**************** Component props ***************/
interface ComponentProps extends Partial<FormProvidedProps> {
    name?: string;
    className?: string;
}

/**************** Component ***************/
class Component extends React.Component<ComponentProps> {
    /**
     * Render
     */
    public render() {
        if (!this.props.formErrorHandler) {
            return null;
        }

        const error =  this.props.formErrorHandler(this.props.name);
        if (!error) {
            return null;
        }

        return (
            <label className={cx(style.label, this.props.className)}>
                {error}
            </label>
        );
    }
}

export const FormError = withFormData(Component);
