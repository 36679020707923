
import { AppActions } from '../state/app';
import { v4 as uuid } from 'uuid';

/**
 * Wrap promise by waiting function
 * @param dispatch
 * @param promise
 * @param key
 */
export function wrapLoadingPromise<T>(dispatch: (action: any) => void, promise: Promise<T>, silent?: boolean): Promise<T> {
    const key = uuid();
    if (!silent) {
        dispatch(AppActions.pushWaitingFor(key));
    }
    return promise
        .then((value) => {
            if (!silent) {
                dispatch(AppActions.popWaitingFor(key));
            }
            return value;
        })
        .catch((err) => {
            if (!silent) {
                dispatch(AppActions.popWaitingFor(key));
            }
            throw err;
        });
}
