/**
 * Wait for condition to be true - checked by interval
 * @param condition
 * @param interval
 */
export async function waitFor(condition: () => boolean, interval: number = 100) {
    if (condition()) {
        return;
    }
    return new Promise((resolve: () => void, reject: () => void) => {
        const intervalRef = setInterval(() => {
            if (condition()) {
                clearInterval(intervalRef);
                resolve();
            }
        }, interval);
    });
}