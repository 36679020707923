/**
 * Get query param names from array of names
 * @param paramNames
 * @returns
 */
export function getQueryParams(paramNames?: string[]): { [name: string]: string } {
  const { searchParams } = new URL(window.location.href)
  if (paramNames) {
    return paramNames.reduce(
      (acc, item) => ({
        ...acc,
        [item]: searchParams.get(item),
      }),
      {},
    )
  }
  return paramsToObject(searchParams.entries())
}

/**
 * Remove search params
 */
export function removeQueryParams(paramNames?: string[]) {
  if (!paramNames) {
    window.history.replaceState({}, document.title, window.location.origin + window.location.pathname)
  } else {
    const params = getQueryParams()
    const paramsFiltered = Object.keys(params).reduce((result, key) => {
      if (paramNames.indexOf(key) === -1) {
        result[key] = params[key]
      }
      return result
    }, {})
    const query = new URLSearchParams(paramsFiltered).toString()
    window.history.replaceState(
      {},
      document.title,
      window.location.origin + window.location.pathname + query ? `?${query}` : '',
    )
  }
}

export function addQueryParams(params: { [name: string]: string }) {
  const query = new URLSearchParams({
    ...getQueryParams(),
    ...params,
  }).toString()

  window.history.replaceState(
    {},
    document.title,
    window.location.origin + window.location.pathname + query ? `?${query}` : '',
  )
}

/**
 * Helper function
 * @param entries
 */
function paramsToObject(entries) {
  const result = {}
  for (const [key, value] of entries) {
    result[key] = value
  }
  return result
}
