import * as React from 'react';
import { css, cx } from 'emotion';

const style = {
    root: css`
        display: flex;
        padding: 20px;
        flex-direction: column;
        background: #fff;
        margin-bottom: 20px;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
        border-radius: 3px;
    `,
};

/**************** Component ***************/
export class ContentBox extends React.Component<{
    divRef?: React.Ref<HTMLDivElement>;
}> {
    /**
     * Render
     */
    public render() {
        return (
            <div className={cx(style.root)} ref={this.props.divRef}>
                {this.props.children}
            </div>
        );
    }
}
