export const JSONSchemaApplicator = {
    '$schema': 'https://json-schema.org/draft/2019-09/schema',
    '$id': 'https://json-schema.org/draft/2019-09/meta/applicator',
    '$vocabulary': {
        'https://json-schema.org/draft/2019-09/vocab/applicator': true
    },
    '$dynamicAnchor': 'meta',

    'title': 'Applicator vocabulary meta-schema',
    'type': ['object', 'boolean'],
    'properties': {
        'prefixItems': { '$ref': '#/$defs/schemaArray' },
        'items': { '$dynamicRef': '#meta' },
        'unevaluatedItems': { '$dynamicRef': '#meta' },
        'contains': { '$dynamicRef': '#meta' },
        'additionalProperties': { '$dynamicRef': '#meta' },
        'unevaluatedProperties': { '$dynamicRef': '#meta' },
        'properties': {
            'type': 'object',
            'additionalProperties': { '$dynamicRef': '#meta' },
            'default': {}
        },
        'patternProperties': {
            'type': 'object',
            'additionalProperties': { '$dynamicRef': '#meta' },
            'propertyNames': { 'format': 'regex' },
            'default': {}
        },
        'dependentSchemas': {
            'type': 'object',
            'additionalProperties': {
                '$dynamicRef': '#meta'
            }
        },
        'propertyNames': { '$dynamicRef': '#meta' },
        'if': { '$dynamicRef': '#meta' },
        'then': { '$dynamicRef': '#meta' },
        'else': { '$dynamicRef': '#meta' },
        'allOf': { '$ref': '#/$defs/schemaArray' },
        'anyOf': { '$ref': '#/$defs/schemaArray' },
        'oneOf': { '$ref': '#/$defs/schemaArray' },
        'not': { '$dynamicRef': '#meta' }
    },
};

export const JSONSchemaContent = {
    '$schema': 'https://json-schema.org/draft/2019-09/schema',
    '$id': 'https://json-schema.org/draft/2019-09/meta/content',
    '$vocabulary': {
        'https://json-schema.org/draft/2019-09/vocab/content': true
    },
    '$dynamicAnchor': 'meta',

    'title': 'Content vocabulary meta-schema',

    'type': ['object', 'boolean'],
    'properties': {
        'contentMediaType': { 'type': 'string' },
        'contentEncoding': { 'type': 'string' },
        'contentSchema': { '$dynamicRef': '#meta' }
    }
};

export const JSONSchemaCore = {
    '$schema': 'https://json-schema.org/draft/2019-09/schema',
    '$id': 'https://json-schema.org/draft/2019-09/meta/core',
    '$vocabulary': {
        'https://json-schema.org/draft/2019-09/vocab/core': true
    },
    '$dynamicAnchor': 'meta',

    'title': 'Core vocabulary meta-schema',
    'type': ['object', 'boolean'],
    'properties': {
        '$id': {
            'type': 'string',
            'format': 'uri-reference',
            '$comment': 'Non-empty fragments not allowed.',
            'pattern': '^[^#]*#?$'
        },
        '$schema': {
            'type': 'string',
            'format': 'uri'
        },
        '$anchor': {
            'type': 'string',
            'pattern': '^[A-Za-z_][-A-Za-z0-9._]*$'
        },
        '$ref': {
            'type': 'string',
            'format': 'uri-reference'
        },
        '$recursiveRef': {
            'type': 'string',
            'format': 'uri-reference'
        },
        '$recursiveAnchor': {
            'type': 'boolean',
            'default': false
        },
        '$vocabulary': {
            'type': 'object',
            'propertyNames': {
                'type': 'string',
                'format': 'uri'
            },
            'additionalProperties': {
                'type': 'boolean'
            }
        },
        '$comment': {
            'type': 'string'
        },
        '$defs': {
            'type': 'object',
            'additionalProperties': { '$dynamicRef': '#meta' },
            'default': {}
        }
    }
};

export const JSONSchemaFormat = {
    '$schema': 'https://json-schema.org/draft/2019-09/schema',
    '$id': 'https://json-schema.org/draft/2019-09/meta/format',
    '$vocabulary': {
        'https://json-schema.org/draft/2019-09/vocab/format': true
    },
    '$dynamicAnchor': 'meta',

    'title': 'Format vocabulary meta-schema',
    'type': ['object', 'boolean'],
    'properties': {
        'format': { 'type': 'string' }
    }
};

export const JSONSchemaHyperSchema = {
    '$schema': 'https://json-schema.org/draft/2019-09/hyper-schema',
    '$id': 'https://json-schema.org/draft/2019-09/meta/hyper-schema',
    '$vocabulary': {
        'https://json-schema.org/draft/2019-09/vocab/hyper-schema': true
    },
    '$dynamicAnchor': 'meta',

    'title': 'JSON Hyper-Schema Vocabulary Schema',
    'type': ['object', 'boolean'],
    'properties': {
        'base': {
            'type': 'string',
            'format': 'uri-template'
        },
        'links': {
            'type': 'array',
            'items': {
                '$ref': 'https://json-schema.org/draft/2019-09/links'
            }
        }
    },
    'links': [
        {
            'rel': 'self',
            'href': '{+%24id}'
        }
    ]
};

export const JSONSchemaMetaData = {
    '$schema': 'https://json-schema.org/draft/2019-09/schema',
    '$id': 'https://json-schema.org/draft/2019-09/meta/meta-data',
    '$vocabulary': {
        'https://json-schema.org/draft/2019-09/vocab/meta-data': true
    },
    '$dynamicAnchor': 'meta',

    'title': 'Meta-data vocabulary meta-schema',

    'type': ['object', 'boolean'],
    'properties': {
        'title': {
            'type': 'string'
        },
        'description': {
            'type': 'string'
        },
        'default': true,
        'deprecated': {
            'type': 'boolean',
            'default': false
        },
        'readOnly': {
            'type': 'boolean',
            'default': false
        },
        'writeOnly': {
            'type': 'boolean',
            'default': false
        },
        'examples': {
            'type': 'array',
            'items': true
        }
    }
};

export const JSONSchemaValidation = {
    '$schema': 'https://json-schema.org/draft/2019-09/schema',
    '$id': 'https://json-schema.org/draft/2019-09/meta/validation',
    '$vocabulary': {
        'https://json-schema.org/draft/2019-09/vocab/validation': true
    },
    '$dynamicAnchor': 'meta',

    'title': 'Validation vocabulary meta-schema',
    'type': ['object', 'boolean'],
    'properties': {
        'multipleOf': {
            'type': 'number',
            'exclusiveMinimum': 0
        },
        'maximum': {
            'type': 'number'
        },
        'exclusiveMaximum': {
            'type': 'number'
        },
        'minimum': {
            'type': 'number'
        },
        'exclusiveMinimum': {
            'type': 'number'
        },
        'maxLength': { '$ref': '#/$defs/nonNegativeInteger' },
        'minLength': { '$ref': '#/$defs/nonNegativeIntegerDefault0' },
        'pattern': {
            'type': 'string',
            'format': 'regex'
        },
        'maxItems': { '$ref': '#/$defs/nonNegativeInteger' },
        'minItems': { '$ref': '#/$defs/nonNegativeIntegerDefault0' },
        'uniqueItems': {
            'type': 'boolean',
            'default': false
        },
        'maxContains': { '$ref': '#/$defs/nonNegativeInteger' },
        'minContains': {
            '$ref': '#/$defs/nonNegativeInteger',
            'default': 1
        },
        'maxProperties': { '$ref': '#/$defs/nonNegativeInteger' },
        'minProperties': { '$ref': '#/$defs/nonNegativeIntegerDefault0' },
        'required': { '$ref': '#/$defs/stringArray' },
        'dependentRequired': {
            'type': 'object',
            'additionalProperties': {
                '$ref': '#/$defs/stringArray'
            }
        },
        'const': true,
        'enum': {
            'type': 'array',
            'items': true
        },
        'type': {
            'anyOf': [
                { '$ref': '#/$defs/simpleTypes' },
                {
                    'type': 'array',
                    'items': { '$ref': '#/$defs/simpleTypes' },
                    'minItems': 1,
                    'uniqueItems': true
                }
            ]
        }
    },
};

export const JSONSchema = JSON.stringify({
    '$schema': 'https://json-schema.org/draft/2019-09/schema',
    '$id': 'https://json-schema.org/draft/2019-09/schema',
    '$vocabulary': {
        'https://json-schema.org/draft/2019-09/vocab/core': true,
        'https://json-schema.org/draft/2019-09/vocab/applicator': true,
        'https://json-schema.org/draft/2019-09/vocab/validation': true,
        'https://json-schema.org/draft/2019-09/vocab/meta-data': true,
        'https://json-schema.org/draft/2019-09/vocab/format': false,
        'https://json-schema.org/draft/2019-09/vocab/content': true
    },
    '$dynamicAnchor': 'meta',

    'title': 'Core and Validation specifications meta-schema',
    'allOf': [
        JSONSchemaCore,
        JSONSchemaApplicator,
        JSONSchemaValidation,
        JSONSchemaMetaData,
        JSONSchemaFormat,
        JSONSchemaContent,
    ],
    'type': ['object', 'boolean'],
    'properties': {
        'definitions': {
            '$comment': 'While no longer an official keyword as it is replaced by $defs, this keyword is retained in the meta-schema to prevent incompatible extensions as it remains in common use.',
            'type': 'object',
            'additionalProperties': { '$dynamicRef': '#meta' },
            'default': {}
        },
        'dependencies': {
            '$comment': '\"dependencies\" is no longer a keyword, but schema authors should avoid redefining it to facilitate a smooth transition to \"dependentSchemas\" and \"dependentRequired\"',
            'type': 'object',
            'additionalProperties': {
                'anyOf': [
                    { '$dynamicRef': '#meta' },
                    { '$ref': '#/$defs/stringArray' }
                ]
            }
        }
    },
    '$defs': {
        'nonNegativeInteger': {
            'type': 'integer',
            'minimum': 0
        },
        'nonNegativeIntegerDefault0': {
            '$ref': '#/$defs/nonNegativeInteger',
            'default': 0
        },
        'simpleTypes': {
            'enum': [
                'array',
                'boolean',
                'integer',
                'null',
                'number',
                'object',
                'string'
            ]
        },
        'stringArray': {
            'type': 'array',
            'items': { 'type': 'string' },
            'uniqueItems': true,
            'default': []
        },
        'schemaArray': {
            'type': 'array',
            'minItems': 1,
            'items': { '$dynamicRef': '#meta' }
        }
    }
});