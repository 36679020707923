// tslint:disable-next-line:no-reference
/// <reference path='./types/index.d.ts'/>

import * as React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMidlewear from 'redux-thunk';
import { RootStore } from './state';
import { Router } from './routes/Router';
import { Loading } from './components/utils/Loading';
import * as ReactDOM from 'react-dom';
import { LayoutMain } from './components/layouts/LayoutMain';
import { StylesReset } from './components/utils/StylesReset';
import { AuthNeed } from './components/utils/AuthNeed';
import { Notifications } from './components/utils/Notifications';
import { Menu } from './components/content/Menu';
import { SessionRefresher } from './components/utils/SessionRefresher';
import { Redirect } from './components/utils/Redirect';
import { SaveEventHandler } from './components/utils/SaveEventHandler';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { getEnvName, getVersion } from './utils/enviroment';
import { OauthRedirection } from './components/ux/OauthRedirection';

// tslint:disable-next-line: no-var-requires
const config = require('config');

if (config.sentryEnabled) {
    // tslint:disable-next-line: no-console
    console.info('Monitoring enabled');
    Sentry.init({
        dsn: 'https://241d450d347a41b7b0af99a7e3a4a21d@o467112.ingest.sentry.io/5492968',
        integrations: [
            new Integrations.BrowserTracing(),
        ],
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
        release: getVersion(),
        environment: getEnvName(),
    });
}

// init store
const store = createStore(
    RootStore,
    composeWithDevTools(applyMiddleware(thunkMidlewear)),
);

export class App extends React.Component {
    public render() {
        return (
            <BrowserRouter basename={config.publicPath ? config.publicPath : '/'}>
                <Provider store={store}>
                    <StylesReset />
                    <AuthNeed>
                        <LayoutMain menuComponent={() => (<Menu />)}>
                            <Switch>
                                <Router />
                            </Switch>
                        </LayoutMain>
                    </AuthNeed>
                    <Loading />
                    <Notifications />
                    <Redirect />
                    <SessionRefresher />
                    <SaveEventHandler />
                    <OauthRedirection />
                </Provider>
            </BrowserRouter>
        );
    }
}

ReactDOM.render(
    <App />
, document.getElementById('root'));
