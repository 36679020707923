import { isArray, isObject, isUndefined } from "./typechecks";

/**
 * Copy object without property
 * @param obj
 * @param omitKey
 */
export function objectOmit<T extends object>(obj: T, omitKeys: string[]): Partial<T> {
    if (!obj) {
        return null;
    }
    return Object.keys(obj).reduce((result, key) => {
        if (omitKeys.indexOf(key) === -1) {
            result[key] = obj[key];
        }
        return result;
    }, {} as Partial<T>);
}

/**
 * Filter object properties
 * @param obj
 * @param omitKey
 */
export function objectFilter<T extends object>(obj: T, callback: (name: string, value: any) => boolean): Partial<T> {
        return Object.keys(obj).reduce((result, key) => {
            if (callback(key, obj[key])) {
                result[key] = obj[key];
            }
            return result;
    }, {} as Partial<T>);
}

/**
 * All property names to lower case
 * @param obj
 */
export function objectToLowerCase<T extends object>(obj: T): T {
    if (!obj) {
        return null;
    }
    return Object.keys(obj).reduce((result, key) => {
        result[key.toLowerCase()] = obj[key];
        return result;
    }, {} as T);
}

/**
 * Check if object has properties
 * @param obj
 */
export function objectHasProperties<T extends string>(obj: object, keys: T[]): obj is {[K in T]: K} {
    for (const key of keys) {
        if (!obj.hasOwnProperty(key)) {
            return false;
        }
    }
    return true;
}

/**
 * Filter all undefined in object
 */
export function filterUndefined(input: any): any {
    if (isObject(input)) {
        return Object.keys(input).reduce((result, key) => {
            if (!isUndefined(input[key])) {
                result[key] = filterUndefined(input[key]);
            }
            return result;
        }, {});
    } else if (isArray(input)) {
        return input.filter((item) => !isUndefined(item))
            .map((item) => filterUndefined(item));
    } else if (isUndefined(input)) {
        return null;
    }
    return input;
}