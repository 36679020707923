import * as React from 'react';
import { css, cx } from 'emotion';
import { ContentBox } from '../layouts/ContentBox';
import { Form } from '../ux/forms/Form';
import { JsonValidatorObjectChildsSchema, objectOmit, safePromise } from '../../utils';
import { FormSubmit } from '../ux/forms/FormSubmit';
import { Button } from '../ux/Button';
import { FormData } from '../ux/forms/FormProvider';
import { FormError } from '../ux/forms/FormError';
import { ApiCallOptions } from '../../state/api';

const style = {
    bottomButtons: css`
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
        margin-top: 15px;
    `,
    bottomErrors: css`
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
        margin-top: 5px;
    `,
    button: css`
        margin-left: 8px;
    `,
    input: css`
        margin-bottom: 15px;
        &.small {
            max-width: 300px;
        }
    `,
    group: css`
        padding: 20px;
        background: #f1f1f1;
    `,
};

export function EntityEditorInput(props: {small?: boolean} & React.Props<any>) {
    return (
        <div className={cx(style.input, {['small']: props.small})}>{props.children}</div>
    );
}

export function EntityEditorGroup(props: {className?: string} & React.Props<any>) {
    return (
        <div className={cx(style.group, props.className)}>{props.children}</div>
    );
}

export interface EntityEditorProps<T> {
    // this props should be connected by redux
    data: T;
    loadData: (options: ApiCallOptions, id: string) => Promise<T>;
    saveData?: (opt: ApiCallOptions, entity: Partial<T>) => Promise<T>;
    patchData: (opt: ApiCallOptions, id: string, entity: Partial<T>) => Promise<T>;

    // entity id is needed, can be 'create'
    entityId?: string;

    // additional
    schema?: JsonValidatorObjectChildsSchema;
    onClose?: () => void;
    transformError?: (e) => void;
    onComplete?: (data: T) => void;
    onChange?: (data: FormData, internal?: boolean) => void;
    initialData?: Partial<T>;
    onInitialLoad?: (data: T) => void;
    outputDataTransform?: (data: FormData, isCreating?: boolean) => Partial<T>;
    inputDataTransform?: (data: any) => Partial<T>;

    saveOnNativeEvent?: boolean;
}

export interface EntityEditorState<T> {
    loaded: boolean;
    currentData: any;
}

export class EntityEditor<T> extends React.Component<EntityEditorProps<T>, EntityEditorState<T>> {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            currentData: null,
        };
    }

    public componentDidMount() {
        this.loadData()
            .then((data) => {
                if (data) {
                    this.setState({loaded: true});

                    if (this.props.onInitialLoad) {
                        this.props.onInitialLoad(data);
                    }
                } else {
                    this.setState({loaded: true});
                }
            });
    }

    public render() {
        let data = this.isCreatingNew() ? (this.props.initialData ? this.props.initialData : {}) : this.props.data;
        if (this.props.inputDataTransform && data) {
            data = this.props.inputDataTransform(data);
        }
        data = objectOmit(data, ['_id']);

        return (
            <ContentBox>
                {data && this.state.loaded ? (
                    <Form
                        defaultData={data}
                        action={this.action}
                        schema={this.props.schema}
                        onChange={this.onChange}
                        saveOnNativeEvent
                    >
                        {typeof this.props.children === 'function' ? this.props.children({...data, ...this.state.currentData}) : this.props.children}
                        <div className={style.bottomButtons}>
                            {this.props.onClose ? <Button onClick={this.props.onClose} className={style.button} color={'gray'}>Close</Button> : null}
                            <FormSubmit className={style.button} color={'green'}>Save</FormSubmit>
                        </div>
                        <div className={style.bottomErrors}>
                            <div>
                                <FormError name="" />
                                <FormError />
                            </div>
                        </div>
                    </Form>
                ) : null}
            </ContentBox>
        );
    }

    protected onChange = (currentData) => {
        this.setState({currentData});
        if (this.props.onChange) {
            this.props.onChange(currentData);
        }
    }

    protected loadData = async (): Promise<T> => {
        if (this.isCreatingNew()) {
            return Promise.resolve({} as T);
        }

        return safePromise(this.props.loadData({}, this.props.entityId));
    }

    protected action = async (incommingData: FormData) => {
        const isCreatingNew = this.isCreatingNew();
        const data = this.props.outputDataTransform ? this.props.outputDataTransform(incommingData, isCreatingNew) : incommingData;
        let final = null;

        try {
            if (isCreatingNew && this.props.saveData) {
                final = await this.props.saveData({}, data as Partial<T>);
            } else {
                final = await this.props.patchData({}, this.props.entityId, data as Partial<T>);
            }
        } catch (e) {
            if (this.props.transformError) {
                this.props.transformError(e);
            }
            throw e;
        }

        if (this.props.onComplete) {
            this.props.onComplete(final);
        }
    }

    protected isCreatingNew() {
        return this.props.entityId === 'create';
    }

}
