/**
 * Set of regexps used in whole application.
 * Its used for validating data.
 */
export const SessionTokenRegexp = /^[a-zA-Z0-9]{64}$/;

export const RegExps = {
    anyToken: {
        regexp: /^[a-zA-Z0-9]{12}$/,
        message: 'Invalid token format',
    },
    username: {
        // this is regexp with legacy support, it can be username or email, TODO remove it in next versions
        regexp: /^(((([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))|([a-zA-Z0-9][a-zA-Z0-9_\-.]{0,127}))$/,
        message: 'Username must have email format',
    },
    usernameLegacy: {
        regexp: /^[a-zA-Z0-9][a-zA-Z0-9_\-.]{0,127}$/,
        message: 'Username can contain only alphanumeric and _ . - characters and must start with alphanumeric character',
    },
    password: {
        regexp: /^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,}$/,
        message: 'Password must contain at least one number and one alphabet character and must have minimaly 8 characters',
    },
    otpToken: {
        regexp: /^[0-9]{3}[ ]?[0-9]{3}$/,
        message: 'Invalid OTP token format',
    },
    clientName: {
        regexp: /^[a-zA-Z0-9-]{2,64}$/,
        message: 'Invalid client name format',
    },
    flowId: {
        regexp: /^[a-zA-Z0-9-]{2,64}$/,
        message: 'Invalid flowId format',
    },
    flowName: {
        regexp: /^[a-zA-Z0-9-]{2,64}$/,
        message: 'Invalid flow name format',
    },
    apiKey: {
        regexp: /^[a-zA-Z0-9]{64}$/,
        message: 'Invalid API key format',
    },
    fileKey: {
        regexp: /^[a-zA-Z0-9_-]{2,64}$/,
        message: 'Invalid file key format',
    },
    requestToken: {
        regexp: /^[a-zA-Z0-9]{26}$/,
        message: 'Invalid request token format',
    },
    datetime: {
        regexp: /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)$/,
        message: 'Invalid ISO date and time format (YYYY-MM-DDTHH:mm:ss.SSSZ)',
    },
    date: {
        regexp: /^\d{4}-[01]\d-[0-3]\d$/,
        message: 'Invalid ISO date format (YYYY-MM-DD)',
    },
    email: {
        regexp: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: 'Invalid email format',
    },
    adminSessionToken: {
        regexp: /^[a-zA-Z0-9]{64}$/,
        message: 'Invalid admin session token format',
    },
    selfcareSessionToken: {
        regexp: /^[a-zA-Z0-9]{64}$/,
        message: 'Invalid selfcare session token format',
    },
    entityId: {
        regexp: /^[0-9a-fA-F]{24}$/,
        message: 'Invalid entity ID format',
    },
    filename: {
        regexp: /^([A-Za-z0-9_\-:.][A-Za-z0-9_\-:. ]+[A-Za-z0-9_\-:](\.[a-zA-Z0-9]{1,6})?)$/,
        message: 'Invalid filename',
    },
    fileSystemFilename: {
        regexp: /^\/?([A-Za-z0-9_\-]+\/)*((([A-Za-z0-9_\-:.][A-Za-z0-9_\-:. ]+[A-Za-z0-9_\-:])|([A-Za-z0-9_\-:.][A-Za-z0-9_\-:])|([A-Za-z0-9_\-]))(\.[a-zA-Z0-9]{1,6})?)$/,
        message: 'Invalid filename',
    },
    fileSystemContentType: {
        regexp: /^[a-z]+\/[A-Za-z0-9_\.\-\+]+$/,
        message: 'Invalid contentType',
    },
    name: {
        regexp: /^[a-zA-Z0-9][a-zA-Z0-9_\-.]{0,127}$/,
        message: 'Name can contain only alphanumeric and _ . - characters and must start with alphanumeric character',
    },
    clientConnectionId: {
        regexp: /^[a-zA-Z0-9]{64}$/,
        message: 'Client connection ID format',
    },
};
