import * as React from 'react';
import { Route } from 'react-router-dom';
import { Overview } from './Overview';
import { GoHomeAdmin, GoHomeSelfcare } from './GoHome';
import { Users } from './Users';
import { Datasets } from './Datasets';
import { DatasetsEdit } from './DatasetsEdit';
import { Nodes } from './Nodes';
import { NodeEdit } from './NodeEdit';
import { Clients } from './Clients';
import { ClientsEdit } from './ClientsEdit';
import { UsersEdit } from './UsersEdit';
import { Log } from './Log';
import { Script } from './Script';
import { Requests } from './Requests';
import { RequestShow } from './RequestShow';
import { Credits } from './Credits';
import { ConnectorsEdit } from './ConnectorsEdit';
import { IRootStore } from '../state';
import { connect } from 'react-redux';
import { UserSessionType } from '../api/structure';
import { Integration } from './Integration';

export const routes = {
    home: {
        path: '/',
    },
    overview: {
        path: '/overview',
    },
    requests: {
        path: '/requests',
        show: {
            path: '/requests/:id',
        },
    },
    clients: {
        path: '/clients',
        edit: {
            path: '/clients/:id',
        },
    },
    connectors: {
        path: '/connectors',
        edit: {
            path: '/connectors/:id',
        },
    },
    integration: {
        path: '/integration',
    },
    nodes: {
        path: '/nodes',
        edit: {
            path: '/nodes/:id',
        },
    },
    datasets: {
        path: '/datasets',
        edit: {
            path: '/datasets/:id',
        },
    },
    users: {
        path: '/users',
        edit: {
            path: '/users/:id',
        },
    },
    credits: {
        path: '/credits',
    },
    log: {
        path: '/log',
    },
    script: {
        path: '/script'
    }
};

class RouterComponent extends React.Component<{
    sessionUser: boolean;
    sessionType: UserSessionType;
}> {
    public readonly state = {
        gameId: null,
        playerId: null,
    };

    public render() {
        const isAdmin = this.props.sessionType === 'admin';
        if (!this.props.sessionUser) {
            return null;
        }
        return (
            <>
                {isAdmin ? (
                    <Route path={`${routes.home.path}`} exact component={GoHomeAdmin}/>
                ) : (
                    <Route path={`${routes.home.path}`} exact component={GoHomeSelfcare}/>
                )}
                <Route path={`${routes.overview.path}`} exact component={Overview}/>
                <Route path={`${routes.requests.path}`} exact component={Requests} />
                {isAdmin ? <Route path={`${routes.requests.show.path}`} exact component={RequestShow} /> : null}
                {isAdmin ? <Route path={`${routes.clients.path}`} exact component={Clients} /> : null}
                {isAdmin ? <Route path={`${routes.clients.edit.path}`} exact component={ClientsEdit} /> : null}
                <Route path={`${routes.connectors.edit.path}`} exact component={ConnectorsEdit} />
                <Route path={`${routes.nodes.path}`} exact component={Nodes} />
                {isAdmin ? <Route path={`${routes.nodes.edit.path}`} exact component={NodeEdit} /> : null}
                {isAdmin ? <Route path={`${routes.datasets.path}`} exact component={Datasets} /> : null}
                {isAdmin ? <Route path={`${routes.datasets.edit.path}`} exact component={DatasetsEdit} /> : null}
                {isAdmin ? <Route path={`${routes.users.path}`} exact component={Users} /> : null}
                <Route path={`${routes.users.edit.path}`} exact component={UsersEdit} />
                <Route path={`${routes.credits.path}`} exact component={Credits} />
                {isAdmin ? <Route path={`${routes.log.path}`} exact component={Log} /> : null}
                {isAdmin ? <Route path={`${routes.script.path}`} exact component={Script} /> : null}
                {!isAdmin ? <Route path={`${routes.integration.path}`} exact component={Integration} /> : null}
            </>
        );
    }
}

export const Router = connect(
    (state: IRootStore) => ({
        sessionUser: !!state.api.sessionUser,
        sessionType: state.api.sessionUser?.type,
    }),
    (dispatch) => ({}),
)(RouterComponent);
