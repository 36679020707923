import { faCloudUploadAlt, faTerminal, faWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { css } from 'emotion';
import * as React from 'react';
import { BaseConnectorInterface, ConnectorEntity } from '../../api/structure';
import { connectorViewer } from './connectors/viewers';

export const style = {
    label: css`
        border-bottom: 1px solid #ddd;
        margin-bottom: 20px;
        display: block;
        font-size: 1.2em;
        svg {
            margin-right: 10px;
        }
    `,
    interfaceGroup: css`
        margin-bottom: 30px;
        background: #f9f9f9;
        padding: 10px;
        border: 1px dotted #ccc;
        position: relative;
    `,
    interfaceGroupContent: css`
    `,
    notSet: css`
        text-align: center;
        font-size: 16px;
        padding: 20px 0;
    `,
};

export const InterfaceViewer = (props: {prefix: string; data: BaseConnectorInterface}) => {
    if (props.data && props.data.type) {
        if (connectorViewer[props.data.type]) {
            return connectorViewer[props.data.type](props.prefix, props.data);
        } else {
            throw new Error(`Editor for connector with type ${props.data.type} does not exists.`);
        }
    }
    return (<div className={style.notSet}>Connector is not set</div>);
};

/**************** Props ***************/
interface ComponentProps {
    data?: ConnectorEntity;
}

/**************** Component ***************/
export class ConnectorDetails extends React.Component<ComponentProps> {
    public render() {
        // conditions for renderer
        const metaInfo = this.props.data?.interfaceMetadata ? this.props.data?.interfaceMetadata : {
            fetchInterface: null,
            commandInterface: null,
            serviceInterface: null,
        };

        const interfacesEnabled = {
            fetchInterface:   metaInfo.fetchInterface?.enabled,
            commandInterface: metaInfo.commandInterface?.enabled,
            serviceInterface: metaInfo.serviceInterface?.enabled,
        };

        if (!interfacesEnabled.fetchInterface && !interfacesEnabled.commandInterface && !interfacesEnabled.serviceInterface) {
            return (
                <>
                    <div className={style.notSet}>No interface was enabled</div>
                </>
            );
        }

        return (
            <>
                {interfacesEnabled.fetchInterface ? (
                    <>
                        <label className={style.label}>
                            <FontAwesomeIcon icon={faCloudUploadAlt} />
                            Fetch interface
                        </label>
                        <div className={style.interfaceGroup}>
                            <div className={style.interfaceGroupContent}>
                                <InterfaceViewer
                                    prefix={'fetchInterface'}
                                    data={this.props.data.fetchInterface}
                                />
                            </div>
                        </div>
                    </>
                ) : null}
                {interfacesEnabled.commandInterface ? (
                    <>
                        <label className={style.label}>
                            <FontAwesomeIcon icon={faTerminal} />
                            Command interface
                        </label>
                        <div className={style.interfaceGroup}>
                            <div className={style.interfaceGroupContent}>
                                <InterfaceViewer
                                    prefix={'commandInterface'}
                                    data={this.props.data.commandInterface}
                                />
                            </div>
                        </div>
                    </>
                ) : null}
                {interfacesEnabled.serviceInterface ? (
                    <>
                        <label className={style.label}>
                            <FontAwesomeIcon icon={faWrench} />
                            Service interface
                        </label>
                        <div className={style.interfaceGroup}>
                            <div className={style.interfaceGroupContent}>
                                <InterfaceViewer
                                    prefix={'serviceInterface'}
                                    data={this.props.data.serviceInterface}
                                />
                            </div>
                        </div>
                    </>
                ) : null}
            </>
        );
    }
}

