import { v4 as uuid } from 'uuid';
import { ReduxActionBody, ThunkAction } from '../utils/interfaces';

// interfaces
export type NotificationType = 'red' | 'green' | 'yellow' | 'gray';
export interface Notification {
    text: string;
    timestamp: Date;
    color: NotificationType;
    id: string;
    persistent?: boolean;
}

// Constants
export const constants = {
    SET_STATE: 'APP_SET_STATE',
    PUSH_NOTIFICATION: 'APP_PUSH_NOTIFICATION',
    POP_OLD_NOTIFICATIONS: 'APP_POP_OLD_NOTIFICATIONS',
    POP_NOTIFICATION: 'APP_POP_NOTIFICATION',
    PUSH_WAITING_FOR: 'APP_PUSH_WAITING_FOR',
    POP_WAITING_FOR: 'APP_POP_WAITING_FOR',
    REDIRECT_TO: 'REDIRECT_TO',
    REDIRECT_BACK: 'REDIRECT_BACK',
    REDIRECT_RESET: 'REDIRECT_RESET',
    REDIRECT_LOCK: 'REDIRECT_LOCK',
};

// Store
export class AppInitialState {
    public readonly initialized: boolean = false;
    public readonly notifications: Notification[] = [];
    public readonly waitingFor: string[] = [];
    public readonly redirectTo: string = null;
    public readonly redirectBack: boolean = false;
    public readonly redirectLocked: boolean = false;
}

// Actions
export class AppActions {
    /**
     * Generic function to set values to store
     * @param values
     */
    public static setState(values: Partial<AppInitialState>): ReduxActionBody {
        return {
            type: constants.SET_STATE,
            values,
        };
    }

    /**
     * Initialiez app triger
     */
    public static init(): ReduxActionBody {
        return AppActions.setState({
            initialized: true,
        });
    }

    /**
     * Push notification - show it
     * @param text
     * @param type
     * @param persistent
     */
    public static pushNotification(text: string, color: NotificationType, persistent: boolean = false): ThunkAction<string> {
        return function(dispatch, getState) {
            const id = uuid();
            dispatch({
                type: constants.PUSH_NOTIFICATION,
                notification: {
                    text,
                    color,
                    timestamp: new Date(),
                    persistent,
                    id,
                },
            });
            return id;
        };
    }

    /**
     * Pop notification menas remove it
     * @param uuid
     */
    public static popNotification(notificationUuid: string): ReduxActionBody {
        return {
            type: constants.POP_NOTIFICATION,
            id: notificationUuid,
        };
    }

    /**
     * Remove old notifications
     */
    public static popOldNotifications(): ReduxActionBody {
        return {
            type: constants.POP_OLD_NOTIFICATIONS,
        };
    }

    /**
     * Push key for what app is waiting for (show loader)
     * @param key
     */
    public static pushWaitingFor(key: string): ReduxActionBody {
        return {
            type: constants.PUSH_WAITING_FOR,
            key,
        };
    }

    /**
     * Pop key for what app is waiting for (hide loader - if array is empty)
     * @param key
     */
    public static popWaitingFor(key: string): ReduxActionBody {
        return {
            type: constants.POP_WAITING_FOR,
            key,
        };
    }

    /**
     * Redirect to path
     * @param path
     */
    public static redirectTo(path: string, unlock?: boolean): ReduxActionBody {
        return {
            type: constants.REDIRECT_TO,
            path,
            unlock,
        };
    }

    /**
     * Redirect to path
     * @param path
     */
    public static redirectBack(unlock?: boolean): ReduxActionBody {
        return {
            type: constants.REDIRECT_BACK,
            unlock,
        };
    }

    /**
     * Reset redirection - its internaly called from redirect component
     */
    public static redirectReset(): ReduxActionBody {
        return {
            type: constants.REDIRECT_RESET,
        };
    }

    /**
     * Lock redirect - if true this will ask user for leave
     */
    public static redirectLock(locked: boolean): ReduxActionBody {
        return {
            type: constants.REDIRECT_LOCK,
            locked: locked,
        };
    }
}

// Reducer
export const AppReducer = (state: AppInitialState = new AppInitialState(), action: ReduxActionBody) => {
    switch (action.type) {
        case constants.SET_STATE:
            return {
                ...state,
                ...action.values,
            };
            break;
        case constants.PUSH_NOTIFICATION: {
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    action.notification,
                ],
            };
        }
        case constants.POP_OLD_NOTIFICATIONS: {
            const time = 10 * 1000; // 10 seconds
            const now = new Date().getTime();
            return {
                ...state,
                notifications: [
                    ...state.notifications.filter((item) => (now - item.timestamp.getTime()) < time || item.persistent),
                ],
            };
        }
        case constants.POP_NOTIFICATION: {
            return {
                ...state,
                notifications: [
                    ...state.notifications.filter((item) => item.id !== action.id),
                ],
            };
        }
        case constants.PUSH_WAITING_FOR: {
            return {
                ...state,
                waitingFor: [
                    ...state.waitingFor,
                    action.key,
                ],
            };
        }
        case constants.POP_WAITING_FOR: {
            return {
                ...state,
                waitingFor: [
                    ...state.waitingFor.filter((key) => key !== action.key),
                ],
            };
        }
        case constants.REDIRECT_TO: {
            return {
                ...state,
                redirectTo: action.path,
                redirectBack: false,
                redirectLocked: action.unlock ? false : state.redirectLocked,
            };
        }
        case constants.REDIRECT_BACK: {
            return {
                ...state,
                redirectTo: null,
                redirectBack: true,
                redirectLocked: action.unlock ? false : state.redirectLocked,
            };
        }
        case constants.REDIRECT_RESET: {
            return {
                ...state,
                redirectBack: false,
                redirectTo: null,
            };
        }
        case constants.REDIRECT_LOCK: {
            return {
                ...state,
                redirectLocked: action.locked,
            };
        }
        default:
            return state;
    }
};
