import * as React from 'react';
import { connect } from 'react-redux';
import { IRootStore } from '../../state';
import { AppActions, NotificationType } from '../../state/app';
import { getQueryParams, removeQueryParams } from '../../utils/query';

/**
 * Page
 */
class OauthRedirectionComponent extends React.Component<{
    pushNotification: (text: string, type: NotificationType, persistent?: boolean) => void;
}> {
    componentDidMount(): void {
        const query = getQueryParams(['oauth-redirection-url', 'oauth-service-name', 'oauth-failed'])
        if (query['oauth-redirection-url'] && query['oauth-service-name'] === 'slack') {
            window.location.href = query['oauth-redirection-url'];
            this.props.pushNotification(`Successfully installed slack application.`, 'green', true);
        } else if (query['oauth-failed'] && query['oauth-service-name'] === 'slack') {
            this.props.pushNotification(`Installation of slack application failed. Please try later.`, 'red', true);
        }

        removeQueryParams(['oauth-redirection-url', 'oauth-service-name', 'oauth-failed']);
    }

    public render() {
        return null;
    }
}

export const OauthRedirection = connect(
    (state: IRootStore) => ({}),
    (dispatch) => ({
        pushNotification: (text: string, type: NotificationType, persistent?: boolean) => dispatch(AppActions.pushNotification(text, type, persistent)),
    }),
)(OauthRedirectionComponent);
