import * as React from 'react';
import { FormProvidedProps, withFormData } from './FormProvider';
import { css, cx } from 'emotion';
import FormMonacoInput, { ComponentProps as MonacoComponentProps } from './FormMonacoInput'
import { FormTextarea } from './FormTextarea';
import { Button } from '../Button';
import { faCode, faPencilAlt } from '@fortawesome/free-solid-svg-icons';

const styles = {
    root: css`
        position: relative;
    `,
    rootPadding: css`
        padding-top: 24px;
    `,
    switches: css`
        position: absolute;
        right: 0;
        top: -3px;
    `,
    textarea: css`
        textarea {
            resize: none;
            position: relative;
            z-index: 1;
            background: #1e1e1e;
            color: #9cdcfe;
            font-family: Menlo, Monaco, "Courier New", monospace;
            font-weight: normal;
            font-size: 12px;
            font-feature-settings: "liga" 0, "calt" 0;
            line-height: 18px;
            letter-spacing: 0px;
            border: none;
            border-radius: 0px;
        }
    `,
};

/**************** Component props ***************/
interface ComponentProps extends Partial<FormProvidedProps>, MonacoComponentProps {}

/**************** Component ***************/
class Component extends React.Component<ComponentProps, {
    view: string,
}> {
    public readonly state = {
        view: 'monaco',
    };

    /**
     * Render
     */
    public render() {
        return (
            <div className={cx(styles.root, { [styles.rootPadding]: !this.props.label })}>
                <div className={styles.switches}>
                    {this.state.view === 'monaco' ? (
                        <Button size="small" icon={faPencilAlt} onClick={() => this.switchView('raw')} color={'green'}>Raw</Button>
                    ) : (
                        <Button size="small" icon={faCode} onClick={() => this.switchView('monaco')} color={'green'}>Monaco</Button>
                    )}
                </div>
                <div>
                    {this.state.view === 'monaco' ? (
                        <FormMonacoInput {...this.props}/>
                    ) : (
                        <FormTextarea
                            {...this.props}
                            className={cx(styles.textarea, this.props.className)}
                            textareaStyle={{
                                height: this.props.height ? `${this.props.height}px` : 'inherit'
                            }}
                        />
                    )}
                </div>
            </div>
        )
    }

    protected switchView = (view: string) => {
        this.setState({view})
    }
}

export default Component
