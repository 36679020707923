import { NodeEntity, ClientEntity, ConnectorEntity, DatasetEntity, DatasetEntityPatch, UserEntity } from './structure';
import { isNumber, objectOmit } from '../utils';

export function nodeEntityPatch(data: NodeEntity): Partial<NodeEntity> {
    return {
        name: data.name,
        dataSetId: data.dataSetId,
        pricing: data.pricing,
        ban: data.ban,
        groupName: data.groupName,
        identificationMode: data.identificationMode,
        ...(isNumber(data.anonymizeTimeout) ? {anonymizeTimeout : data.anonymizeTimeout} : null),
    };
}

export function userEntityPatch(data: UserEntity): Partial<UserEntity> {
    return {
        username: data.username,
        password: data.password,
        type: data.type,
        clientsId: data.clientsId,
    };
}

export function userSelfcareEntityPatch(data: UserEntity): Partial<UserEntity> {
    return {};
}

export function datasetEntityPost(data: DatasetEntity): DatasetEntity {
    return {
        name: data.name,
        queryList: data.queryList,
        fieldList: data.fieldList,
    };
}

export function datasetEntityPatch(data: DatasetEntity): DatasetEntityPatch {
    return {
        queryList: data.queryList,
        fieldList: data.fieldList,
    };
}

export function clientEntityPatch(data: ClientEntity): ClientEntity {
    return {
        name: data.name,
    };
}

export function connectorEntityPatch(data: ConnectorEntity): Partial<ConnectorEntity> {
    return {
        name: data.name,
        commandInterface: data.commandInterface,
        fetchInterface: data.fetchInterface,
        serviceInterface: data.serviceInterface,
        ...(data.interfaceMetadata ? {interfaceMetadata: data.interfaceMetadata} : null),
    };
}