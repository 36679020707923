import { css, cx } from 'emotion';
import * as React from 'react';
import { rotate } from '../../utils';


/**************** Style ***************/
const style = {
    ring: css`
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -25px;
        margin-top: -25px;
        width: 50px;
        height: 50px;
    `,
    after: (color: string = '#fff') => ({
        display: 'block',
        width: '46px',
        height: '46px',
        margin: '1px',
        borderRadius: '50%',
        borderSize: '5px',
        borderColor: `${color} transparent ${color} transparent`,
        borderStyle: 'solid',
        animation: `${rotate} 1.2s linear infinite`,
        borderWidth: '5px',
    }),
};

interface ComponentProps {
    style?: any;
    color?: string;
    className?: string;
}

/**************** Component ***************/
export const Ring = (props: ComponentProps) => (
    <div style={props.style} className={cx(props.className, style.ring)}>
        <div style={style.after(props.color ? props.color : '#fff')} />
    </div>
);
