import * as React from 'react';
import { css, cx } from 'emotion';
import { TableRow } from './TableRow';
import { isNumber } from '../../../utils';
import { TablePaginator } from './TablePaginator';
import { TableHead } from './TableHead';
import { mediaQMedium } from '../../../utils/responsivity';

const styles = {
    root: css`
        display: flex;
        flex-direction: column;
        background: #fff;
        color: #212529;
        overflow-x: auto;
        min-height: 400px;
    `,
    table: css`
        display: table;
        width: 100%;
        @media ${mediaQMedium} {
            display: block;
        }
    `,
    tableBody: css`
        display: table-row-group;
        overflow: auto;
        max-height: 200px;
        @media ${mediaQMedium} {
            display: block;
            max-height: initial;
        }
    `,

    empty: css`
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        color: #666;
    `,
};

export interface TableDataStructure {
    text: string;
    name?: string;
    sortable?: boolean;
    filterType?: 'full' | 'enum';
    filterOptions?: {[key: string]: string};
    filterPlaceholder?: string;
    columnClassName?: string;
    size?: number;
    defaultSort?: -1 | 1;
    mobileBlock?: boolean;
}

export interface TableFilter {
    sortName?: string;
    sortOrder?: -1 | 1;
    filters: {[name: string]: string | number};
    currentPage: number;
}

export type TableData = (React.ReactChild[] | {[name: string]: React.ReactChild});

export interface TableProps {
    className?: string;
    columns: TableDataStructure[];
    data: TableData[];
    onFilterChange?: (filter: TableFilter) => void;
    filter?: TableFilter;
    pagesCount?: number;
    cellsClassName?: (index: number, odd: boolean) => string;
    itemsCount?: number;
}

export class Table extends React.Component<TableProps> {
    public render() {
        const sizeCount = this.props.columns.reduce((result, col) => result + (isNumber(col.size) ? col.size : 1), 0);
        return (
            <div className={cx(styles.root, this.props.className)}>
                <div className={styles.table}>
                    <TableHead
                        filter={this.props.filter}
                        columns={this.props.columns}
                        onFilterChange={this.onTableHeadFilterChange}
                    />
                    <div className={styles.tableBody}>
                        {this.props.data.map((row, index) => (
                            <TableRow
                                cols={this.props.columns.map((i) => ({
                                    columnClassName: i.columnClassName,
                                    size: isNumber(i.size) ? i.size : 1,
                                    name: i.name,
                                    text: i.text,
                                    mobileBlock: i.mobileBlock,
                                }))}
                                key={`table-row-${index}`}
                                data={row}
                                odd={index % 2 !== 0}
                                sizeCount={sizeCount}
                                cellsClassName={this.props.cellsClassName ? this.props.cellsClassName(index, index % 2 !== 0) : null}
                            />
                        ))}
                    </div>
                </div>
                {(!this.props.data || !this.props.data.length) ? (
                    <div className={styles.empty}>
                        <span>There is no data</span>
                    </div>
                ) : null}
                {this.props.pagesCount > 1 ? (
                    <TablePaginator
                        current={this.props.filter.currentPage ? this.props.filter.currentPage : 0}
                        count={this.props.pagesCount}
                        onFilterChange={this.onFilterChange}
                        itemsCount={this.props.itemsCount}
                    />
                ) : null}
            </div>
        );
    }

    protected onTableHeadFilterChange = (filter: Partial<TableFilter>) => {
        this.onFilterChange({
            ...filter,
            currentPage: 0,
        });
    }

    protected onFilterChange = (filter: Partial<TableFilter>) => {
        if (this.props.onFilterChange) {
            this.props.onFilterChange({
                ...this.props.filter,
                ...filter,
            });
        }
    }
}
