import * as React from 'react';
import { Modal } from './Modal';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ButtonColors } from '../ux/Button';

export interface ModalConfirmProps {
    title?: string;
    text?: string;
    active: boolean;
    onClose: () => void;
    onConfirm: () => void;
    confirmText: string;
    confirmColor?: ButtonColors;
    confirmIcon?: IconProp;
}

/**************** Component ***************/
export class ModalConfirm extends React.Component<ModalConfirmProps> {
    public render() {
        return (
            <Modal
                active={this.props.active}
                size={'small'}
                buttons={[{
                    text: this.props.confirmText,
                    color: (this.props.confirmColor ? this.props.confirmColor : 'green'),
                    icon: (this.props.confirmIcon ? (this.props.confirmIcon as any) : faCheck),
                    action: this.props.onConfirm
                }, {
                    text: 'Cancel',
                    color: 'gray',
                    icon: faTimes,
                    action: this.props.onClose
                }]}
            >
                {this.props.title ? <h1>{this.props.title}</h1> : null}
                {this.props.text ? <p>{this.props.text}</p> : null}
                {this.props.children}
            </Modal>
        );
    }
}

