import * as React from 'react';
import { css, cx } from 'emotion';
import { mediaQMedium } from '../../../utils/responsivity';
import * as DayPicker from 'react-day-picker';
import { isObject } from '../../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

// nasty hack to fix typings of this component
const DayPickerComponent = DayPicker as any
const DateUtils = DayPicker.DateUtils

const styles = {
    cell: css`
        display: table-cell;
        line-height: 20px;
        vertical-align: middle;
        @media ${mediaQMedium} {
            display: block;
        }
    `,
    filter: css`
        padding: 8px;
        background: #eff2f6;
        height: 30px;
        position: relative;
    `,
    filterRow: css`
        padding: 8px;
        background: #eff2f6;
        height: 30px;
        position: relative;
        display: flex;
        flex-direction: row;
    `,
    input: css`
        border: 1px solid rgba(0,0,0,0.1);
        background: #fff;
        padding: 5px 7px;
        font-size: inherit;
        border-radius: 3px;
        font-weight: normal;
        outline-width: 0;
        width: calc(100% - 20px);
        min-width: 130px;
        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #909190;
            opacity: 1;
        }
    `,
    select: css`
        border: 1px solid rgba(0,0,0,0.1);
        background: #fff;
        padding: 5px 7px;
        font-size: inherit;
        border-radius: 3px;
        font-weight: normal;
        outline-width: 0;
        width: 100%;
        height: 30px;
        min-width: 130px;
        &.placeholder {
            color: #909190;
        }
    `,

    dateInputContainer: css`
        position: absolute;
        left: 10px;
        top: 100%;
        background: #fff;
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
        z-index: 20;
        border-radius: 3px;

        .DayPicker-Day {
            line-height: 1em;
        }
    `,
    dateInputOverlay: css`
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.2);
        z-index: 19;
    `,
    dateRangeDisplay: css`
        display: flex;
        flex-direction: row;
        padding-left: 5px;
        align-items: center;
        cursor: pointer;
    `,
    dateRangeItem: css`
        font-size: 14px;
        white-space: nowrap;
    `,
    dateRangeIcon: css`
        margin: 2px 5px 0px 5px;
    `,
    dateRangeButton: css`
        margin: 2px 5px 0px 5px;
        cursor: pointer;
    `,

    mobileHidden: css`
        @media ${mediaQMedium} {
            display: none;
        }
    `,

    dayPicker: css`
        .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
            background-color: #f0f8ff !important;
            color: #4a90e2;
        }
        .DayPicker-Day {
            border-radius: 0 !important;
        }
        .DayPicker-Day--start {
            border-top-left-radius: 50% !important;
            border-bottom-left-radius: 50% !important;
        }
        .DayPicker-Day--end {
            border-top-right-radius: 50% !important;
            border-bottom-right-radius: 50% !important;
        }
    `,
};

export interface TableFilterCellProps {
    filterType?: 'full' | 'enum' | 'date';
    filterPlaceholder?: string;
    filterOptions?: {[key: string]: string};
    filterValue?: string | number | object;
    onFilterChange: (value: string | object) => void;
}

export class TableFilterCell extends React.Component<TableFilterCellProps> {
    public readonly state = {
        dateOpened: false,
    }

    public render() {
        let currentDateRange = null;
        if (this.props.filterType === 'date') {
            currentDateRange = this.getFilterDateCurrentValue()
        }

        const filterValue = this.props.filterValue as string;

        return (
            <div className={styles.cell}>
                {this.props.filterType === 'full' ? (
                    <div className={styles.filter}>
                        <input
                            placeholder={this.props.filterPlaceholder}
                            className={styles.input}
                            type={'text'}
                            onChange={this.onFilterType}
                            value={filterValue ? filterValue : ''}
                        />
                    </div>
                ) : this.props.filterType === 'enum' ? (
                    <div className={styles.filter}>
                        <select
                            className={cx(styles.select, {['placeholder']: !filterValue})}
                            onChange={this.onSelectChoose}
                            value={filterValue}
                        >
                            <option key={`empty-option`} value={''}>{this.props.filterPlaceholder}</option>
                            {Object.keys(this.props.filterOptions).map((key) => (
                                <option key={`option-${key}`} value={key}>{this.props.filterOptions[key]}</option>
                            ))}
                        </select>
                    </div>
                ) : this.props.filterType === 'date' ? (
                    <div className={styles.filterRow}>
                        <div className={styles.dateRangeDisplay} onClick={this.toggleDateRange}>
                            <div className={styles.dateRangeItem}>{currentDateRange.from ? `${currentDateRange.from.toLocaleDateString()}` : ''}</div>
                            <div className={styles.dateRangeIcon}><FontAwesomeIcon icon={faArrowRight} /></div>
                            <div className={styles.dateRangeItem}>{currentDateRange.to ? `${currentDateRange.to.toLocaleDateString()}` : 'now'}</div>
                            {(currentDateRange.from || currentDateRange.to) ? <div className={styles.dateRangeButton} onClick={this.onFilterDateReset}><FontAwesomeIcon icon={faTimesCircle} /></div> : null}
                        </div>
                        {this.state.dateOpened ? (
                        <>
                            <div className={styles.dateInputOverlay} onClick={this.toggleDateRange}></div>
                            <div className={styles.dateInputContainer}>
                                <DayPickerComponent
                                    className={styles.dayPicker}
                                    modifiers={{start: currentDateRange.from, end: currentDateRange.to}}
                                    selectedDays={[currentDateRange.from, { from: currentDateRange.from, to: currentDateRange.to }]}
                                    onDayClick={this.onFilterDateChange}
                                />
                            </div>
                        </>
                        ) : null}
                    </div>
                ) : <div className={cx(styles.filter, styles.mobileHidden)}>&nbsp;</div>}
            </div>
        );
    }

    protected onSelectChoose = (e) => {
        this.props.onFilterChange(e.target.value);
    }

    protected onFilterType = (e) => {
        this.props.onFilterChange(e.target.value);
    }

    protected toggleDateRange = () => {
        this.setState({
            dateOpened: !this.state.dateOpened,
        })
    }

    protected getFilterDateCurrentValue = () => {
        if (isObject<{from: any; to: any;}>(this.props.filterValue)) {
            return {
                from: this.props.filterValue?.from ? new Date(this.props.filterValue.from) : null,
                to: this.props.filterValue?.to ? new Date(this.props.filterValue.to) : null,
            };
        } else {
            return {
                from: null,
                to: null,
            };
        }
    }

    protected onFilterDateChange = (day: Date) => {
        const range = DateUtils.addDayToRange(day, this.getFilterDateCurrentValue());
        if (range.from) {
            range.from.setHours(0, 0, 0, 0);
        }
        if (range.to) {
            range.to.setHours(23, 59, 59, 999);
        }

        this.props.onFilterChange({
            from: range.from ? range.from.toISOString() : null,
            to: range.to ? range.to.toISOString() : null,
        })
    }

    protected onFilterDateReset = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onFilterChange(null);
    }
}
