import * as React from 'react';
import { css, cx } from 'emotion';
import { Header } from '../content/Header';
import { mediaQSmall } from '../../utils/responsivity';

const style = {
    rootContainer: css`
        display: flex;
        padding: 0;
    `,
    contentWraper: css`
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        background: #eff2f6;
        width: calc(100% - 270px);

        @media ${mediaQSmall} {
            position: relative;
            transition: left 0.2s linear;
            left: 0;
        }
    `,
    content: css`
        padding: 20px;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        background: #eff2f6;
        flex-shrink: 1;
        min-height: calc(100vh - 100px);
        @media ${mediaQSmall} {
            min-height: calc(100vh - 100px);
            padding-top: 80px;
        }
    `,
};

/**************** Component ***************/
export class LayoutMain extends React.Component<{menuComponent: () => React.ReactElement}> {
    /**
     * Render
     */
    public render() {
        return (
            <>
                <div className={cx(style.rootContainer)}>
                    {this.props.menuComponent()}
                    <div className={cx(style.contentWraper)}>
                        <Header />
                        <div className={cx(style.content)}>
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
