import * as React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { css, cx } from 'emotion';

export type ButtonColors = 'red' | 'green' | 'yellow' | 'gray';
export type ButtonSize = 'normal' | 'small';

const styles = {
    button: css`
        display: flex;
        flex-direction: row;
        font-weight: 400;
        text-align: center;
        vertical-align: middle;
        user-select: none;
        border: 1px solid #2f69b2;
        padding: 8px 12px;
        font-size: 16px;
        line-height: 1.5;
        border-radius: 4px;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        color: #fff;
        background-color: #2f69b2;
        white-space: nowrap;
        cursor: pointer;
        &.red {
            color: #fff;
            background-color: #dc3545;
            border-color: #dc3545;
        }
        &.green {
            color: #fff;
            background-color: #28a745;
            border-color: #28a745;
        }
        &.yellow {
            color: #212529;
            background-color: #ffc107;
            border-color: #ffc107;
        }
        &.gray {
            color: #fff;
            background-color: #6c757d;
            border-color: #6c757d;
        }
        &.small {
            font-size: 12px;
            line-height: 1em;
        }
    `,
    icon: css`
        margin-right: 0.4em;
        display: inline-block;
        &.alone {
            margin-right: 0;
        }
    `,
};

export interface ComponentProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string;
    buttonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
    icon?: IconProp;
    iconClassName?: string;
    color?: ButtonColors;
    size?: ButtonSize;
}

/**************** Component ***************/
export class Button extends React.Component<ComponentProps> {
    public render() {
        return (
            <button
                {...this.props.buttonProps ? this.props.buttonProps : null}
                {...this.props}
                className={cx(
                    styles.button,
                    this.props.className,
                    this.props.color,
                    this.props.size,
                )}
            >
                {this.props.icon ? (
                    <span className={cx(
                        styles.icon,
                        this.props.iconClassName,
                        {['alone']: !React.Children.count(this.props.children)}
                    )}>
                        <FontAwesomeIcon icon={this.props.icon} /></span>
                    ) : null}
                {this.props.children}
            </button>
        );
    }
}

