import * as React from 'react';
import { css, cx } from 'emotion';
import { PropsRouteMatch, safe, isObject, isNumber } from '../../utils';
import { Badge } from '../ux/Badge';
import { JSONView } from '../ux/JSONView';
import { renderErrors } from '../utils/DataErrors';
import { RequestEntityCallStatus } from '../../api/structure';
import { EntityIndex } from '../../api/Endpoints';

const style = {
    itemWraper: css`
        background: #efefef;
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 20px;
    `,
    keyValueTable: css`
        display: table;
    `,
    keyValueRow: css`
        display: table-row;
        &.red {
            color: #dc3545;
        }
    `,
    keyValueCell: css`
        display: table-cell;
        padding: 2px 5px;
    `,
    keyValueError: css`
        color: #dc3545;
        word-break: break-all;
    `,
};

export class RequestCallStatuses extends React.Component<{
    callStatuses: {[clientConnectorId: string]: RequestEntityCallStatus;};
    clientIndex: EntityIndex;
} & PropsRouteMatch<{id: string}>> {

    public render() {
        return (
            <>
                {Object.keys(this.props.callStatuses || {}).map((key, index) => {
                    const status = this.props.callStatuses[key];
                    return (
                        <div className={style.itemWraper} key={`status-item-${index}-${key}`}>
                            <div className={style.keyValueTable}>
                                <div className={style.keyValueRow}>
                                    <div className={style.keyValueCell}><strong>Client</strong></div>
                                    <div className={style.keyValueCell}>{safe(() => this.props.clientIndex[status.clientId], 'N/A')}</div>
                                </div>
                                <div className={style.keyValueRow}>
                                    <div className={style.keyValueCell}><strong>Duration</strong></div>
                                    <div className={style.keyValueCell}>{safe(() => (new Date(status.timestampEnd).getTime() - new Date(status.timestampStart).getTime()) + 'ms', 'N/A')}</div>
                                </div>
                                {isNumber(status.dataIndex) ? (
                                    <div className={style.keyValueRow}>
                                        <div className={style.keyValueCell}><strong>Position in data</strong></div>
                                        <div className={style.keyValueCell}>{status.dataIndex}</div>
                                    </div>
                                ) : null}
                                <div className={style.keyValueRow}>
                                    <div className={style.keyValueCell}><strong>Status</strong></div>
                                    <div className={style.keyValueCell}>{
                                        status.status === 'success' ?
                                            <Badge color={'green'}>Success</Badge>
                                        : status.status === 'error' ?
                                            <Badge color={'red'}>Error</Badge>
                                        : status.status === 'not-found' ?
                                            <Badge color={'yellow'}>Not found</Badge>
                                        : <Badge color={'gray'}>{status.status}</Badge>
                                    }</div>
                                </div>
                                {status.errors ? (<div className={style.keyValueRow}>
                                    <div className={style.keyValueCell}><strong>Errors</strong></div>
                                    <div className={cx(style.keyValueCell, style.keyValueError)}>
                                        {renderErrors(status.errors)}
                                    </div>
                                </div>) : null}
                            </div>
                            {isObject(status.data) ? (
                                <JSONView data={status.data || {}} collapsed/>
                            ) : null}
                        </div>
                    );
                })}
            </>
        );
    }
}
