import * as React from 'react';
import { css, cx } from 'emotion';
import { faBan, faCheck, faTools, faTerminal, faWrench, faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import { mediaQLarge, mediaQMedium } from '../../utils/responsivity';
import { Badge } from '../ux/Badge';
import { ConnectorEntity, ConnectorStatusEntity, GetEntity, UserSessionType } from '../../api/structure';
import { IRootStore } from '../../state';
import { ApiActions, ApiCallOptions } from '../../state/api';
import { connect } from 'react-redux';
import { safePromise } from '../../utils';

const styles = {
    connectorStatusBox: css`
        display: flex;
        flex-direction: column;
        margin-right: 15px;
        flex-basis: 350px;
        margin-bottom: 20px;
        background: #efefef;
        &:last-child {
            margin-right: 0;
        }
        @media ${mediaQLarge} {
            flex-basis: calc(50% - 15px);
        }
        @media ${mediaQMedium} {
            flex-basis: 100%;
            margin-right: 0;
            max-width: initial;
        }
    `,
    connectorStatusHeader: css`
        font-size: 1.1em;
        border-bottom: 1px solid #aaa;
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;
        white-space: nowrap;
        padding-bottom: 4px;
        @media ${mediaQMedium} {
            flex-direction: column;
        }
    `,
    connectorStatusStatusesBoxWrapper: css`
        position: relative;
        &::before {
            content: "";
            position: absolute;
            height: 10px;
            top: 0;
            left: 0;
            right: 15px;
            background: linear-gradient(180deg, rgba(239,239,239,1) 0%, rgba(239,239,239,0) 100%);
            z-index: 1;
        }
        &::after {
            content: "";
            position: absolute;
            height: 10px;
            bottom: 0;
            left: 0;
            right: 15px;
            background: linear-gradient(0deg, rgba(239,239,239,1) 0%, rgba(239,239,239,0) 100%);
            z-index: 1;
        }
    `,
    connectorStatusStatusesBox: css`
        text-align: center;
        max-height: 335px;
        overflow-y: auto;
    `,
    connectorStatusReason: css`
        color: gray;
        font-size: 11px;
        font-weight: 300;
        word-break: break-all;
    `,
    connectorStatusItem: css`
        margin-bottom: 5px;
        display: flex;
        flex-direction: row;
    `,
    connectorStatusTime: css`
        font-weight: 300;
        margin-top: 10px;
        margin-bottom: 10px;
        position: relative;
        border: 1px #ccc solid;
        display: inline-block;
        padding: 2px 8px;
        border-radius: .25rem;
        &::before {
            content: "";
            position: absolute;
            height: 8px;
            width: 2px;
            background-color: #ccc;
            left: 50%;
            margin-left: -1px;
            top: -8px;
        }
        &::after {
            content: "";
            position: absolute;
            height: 8px;
            width: 2px;
            background-color: #ccc;
            left: 50%;
            margin-left: -1px;
            bottom: -8px;
        }
    `,
    connectorStatusTimeNoTop: css`
        &::before {
            display: none;
        }
    `,
    connectorTypes: css``,
    connectorTypesItem: css`
        margin-left: 5px;
        @media ${mediaQMedium} {
            margin-left: 0;
            margin-right: 5px;
        }
    `,
    connectorNoStatuses: css`
        padding: 20px;
        text-align: center;
        font-size: 1.1em;
    `,

    connectorStatusBan: css`
        font-weight: 300;
        margin-top: -7px;
        margin-bottom: 10px;
        position: relative;
        display: inline-block;
        font-size: 1.5em;
        &::after {
            content: "";
            position: absolute;
            height: 8px;
            width: 2px;
            background-color: #ccc;
            left: 50%;
            margin-left: -1px;
            bottom: -8px;
        }
        &::before {
            content: "";
            position: absolute;
            height: 8px;
            width: 2px;
            background-color: #ccc;
            left: 50%;
            margin-left: -1px;
            top: -2px;
        }
    `,
};

const icons = {
    maintenance: faTools,
    online: faCheck,
    offline: faBan,
};

const names = {
    maintenance: 'Maintenance',
    online: 'Online',
    offline: 'Offline',
};

const colors = {
    maintenance: 'yellow',
    online: 'green',
    offline: 'red',
};

/**
 * Contector status
 */
class ConnectorStatusComponent extends React.Component<{
    getClientConnectorStatus: (opt: ApiCallOptions, clientId: string, connectorId: string) => Promise<void>;
    getClientConnectorIsBanned: (opt: ApiCallOptions, clientId: string, connectorId: string) => Promise<void>;
    sessionType: UserSessionType;
    clientConnectorsStatuses: {[id: string]: ConnectorStatusEntity[]};
    clientConnectorsBans: {[id: string]: boolean; };
    connector: GetEntity<ConnectorEntity>;
}> {
    protected statusInterval = null;

    public componentDidMount() {
        this.getStatuses();
        this.statusInterval = setInterval(() => this.getStatuses(true), 5000);
    }

    public componentWillUnmount() {
        clearInterval(this.statusInterval);
        this.statusInterval = null;
    }

    public render() {
        const isAdmin = this.props.sessionType === 'admin';
        const statuses = this.props.clientConnectorsStatuses && this.props.clientConnectorsStatuses[this.props.connector._id] ? this.props.clientConnectorsStatuses[this.props.connector._id] : [];
        const data = statuses.map((status) => ({
            time: new Date(status.timestamp),
            status: status.status,
            reason: status.reason,
        }));
        const isBanned = !!(this.props.clientConnectorsBans && this.props.clientConnectorsBans[this.props.connector._id]);

        const fetchColor = this.props.connector.interfaceMetadata?.fetchInterface?.enabled ? (this.props.connector.fetchInterface ? 'green' : 'red') : 'yellow';
        const commandColor = this.props.connector.interfaceMetadata?.commandInterface?.enabled ? (this.props.connector.commandInterface ? 'green' : 'red') : 'yellow';
        const serviceColor = this.props.connector.interfaceMetadata?.serviceInterface?.enabled ? (this.props.connector.serviceInterface ? 'green' : 'red') : 'yellow';

        return (
            <div className={styles.connectorStatusBox}>
                <div className={styles.connectorStatusHeader}>
                    <div>{this.props.connector.name}</div>
                    <div className={styles.connectorTypes}>
                        {this.props.connector.interfaceMetadata?.fetchInterface?.enabled || isAdmin ? <Badge icon={faCloudUploadAlt} className={styles.connectorTypesItem} color={fetchColor}>Fetch</Badge> : null}
                        {this.props.connector.interfaceMetadata?.commandInterface?.enabled || isAdmin ? <Badge icon={faTerminal} className={styles.connectorTypesItem} color={commandColor}>Command</Badge> : null}
                        {this.props.connector.interfaceMetadata?.serviceInterface?.enabled || isAdmin ? <Badge icon={faWrench} className={styles.connectorTypesItem} color={serviceColor}>Service</Badge> : null}
                    </div>
                </div>
                <div className={styles.connectorStatusStatusesBoxWrapper}>
                    <div className={styles.connectorStatusStatusesBox}>

                        {!data.length ? (
                            <div className={styles.connectorNoStatuses}>No status was reported</div>
                        ) : (
                            <div className={cx(styles.connectorStatusTime, styles.connectorStatusTimeNoTop)}> Now </div>
                        )}

                        {isBanned ? <div>
                            <div className={cx(styles.connectorStatusBan)}>
                                <Badge infill size="large" icon={faBan} color="red">Connector is banned</Badge>
                            </div>
                        </div> : null}

                        {data.map((item, index) => (
                            <React.Fragment key={`status-item-${index}`}>
                                <div>
                                    <Badge icon={icons[item.status]} color={colors[item.status] as any}>{names[item.status]}</Badge>
                                    <div className={styles.connectorStatusReason}>{item.reason}</div>
                                </div>
                                <div className={styles.connectorStatusTime}>
                                    {item.time.toLocaleString()}
                                </div>
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    protected getStatuses = (silent?: boolean) => {
        safePromise(this.props.getClientConnectorStatus({silent}, this.props.connector.clientId, this.props.connector._id));
        safePromise(this.props.getClientConnectorIsBanned({silent}, this.props.connector.clientId, this.props.connector._id));
    }
}

export const ConnectorStatus = connect(
    (state: IRootStore) => ({
        sessionType: state.api.sessionUser?.type,
        clientConnectorsStatuses: state.api.clientConnectorsStatuses,
        clientConnectorsBans: state.api.clientConnectorsBans,
    }),
    (dispatch) => ({
        getClientConnectorStatus: (opt: ApiCallOptions, clientId: string, connectorId: string) => dispatch(ApiActions.getClientConnectorStatus(opt, clientId, connectorId, 20)),
        getClientConnectorIsBanned: (opt: ApiCallOptions, clientId: string, connectorId: string) => dispatch(ApiActions.getClientConnectorIsBanned(opt, clientId, connectorId)),
    }),
)(ConnectorStatusComponent);