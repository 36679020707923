import * as React from 'react';
import { css, cx } from 'emotion';
import { SectionHeader } from '../components/ux/SectionHeader';
import { connect } from 'react-redux';
import { ApiInitialState, ApiActions, ApiCallOptions } from '../state/api';
import { IRootStore } from '../state';
import { EntityListView } from '../components/content/EntityListView';
import { EntityIndex, TransactionsFilter, EntityListFilterQuery, InvoicingFilter } from '../api/Endpoints';
import { Form } from '../components/ux/forms/Form';
import { FormSelect } from '../components/ux/forms/FormSelect';
import { FormInput } from '../components/ux/forms/FormInput';
import { FormDate } from '../components/ux/forms/FormDate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFunnelDollar, faLongArrowAltDown, faLongArrowAltUp, faWrench, faPlus, faMinus, faTimes, faMinusCircle, faPlusCircle, faSearch } from '@fortawesome/free-solid-svg-icons';
import { CreditTransactionEntity, CreditBalance, UserSessionType, CreditInvoicing } from '../api/structure';
import { Token } from '../components/ux/Token';
import { formatBigNumber, isNumber, JsonValidatorRequired, JsonValidatorType, safePromise } from '../utils';
import { ContentBox } from '../components/layouts/ContentBox';
import { Table } from '../components/ux/table/Table';
import { Button } from '../components/ux/Button';
import { mediaQSmall, mediaQMedium, mediaQLarge } from '../utils/responsivity';
import { Modal } from '../components/modals/Modal';
import { FormTextarea } from '../components/ux/forms/FormTextarea';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { AppActions, NotificationType } from '../state/app';
import { dateToTimezone } from '../utils/date';

const style = {
    filter: css`
        margin-bottom: 30px;
        padding: 20px;
        background: #f1f1f1;
        position: relative;
    `,
    filterBackicon: css`
        position: absolute;
        right: 20px;
        bottom: 0;
        font-size: 120px;
        color: #e6e6e6;
        z-index: 0;
    `,
    filterContent: css`
        position: relative;
        z-index: 1;
    `,
    filterRow: css`
        display: flex;
        margin-bottom: 15px;
        @media ${mediaQMedium} {
            flex-direction: column;
        }
    `,
    filterInput: css`
        width: 200px;
        margin-right: 10px;
        @media ${mediaQMedium} {
            margin-right: 0;
            margin-bottom: 10px;
        }
    `,
    label: css`
        border-bottom: 1px solid #ddd;
        margin-bottom: 15px;
        display: block;
        font-size: 1.1em;
    `,
    iconAmountDown: css`
        color: #dc3545;
        margin-right: 5px;
    `,
    iconAmountUp: css`
        color: #28a745;
        margin-right: 5px;
    `,
    amountWrap: css`
        white-space: nowrap;
    `,
    amount: css`
        white-space: nowrap;
        font-size: 14px;
    `,
    icon: css`
        margin-right: 5px;
    `,
    cellRow: css`
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-end;
    `,
    cellRowItem: css`
        margin-left: 8px;
        white-space: nowrap;
        @media ${mediaQSmall} {
            margin-left: 0px;
            margin-right: 8px;
        }
    `,
    message: css`
        font-size: 13px;
    `,
    transactionMessage: css`
        margin-top: 20px;
    `,

    statisticsBox: css`
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        padding: 20px;
        padding-right: 80px;
        margin-right: 15px;
        position: relative;
        margin-bottom: 15px;
        &:last-child {
            margin-right: 0;
        }
        &.red {
            color: #fff;
            background-color: #dc3545;
            border-color: #dc3545;
        }
        &.green {
            color: #fff;
            background-color: #28a745;
            border-color: #28a745;
        }
        &.yellow {
            color: #212529;
            background-color: #ffc107;
            border-color: #ffc107;
        }
        &.gray {
            color: #fff;
            background-color: #6c757d;
            border-color: #6c757d;
        }
    `,
    statisticsContent: css`
        display: flex;
        align-items: center;
    `,
    statisticsBoxIcon: css`
        font-size: 60px;
        margin-right: 10px;
        position: absolute;
        right: 10px;
        top: 50%;
        margin-top: -45px;
        color: #000;
        opacity: 0.2;
    `,
    statisticsBoxName: css`
        text-align: center;
        margin-right: 20px;
    `,
    statisticsBoxValue: css`
        text-align: center;
        font-size: 25px;
        font-weight: bold;
        white-space: nowrap;
    `,
    labelSelectFilter: css`
        text-align: center;
        font-size: 20px;
        color: #666;
        padding: 40px 0;
    `,
    actions: css`
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-end;
        @media ${mediaQSmall} {
            flex-wrap: wrap;
        }
    `,
    actionsButton: css`
        margin-left: 8px;
        @media ${mediaQSmall} {
            margin-left: 0px;
            margin-right: 8px;
            margin-bottom: 5px;
        }
    `,
};

/**
 * Statistics box
 * @param props
 */
export function StatisticsBox(props: {
    color: string;
    icon?: IconProp;
    name: string;
    value: number | string;
}) {
    return (
        <div className={cx(style.statisticsBox, props.color)}>
            {props.icon ? <div className={style.statisticsBoxIcon}>
                <FontAwesomeIcon icon={props.icon} />
            </div> : null}
            <div className={style.statisticsContent}>
                <div className={style.statisticsBoxName}>{props.name}</div>
                <div className={style.statisticsBoxValue}>{props.value}</div>
            </div>
        </div>
    );
}

const TransactionsList = connect(
    (state: {api: ApiInitialState}) => ({
        data: state.api.transactions,
    }),
    (dispatch) => ({
        loadData: (opt: ApiCallOptions, filter: TransactionsFilter) => dispatch(ApiActions.listTransactions(opt, filter))
    }),
)(EntityListView);

const ownWallets = {
    request_revenue: 'SYSTEM: Request revenue',
    request_rest: 'SYSTEM: Request rest',
};

/**
 * Page
 */
class CreditsComponent extends React.Component<{
    sessionType: UserSessionType;
    sessionClientId: string;
    clientIndex: EntityIndex<string>;
    nodeIndex: EntityIndex<string>;
    balance: CreditBalance;
    invoicing: CreditInvoicing;
    timezone: {offset: number};
    getClientsIndex: (opt: ApiCallOptions) => Promise<void>;
    getNodesIndex: (opt: ApiCallOptions) => Promise<void>;
    getCreditBalance: (opt: ApiCallOptions) => Promise<void>;
    putCreditDeposit: (opt: ApiCallOptions, clientId: string, amount: number, message?: string) => Promise<void>;
    putCreditWithdraw: (opt: ApiCallOptions, clientId: string, amount: number, message?: string) => Promise<void>;
    pushNotification: (text: string, type: NotificationType) => void;
    getCreditInvoicing: (opt: ApiCallOptions, filter: InvoicingFilter) => Promise<void>;
}, {
    filter: any;
    invoicingFilter: any;
    tab: string;
    newTransactionActive: boolean;
    showInvoicingDetails: string;
    newTransaction: {
        clientId: string;
        direction: 'withdraw' | 'deposit';
    };
}> {
    protected checkInterval = null;
    protected invoicingLoadTimeout = null;

    constructor(props) {
        super(props);
        this.state = {
            filter: {},
            invoicingFilter: {},
            tab: null,
            newTransactionActive: false,
            showInvoicingDetails: null,
            newTransaction: null,
        };
    }

    public componentDidMount() {
        const isAdmin = this.props.sessionType === 'admin';
        if (isAdmin) {
            safePromise(this.props.getClientsIndex({}));
            safePromise(this.props.getNodesIndex({}));
        }
        this.loadData();
        this.checkInterval = setInterval(() => this.loadData(true), 10000);
    }

    public componentWillUnmount() {
        clearInterval(this.checkInterval);
        this.checkInterval = null;
        if (this.invoicingLoadTimeout) {
            clearTimeout(this.invoicingLoadTimeout);
        }
    }

    public render() {
        const isAdmin = this.props.sessionType === 'admin';
        const isSelfcare = this.props.sessionType === 'selfcare';

        const tabs = {
            invoicing: 'Invoicing',
            credits: 'Credits',
        }
        const currentTab = this.state.tab ? this.state.tab : Object.keys(tabs)[0];

        const balance = isAdmin ? this.getAdminBalance() : this.getSelfcareBalance();
        const invoicing = isAdmin ? this.getAdminInvoicings() : [];

        return (
            <>
                {isAdmin ? (
                    <>
                        <SectionHeader
                            tabs={tabs}
                            currentTab={currentTab}
                            onTabClick={this.onTabClick}
                        >
                            Balances
                        </SectionHeader>
                        {currentTab === 'credits' ? (
                            <ContentBox>
                                <Table
                                    columns={[
                                        {
                                            text: '',
                                            name: 'name',
                                            size: 1,
                                            mobileBlock: true,
                                        },
                                        {
                                            text: '',
                                            name: 'balance',
                                            size: 0,
                                            mobileBlock: true,
                                        },
                                        {
                                            text: '',
                                            name: 'actions',
                                            size: 0,
                                            mobileBlock: true,
                                        }
                                    ]}
                                    data={balance}
                                />
                            </ContentBox>
                        ) : currentTab === 'invoicing' ? (
                            <ContentBox>
                                <Form
                                    data={{
                                        timezoneOffset: (this.state.invoicingFilter.timezoneOffset ? this.state.invoicingFilter.timezoneOffset : this.props.timezone?.offset) || 0,
                                        ...this.state.invoicingFilter
                                    }}
                                    onChange={this.onInvoicingFilterChange}
                                >
                                    <label className={style.label}>Filter by date</label>
                                    <div className={style.filterRow}>
                                        <FormInput className={style.filterInput} type={'number'} label={'Timezone offset'} name={'timezoneOffset'} />
                                        <FormDate className={style.filterInput} label={'From'} name={'from'} />
                                        <FormDate className={style.filterInput} label={'To'} name={'to'} />
                                    </div>
                                    {this.state.invoicingFilter.from && this.state.invoicingFilter.to ? (
                                        <Table
                                            columns={[
                                                {
                                                    text: 'Name',
                                                    name: 'name',
                                                    size: 1,
                                                    mobileBlock: true,
                                                },
                                                {
                                                    text: 'Data fee',
                                                    name: 'dataFee',
                                                    size: 0,
                                                },
                                                {
                                                    text: 'Request fee',
                                                    name: 'requestFee',
                                                    size: 0,
                                                },
                                                {
                                                    text: 'Data revenue',
                                                    name: 'dataRevenue',
                                                    size: 0,
                                                },
                                                {
                                                    text: '',
                                                    name: 'actions',
                                                    size: 0,
                                                    mobileBlock: true,
                                                },
                                            ]}
                                            data={invoicing}
                                        />
                                    ) : (
                                        <label className={style.labelSelectFilter}>Select filter to see invoicings</label>
                                    )}
                                </Form>
                            </ContentBox>
                        ) : null}
                    </>
                ) : (
                    <>
                        {balance.map((value, index) => (
                            <div key={`balance-view-${index}`}>
                                <StatisticsBox
                                    color={value < 0 ? 'red' : 'green'}
                                    name="Current account balance"
                                    icon={value < 0 ? faMinus : value > 0 ? faPlus : null}
                                    value={(value > 0 ? '+' : '') + formatBigNumber(value)}
                                />
                            </div>
                        ))}
                    </>
                )}

                <SectionHeader>Transactions</SectionHeader>
                <TransactionsList
                    mainList
                    reloadInterval={10000}
                    columns={[
                        ...(isAdmin ? [{
                            text: 'Owner',
                            name: 'owner',
                        }] : []),
                        {
                            text: 'Time',
                            name: 'timestamp',
                        },
                        {
                            text: 'Amount',
                            name: 'amount',
                        },
                        {
                            text: 'Message',
                            name: 'message',
                            size: 2,
                        },
                        ...(isAdmin ? [{
                            text: 'Source client',
                            name: 'sourceClientId',
                        }] : []),
                        ...(isAdmin ? [{
                            text: 'Source node',
                            name: 'sourceNodeId',
                        }] : []),
                        {
                            text: 'Request token',
                            name: 'sourceRequestToken',
                        },
                    ]}
                    renderer={{
                        owner: (item: CreditTransactionEntity) => (
                            <span>
                                {(item.owner as any).clientId ? (
                                    (this.props.clientIndex || {})[(item.owner as any).clientId]
                                ) : (
                                    <>
                                        <FontAwesomeIcon className={style.icon} icon={faWrench} />
                                        <strong>{ownWallets[(item.owner as any).creditAccountType]}</strong>
                                    </>
                                )}
                            </span>
                        ),
                        sourceRequestToken: (item: CreditTransactionEntity) => (
                            <span>
                                <Token token={item.sourceRequestToken} />
                            </span>
                        ),
                        message: (item: CreditTransactionEntity) => (
                            <span className={style.message}>
                                {item.message}
                            </span>
                        ),
                        sourceNodeId: (item: CreditTransactionEntity) => (
                            <span>
                                {item.sourceNodeId ? (this.props.nodeIndex || {})[item.sourceNodeId] : ''}
                            </span>
                        ),
                        sourceClientId: (item: CreditTransactionEntity) => (
                            <span>
                                {item.sourceClientId ? (this.props.clientIndex || {})[item.sourceClientId] : ''}
                            </span>
                        ),
                        timestamp: (item: CreditTransactionEntity) => (
                            <span>
                                {(new Date(item.timestamp)).toLocaleDateString()}
                            </span>
                        ),
                        amount: (item: CreditTransactionEntity) => this.renderAmount(item.amount),
                    }}
                    filters={this.state.filter}
                    transformFilters={this.transformFilter}
                >
                    <div className={style.filter}>
                        <div className={style.filterBackicon}>
                            <FontAwesomeIcon icon={faFunnelDollar} />
                        </div>
                        <div className={style.filterContent}>
                            <Form
                                data={this.state.filter}
                                onChange={this.onFilterChange}
                            >
                                {isAdmin ? (
                                    <>
                                        <label className={style.label}>Filter by owner</label>
                                        <div className={style.filterRow}>
                                            <FormSelect
                                                className={style.filterInput}
                                                canBeEmpty
                                                name={'owner'}
                                                label={'Owner'}
                                                options={{
                                                    ...ownWallets,
                                                    ...this.props.clientIndex,
                                                }}
                                            />
                                        </div>
                                    </>
                                ) : null}
                                <label className={style.label}>Filter by source</label>
                                <div className={style.filterRow}>
                                    {isAdmin ? <FormSelect className={style.filterInput} canBeEmpty name={'sourceClientId'} label={'Source client'} options={this.props.clientIndex || {}}/> : null}
                                    {isAdmin ? <FormSelect className={style.filterInput} canBeEmpty name={'sourceNodeId'} label={'Source node'} options={this.props.nodeIndex || {}}/> : null}
                                    <FormInput className={style.filterInput} type={'text'} name={'sourceRequestToken'} label={'Filter by request token'}/>
                                </div>

                                <label className={style.label}>Filter by date</label>
                                <div className={style.filterRow}>
                                    <FormDate className={style.filterInput} label={'From'} name={'timestamp.$gte'} />
                                    <FormDate className={style.filterInput} label={'To'} name={'timestamp.$lte'} />
                                </div>
                            </Form>
                        </div>
                    </div>
                </TransactionsList>

                <Form
                    action={this.onNewTransactionConfirm}
                    schema={{
                        amount: {
                            type: JsonValidatorType.Number,
                            min: 0,
                        },
                        message: {
                            required: JsonValidatorRequired.False,
                            type: JsonValidatorType.String,
                        },
                    }}
                >
                    <Modal
                        size={'auto'}
                        active={this.state.newTransactionActive}
                        buttons={[{
                            text: this.state.newTransaction?.direction === 'withdraw' ? 'Confirm withdraw' : 'Confirm deposit',
                            color: 'yellow',
                            icon: this.state.newTransaction?.direction === 'withdraw' ?  faMinus : faPlus,
                            isSubmit: true,
                        }, {
                            text: 'Cancel',
                            color: 'gray',
                            icon: faTimes,
                            action: this.onNewTransactionClose,
                        }]}
                    >
                        <label className={style.label}>
                            Set amount of credits to be {this.state.newTransaction?.direction === 'withdraw' ? 'withdrawn' : 'deposit'}
                        </label>
                        <FormInput resetOnUnmount name={'amount'} label={'Amount'} type={'float'} />
                        <FormTextarea resetOnUnmount className={style.transactionMessage} name={'message'} label={'Message'} />
                    </Modal>
                </Form>

                <Modal
                    size={'medium'}
                    active={!!this.state.showInvoicingDetails}
                    buttons={[{
                        text: 'Close',
                        color: 'gray',
                        action: this.onInvoicingDetailsClose,
                    }]}
                >
                    <label className={style.label}>
                        Invoicing details for <strong>{this.props.clientIndex ? this.props.clientIndex[this.state.showInvoicingDetails] : 'unknown'}</strong>
                    </label>
                    <Table
                        columns={[
                            {
                                text: 'Node name',
                                name: 'node',
                                size: 1,
                                mobileBlock: true,
                            },
                            {
                                text: 'Data fee',
                                name: 'dataFee',
                                size: 0,
                            },
                            {
                                text: 'Request fee',
                                name: 'requestFee',
                                size: 0,
                            },
                            {
                                text: 'Data revenue',
                                name: 'dataRevenue',
                                size: 0,
                            },
                        ]}
                        data={this.getInvoicingDetails(this.state.showInvoicingDetails)}
                    />
                </Modal>
            </>
        );
    }

    protected onTabClick = (name: string) => {
        this.setState({tab: name});
    }

    protected loadData = (silent?: boolean) => {
        safePromise(this.props.getCreditBalance({silent}));
    }

    protected onNewTransaction = async (clientId: string, direction: 'withdraw' | 'deposit') => {
        this.setState({
            newTransactionActive: true,
            newTransaction: {
                clientId,
                direction,
            },
        });
    }

    protected onNewTransactionConfirm = async (data) => {
        if (this.state.newTransaction.direction === 'withdraw') {
            await (this.props.putCreditWithdraw({}, this.state.newTransaction.clientId, data.amount, data.message))
                .catch((err) => {
                    this.props.pushNotification(err.message, 'red');
                });
        } else if (this.state.newTransaction.direction === 'deposit') {
            await (this.props.putCreditDeposit({}, this.state.newTransaction.clientId, data.amount, data.message))
                .catch((err) => {
                    this.props.pushNotification(err.message, 'red');
                });
        }
        this.setState({
            newTransactionActive: false,

            // nasty hack to reload data
            filter: {
                ...this.state.filter,
            },
        });
    }

    protected onNewTransactionClose = () => {
        this.setState({newTransactionActive: false});
    }

    protected onFilterChange = (filter) => {
        this.setState({filter});
    }

    protected onInvoicingFilterChange = (invoicingFilter) => {
        this.setState({invoicingFilter});
        if (this.invoicingLoadTimeout) {
            clearTimeout(this.invoicingLoadTimeout)
        }
        this.invoicingLoadTimeout = setTimeout(this.reloadInvoicing, 100);
    }

    protected reloadInvoicing = () => {
        if (this.state.invoicingFilter.from && this.state.invoicingFilter.to) {
            this.props.getCreditInvoicing({}, {
                from: dateToTimezone(this.state.invoicingFilter.from, this.state.invoicingFilter.timezoneOffset),
                to: dateToTimezone(this.state.invoicingFilter.to, this.state.invoicingFilter.timezoneOffset),
             });
        }
        this.invoicingLoadTimeout = null;
    }

    protected transformFilter = (filter: EntityListFilterQuery<CreditTransactionEntity>) => {
        const outFilter: any = {...this.state.filter};
        outFilter.timestamp = {
            $gte: outFilter.timestamp?.$gte ? (new Date(outFilter.timestamp.$gte)).toISOString() : undefined,
            $lte: outFilter.timestamp?.$lte ? (new Date(outFilter.timestamp.$lte)).toISOString() : undefined,
        };

        if (outFilter.owner) {
            if (ownWallets.hasOwnProperty(outFilter.owner)) {
                outFilter.owner = {
                    creditAccountType: outFilter.owner,
                };
            } else {
                outFilter.owner = {
                    clientId: outFilter.owner,
                };
            }
        }

        return outFilter;
    }

    /**
     * Render amount
     * @param amount
     */
    protected renderAmount = (amount: number, staticAmountIcon?: boolean) => {
        if (!isNumber(amount)) {
            return '0';
        }

        if (amount === 0) {
            return (
                <span className={style.amountWrap}>
                    <strong className={style.amount}>0</strong>
                </span>
            );
        }

        return (
            <span className={style.amountWrap}>
                {!staticAmountIcon ? (
                    <FontAwesomeIcon
                        className={amount < 0 ? style.iconAmountDown : style.iconAmountUp}
                        icon={amount < 0 ? faLongArrowAltDown : faLongArrowAltUp}
                    />
                ) : (
                    <FontAwesomeIcon
                        className={amount < 0 ? style.iconAmountDown : style.iconAmountUp}
                        icon={amount < 0 ? faMinusCircle : faPlusCircle}
                    />
                )}
                <strong className={style.amount}>{(amount > 0 ? '+' : '') + formatBigNumber(amount)}</strong>
            </span>
        );
    }

    /**
     * Get admin balance data items
     */
    protected getAdminBalance = () => {
        const balanceClientData = this.props.balance ? this.props.balance?.clients || {} : {};
        const balanceSystemData = this.props.balance ? this.props.balance?.system || {} : {};

        const balance = Object.keys(balanceClientData)
            .reduce((result, clientId) => {
                result.push({
                    name: <span>{(this.props.clientIndex || {})[clientId]}</span>,
                    balance: this.renderAmount(balanceClientData[clientId], true),
                    actions: (
                        <div className={style.cellRow}>
                            <Button
                                icon={faPlus}
                                size={'small'}
                                className={style.cellRowItem}
                                color={'yellow'}
                                onClick={this.onNewTransaction.bind(this, clientId, 'deposit')}
                            >
                                Deposit credits
                            </Button>
                            <Button
                                icon={faMinus}
                                size={'small'}
                                className={style.cellRowItem}
                                color={'yellow'}
                                onClick={this.onNewTransaction.bind(this, clientId, 'withdraw')}
                            >
                                Withdraw credits
                            </Button>
                        </div>
                    )
                });
                return result;
            }, [])
            .concat([
                {
                    name: (<>
                        <FontAwesomeIcon className={style.icon} icon={faWrench} />
                        <strong>{ownWallets['request_revenue']}</strong>
                    </>),
                    balance: this.renderAmount((balanceSystemData as any).request_revenue, true),
                },
                {
                    name: (<>
                        <FontAwesomeIcon className={style.icon} icon={faWrench} />
                        <strong>{ownWallets['request_rest']}</strong>
                    </>),
                    balance: this.renderAmount((balanceSystemData as any).request_rest, true),
                },
            ]);
        return balance;
    }

    /**
     * Get selfcare balance
     */
    protected getSelfcareBalance = () => {
        const balanceClientData = this.props.balance ? this.props.balance?.clients || {} : {};
        return [balanceClientData[this.props.sessionClientId]];
    }

    /**
     * Get admin balance data items
     */
     protected onInvoicingDetailsShow = (clientId: string) => {
        this.setState({showInvoicingDetails: clientId});
    }

    protected onInvoicingDetailsClose = () => {
        this.setState({showInvoicingDetails: null});
    }

    protected getInvoicingDetails = (clientId: string) => {
        if (!this.props.invoicing || !this.props.invoicing[clientId]) {
            return [];
        }

        const invoicingData = this.props.invoicing[clientId];

        const data: {
            [nodeId: string]: {dataFee: number; requestFee: number; dataRevenue: number;}
        } = {};

        Object.keys(invoicingData.dataFee).forEach((nodeId) => {
            if (!data[nodeId]) {
                data[nodeId] = {} as any;
            }
            data[nodeId].dataFee = invoicingData.dataFee[nodeId];
        });
        Object.keys(invoicingData.requestFee).forEach((nodeId) => {
            if (!data[nodeId]) {
                data[nodeId] = {} as any;
            }
            data[nodeId].requestFee = invoicingData.requestFee[nodeId];
        });
        Object.keys(invoicingData.dataRevenue).forEach((nodeId) => {
            if (!data[nodeId]) {
                data[nodeId] = {} as any;
            }
            data[nodeId].dataRevenue = invoicingData.dataRevenue[nodeId];
        });

        const content = (nodeId: string) => (data[nodeId] ? {
            node: (
                <span>
                    {nodeId === 'count' ? <strong>Count</strong> : (this.props?.nodeIndex && this.props.nodeIndex[nodeId] ? this.props.nodeIndex[nodeId] : 'Unknown')}
                </span>
            ),
            dataFee: this.renderAmount(-data[nodeId].dataFee || 0, true),
            requestFee: this.renderAmount(-data[nodeId].requestFee || 0, true),
            dataRevenue: this.renderAmount(-data[nodeId].dataRevenue || 0, true),
        } : {})

        return Object.keys(data)
            .filter((nodeId) => nodeId !== 'count')
            .map((nodeId) => content(nodeId))
            .concat([content('count')])
    }

    protected getAdminInvoicings = () => {
        const invoicingsData = this.props.invoicing || {};

        return Object.keys(invoicingsData)
            .reduce((result, clientId) => ([
                {
                    name: <span>{(this.props.clientIndex || {})[clientId]}</span>,
                    dataFee: this.renderAmount(-invoicingsData[clientId].dataFee?.count || 0, true),
                    requestFee: this.renderAmount(-invoicingsData[clientId].requestFee?.count || 0, true),
                    dataRevenue: this.renderAmount(-invoicingsData[clientId].dataRevenue?.count || 0, true),
                    actions: (
                        <div className={style.actions}>
                            <Button
                                color={'green'}
                                onClick={() => this.onInvoicingDetailsShow(clientId)}
                                className={style.actionsButton}
                                icon={faSearch}
                                size={'small'}
                            >
                                Show details
                            </Button>
                        </div>
                    )
                },
                ...result,
            ]), []);
    }
}

export const Credits = connect(
    (state: IRootStore) => ({
        sessionType: state.api.sessionUser?.type,
        sessionClientId: state.api.sessionClientId,
        clientIndex: state.api.clientIndex,
        nodeIndex: state.api.nodeIndex,
        balance: state.api.balance,
        invoicing: state.api.invoicing,
        timezone: state.api.timezone,
    }),
    (dispatch) => ({
        getClientsIndex: (opt: ApiCallOptions) => dispatch(ApiActions.getClientsIndex(opt)),
        getNodesIndex: (opt: ApiCallOptions) => dispatch(ApiActions.getNodesIndex(opt)),
        getCreditBalance: (opt: ApiCallOptions) => dispatch(ApiActions.getCreditBalance(opt)),
        putCreditDeposit: (opt: ApiCallOptions, clientId: string, amount: number, message?: string) => dispatch(ApiActions.putCreditDeposit(opt, clientId, amount, message)),
        putCreditWithdraw: (opt: ApiCallOptions, clientId: string, amount: number, message?: string) => dispatch(ApiActions.putCreditWithdraw(opt, clientId, amount, message)),
        pushNotification: (text: string, type: NotificationType) => dispatch(AppActions.pushNotification(text, type)),
        getCreditInvoicing: (opt: ApiCallOptions, filter: InvoicingFilter) => dispatch(ApiActions.getCreditInvoicing(opt, filter)),
    }),
)(CreditsComponent);