import * as React from 'react';
import { css } from 'emotion';
import { SectionHeader } from '../components/ux/SectionHeader';
import { connect } from 'react-redux';
import { AppActions } from '../state/app';
import { routes } from './Router';
import { PropsRouteMatch, JsonValidatorType, JsonValidatorRequired } from '../utils';
import { ApiInitialState, ApiActions, ApiCallOptions } from '../state/api';
import { EntityEditor, EntityEditorInput } from '../components/content/EntityEditor';
import { FormInput } from '../components/ux/forms/FormInput';
import FormCodeInput from '../components/ux/forms/FormCodeInput';
import { DatasetEntityPatch, DatasetEntity } from '../api/structure';
import { RegExps } from '../api/regexps';
import { FormData } from '../components/ux/forms/FormProvider';
import { datasetEntityPatch, datasetEntityPost } from '../api/sanitize';
import { JSONSchema } from '../utils/JSONSchema';
import { FormValue } from '../components/ux/forms/FormValue';

const style = {};

const DatasetEditor = connect(
    (state: {api: ApiInitialState}) => ({
        data: state.api.dataset,
    }),
    (dispatch) => ({
        loadData: (opt: ApiCallOptions, id: string) => dispatch(ApiActions.getDataset(opt, id)),
        patchData: (opt: ApiCallOptions, id: string, data: Partial<DatasetEntityPatch>) => dispatch(ApiActions.patchDataset(opt, id, data)),
        saveData: (opt: ApiCallOptions, data: DatasetEntity) => dispatch(ApiActions.postDataset(opt, data)),
    }),
)(EntityEditor);

/**
 * Page
 */
class DatasetsEditComponent extends React.Component<{
    redirectBack: () => void;
    redirectTo: (to: string) => void;
    redirectLock: (locked: boolean) => void;
} & PropsRouteMatch<{id: string}>> {
    public render() {
        const isCreating = this.props.match.params.id === 'create';
        return (
            <>
                <SectionHeader>Edit Dataset</SectionHeader>
                <DatasetEditor
                    entityId={this.props.match.params.id}
                    onComplete={this.onComplete}
                    onClose={this.onClose}
                    onChange={this.onChange}
                    outputDataTransform={isCreating ? datasetEntityPost : datasetEntityPatch}
                    schema={{
                        name: {
                            required: JsonValidatorRequired.True,
                            type: JsonValidatorType.String,
                            regexp: RegExps.name,
                        },
                        queryList: {
                            required: JsonValidatorRequired.True,
                            type: JsonValidatorType.String,
                        },
                        fieldList: {
                            required: JsonValidatorRequired.True,
                            type: JsonValidatorType.String,
                        },
                    }}
                >
                    <EntityEditorInput small>
                        {isCreating ? (
                            <FormInput label={'Name'} name={'name'} />
                        ) : (
                            <div>
                                <strong>Name: </strong><FormValue name={'name'} />
                            </div>
                        )}
                    </EntityEditorInput>
                    <EntityEditorInput>
                        <FormCodeInput jsonSchema={JSONSchema} language={'json'} label={'Query list'} name={'queryList'} height={400}/>
                    </EntityEditorInput>
                    <EntityEditorInput>
                        <FormCodeInput jsonSchema={JSONSchema} language={'json'} label={'Fields list'} name={'fieldList'} height={400} />
                    </EntityEditorInput>
                </DatasetEditor>
            </>
        );
    }

    protected onComplete = () => {
        this.props.redirectLock(false);
        this.props.redirectTo(routes.datasets.path);
    }

    protected onClose = () => {
        this.props.redirectTo(routes.datasets.path);
    }

    protected onChange = (data: FormData, internal?: boolean) => {
        if (!internal) {
            this.props.redirectLock(true);
        }
    }
}

export const DatasetsEdit = connect(
    (state: {app: AppActions}) => ({}),
    (dispatch) => ({
        redirectBack: () => dispatch(AppActions.redirectBack()),
        redirectTo: (to: string) => dispatch(AppActions.redirectTo(to)),
        redirectLock: (locked: boolean) => dispatch(AppActions.redirectLock(locked)),
    }),
)(DatasetsEditComponent);