import * as React from 'react';
import { css, cx } from 'emotion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { mediaQMedium } from '../../../utils/responsivity';

const styles = {
    cell: css`
        display: table-cell;
        line-height: 20px;
        color: #4f73a1;
        @media ${mediaQMedium} {
            display: block;
        }
    `,
    name: css`
        cursor: pointer;
        user-select: none;
        padding: 2px 10px;
        position: relative;
        display: flex;
        flex-direction: row;
        white-space: nowrap;
        @media ${mediaQMedium} {
            padding: 8px 0;
        }
    `,
    sort: css`
        display: flex;
        flex-direction: column;
        color: #edf1f5;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
        span.up {
            margin-bottom: -20px;
        }
        .active {
            color: #4f73a1;
        }
    `,
    mobileHidden: css`
        @media ${mediaQMedium} {
            display: none;
        }
    `,
};

export interface TableHeadCellProps {
    text: string;
    name?: string;
    sortable?: boolean;
    sortValue?: -1 | 1 | undefined;
    onSortToggle: () => void;
    className?: string;
}

export class TableHeadCell extends React.Component<TableHeadCellProps> {
    public render() {
        const sortClass = (this.props.sortable && this.props.sortValue) ? (
            this.props.sortValue === -1 ? 'sortUp' : 'sortDown'
        ) : '';
        return (
            <div className={cx(styles.cell, {[styles.mobileHidden]: !this.props.sortable}, this.props.className)}>
                <div className={cx(styles.name, sortClass)} onClick={this.onSortClick}>
                    {this.props.sortable ? (
                        <div className={styles.sort}>
                            <span className={cx({['active']: this.props.sortValue === 1}, 'up')}><FontAwesomeIcon icon={faSortUp} /></span>
                            <span className={cx({['active']: this.props.sortValue === -1})}><FontAwesomeIcon icon={faSortDown} /></span>
                        </div>
                    ) : null}
                    {this.props.text ? this.props.text : <span>&nbsp;</span>}
                </div>
            </div>
        );
    }

    protected onSortClick = () => {
        if (this.props.sortable) {
            this.props.onSortToggle();
        }
    }
}
