import { css, cx } from 'emotion';
import * as React from 'react';
import PinInput from 'react-pin-input';
import { FormProvidedProps, withFormData } from './FormProvider';
import { FormError } from './FormError';

/**************** Style ***************/
const style = {
    label: css`
        font-weight: 500;
        margin-bottom: 0.5em;
    `,
    root: css``,
    pinWrap: css`
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: stretch;
        .pincode-input-container {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: stretch;
            justify-content: stretch;
        }
    `,
    pinInput: {
        root: {
            height: 'calc(2.25rem + 2px)',
            padding: '0',
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: '1.5',
            border: '1px solid #ced4da',
            borderRadius: '.25rem',
            transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
            margin: '0 2px',
            boxShadow: 'none',
            width: 'inherit',
            background: '#fff',
        },
        focused: {
            border: '1px solid #28a745',
            boxShadow: '0 0 0 0.2rem rgba(40,167,69,.25)',
        },
    },
};

/**************** Component props ***************/
interface ComponentProps extends Partial<FormProvidedProps> {
    name: string;
    length: number;
    label?: React.ReactChild;
    className?: string;
    onChange?: (value: string) => void;
    inputStyle?: any;
    inputFocusStyle?: any;
    submitOnEnter?: boolean;
    resetOnUnmount?: boolean;
    focusOnMount?: boolean;
}

/**************** Component ***************/
class Component extends React.Component<ComponentProps> {
    protected inputRef: React.RefObject<PinInput> = React.createRef();
    protected wrapRef: React.RefObject<HTMLDivElement> = React.createRef();

    public componentWillUnmount() {
        if (this.props.resetOnUnmount) {
            this.props.formValueRemove(this.props.name);
        }
    }

    public componentDidMount() {
        if (this.props.focusOnMount && this.inputRef.current) {
            this.inputRef.current.focus();
            if (this.wrapRef.current) {
                const inp = this.wrapRef.current.querySelector('input');
                if (inp) {
                    inp.focus();
                }
            }
        }
    }

    /**
     * Render
     */
    public render() {
        return (
            <div
                className={cx(style.root, this.props.className)}
                onKeyDown={this.onKeyDown}
            >
                {this.props.label ? <label className={style.label}>{this.props.label}</label> : null}
                <div className={style.pinWrap} ref={this.wrapRef}>
                    <PinInput
                        ref={this.inputRef}
                        length={this.props.length}
                        onChange={this.onChange}
                        type={'numeric'}
                        inputStyle={style.pinInput.root}
                        inputFocusStyle={style.pinInput.focused}
                        {...{inputMode: 'number'} as any}
                    />
                </div>
                <FormError name={this.props.name} />
            </div>
        );
    }

    /**
     * Handle on change
     */
    protected onChange = (value: string) => {
        if (this.props.onChange) {
            this.props.onChange(value);
        }
        this.props.formValueChange(this.props.name, value);
    };

    /**
     * On key pressed
     */
    protected onKeyDown = (e: any) => {
        if (e.keyCode === 13 && this.props.submitOnEnter) {
            this.props.formSubmit();
        }
    };
}

export default withFormData(Component);
