import * as React from 'react';
import { css } from 'emotion';
import { SectionHeader } from '../components/ux/SectionHeader';
import { connect } from 'react-redux';
import { ApiInitialState, ApiActions, ApiCallOptions } from '../state/api';
import { IRootStore } from '../state';
import { EntityListView } from '../components/content/EntityListView';
import { EntityListFilter } from '../api/Endpoints';
import { ClientEntity, GetEntity } from '../api/structure';
import { AppActions } from '../state/app';
import { routes } from './Router';
import { faTrash, faPencilAlt, faPlus } from '@fortawesome/free-solid-svg-icons';

const style = {};

const ClientsList = connect(
    (state: {api: ApiInitialState}) => ({
        data: state.api.clients,
    }),
    (dispatch) => ({
        loadData: (opt: ApiCallOptions, filter: EntityListFilter<GetEntity<ClientEntity>>) => dispatch(ApiActions.listClients(opt, filter)),
    }),
)(EntityListView);

/**
 * Page
 */
class ClientsComponent extends React.Component<{
    redirectTo: (to: string) => void;
    deleteClient: (opt: ApiCallOptions, id: string) => Promise<void>;
}> {
    public render() {
        return (
            <>
                <SectionHeader>Clients</SectionHeader>
                <ClientsList
                    columns={[
                        {
                            text: 'Name',
                            name: 'name',
                            sortable: true,
                            filterType: 'full',
                            size: 2,
                            defaultSort: 1,
                            filterPlaceholder: 'Filter by name',
                        }
                    ]}
                    actions={[{
                        name: 'Edit',
                        color: 'green',
                        action: this.onEdit,
                        icon: faPencilAlt,
                    }, {
                        name: 'Remove',
                        color: 'red',
                        action: this.onRemove,
                        confirm: 'Do you realy wont to remove this Client?',
                        reloadAfter: true,
                        icon: faTrash,
                    }]}
                    headerActions={[{
                        text: 'Add client',
                        icon: faPlus,
                        color: 'green',
                        action: this.onCreateNew,
                    }]}
                    mainList
                />
            </>
        );
    }

    protected onEdit = async (i: GetEntity<ClientEntity>) => {
        this.props.redirectTo(routes.clients.edit.path.replace(':id', i._id));
    }

    protected onRemove = async (i: GetEntity<ClientEntity>) => {
        return this.props.deleteClient({}, i._id);
    }

    protected onCreateNew = () => {
        this.props.redirectTo(routes.clients.edit.path.replace(':id', 'create'));
    }
}

export const Clients = connect(
    (state: IRootStore) => ({}),
    (dispatch) => ({
        redirectTo: (to: string) => dispatch(AppActions.redirectTo(to)),
        deleteClient: (opt: ApiCallOptions, id: string) => dispatch(ApiActions.deleteClient(opt, id)),
    }),
)(ClientsComponent);