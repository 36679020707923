import { replaceAll } from './string';
import { isUndefined, isObject, isString, isNumber, isDate } from './typechecks';

export function normalizeUrl(input: string): string {
    const urlParts = input.split('://');
    if (urlParts.length !== 2) {
        urlParts.unshift('https');
    }
    urlParts[1] = replaceAll(urlParts[1], '//', '/');
    return urlParts.join('://');
}


export type QueryParamComponent = undefined | string | number | boolean | QueryParams | Date;
export interface QueryParams {
    [name: string]: QueryParamComponent;
}

function getObjectPairs(input: QueryParamComponent, arr: string[] = [], prefix: string = '', depth: number = 0): string[] {
    if (isObject(input)) {
        Object.keys(input).forEach((name) => {
            if (isUndefined(input[name]) || (isString(input[name]) && !(input[name] as string).length)) {
                // nothing
            } else if (isString(input[name]) || isNumber(input[name])) {
                arr.push(`${prefix}${depth === 0 ? name : `[${name}]`}=${encodeURIComponent(input[name] as any)}`);
            } else if (isDate(input[name])) {
                arr.push(`${prefix}${depth === 0 ? name : `[${name}]`}=${encodeURIComponent(input[name].toISOString())}`);
            } else if (isObject(input[name])) {
                getObjectPairs(input[name], arr, prefix + (depth === 0 ? name : `[${name}]`), depth + 1);
            }
        });
    }

    return arr;
}

export function queryParams(params: QueryParams): string {
    if (!params || Object.keys(params).length === 0) {
        return '';
    }
    return '?' + getObjectPairs(params).join('&');
}

export function setCurrentQuery(query: {[name: string]: any}) {
    const newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + queryParams(query);
    window.history.pushState({path: newurl}, '', newurl);
}
