import * as React from 'react';
import { FormProvidedProps, withFormData } from './FormProvider';
import { FormError } from './FormError';
import { css, cx } from 'emotion';
import { EntityIndex } from '../../../api/Endpoints';
import { isObject, isNumber, isString } from '../../../utils';

const styles = {
    inputWrap: css`
        display: block;
        height: 40px;
        padding: 0;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 4px;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        position: relative;
        cursor: pointer;
    `,
    label: css`
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        font-size: 16px;
        transition: top 0.2s linear, transform 0.2s linear, font-size 0.2s linear;
        pointer-events: none;
        color: #848b91;
        &.top {
            transform: translateY(0);
            top: 2px;
            font-size: 12px;
        }
    `,
    input: css`
        display: block;
        line-height: 1.5;
        color: #495057;
        border: none;
        font-size: 16px;
        font-weight: 400;
        padding: 0;
        z-index: 0;
        width: calc(100% - 10px);
        padding: 0 15px;
        height: 40px;
        line-height: 25px;
        padding-top: 15px;
        cursor: pointer;
        transition: padding-top 0.2s linear;
        background: transparent;
        -webkit-appearance:none;
        &.top {
            padding-top: 7.5px;
        }
    `,
};

/**************** Component props ***************/
interface ComponentProps extends Partial<FormProvidedProps> {
    name: string;
    className?: string;
    inputClassName?: string;
    inputProps?: React.InputHTMLAttributes<HTMLSelectElement>;
    options: {[value: string]: string};
    label?: React.ReactChild;
    resetOnUnmount?: boolean;
    canBeEmpty?: boolean;
}

/**************** Component ***************/
class Component extends React.Component<ComponentProps> {
    protected inputRef = React.createRef<HTMLSelectElement>();

    public componentDidMount() {
        if (this.props.resetOnUnmount) {
            setImmediate(() => this.props.formValueRemove(this.props.name, true));
        }

        if (!this.props.canBeEmpty && !this.props.formGetValue(this.props.name) && this.props.options) {
            this.props.formValueChange(this.props.name, Object.keys(this.props.options)[0], true);
        }
    }

    public componentWillUnmount() {
        if (this.props.resetOnUnmount) {
            setImmediate(() => this.props.formValueRemove(this.props.name, true));
        }
    }

    public UNSAFE_componentWillReceiveProps(nextProps) {
        // options was changed
        if (this.props.options !== nextProps.options && nextProps.options) {
            const currentValue = this.props.formGetValue(nextProps.name);

            if ((currentValue && !nextProps.options.hasOwnProperty(currentValue)) || !currentValue) {
                // new options does not have current value
                if (nextProps.canBeEmpty) {
                    if (currentValue) {
                        this.props.formValueRemove(nextProps.name, true);
                    }
                } else {
                    this.props.formValueChange(nextProps.name, Object.keys(nextProps.options)[0], true);
                }
            }
        }
    }

    /**
     * Render
     */
    public render() {
        const value = this.props.formGetValue(this.props.name);
        const valueCorrect = isNumber(value) || (isString(value) && value.length);
        const labelTop = (valueCorrect || !this.props.canBeEmpty) ? true : false;

        return (
            <div
                className={this.props.className}
            >
                <div className={styles.inputWrap}>
                    {this.props.label ? <label className={cx(styles.label, {['top']: labelTop})}>{this.props.label}</label> : null}
                    <select
                        value={value}
                        onChange={this.onChange}
                        {...this.props.inputProps ? this.props.inputProps : null}
                        className={cx(styles.input, {['top']: !labelTop}, this.props.inputClassName)}
                        ref={this.inputRef}
                    >
                        {this.props.canBeEmpty ? <option key={'null-input-select'} value={null} /> : null}
                        {
                            this.props.options ? (
                                Object.keys(this.props.options).map((option) => (
                                    <option key={'input-select-' + option} value={option}>{this.props.options[option]}</option>
                                ))
                            ) : null
                        }
                    </select>
                </div>
                <FormError name={this.props.name} />
            </div>
        );
    }

    protected onChange = (e) => {
        if (e.target.value === null) {
            this.props.formValueRemove(this.props.name);
        } else {
            this.props.formValueChange(this.props.name, e.target.value);
        }
    }

    protected onBlur = (e) => {
        this.props.formValidate(this.props.name);
    }
}

export const FormSelect = withFormData(Component);
