import { css } from 'emotion';
import * as React from 'react';
import { connect } from 'react-redux';
import { GetEntity, UserEntity } from '../../api/structure';
import { ApiInitialState } from '../../state/api';
import { LoginPage } from '../content/LoginPage';
import { Ring } from '../ux/Ring';

const styles = {
    loading: css`
        position: absolute;
        top: 50%;
        left: 50%;
    `,
}

function Component(props: {token: string; sessionUser: GetEntity<UserEntity>} & React.Props<any>) {
    if (!props.token) {
        return (
            <LoginPage />
        );
    }

    if (!props.sessionUser) {
        return <Ring />;
    } else {
        return props.children;
    }
}

// Export connected component as default
export const AuthNeed = connect(
    (state: {api: ApiInitialState}) => ({
        token: state.api.token,
        sessionUser: state.api.sessionUser,
    }),
)(Component);
