import * as React from 'react';
import { css, cx } from 'emotion';
import { ContentBox } from '../components/layouts/ContentBox';
import { SectionHeader } from '../components/ux/SectionHeader';
import { LogView } from '../components/content/LogView';
import { connect } from 'react-redux';
import { IRootStore } from '../state';
import { LogItem } from '../api/structure';
import { EntityList, EntityListFilter } from '../api/Endpoints';
import { ApiActions, ApiCallOptions } from '../state/api';
import { NotificationType, AppActions } from '../state/app';

const style = {
    log: css`
        height: 750px;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
    `,
    logWraper: css`
        position: relative;
        width: 100%;
        height: 750px;
    `,
};

/**
 * Page
 */
class LogComponent extends React.Component<{
    log: EntityList<LogItem>;
    listLog: (opt: ApiCallOptions, options?: EntityListFilter<LogItem>, silent?: boolean) => Promise<EntityList<LogItem>>;
    pushNotification: (text: string, type: NotificationType) => void;
}> {
    protected reloadInterval = null;

    /**
     * Lifecycle methods
     */
    public componentDidMount() {
        this.clearTimeout();
        this.reloadInterval = setInterval(() => this.fetchData(true), 5000);
        this.fetchData();
    }

    public componentWillUnmount() {
        this.clearTimeout();
    }

    public render() {
        const data = this.props.log ? this.props.log.items : null;

        return (
            <>
                <SectionHeader>Log</SectionHeader>
                <ContentBox>
                    <div className={style.logWraper}>
                        <LogView className={style.log} logs={data}/>
                    </div>
                </ContentBox>
            </>
        );
    }

    /**
     * Clear reload timer
     */
    protected clearTimeout = () => {
        if (this.reloadInterval) {
            clearInterval(this.reloadInterval);
            this.reloadInterval = null;
        }
    }

    /**
     * Load data from api
     */
    protected fetchData = (silent?: boolean) => {
        this.props.listLog({silent}, null)
            .catch((err) => {
                this.props.pushNotification(err.message, 'red');
            });
    }
}

export const Log = connect(
    (state: IRootStore) => ({
        log: state.api.log,
    }),
    (dispatch) => ({
        listLog: (opt: ApiCallOptions, options: EntityListFilter<LogItem>) => dispatch(ApiActions.listLog(opt, options)),
        pushNotification: (text: string, type: NotificationType) => dispatch(AppActions.pushNotification(text, type)),
    }),
)(LogComponent);
