import * as React from 'react';
import { FormProvidedProps, withFormData } from './FormProvider';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Button, ButtonColors } from '../Button';

/**************** Component props ***************/
interface ComponentProps extends Partial<FormProvidedProps> {
    className?: string;
    buttonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
    icon?: IconProp;
    iconClassName?: string;
    color?: ButtonColors;
    altAction?: string
}

/**************** Component ***************/
class Component extends React.Component<ComponentProps> {
    /**
     * Render
     */
    public render() {
        return (
            <Button
                {...this.props.buttonProps ? this.props.buttonProps : null}
                className={this.props.className}
                onClick={() => this.props.formSubmit(this.props.altAction)}
                icon={this.props.icon}
                color={this.props.color}
            >
                {this.props.children}
            </Button>
        );
    }
}

export const FormSubmit = withFormData(Component);
