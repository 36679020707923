import * as React from 'react';
import { css } from 'emotion';
import { SectionHeader } from '../components/ux/SectionHeader';
import { connect } from 'react-redux';
import { AppActions, NotificationType } from '../state/app';
import { routes } from './Router';
import { PropsRouteMatch, JsonValidatorType, JsonValidatorRequired, safePromise, RequestError, HTTP_STATUS } from '../utils';
import { ApiInitialState, ApiActions, ApiCallOptions } from '../state/api';
import { EntityEditor, EntityEditorInput } from '../components/content/EntityEditor';
import { FormInput } from '../components/ux/forms/FormInput';
import { UserEntityPatch, UserEntity, OtpSessionRequest, GetEntity, UserSessionType } from '../api/structure';
import { RegExps } from '../api/regexps';
import { FormSelect } from '../components/ux/forms/FormSelect';
import { IRootStore } from '../state';
import { EntityIndex, SessionReincarnation } from '../api/Endpoints';
import { FormData } from '../components/ux/forms/FormProvider';
import { Button } from '../components/ux/Button';
import { Badge } from '../components/ux/Badge';
import { Form } from '../components/ux/forms/Form';
import { ModalOtp } from '../components/modals/ModalOtp';
import { userEntityPatch, userSelfcareEntityPatch } from '../api/sanitize';
import { ContentBox } from '../components/layouts/ContentBox';
import { ModalConfirm } from '../components/modals/ModalConfirm';

const style = {
    section2FA: css`
        display: flex;
        align-items: center;
    `,
    section2FALabel: css`
        margin-left: 8px;
    `,
    warnLabel: css`
        margin-top: 5px;
        display: block;
        font-size: 14px;
        font-style: italic;
        color: rgb(73, 80, 87);
    `,
    noAccess: css`
        text-align: center;
        margin: 30px 0;
        font-size: 22px;
    `,
    clientsList: css`
        background-color: #f1f1f1;
        padding: 15px;
        margin-bottom: 30px;
        margin-left: -20px;
        margin-right: -20px;
    `,
    clientWrapper: css`
        display: flex;
        margin-bottom: 10px;
    `,
    clientSelect: css`
        flex: 1;
        margin-right: 5px;
        max-width: 300px;
    `,
};

const UserEditor = connect(
    (state: {api: ApiInitialState}) => ({
        data: state.api.user,
    }),
    (dispatch) => ({
        loadData: (opt: ApiCallOptions, id: string) => dispatch(ApiActions.getUser(opt, id)),
        patchData: (opt: ApiCallOptions, id: string, data: Partial<UserEntityPatch>) => dispatch(ApiActions.patchUser(opt, id, data)),
        saveData: (opt: ApiCallOptions, data: UserEntity) => dispatch(ApiActions.postUser(opt, data)),
    }),
)(EntityEditor);

/**
 * Page
 */
class UserEditComponent extends React.Component<{
    clientIndex: EntityIndex
    sessionType: UserSessionType
    sessionReincarnation: SessionReincarnation
    redirectBack: () => void
    redirectTo: (to: string) => void
    redirectLock: (locked: boolean) => void
    getClientsIndex: (opt: ApiCallOptions) => Promise<void>
    putUserOtpRequest: (opt: ApiCallOptions, id: string) => Promise<OtpSessionRequest>
    putUserOtpVerify: (opt: ApiCallOptions, id: string, otpToken: string) => Promise<void>
    putUserOtpReset: (opt: ApiCallOptions, id: string) => Promise<void>
    pushNotification: (text: string, type: NotificationType) => void;
} & PropsRouteMatch<{id: string}>, {
    data: Partial<GetEntity<UserEntity>>;
    initialData: Partial<GetEntity<UserEntity>>;
    otpActivationUri: string;
    otpResetConfirmation: string;
}> {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            initialData: {},
            otpActivationUri: null,
            otpResetConfirmation: null,
        };
    }

    public componentDidMount() {
        safePromise(this.props.getClientsIndex({}));
    }

    public render() {
        const creating = this.props.match.params.id === 'create';
        const isAdmin = this.props.sessionType === 'admin';

        if (this.props.sessionReincarnation) {
            return (
                <>
                    <SectionHeader>Edit User</SectionHeader>
                    <ContentBox>
                        <p className={style.noAccess}>Can't edit user while you are reincarnated only.</p>
                    </ContentBox>
                </>
            );
        }

        return (
            <>
                <SectionHeader>Edit User</SectionHeader>
                <UserEditor
                    entityId={this.props.match.params.id}
                    onComplete={this.onComplete}
                    onClose={this.onClose}
                    onChange={this.onChange}
                    onInitialLoad={this.onInit}
                    inputDataTransform={isAdmin ? userEntityPatch : userSelfcareEntityPatch}
                    outputDataTransform={this.outputTransform}
                    transformError={this.transformError}
                    schema={isAdmin ? {
                        username: {
                            required: creating ? JsonValidatorRequired.True : JsonValidatorRequired.False,
                            type: JsonValidatorType.String,
                            regexp: RegExps.username,
                        },
                        password: {
                            required: creating ? JsonValidatorRequired.True : JsonValidatorRequired.False,
                            type: JsonValidatorType.String,
                            regexp: RegExps.password,
                        },
                        type: {
                            required: JsonValidatorRequired.True,
                            type: JsonValidatorType.Enum,
                            enum: ['admin', 'selfcare'],
                        },
                        clientsId: {
                            required: JsonValidatorRequired.False,
                            type: JsonValidatorType.Array,
                            of: {
                                type: JsonValidatorType.String,
                                regexp: RegExps.entityId,
                            }
                        },
                    } : {
                        passwordOld: {
                            required: JsonValidatorRequired.True,
                            type: JsonValidatorType.String,
                            regexp: RegExps.password,
                        },
                        password: {
                            required: JsonValidatorRequired.True,
                            type: JsonValidatorType.String,
                            regexp: RegExps.password,
                        },
                    }}
                >
                    {isAdmin ? (
                        <EntityEditorInput small>
                            <FormInput label={'Username'} name={'username'} />
                        </EntityEditorInput>
                    ) : null}
                    {!isAdmin ? (
                        <EntityEditorInput small>
                            <FormInput type={'password'} label={'Old Password'} name={'passwordOld'} />
                        </EntityEditorInput>
                    ) : null}
                    <EntityEditorInput small>
                        <FormInput type={'password'} label={'Password'} name={'password'} />
                    </EntityEditorInput>
                    {isAdmin ? (
                        <EntityEditorInput small>
                            <FormSelect label={'Type'} name={'type'} options={{admin: 'Admin', selfcare: 'Selfcare'}}/>
                        </EntityEditorInput>
                    ) : null}
                    {isAdmin && this.state.data.type === 'selfcare' ? (
                        <div className={style.clientsList}>
                            {(this.state.data.clientsId || []).map((clientId, index) => (
                                <div className={style.clientWrapper} key={`client-${index}`}>
                                    <FormSelect className={style.clientSelect} canBeEmpty label={'Client'} name={`clientsId[${index}]`} options={this.props.clientIndex}/>
                                    <Button
                                        color="red"
                                        onClick={() => this.removeClient(index)}
                                    >
                                        Remove
                                    </Button>
                                </div>
                            ))}
                            <div>
                                <Button
                                    color="green"
                                    onClick={this.addClient}
                                >
                                    Add client
                                </Button>
                            </div>
                        </div>
                    ) : null}

                    {!creating ? (
                        <>
                            <EntityEditorInput>
                                <label className={''}>2FA Authentication</label>
                                <div className={style.section2FA}>
                                    <Button
                                        color="red"
                                        onClick={this.requestOtpConfirmationOpen}
                                    >
                                        Request OTP
                                    </Button>
                                    {
                                        (this.state.initialData && !this.state.initialData.otpActivated) ? (
                                            <label className={style.section2FALabel}>Not active!</label>
                                        ) : null
                                    }
                                </div>
                                <label className={style.warnLabel}>
                                    Be careful, when you click on this button, previous OTP configuration will be changed.
                                </label>
                                {isAdmin ? (
                                    <>
                                        <div className={style.section2FA}>
                                            <Button
                                                color="yellow"
                                                onClick={this.resetOtpConfirmationOpen}
                                            >
                                                Reset OTP
                                            </Button>
                                        </div>
                                        <label className={style.warnLabel}>
                                            This will delete OTP token for user. After login attemp he will be asked for OTP again.
                                        </label>
                                    </>
                                ) : null}
                            </EntityEditorInput>
                        </>
                    ) : null}
                </UserEditor>

                <Form action={this.otpFinish}>
                    <ModalOtp
                        active={!!this.state.otpActivationUri}
                        qrcode={this.state.otpActivationUri}
                        title={'Setup Two factor authorization'}
                        confirmText={'Save'}
                    />
                </Form>

                <ModalConfirm
                    onClose={this.resetOtpConfirmationClose}
                    onConfirm={this.resetOtpConfirmationConfirm}
                    active={!!this.state.otpResetConfirmation}
                    text={`Do you really want to reset OTP for user?`}
                    confirmText={'Delete'}
                    confirmColor={'red'}
                />
            </>
        );
    }

    protected addClient = () => {
        this.setState({data: {
            ...this.state.data,
            clientsId: [
                ...this.state?.data?.clientsId || [],
                '',
            ]
        }});
    }

    protected removeClient = (index: number) => {
        const clientsId = (this.state?.data?.clientsId || [])
        clientsId.splice(index, 1)
        this.setState({data: {
            ...this.state.data,
            clientsId,
        }});
    }

    protected resetOtpConfirmationOpen = () => {
        this.setState({otpResetConfirmation: this.props.match.params.id});
    }

    protected resetOtpConfirmationClose = () => {
        this.setState({otpResetConfirmation: null});
    }

    protected resetOtpConfirmationConfirm = async () => {
        try {
            const otp = await this.props.putUserOtpReset({}, this.props.match.params.id);
            this.setState({otpResetConfirmation: null});
        } catch (err) {
            this.props.pushNotification(err.message, 'red');
        }
    }

    protected requestOtpConfirmationOpen = async () => {
        try {
            const otp = await this.props.putUserOtpRequest({}, this.props.match.params.id);
            this.setState({otpActivationUri: otp.uri});
        } catch (err) {
            this.props.pushNotification(err.message, 'red');
        }
    }

    protected otpFinish = async (data: FormData) => {
        this.setState({otpActivationUri: null});
        return this.props.putUserOtpVerify({}, this.props.match.params.id, data.otpToken)
            .catch((err) => {
                this.props.pushNotification(err.message, 'red');
            });
    }

    protected onComplete = () => {
        const isAdmin = this.props.sessionType === 'admin';
        this.props.redirectLock(false);
        if (isAdmin) {
            this.props.redirectTo(routes.users.path);
        } else {
            this.props.redirectBack();
        }
    }

    protected onClose = () => {
        const isAdmin = this.props.sessionType === 'admin';
        if (isAdmin) {
            this.props.redirectTo(routes.users.path);
        } else {
            this.props.redirectBack();
        }
    }

    protected onChange = (data: FormData, internal?: boolean) => {
        if (data.type !== 'selfcare') {
            delete data.clientsId;
        }

        this.setState({data});
        if (!internal) {
            this.props.redirectLock(true);
        }
    }

    protected onInit = (data: FormData) => {
        this.setState({
            data,
            initialData: data,
        });
    }

    protected transformError = (e) => {
        if (e instanceof RequestError) {
            if (e.status === HTTP_STATUS.FORBIDEN) {
                throw new Error('Password was incorrect.');
            }
        }
    }

    protected outputTransform = (data: FormData) => {
        if (data.clientsId) {
            data.clientsId = data.clientsId.filter(Boolean)
        }
        return data
    }
}

export const UsersEdit = connect(
    (state: IRootStore) => ({
        sessionType: state.api.sessionUser?.type,
        clientIndex: state.api.clientIndex,
        sessionReincarnation: state.api.sessionReincarnation,
    }),
    (dispatch) => ({
        redirectBack: () => dispatch(AppActions.redirectBack()),
        redirectTo: (to: string) => dispatch(AppActions.redirectTo(to)),
        redirectLock: (locked: boolean) => dispatch(AppActions.redirectLock(locked)),
        getClientsIndex: (opt: ApiCallOptions) => dispatch(ApiActions.getClientsIndex(opt)),
        putUserOtpRequest: (opt: ApiCallOptions, id: string) => dispatch(ApiActions.putUserOtpRequest(opt, id)),
        putUserOtpVerify: (opt: ApiCallOptions, id: string, otpToken: string) => dispatch(ApiActions.putUserOtpVerify(opt, id, otpToken)),
        putUserOtpReset: (opt: ApiCallOptions, id: string) => dispatch(ApiActions.putUserOtpReset(opt, id)),
        pushNotification: (text: string, type: NotificationType) => dispatch(AppActions.pushNotification(text, type)),
    }),
)(UserEditComponent);