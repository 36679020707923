import { css, cx } from 'emotion';
import * as React from 'react';
import { Notification, NotificationType } from '../../state/app';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

/**************** Style ***************/
const style = {
    root: css`
        position: fixed;
        right: 0.5em;
        width: 300px;
        top: 0.5em;
        z-index: 500;
    `,
    alert: css`
        width: 300px;
        border-radius: 10px;
        padding: 15px;
        text-align: left;
        box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.6);
        margin-bottom: 0.3em;
        display: flex;
        flex-direction: row;
        &.red {
            color: #fff;
            background-color: #dc3545;
            border-color: #dc3545;
        }
        &.green {
            color: #fff;
            background-color: #28a745;
            border-color: #28a745;
        }
        &.yellow {
            color: #212529;
            background-color: #ffc107;
            border-color: #ffc107;
        }
        &.gray {
            color: #fff;
            background-color: #6c757d;
            border-color: #6c757d;
        }
    `,
    alertContent: css`
        flex: 1;
    `,
    alertClose: css`
        flex-basis: 30px;
        cursor: pointer;
    `,
};

export function Alert(props: {color?: NotificationType; text: string; onDismiss: () => void; }) {
    return (
        <div className={cx(style.alert, props.color ? props.color : 'red')}>
            <div className={style.alertContent}>{props.text}</div>
            {props.onDismiss ? (<a className={style.alertClose} onClick={props.onDismiss}>
                <FontAwesomeIcon icon={faTimes} />
            </a>) : null}
        </div>
    );
}

/**************** Component ***************/
export class NotificationsComponent extends React.Component<{
    notifications: Notification[];
    onDismiss: (id: string) => void;
}> {
    public render() {
        return (
            <div className={style.root}>
                {
                    [...this.props.notifications].reverse().map((item) =>
                        <Alert
                            text={item.text}
                            key={item.timestamp.getTime()}
                            onDismiss={this.props.onDismiss.bind(this, item.id)}
                            color={item.color as NotificationType}
                        />
                    )
                }
            </div>
        );
    }
}

