import * as React from 'react';
import { connect } from 'react-redux';
import { AppInitialState } from '../../state/app';
import { LoadingOverlay } from '../ux/LoadingOverlay';

function Component(props: any) {
    return (
        <LoadingOverlay isLoading={props.waitingFor && props.waitingFor.length}/>
    );
}

// Export connected component as default
export const Loading = connect(
    (state: {app: AppInitialState}) => ({
        waitingFor: state.app.waitingFor,
    }),
)(Component);
