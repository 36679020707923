import * as React from 'react';
import { css } from 'emotion';
import { SectionHeader } from '../components/ux/SectionHeader';
import { connect } from 'react-redux';
import { AppActions, NotificationType } from '../state/app';
import { PropsRouteMatch, safePromise } from '../utils';
import { ApiActions, ApiCallOptions } from '../state/api';
import { ConnectorEntity, GetEntity, UserSessionType } from '../api/structure';
import { mediaQSmall } from '../utils/responsivity';
import { IRootStore } from '../state';
import { Table } from '../components/ux/table/Table';
import { ContentBox } from '../components/layouts/ContentBox';
import { Button } from '../components/ux/Button';
import { faPlus, faPencilAlt, faTrash, faCloudUploadAlt, faTerminal, faWrench, faCheck, faTimesCircle, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Badge } from '../components/ux/Badge';
import { Modal } from '../components/modals/Modal';
import { ModalConfirm } from '../components/modals/ModalConfirm';
import { ConnectorForm } from '../components/forms/connectors/ConnectorForm';
import { FormSubmit } from '../components/ux/forms/FormSubmit';
import { connectorEntityPatch } from '../api/sanitize';
import { FormError } from '../components/ux/forms/FormError';
import { ConnectorDetails } from '../components/content/ConnectorDetails';

const style = {
    header: css`
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
        margin-bottom: 15px;
    `,
    button: css`
        display: inline-block;
    `,
    cellRow: css`
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-end;
    `,
    cellRowLeft: css`
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
    `,
    cellRowItem: css`
        margin-left: 8px;
        @media ${mediaQSmall} {
            margin-left: 0px;
            margin-right: 8px;
        }
    `,
    cellRowItemLeft: css`
        margin-right: 8px;
    `,

    connectorModalContent: css`
        padding-bottom: 80px;
    `,
    modalFooter: css`
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background: #EEE;
        border-top: 1px solid #ccc;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        button {
            margin-right: 10px;
        }
    `,
    modalError: css`
        margin-right: 10px;
    `,
    clientConnectorText: css`
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        color: #666;
    `,
};

/**
 * Page
 */
class ConnectorsEditComponent extends React.Component<{
    sessionType: UserSessionType;
    clientConnectors: {[id: string]: GetEntity<ConnectorEntity>};
    redirectTo: (to: string) => void;
    redirectLock: (locked: boolean) => void;
    getClientConnectors: (opt: ApiCallOptions, clientId: string) => Promise<void>;
    postClientConnector: (opt: ApiCallOptions, clientId: string, data: Partial<ConnectorEntity>) => Promise<void>;
    patchClientConnector: (opt: ApiCallOptions, clientId: string, connectorId: string, data: Partial<ConnectorEntity>) => Promise<void>;
    deleteClientConnector: (opt: ApiCallOptions, clientId: string, connectorId: string) => Promise<void>;
    postClientConnectorActivate: (opt: ApiCallOptions, clientId: string, connectorId: string) => Promise<void>;
    postClientConnectorDeactivate: (opt: ApiCallOptions, clientId: string, connectorId: string) => Promise<void>;
    pushNotification: (text: string, type: NotificationType) => void;
} & PropsRouteMatch<{id: string}>, {
    editConnector: string;
    editConnectorData: Partial<ConnectorEntity>;
    showConnector: string;
    showConnectorData: Partial<ConnectorEntity>;
    removeConnector: string;
    activateConnector: string;
    deactivateConnector: string;
}> {
    public readonly state = {
        editConnector: null,
        editConnectorData: null,
        showConnector: null,
        showConnectorData: null,
        removeConnector: null,
        activateConnector: null,
        deactivateConnector: null,
    };

    public componentDidMount() {
        this.loadAdditionalData();
    }

    public render() {
        const isAdmin = this.props.sessionType === 'admin';

        let connectors = [];
        if (this.props.match.params.id !== 'create' && this.props.clientConnectors) {
            connectors = Object.keys(this.props.clientConnectors)
                .map((id) => {
                    const conn = this.props.clientConnectors[id];
                    return this.renderConnectorItem(conn);
                });
        }
        return (
            <>
                <SectionHeader>{isAdmin ? 'Edit Client Connectors' : 'Connectors'}</SectionHeader>
                <ContentBox>
                    {isAdmin ? (
                        <div className={style.header}>
                            <Button onClick={this.onCreateNewConnector} className={style.button} color={'green'} icon={faPlus}>Create new connector</Button>
                        </div>
                    ) : null}
                    <Table
                        columns={[
                            {
                                text: '',
                                name: 'name',
                                size: 1,
                                mobileBlock: true,
                            },
                            {
                                text: '',
                                name: 'services',
                                size: 0,
                                mobileBlock: true,
                            },
                            {
                                text: '',
                                name: 'actions',
                                size: 0,
                                mobileBlock: true,
                            }
                        ]}
                        data={connectors}
                    />
                </ContentBox>
                <Modal
                    size={'medium'}
                    active={!!this.state.editConnector}
                >
                    <div className={style.connectorModalContent}>
                        <ConnectorForm
                            data={this.state.editConnectorData}
                            onSubmit={this.onConnectorSubmit}
                            isAdmin={isAdmin}
                        >
                            <div className={style.modalFooter}>
                                <span className={style.modalError}><FormError /></span>
                                <FormSubmit color="green">Save</FormSubmit>
                                <Button onClick={this.onConnectorEditClose} color="gray">Close</Button>
                            </div>
                        </ConnectorForm>
                    </div>
                </Modal>
                <Modal
                    size={'small'}
                    active={!!this.state.showConnector}
                    buttons={[{
                        text: 'Close',
                        color: 'gray',
                        action: this.onConnectorShowClose
                    }]}
                >
                    <ConnectorDetails data={this.state.showConnectorData}/>
                </Modal>
                <ModalConfirm
                    onClose={this.onConnectorRemoveClose}
                    onConfirm={this.onConnectorRemoveConfirm}
                    active={this.state.removeConnector}
                    text={'Do you realy want to remove this connector?'}
                    confirmText={'Remove'}
                    confirmColor={'red'}
                    confirmIcon={faTrash}
                />
                <ModalConfirm
                    onClose={this.onConnectorActivateDeactivateClose}
                    onConfirm={this.onConnectorActivateConfirm}
                    active={this.state.activateConnector}
                    text={'Do you realy want to activate this connector?'}
                    confirmText={'Activate'}
                />
                <ModalConfirm
                    onClose={this.onConnectorActivateDeactivateClose}
                    onConfirm={this.onConnectorDeactivateConfirm}
                    active={this.state.deactivateConnector}
                    text={'Do you realy want to deactivate this connector?'}
                    confirmText={'Deactivate'}
                    confirmColor={'red'}
                    confirmIcon={faTimesCircle}
                />
            </>
        );
    }

    /**
     * Load additional data about client
     */
    protected loadAdditionalData = () => {
        safePromise(this.props.getClientConnectors({}, this.props.match.params.id));
    }

    /**
     * Edit connector methods
     */
    protected onConnectorEdit = (id: string) => {
        this.setState({
            editConnectorData: connectorEntityPatch(this.props.clientConnectors[id]),
            editConnector: id,
        });
    }

    protected onCreateNewConnector = () => {
        this.setState({
            editConnectorData: {} as any,
            editConnector: 'create',
        });
    }

    protected onConnectorEditClose = () => {
        this.setState({
            editConnectorData: null,
            editConnector: null,
        });
    }

    protected onConnectorSubmit = async (data: ConnectorEntity) => {
        const outgoingData = connectorEntityPatch(data);
        if (this.state.editConnector === 'create') {
            await this.props.postClientConnector({}, this.props.match.params.id, outgoingData);
        } else {
            await this.props.patchClientConnector({}, this.props.match.params.id, this.state.editConnector, outgoingData);
        }
        this.setState({
            editConnectorData: null,
            editConnector: null,
        });
    }

    /**
     * Show connector methods
     */
    protected onConnectorShow = (id: string) => {
        this.setState({
            showConnectorData: connectorEntityPatch(this.props.clientConnectors[id]),
            showConnector: id,
        });
    }

    protected onConnectorShowClose = () => {
        this.setState({
            showConnectorData: null,
            showConnector: null,
        });
    }

    /**
     * Activate connector methods
     */
    protected onConnectorDeactivate = (id: string) => {
        this.setState({deactivateConnector: id});
    }

    protected onConnectorActivate = (id: string) => {
        this.setState({activateConnector: id});
    }

    protected onConnectorDeactivateConfirm = async () => {
        await (this.props.postClientConnectorDeactivate({}, this.props.match.params.id, this.state.deactivateConnector)
            .catch((err) => {
                this.props.pushNotification(err.message, 'red');
            }));
        this.setState({deactivateConnector: null});
    }

    protected onConnectorActivateConfirm = async () => {
        await (this.props.postClientConnectorActivate({}, this.props.match.params.id, this.state.activateConnector)
            .catch((err) => {
                this.props.pushNotification(err.message, 'red');
            }));
        this.setState({activateConnector: null});
    }

    protected onConnectorActivateDeactivateClose = () => {
        this.setState({
            activateConnector: null,
            deactivateConnector: null,
        });
    }

    /**
     * Remove connector methods
     */
    protected onConnectorRemove = (id: string) => {
        this.setState({removeConnector: id});
    }

    protected onConnectorRemoveConfirm = async () => {
        await (this.props.deleteClientConnector({}, this.props.match.params.id, this.state.removeConnector)
            .catch((err) => {
                this.props.pushNotification(err.message, 'red');
            }));
        this.setState({removeConnector: null});
    }

    protected onConnectorRemoveClose = () => {
        this.setState({removeConnector: null});
    }

    /**
     * Table
     */
    protected renderConnectorItem = (item: GetEntity<ConnectorEntity>) => {
        const isAdmin = this.props.sessionType === 'admin';

        const fetchColor = item.interfaceMetadata?.fetchInterface?.enabled ? (item.fetchInterface ? 'green' : 'red') : 'yellow';
        const commandColor = item.interfaceMetadata?.commandInterface?.enabled ? (item.commandInterface ? 'green' : 'red') : 'yellow';
        const serviceColor = item.interfaceMetadata?.serviceInterface?.enabled ? (item.serviceInterface ? 'green' : 'red') : 'yellow';

        return {
            name: (
                <div className={style.cellRowLeft}>
                    <Badge className={style.cellRowItemLeft} color={item.active ? 'green' : 'red'}>{item.active ? 'Active' : 'Inactive'}</Badge>
                    <div>{item.name}</div>
                </div>
            ),
            services: (
                <div className={style.cellRow}>
                    {item.interfaceMetadata?.fetchInterface?.enabled || isAdmin ? <Badge icon={faCloudUploadAlt} className={style.cellRowItem} color={fetchColor}>Fetch</Badge> : null}
                    {item.interfaceMetadata?.commandInterface?.enabled || isAdmin ? <Badge icon={faTerminal} className={style.cellRowItem} color={commandColor}>Command</Badge> : null}
                    {item.interfaceMetadata?.serviceInterface?.enabled || isAdmin ? <Badge icon={faWrench} className={style.cellRowItem} color={serviceColor}>Service</Badge> : null}
                </div>
            ),
            actions: (
                <div className={style.cellRow}>
                    {isAdmin ? (
                        item.active ? (
                            <Button
                                icon={faTimesCircle}
                                size={'small'}
                                className={style.cellRowItem}
                                color={'red'}
                                onClick={this.onConnectorDeactivate.bind(this, item._id)}
                            >
                                Deactivate
                            </Button>
                        ) : (
                            <Button
                                icon={faCheck}
                                size={'small'}
                                className={style.cellRowItem}
                                color={'green'}
                                onClick={this.onConnectorActivate.bind(this, item._id)}
                            >
                                Activate
                            </Button>
                        )
                    ) : null}
                    <Button
                        icon={faSearch}
                        size={'small'}
                        className={style.cellRowItem}
                        color={'green'}
                        onClick={this.onConnectorShow.bind(this, item._id)}
                    >
                        Show details
                    </Button>
                    <Button
                        icon={faPencilAlt}
                        size={'small'}
                        className={style.cellRowItem}
                        color={'green'}
                        onClick={this.onConnectorEdit.bind(this, item._id)}
                    >
                        Edit
                    </Button>
                    {isAdmin ? <Button
                        icon={faTrash}
                        size={'small'}
                        className={style.cellRowItem}
                        color={'red'}
                        onClick={this.onConnectorRemove.bind(this, item._id)}
                    >
                        Remove
                    </Button> : null}
                </div>
            ),
        };
    }
}

export const ConnectorsEdit = connect(
    (state: IRootStore) => ({
        sessionType: state.api.sessionUser?.type,
        clientConnectors: state.api.clientConnectors,
    }),
    (dispatch) => ({
        redirectTo: (to: string) => dispatch(AppActions.redirectTo(to)),
        redirectLock: (locked: boolean) => dispatch(AppActions.redirectLock(locked)),
        getClientConnectors: (opt: ApiCallOptions, clientId: string) => dispatch(ApiActions.getClientConnectors(opt, clientId)),
        postClientConnector: (opt: ApiCallOptions, clientId: string, data: Partial<ConnectorEntity>) => dispatch(ApiActions.postClientConnector(opt, clientId, data)),
        patchClientConnector: (opt: ApiCallOptions, clientId: string, connectorId: string, data: Partial<ConnectorEntity>) => dispatch(ApiActions.patchClientConnector(opt, clientId, connectorId, data)),
        deleteClientConnector: (opt: ApiCallOptions, clientId: string, connectorId: string) => dispatch(ApiActions.deleteClientConnector(opt, clientId, connectorId)),
        postClientConnectorActivate: (opt: ApiCallOptions, clientId: string, connectorId: string) => dispatch(ApiActions.postClientConnectorActivate(opt, clientId, connectorId)),
        postClientConnectorDeactivate: (opt: ApiCallOptions, clientId: string, connectorId: string) => dispatch(ApiActions.postClientConnectorDeactivate(opt, clientId, connectorId)),
        pushNotification: (text: string, type: NotificationType) => dispatch(AppActions.pushNotification(text, type)),
    }),
)(ConnectorsEditComponent);